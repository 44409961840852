import React from 'react'
import PropTypes from 'prop-types'
import {
  TextField, MenuItem, FormControl,
  FormLabel, FormControlLabel,
  FormGroup,
  Checkbox, InputLabel, Select, Button,
  RadioGroup, Radio
} from '@mui/material'
import { useField, useFormikContext } from 'formik'
// import GooglePlaceAutocomplete from './GooglePlaceAutocomplete.js'

export const FormTextField = ({ name, callback, ...otherProps }) => {
  const [field, meta] = useField(name)
  const { setFieldValue } = useFormikContext()

  const handleChange = (event) => {
    setFieldValue(name, event.target.value)
    if (callback) { callback(event) }
  }

  const configTextField = {
    fullWidth: true,
    onChange: handleChange,
    sx: { margin: '5px' },
    ...field,
    ...otherProps
  }

  if (meta && meta.touched && meta.error) {
    configTextField.error = true
    configTextField.helperText = meta.error
  }

  return <TextField {...configTextField} />
}

FormTextField.propTypes = {
  name: PropTypes.string,
  callback: PropTypes.func
}

export const FormSelectField = ({ name, options, label, onChange, ...otherProps }) => {
  const { setFieldValue } = useFormikContext()
  const [field, meta] = useField(name)

  const handleChange = (event) => {
    setFieldValue(name, event.target.value)
    if (onChange) { onChange(event) }
  }

  const configSelect = {
    fullWidth: true,
    onChange: handleChange,
    // sx: { margin: '5px' },
    ...field,
    ...otherProps
  }

  const controlSelect = {
    variant: 'standard',
    fullWidth: true,
    sx: { margin: '5px' },
  }

  // meta object containes
  // submitForm, isSubmitting, touched, errors
  if (meta && meta.touched && meta.error) {
    configSelect.error = true
    configSelect.helperText = meta.error
  }

  return (
    <FormControl {...controlSelect}>
      {label ? <InputLabel>{label}</InputLabel> : ''}
      <Select {...configSelect} onChange={handleChange}>
        {Object.keys(options).map((item) => (
          <MenuItem key={item} value={item}>
            {options[item]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

FormSelectField.propTypes = {
  name: PropTypes.string,
  options: PropTypes.object,
  label: PropTypes.string,
  onChange: PropTypes.func
}

export const FormCheckbox = ({ name, label, legend, callback, ...otherProps }) => {
  const { setFieldValue } = useFormikContext()
  const [field, meta] = useField(name)

  const handleChange = (event) => {
    setFieldValue(name, event.target.checked)
    if (callback) { callback(event) }
  }
  // props config for Checkbox
  const configCheckbox = {
    onChange: handleChange,
    sx: { margin: '5px' },
    checked: field.value || false,
    defaultValue: meta.initialValue || false,
    ...field,
    ...otherProps
  }

  // props config for FormControl
  const configFormControl = {}

  if (meta && meta.touched && meta.error) {
    configFormControl.error = true
  }

  return (
    <FormControl {...configFormControl}>
      {legend ? <FormLabel component='legend'>{legend}</FormLabel> : ''}
      <FormGroup>
        <FormControlLabel
          control={<Checkbox {...configCheckbox} onChange={(e) => handleChange(e)} />}
          label={label}
        />
      </FormGroup>
    </FormControl>
  )
}

FormCheckbox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  legend: PropTypes.string,
  callback: PropTypes.func
}

export const FormSubmitButton = ({ children, ...otherProps }) => {
  const { submitForm, isSubmitting } = useFormikContext()

  const handleSubmit = () => {
    submitForm()
  }

  const configButton = {
    fullWidth: true,
    color: 'primary',
    variant: 'contained',
    onClick: handleSubmit,
    disabled: isSubmitting,
    ...otherProps
  }

  return <Button {...configButton}>{children}</Button>
}

FormSubmitButton.propTypes = {
  children: PropTypes.any
}

export const FormRadioField = ({ name, options, label, onChange, ...otherProps }) => {
  const { setFieldValue } = useFormikContext()
  const [field, meta] = useField(name)

  const handleChange = (event) => {
    setFieldValue(name, event.target.value)
    if (onChange) { onChange(event) }
  }

  const configSelect = {
    fullWidth: true,
    onChange: handleChange,
    ...field,
    ...otherProps
  }

  // meta object containes
  // submitForm, isSubmitting, touched, errors
  if (meta && meta.touched && meta.error) {
    configSelect.error = true
    configSelect.helperText = meta.error
  }

  return (
    <FormControl variant='standard'>
      {label ? <FormLabel>{label}</FormLabel> : ''}
      <RadioGroup {...configSelect} onChange={handleChange}>
        {Object.keys(options).map((item) => (
          <FormControlLabel key={item} value={item} label={options[item]} control={<Radio />} />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

FormRadioField.propTypes = {
  name: PropTypes.string,
  options: PropTypes.object,
  label: PropTypes.string,
  onChange: PropTypes.func
}

export const FormPhotos = ({ name, photos }) => {
  return (
    <>
      <br /><br />
      <FormLabel>Photos {name} </FormLabel>
      <br /><br />
      {Object.keys(photos).map((key) => {
        return (<div key={key}><img src={photos[key]} /></div>)
      })}
    </>
  )
}

FormPhotos.propTypes = {
  name: PropTypes.string,
  photos: PropTypes.array
}

// export const FormGeosuggest = ({ name, locationCallback, ...otherProps }) => {
//   const { setFieldValue } = useFormikContext()
//   const [field, meta] = useField(name)
//   // console.log(field)

//   const configTextField = {
//     fullWidth: true,
//     sx: { margin: '5px' },
//     ...field,
//     ...otherProps,
//     meta
//   }
//   if (field.value !== '') {
//     configTextField.defaultValue = field.value
//   }

//   const geocodeByPlaceId = (placeId) => {
//     const geocoder = new window.google.maps.Geocoder()
//     const OK = window.google.maps.GeocoderStatus.OK

//     return new Promise(function (resolve, reject) {
//       geocoder.geocode({ placeId: placeId }, function (results, status) {
//         if (status !== OK) {
//           reject(status)
//         }
//         resolve(results)
//       })
//     })
//   }

//   const handleCallback = (place) => {
//     if (place && place.description) { setFieldValue(name, place.description) }
//     if (place && place.place_id) {
//       geocodeByPlaceId(place.place_id).then((res) => {
//         const loc = {}
//         if (res[0] && res[0].geometry && res[0].geometry.location) {
//           loc.lat = res[0].geometry.location.lat()
//           loc.lng = res[0].geometry.location.lng()
//         }
//         if (locationCallback) {
//           locationCallback(loc, setFieldValue)
//         }
//       })
//     }
//   }

//   if (meta && meta.touched && meta.error) {
//     configTextField.error = true
//     configTextField.helperText = meta.error
//   }

//   return (
//     <GooglePlaceAutocomplete {...configTextField} callback={handleCallback} />
//   )
// }

// FormGeosuggest.propTypes = {
//   name: PropTypes.string,
//   locationCallback: PropTypes.func
// }
