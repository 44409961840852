let config = {}
switch (process.env.NODE_ENV) {
  case 'development': {
    config = require('./config/config.development.json')
    break
  }
  case 'staging': {
    config = require('./config/config.staging.json')
    break
  }
  case 'production': {
    config = require('./config/config.production.json')
    break
  }
  default: {
    config = require('./config/config.development.json')
  }
}
export default config
