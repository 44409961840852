import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import {
  withScriptjs, withGoogleMap,
  GoogleMap, Marker, Polyline
} from 'react-google-maps'
import HeatmapLayer from
  'react-google-maps/lib/components/visualization/HeatmapLayer'

const ReactGoogleMap = compose(
  withScriptjs,
  withGoogleMap
)((props) => {
  let markers = []; let polyline; let heatmap
  if (props.isMarkerShown && props.markers) {
    markers = props.markers
      .map((m, i) => {
        return <Marker key={i} position={{
          lat: m.lat,
          lng: m.lng || m.lon
        }} />
      })
  }
  if (props.isPolyline && props.polyline) {
    const mappedPolyline = props.polyline.map((m) => {
      return {
        lat: m.lat,
        lng: m.lng || m.lon
      }
    })
    polyline = <Polyline path={mappedPolyline} />
  }
  if (props.isHeatmap && props.heatmap) {
    const mappedHeatmap = props.heatmap.map((m) => {
      const lng = m.lon ? m.lon : m.lng
      // eslint-disable-next-line
      return new google.maps.LatLng(m.lat, lng)
    })
    heatmap = <HeatmapLayer data={mappedHeatmap} />
  }
  let defaultCenter = { lat: 1.2851532, lng: 103.8282369 }
  if (props.markers && props.markers[props.markers.length - 1] &&
    props.markers[props.markers.length - 1].lat) {
    if (props.markers[props.markers.length - 1].lng || props.markers[props.markers.length - 1].lon) {
      defaultCenter = {
        lat: props.markers[props.markers.length - 1].lat,
        lng: props.markers[props.markers.length - 1].lng ||
        props.markers[props.markers.length - 1].lon
      }
    }
  }
  if (props.polyline && props.polyline[props.polyline.length - 1] &&
    props.polyline[props.polyline.length - 1].lat) {
    if (props.polyline[props.polyline.length - 1].lng || props.polyline[props.polyline.length - 1].lon) {
      defaultCenter = {
        lat: props.polyline[props.polyline.length - 1].lat,
        lng: props.polyline[props.polyline.length - 1].lng ||
        props.polyline[props.polyline.length - 1].lon
      }
    }
  }
  if (props.heatmap && props.heatmap[props.heatmap.length - 1] && props.heatmap[props.heatmap.length - 1].lat) {
    if (props.heatmap[props.heatmap.length - 1].lng || props.heatmap[props.heatmap.length - 1].lon) {
      defaultCenter = {
        lat: props.heatmap[props.heatmap.length - 1].lat,
        lng: props.heatmap[props.heatmap.length - 1].lng ||
        props.heatmap[props.heatmap.length - 1].lon
      }
    }
  }
  return (
    <GoogleMap
      defaultZoom={13}
      defaultCenter={defaultCenter}
      center={defaultCenter}
    >
      {heatmap}
      {polyline}
      {markers}
    </GoogleMap>
  )
})

class Map extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isHeatmap: true,
      isMarkerShown: true,
      isPolyline: true
    }
  }

  componentDidMount () {
    if (this.props.isHeatmap) {
      this.setMapType(this.props.isHeatmap, 'isHeatmap')
    }
    if (this.props.isMarkerShown) {
      this.setMapType(this.props.isMarkerShown, 'isMarkerShown')
    }
    if (this.props.isPolyline) {
      this.setMapType(this.props.isPolyline, 'isPolyline')
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.isHeatmap !== prevProps.isHeatmap) {
      this.setMapType(this.props.isHeatmap, 'isHeatmap')
    }
    if (this.props.isMarkerShown !== prevProps.isMarkerShown) {
      this.setMapType(this.props.isMarkerShown, 'isMarkerShown')
    }
    if (this.props.isPolyline !== prevProps.isPolyline) {
      this.setMapType(this.props.isPolyline, 'isPolyline')
    }
  }

  setMapType = (prop, name) => {
    const oldState = this.state
    oldState[name] = prop
    this.setState(oldState)
  }

  render () {
    let containerElement = (
      <div style={{ height: '400px', width: '100%' }} />
    )
    if (this.props.height) {
      containerElement = (
        <div style={{
          height: `${this.props.height}px`,
          width: '100%'
        }} />
      )
    }
    const polylineStyle = { cursor: 'pointer' }
    const heatmapStyle = { cursor: 'pointer' }
    const markerStyle = { cursor: 'pointer' }
    if (this.state.isPolyline) { polylineStyle.fontWeight = 'bold' }
    if (this.state.isHeatmap) { heatmapStyle.fontWeight = 'bold' }
    if (this.state.isMarkerShown) { markerStyle.fontWeight = 'bold' }
    return (
      <div>
        <ReactGoogleMap
          isPolyline={this.state.isPolyline}
          polyline={this.props.markers}
          markers={this.props.markers}
          isMarkerShown={this.state.isMarkerShown}
          isHeatmap={this.state.isHeatmap}
          heatmap={this.props.markers}
          height={this.props.height}
          // eslint-disable-next-line
          googleMapURL='https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyDdU388WOx6YCCjaCE5JbjpzvBjna3wQOA&libraries=geometry,drawing,places,visualization'
          loadingElement={<div style={{ height: '100%' }} />}
          containerElement={containerElement}
          mapElement={<div style={{ height: '100%' }} />}
        />
        <span
          onClick={() => this.setState({ isMarkerShown: !this.state.isMarkerShown })}
          style={markerStyle}
        >Markers
        </span> /&nbsp;
        <span
          onClick={() => this.setState({ isHeatmap: !this.state.isHeatmap })}
          style={heatmapStyle}
        >Heatmap
        </span> /&nbsp;
        <span
          onClick={() => this.setState({ isPolyline: !this.state.isPolyline })}
          style={polylineStyle}>Polyline
        </span>
      </div>
    )
  }
}

Map.propTypes = {
  markers: PropTypes.array,
  isPolyline: PropTypes.bool,
  isMarkerShown: PropTypes.bool,
  // polyline: PropTypes.array,
  height: PropTypes.number,
  isHeatmap: PropTypes.bool,
  // heatmap: PropTypes.array
}

export default Map
