import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import {
  List, ListItem, ListItemText,
  Tooltip, Collapse, ListItemButton,
  ListItemIcon,
  FormControlLabel, Checkbox,
  Menu, MenuItem
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import TimerIcon from '@mui/icons-material/TimerOutlined'
import moment from 'moment'
import BulletIcon from 'components/Bullets/BulletIcon'
import Subtask from 'components/Tasks/Subtask'
import { createTask } from 'store/modules/bullets'
import { openEditTaskDialog } from 'store/modules/site'

const priorityLabels = {
  25: 'Low',
  50: 'Medium',
  100: 'High'
}

const TaskCard = ({ task }) => {
  const dispatch = useDispatch()
  const [item, setItem] = useState(task)
  const [menuOpen, setMenuOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  useEffect(() => {
    setItem(task)
  }, [task])

  const renderNested = (tasks) => {
    const subtasks = tasks.map((c) => {
      return <Subtask key={c.id} task={c} />
    })
    return (
      <div style={{ marginLeft: '50px', width: '80%' }}>
        {subtasks}
      </div>
    )
  }

  const renderActions = (t) => {
    return (
      <>
        <MoreVertIcon aria-controls='bullet-menu' aria-haspopup='true'
          style={{ width: '17px', color: '#a95151', cursor: 'pointer' }}
          onClick={(e) => {
            setMenuOpen(true)
            setAnchorEl(e.currentTarget)
          }}
        />
        <Menu
          id='bullet-menu'
          anchorEl={anchorEl}
          keepMounted
          open={menuOpen}
          onClose={() => setMenuOpen(false)}
        >
          <MenuItem onClick={() => {
            dispatch(openEditTaskDialog(t))
            setMenuOpen(false)
          }}>Edit
          </MenuItem>
          <MenuItem onClick={() => {
            const data = {
              id: t.id,
              startedOn: new Date(),
              started: true
            }
            if (!t.startOn) {
              t.startOn = new Date()
            }
            dispatch(createTask(data))
            setMenuOpen(false)
          }}>Start Now
          </MenuItem>
        </Menu>
      </>
    )
  }

  const renderTask = (t) => {
    let startStats, nestedList, childStats, priority, body
    if (t.children && t.children.length > 0) {
      // console.log(t.children)
      childStats = (
        <span style={{ fontSize: '10px', marginLeft: '5px' }}>
          {t.children.filter((c) => { return c.completed || c.status === 'done' }).length} / {t.children.length}
        </span>)
      nestedList = (
        <Collapse in style={{ fontSize: '12px' }}>
          <List dense component='div' style={{ marginLeft: '10px' }}>
            {renderNested(t.children)}
          </List>
        </Collapse>
      )
    }
    if (t.started && t.startedOn && !t.completed && t.status !== 'done') {
      startStats = (
        <span style={{ fontSize: '10px' }}>
          <Tooltip title='Doing Now' placement='top'>
            <TimerIcon
              style={{ width: '10px', height: '10px', marginRight: '3px' }}
            />
          </Tooltip>
          {moment(t.startedOn).fromNow()}
        </span>
      )
    }
    if (t.priority) {
      priority = (
        <Tooltip title='Priority' placement='top'>
          <span style={{ fontSize: '10px', marginLeft: '3px' }}>
            {priorityLabels[t.priority]}
          </span>
        </Tooltip>
      )
    }
    if (t.body) {
      body = (
        <span style={{ fontSize: '10px' }}>{t.body}</span>
      )
    }
    const secondary = (
      <span>
        {childStats}
        {startStats}
        {priority}
        {body}
      </span>
    )
    return (
      <>
        <ListItem className='taskCard'
          secondaryAction={renderActions(t)}
        >
          <ListItemButton>
            <ListItemIcon>
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<BulletIcon type='task' style={{ color: '#2ca5e0' }} />}
                    checkedIcon={<BulletIcon type='task' />}
                    checked={t.completed}
                    onChange={(e) => {
                      if (e.target.checked) {
                        dispatch(createTask({
                          id: t.id,
                          status: 'done',
                          completed: true,
                          completedOn: new Date()
                        }))
                      }
                    }}
                    name={`task-card-checkbox-${t.id}`} />
                }
                label=''
              />
            </ListItemIcon>
            <ListItemText primary={t.title} secondary={secondary} />
          </ListItemButton>
        </ListItem>
        {nestedList}
      </>
    )
  }

  return (
    <>
      {renderTask(item)}
    </>
  )
}

TaskCard.propTypes = {
  task: PropTypes.object
  // expandCallback: PropTypes.func
}

export default TaskCard
