import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import {
  Tooltip
} from '@mui/material'
import moment from 'moment'
import BulletIcon from 'components/Bullets/BulletIcon'
import { Link } from 'react-router-dom'
import Linkify from 'components/Bullets/Linkify'
import { getMoodEmoji } from 'utils/utils'
const isMobile = require('is-mobile')

const styles = {
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  }
}

class BulletCard extends Component {
  renderDescription = (bullet) => {
    let desc
    if (bullet.description) {
      desc = (
        <div style={{ fontSize: '12px', padding: '10px 0' }}>
          {bullet.description}
        </div>
      )
    }
    return desc
  }

  renderRating = (bullet) => {
    let rating
    if (bullet.rating) {
      const stars = (bullet.rating / 100) * 5
      rating = (
        <span style={{ fontSize: '10px' }}>
          {stars}<span role='img' aria-label='rating-star'>&#11088;</span>
        </span>
      )
    }
    return rating
  }

  renderMood = (bullet) => {
    let mood
    if (bullet.mood) {
      mood = (
        <span style={{ fontSize: '10px' }}>
          {bullet.mood} {getMoodEmoji(bullet.mood)}
        </span>
      )
    }
    return mood
  }

  render () {
    const { bullet, openMapDialog } = this.props
    const classes = styles
    const bull = <span className={classes.bullet}>•</span>
    const cardStyle = { marginBottom: '10px' }
    let image
    const contentStyle = { paddingBottom: 0 }
    const metadata = bullet.metadata
    if (metadata && metadata.image) {
      let imageStyle = {
        cursor: 'pointer',
        width: '150px',
      }
      if (isMobile()) {
        imageStyle = { cursor: 'pointer', height: '200px' }
      } else {
        cardStyle.display = 'flex'
        contentStyle.flexGrow = 4
      }
      image = (
        <CardMedia
          image={metadata.image}
          style={imageStyle}
          onClick={() => {
            this.props.openLightbox(metadata.image)
          }}
        />
      )
    }
    let location, duration
    if (bullet.lat && bullet.lng) {
      location = (
        <span>
          <span onClick={() => {
            openMapDialog({
              markers: [{ lat:bullet.lat, lng: bullet.lng, bullet: bullet }]
            })
          }}><span role='img' aria-label='location'>📍</span>
          </span>
        </span>
      )
    }
    if (bullet.task && (bullet.task.startedOn || bullet.task.beginOn) &&
      bullet.task.completedOn) {
      const diff = moment(bullet.task.completedOn).diff(moment(bullet.task.beginOn || bullet.task.startedOn))
      duration = (
        <span>
          {bull}&nbsp;
          {moment.duration(diff, 'milliseconds').format('y[y] w[w] d[d] h[h] m[m]', { trim: 'both' })}
        </span>
      )
    }
    // if (bullet.task) {
    //   viewTask = (
    //     <span>
    //       <a onClick={(_e) => this.props.openEditTaskDialog(bullet.task)}>Edit Task&nbsp;</a>
    //     </span>
    //   )
    // }
    return (
      <div>
        <Card id={`bullet-${bullet.id}`} className={classes.root} style={cardStyle}>
          {image}
          <CardContent style={contentStyle}>
            <span className='bulletIcon'>
              <Tooltip title='Edit' placement='top'>
                <span>
                  <BulletIcon type={bullet.type} style={{ cursor: 'pointer' }} onClick={() =>
                    this.props.openEditBulletDialog(bullet)} />
                </span>
              </Tooltip>
            </span>
            <span className='bulletText'>
              <Linkify>
                {bullet.body}
              </Linkify>
              {this.renderDescription(bullet)}
            </span>
            <div className='bulletDate' style={{
              fontSize: '10px', paddingBottom: '10px'
            }}>{moment(bullet.createdOn).format('ddd')}&nbsp;
              {bull}&nbsp;
              <Link to={`/admin/bullets?d=${new Date(bullet.createdOn).toISOString()}`}>{moment(bullet.createdOn)
                .format('DD/MM/YYYY')}
              </Link>
              {bull}&nbsp;
              {moment(bullet.createdOn).format('h:mm a')}&nbsp;
              {duration}
            </div>
            <div style={{ fontSize: '10px', float: 'right', marginTop: '-35px' }}>
              {location}
              {this.renderMood(bullet)}
              {this.renderRating(bullet)}
            </div>
          </CardContent>
        </Card>
      </div>
    )
  }
}

BulletCard.propTypes = {
  bullet: PropTypes.object,
  openLightbox: PropTypes.func,
  // hasCreatedBullet: PropTypes.bool,
  // createBullet: PropTypes.func,
  openMapDialog: PropTypes.func,
  openEditBulletDialog: PropTypes.func,
  // openEditTaskDialog: PropTypes.func
}

export default BulletCard
