import React from 'react'
import PropTypes, { instanceOf } from 'prop-types'
import { ConnectedRouter } from 'connected-react-router'
import { Route, Switch } from 'react-router' // react-router v4/v5
import BaseLayout from 'layouts/Base/Base'
import AdminLayout from 'layouts/Admin/Admin'
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { BrowserRouter } from 'react-router-dom'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import enLocale from 'date-fns/locale/en-AU'
import { withCookies, Cookies } from 'react-cookie'
import 'styles/main.css'
import 'react-image-lightbox/style.css'
const locale = enLocale

const theme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard'
      }
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'standard'
      }
    },
  },
  palette: {
    primary: {
      light: '#4b5ba7',
      main: 'rgb(187, 123, 123)',
      dark: '#4b5ba7',
      contrastText: '#fff',
    },
    secondary: {
      // light: '#ff7961',
      main: '#33644e',
      // dark: '#ba000d',
      // contrastText: '#000',
    },
  },
})
class App extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    context: PropTypes.object,
    history: PropTypes.object,
    cookies: instanceOf(Cookies).isRequired
  }

  shouldComponentUpdate () {
    return false
  }

  render () {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <ConnectedRouter history={this.props.history} context={this.props.context}
                store={this.props.store} cookies={this.props.cookies}>
                <div>
                  <Switch>
                    <Route path='/admin'
                      render={() => <AdminLayout {...this.props} />}
                    />
                    <Route path='/admin/*'
                      render={() => <AdminLayout {...this.props} />}
                    />
                    <Route path='/' component={BaseLayout} />
                  </Switch>
                </div>
              </ConnectedRouter>
            </BrowserRouter>
          </ThemeProvider>
        </StyledEngineProvider>
      </LocalizationProvider>
    )
  }
}

export default withCookies(App)
