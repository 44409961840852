import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Route, Switch } from 'react-router' // react-router v4/v5
import { Container } from '@mui/material'
// import { Link } from 'react-router-dom'
import routes from 'routes/Public/Content'
import Home from 'routes/Public/Home'

class ContentContainer extends Component {
  renderChildRoutes (routes) {
    const routing = routes().childRoutes.map((route, idx) => {
      return <Route key={idx} path={route.path} component={route.getComponent} />
    })
    return (
      <>
        <Switch>
          {routing}
        </Switch>
      </>
    )
  }

  render () {
    return (
      <div id='content-container'>
        <Container style={{ marginTop: '40px' }}>
          <div>
            <Route key={1} exact path='/' component={Home} />
            <Route key={2} exact path='/content' component={Home} />
            {this.renderChildRoutes(routes)}
          </div>
        </Container>
      </div>
    )
  }
}

ContentContainer.propTypes = {
}

// const mapStateToProps = (state) => {
//   return {
//   }
// }

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({
  }, dispatch),
})

export default connect(
  // mapStateToProps,
  mapDispatchToProps
)(ContentContainer)
