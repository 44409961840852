import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import Tooltip from '@mui/material/Tooltip'
import ImageTools from 'utils/imagetools'
const it = new ImageTools()

const propTypes = {
  callback: PropTypes.func,
  multiple: PropTypes.bool
}

class UploadFileButton extends Component {
  constructor (props) {
    super(props)
    this.state = {
      fileUploaded: false,
      files: []
    }
    this.fileUpload = {}
  }

  handleOpenFileDialog = () => {
    this.fileUpload.click()
  }

  handleFileChange = (e) => {
    if (e.target.files && e.target.files.length) {
      this.setState({
        fileUploaded: true,
        files: e.target.files
      })
      const fileArray = [...e.target.files]
      Promise.all(fileArray.map((f) => {
        return it.resize(f, { width: 500 })
      })).then((files) => {
        console.log(files)
        // console.log(e.target.files)
        this.props.callback(files)
      })
      // this.props.handleFileUpload(e, this.props.fileType)
    }
  }

  render () {
    let input = (
      <input
        multiple
        style={{ display: 'none' }}
        type='file' label='Upload'
        ref={(ref) => { this.fileUpload = ref }}
        onChange={this.handleFileChange}
      />
    )
    if (this.props.multiple === false) {
      input = (<input
        style={{ display: 'none' }}
        type='file' label='Upload'
        ref={(ref) => { this.fileUpload = ref }}
        onChange={this.handleFileChange}
      />
      )
    }
    return (
      <div style={{ cursor: 'pointer' }}>
        {input}
        <Tooltip title='Upload and append images'>
          <AddPhotoAlternateIcon onClick={this.handleOpenFileDialog} />
        </Tooltip>
      </div>
    )
  }
}

UploadFileButton.propTypes = propTypes

export default UploadFileButton
