import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import {
  List,
  CircularProgress
} from '@mui/material'
import TaskCard from 'components/Tasks/TaskCard'
import {
  getTasks
} from 'store/modules/bullets'

const propTypes = {
  status: PropTypes.string,
  withoutAgenda: PropTypes.bool,
  types: PropTypes.array
}

const TaskList = ({ status, withoutAgenda, types }) => {
  const dispatch = useDispatch()
  const {
    tasks,
    createdTask,
    isGettingTasks,
    createdBullet,
    createdSubtask
  } = useSelector((state) => {
    return {
      tasks: state.bullets.tasks,
      isGettingTasks: state.bullets.isGettingTasks,
      createdTask: state.bullets.createdTask,
      createdBullet: state.bullets.createdBullet,
      createdSubtask: state.bullets.createdSubtask
    }
  })
  const [items, setItems] = useState({})
  const fetch = () => {
    const d = {}
    if (status) {
      d.status = status
    }
    if (types) {
      d.types = types
    }
    if (withoutAgenda) {
      d.withoutAgenda = withoutAgenda
    }
    dispatch(getTasks(d))
  }
  useEffect(() => {
    fetch()
  }, [])
  useEffect(() => {
    if (createdBullet.deleted !== true && createdBullet.type === 'task') {
      fetch()
    }
  }, [createdBullet])
  useEffect(() => {
    const data = { ...items }
    tasks.forEach((t) => {
      data[t.id] = t
    })
    setItems(data)
  }, [tasks])
  useEffect(() => {
    if (createdTask.beginOn && createdTask.dueOn) {
      const data = { ...items }
      delete data[createdTask.id]
      setItems(data)
    }
    if (createdTask.completed && items[createdTask.id]) {
      const data = { ...items }
      delete data[createdTask.id]
      setItems(data)
    }
    if (createdTask.deleted && items[createdTask.id]) {
      const data = { ...items }
      delete data[createdTask.id]
      setItems(data)
    }
    if (createdTask.status !== status && items[createdTask.id]) {
      const data = { ...items }
      delete data[createdTask.id]
      setItems(data)
    }
  }, [createdTask])

  useEffect(() => {
    if (createdSubtask.parentId && items[createdSubtask.parentId]) {
      fetch()
    }
  }, [createdSubtask])
  const entries = Object.entries(items).sort((a, b) => {
    return new Date(b[1].modifiedOn) - new Date(a[1].modifiedOn)
  }).sort((a, b) => {
    return (b[1].priority === a[1].priority) ? 0 : a[1].priority ? 1 : -1
  }).sort((a, b) => {
    return (a[1].type === 'story' === b[1].type === 'story') ? 0 : (a.type === 'story') ? 1 : -1
  }).sort((a, b) => {
    return (a[1].started === b[1].started) ? 0 : b[1].started ? 1 : -1
  })
  return (
    <div className='taskListContainer'>
      <List className='taskList' dense>
        {isGettingTasks
          ? <CircularProgress />
          : entries.map((i) => {
            const k = i[0]
            return (
              <TaskCard
                key={k}
                task={items[k]}
              />
            )
          })}
      </List>
    </div>
  )
}

TaskList.propTypes = propTypes

export default TaskList
