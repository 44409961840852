import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Container } from '@mui/material'
import { getPublicContentById } from 'store/modules/content'
import moment from 'moment'
import escapeHtml from 'escape-html'
import { Text } from 'slate'
const serialize = (node, i) => {
  if (Text.isText(node)) {
    return escapeHtml(node.text)
  }
  const children = node.children.map(n => serialize(n)).join('')
  
  switch (node.type) {
    case 'quote':
      return (<blockquote key={'bq_' + i}><p key={'p_' + i}>{children}</p></blockquote>)
    case 'paragraph':
      return (<p key={'p_' + i}>{children}</p>)
    case 'link':
      return (<a key={'a_' + i} href={escapeHtml(node.url)}>{children}</a>)
    default:
      return (<p key={'p_' + i}>{children}</p>)
  }
}

class View extends Component {
  componentDidMount () {
    if (this.props.match.params.id) {
      this.props.getPublicContentById(this.props.match.params.id)
    }
  }

  renderContent = (c) => {
    let html
    if (c.body) {
      const body = JSON.parse(c.body)
      html = body.map((b, i) => { return serialize(b, i) })
    }
    return (
      <div>
        <div style={{
          fontSize: '40px',
          lineHeight: '40px',
          fontWeight: 'bold'
        }}>{c.title}
        </div>
        <small>{moment(c.createdOn).format('DD/MM/YYYY hh:mm a')}</small>
        <div style={{ marginTop: '20px' }}>
          {html}
        </div>
      </div>
    )
  }

  render () {
    return (
      <div id='home-public-container' className='component-container'>
        <Container style={{ marginTop: '40px' }}>
          <div>
            <Link to='/'><center><h1>Log</h1></center></Link>
            {this.renderContent(this.props.selectedPublicContent)}
          </div>
        </Container>
      </div>
    )
  }
}

View.propTypes = {
  getPublicContentById: PropTypes.func,
  match: PropTypes.object,
  selectedPublicContent: PropTypes.object,
}

const mapStateToProps = (state) => {
  const {
    isGettingPublicContentById,
    hasGottenPublicContentById,
    selectedPublicContent,
  } = state.content
  return {
    isGettingPublicContentById,
    hasGottenPublicContentById,
    selectedPublicContent,
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({
    getPublicContentById
  }, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(View)
