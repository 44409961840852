import React, { useState, useCallback, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Slate, Editable,
  withReact,
  // useEditor,
  // useSelected,
  // useFocused
} from 'slate-react'
import { Editor, Transforms, Range, Point, createEditor } from 'slate'
import { withHistory } from 'slate-history'
import imageExtensions from 'image-extensions'
import isUrl from 'is-url'
import {
  // Button,
  Toolbar
} from './SlateComponents'
// import AddPhotoUrlIcon from '@mui/icons-material/AddPhotoAlternate'
import UploadFileButton from './UploadFileButton'
import TitleInput from './TitleInput'

const SHORTCUTS = {
  '*': 'list-item',
  '-': 'list-item',
  '+': 'list-item',
  '>': 'block-quote',
  '#': 'heading-one',
  '##': 'heading-two',
  '###': 'heading-three',
  '####': 'heading-four',
  '#####': 'heading-five',
  '######': 'heading-six',
  '`': 'code',
}
// const emptyValue = [{ type:'text', children: [{ text:'' }] }]
const ContentEditor = (props, { hasUploadedImages, selectedContent, images }) => {
  const editor =
  useMemo(
    () => withImages(
      withShortcuts(
        withReact(
          withHistory(
            createEditor()
          )
        )
      )
    ),
    []
  )
  const [value, setValue] = useState(props.initialValue)
  // console.log(props, emptyValue)
  useEffect(() => {
    if (hasUploadedImages === true) {
      if (images && images.files && images.files.length > 0) {
        images.files.forEach((i) => {
          insertImage(editor, i.path)
        })
      }
    }
    if (selectedContent && selectedContent.body && hasUploadedImages !== true) {
      setValue(JSON.parse(selectedContent.body))
      // setSelectedContent(props.selectedContent)
    }
    if (selectedContent && selectedContent.title && hasUploadedImages !== true) {
      props.setTitleValue(selectedContent.title)
    }
  }, [hasUploadedImages, selectedContent, images])
  const onChangeValue = (value) => {
    // console.log(setValue)
    setValue(value)
    const content = JSON.stringify(value)
    localStorage.setItem('content', content)
    props.setValue(value)
  }
  const onChangeTitle = (e) => {
    localStorage.setItem('content-title', e.target.value)
    props.setTitleValue(e.target.value)
  }
  const handleInsertImage = (files) => {
    props.uploadImages(files)
  }
  const renderElement = useCallback(props => <Element {...props} />, [])
  return (
    <>
      <Slate editor={editor} value={value}
        onChange={value => onChangeValue(value)}>
        <Toolbar>
          <UploadFileButton
            callback={handleInsertImage}
            selectedContent={props.selectedContent}
          />
        </Toolbar>
        <TitleInput
          title={props.title}
          onChangeTitle={onChangeTitle} />
        <Editable
          renderElement={renderElement}
          placeholder='Write some markdown...'
          spellCheck
          autoFocus
          onKeyDown={(event) => {
            if (event.key === 'Enter' && event.shiftKey) {
              event.preventDefault()
              editor.insertText('\n')
            }
          }}
        />
      </Slate>
    </>
  )
}

ContentEditor.propTypes = {
  setValue: PropTypes.func,
  setTitleValue: PropTypes.func,
  title: PropTypes.string,
  uploadImages: PropTypes.func,
  hasUploadedImages: PropTypes.bool,
  images: PropTypes.object,
  selectedContent: PropTypes.object,
  initialValue: PropTypes.array
}

const withImages = editor => {
  const { insertData, isVoid } = editor

  editor.isVoid = element => {
    return element.type === 'image' ? true : isVoid(element)
  }

  editor.insertData = data => {
    const text = data.getData('text/plain')
    const { files } = data

    if (files && files.length > 0) {
      for (const file of files) {
        const reader = new FileReader()
        const [mime] = file.type.split('/')

        if (mime === 'image') {
          reader.addEventListener('load', () => {
            const url = reader.result
            insertImage(editor, url)
          })

          reader.readAsDataURL(file)
        }
      }
    } else if (isImageUrl(text)) {
      insertImage(editor, text)
    } else {
      insertData(data)
    }
  }

  const insertImage = (editor, url) => {
    const text = { text: '' }
    const image = { type: 'image', url, children: [text] }
    Transforms.insertNodes(editor, image)
  }

  const isImageUrl = url => {
    if (!url) return false
    if (!isUrl(url)) return false
    const ext = new URL(url).pathname.split('.').pop()
    return imageExtensions.includes(ext)
  }

  return editor
}

const withShortcuts = editor => {
  const { deleteBackward, insertText } = editor
  editor.insertText = text => {
    const { selection } = editor
    // console.log(selection)

    if (text === ' ' && selection && Range.isCollapsed(selection)) {
      const { anchor } = selection
      const block = Editor.above(editor, {
        match: n => Editor.isBlock(editor, n),
      })
      const path = block ? block[1] : []
      const start = Editor.start(editor, path)
      const range = { anchor, focus: start }
      const beforeText = Editor.string(editor, range)
      const type = SHORTCUTS[beforeText]

      if (type) {
        Transforms.select(editor, range)
        Transforms.delete(editor)
        Transforms.setNodes(
          editor,
          { type },
          { match: n => Editor.isBlock(editor, n) }
        )

        if (type === 'list-item') {
          const list = { type: 'bulleted-list', children: [] }
          Transforms.wrapNodes(editor, list, {
            match: n => n.type === 'list-item',
          })
        }

        return
      }
    }

    insertText(text)
  }

  editor.deleteBackward = (...args) => {
    const { selection } = editor

    if (selection && Range.isCollapsed(selection)) {
      const match = Editor.above(editor, {
        match: n => Editor.isBlock(editor, n),
      })

      if (match) {
        const [block, path] = match
        const start = Editor.start(editor, path)

        if (
          block.type !== 'paragraph' &&
          Point.equals(selection.anchor, start)
        ) {
          Transforms.setNodes(editor, { type: 'paragraph' })

          if (block.type === 'list-item') {
            Transforms.unwrapNodes(editor, {
              match: n => n.type === 'bulleted-list',
              split: true,
            })
          }

          return
        }
      }

      deleteBackward(...args)
    }
  }

  return editor
}

const insertImage = (editor, url) => {
  // console.log(url)
  const text = { text: '' }
  const image = { type: 'image', url, children: [text] }
  Transforms.insertNodes(editor, image)
}

const Element = (props) => {
  const { attributes, children, element } = props
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>
    case 'heading-three':
      return <h3 {...attributes}>{children}</h3>
    case 'heading-four':
      return <h4 {...attributes}>{children}</h4>
    case 'heading-five':
      return <h5 {...attributes}>{children}</h5>
    case 'heading-six':
      return <h6 {...attributes}>{children}</h6>
    case 'list-item':
      return <li {...attributes}>{children}</li>
    case 'code':
      return (
        <pre {...attributes}>
          <code>{children}</code>
        </pre>
      )
    case 'image':
      return <ImageElement {...props} />
    default:
      return <p {...attributes}>{children}</p>
  }
  // return (
  //   <div>
  //     <input
  //       type='text'
  //       defaultValue='Title'
  //     />
  //     {children}
  //   </div>
  // )
}

const ImageElement = ({ attributes, children, element }) => {
  // const selected = useSelected()
  // const focused = useFocused()
  return (
    <div {...attributes}>
      <div contentEditable={false}>
        <img
          src={element.url}
          alt=''
        />
      </div>
      {children}
    </div>
  )
}

ImageElement.propTypes = {
  attributes: PropTypes.object,
  element: PropTypes.object,
  children: PropTypes.object
}

Element.propTypes = {
  attributes: PropTypes.object,
  children: PropTypes.object,
  element: PropTypes.object
}

export default ContentEditor
