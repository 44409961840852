import React, { useState } from 'react'
import { useCookies } from 'react-cookie'
import {
  Container, Grid,
  Checkbox, FormGroup, FormControlLabel
} from '@mui/material'
import BulletEditor from 'components/Dashboard/BulletEditor'
import HabitsWidget from 'components/Habits/HabitsWidget'
import TaskList from 'components/Tasks/TaskList'
import BulletTimeline from 'components/Bullets/BulletTimeline'
import AgendaTimeline from 'components/Bullets/AgendaTimeline'
import Clock from 'react-live-clock'
import CalendarWidget from 'components/Bullets/CalendarWidget'
import GithubWidget from 'components/Bullets/GithubWidget'
import WeatherWidget from 'components/Bullets/WeatherWidget'
import CodingTimeWidget from 'components/Bullets/CodingTimeWidget'
import StepsWidget from 'components/Bullets/StepsWidget'
import HabitsCountWidget from 'components/Bullets/HabitsCountWidget'
import MoodPercentageWidget from 'components/Bullets/MoodPercentageWidget'
import WakeUpBullet from 'components/Bullets/WakeUpBullet'

const DashboardContainer = () => {
  const [cookies, setCookie] = useCookies(['Dashboard__AgendaTimeline__showAllDays'])
  const [date] = useState(new Date())
  const [showAllDays, setShowAllDays] = useState(Boolean(cookies.Dashboard__AgendaTimeline__showAllDays) || true)
  // const [showAllDays, setShowAllDays] = useState(true)
  const [currentTime, setCurrentTime] = useState(0)
  const renderHeader = () => {
    return (
      <div style={{ fontSize: '12px' }}>
        <div style={{ float: 'right' }}>
          <Clock format='h:mm:ss a' ticking
            onChange={(date) => {
              setCurrentTime(date)
            }}
          />
          <FormGroup>
            <FormControlLabel style={{ fontSize: '10px' }}
              labelPlacement='start'
              control={<Checkbox size='small'
                checked={showAllDays}
                onClick={(e) => {
                  const val = e.target.checked
                  setCookie('Dashboard__AgendaTimeline__showAllDays', val)
                  setShowAllDays(val)
                }}
              />}
              label={(<span style={{ fontSize: '10px' }}>All</span>)} />
          </FormGroup>
        </div>
        <br />
        <HabitsCountWidget date={date} />
        <StepsWidget date={date} />
        <CodingTimeWidget date={date} />
        <GithubWidget date={date} /><br />
        <WeatherWidget date={date} />
        <MoodPercentageWidget date={date} />
      </div>
    )
  }
  return (
    <div id='dashboard-container' className='component-container' style={{ marginBottom: '40px' }}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <h2>Dashboard</h2>
            <BulletEditor />
            <h3>Tasks</h3>
            <TaskList
              status='inProgress'
              withoutAgenda
              types={['story', 'task']}
            />
            <h3>Habits</h3>
            <HabitsWidget />
            <h3 style={{ marginTop: '5rem' }}>Calendar</h3>
            <CalendarWidget
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <div style={{ marginBottom: '40px' }}>
              <AgendaTimeline
                date={date}
                header={renderHeader()}
                currentTime={currentTime}
                showAllDays={showAllDays}
              />
            </div>
            <div>
              <BulletTimeline
                start='bottom'
                date={date}
                before={<WakeUpBullet date={date} />}
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default DashboardContainer
