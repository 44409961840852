import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@mui/material'

const DashboardWidget = ({
  targetPercentage,
  targetText,
  hideBarChart,
  title,
  body,
  subtitle
}) => {
  let percentageStyle = {}; let percentageText = ''
  let tooltipText = ''
  if (targetPercentage) {
    percentageStyle = {
      width: `${targetPercentage}%`,
      maxWidth: '100%'
    }
    if (targetPercentage >= 100) {
      percentageStyle.backgroundColor = '#c74040'
    }
    percentageText = Math.round(targetPercentage) + '%'
    // tooltipText += percentageText + ' '
  }
  if (targetText) {
    tooltipText = this.props.targetText
  }
  let barChart = (
    <div className='bar-chart'>
      <Tooltip title={tooltipText} placement='top'>
        <div className='bar-chart-percentage' style={percentageStyle}>
          <span className='bar-chart-percentage-text'>{percentageText}</span>
        </div>
      </Tooltip>
    </div>
  )
  if (hideBarChart) {
    barChart = ''
  }
  return (
    <div className='dashboard-widget'>
      {barChart}
      <div className='dashboard-widget-content'>
        <h4 className='title'>{title}</h4>
        <div className='body'>{body}</div>
        <div className='subtitle'>{subtitle}</div>
      </div>
    </div>
  )
}

DashboardWidget.propTypes = {
  targetPercentage: PropTypes.number,
  targetText: PropTypes.string,
  hideBarChart: PropTypes.bool,
  title: PropTypes.string,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subtitle: PropTypes.string
}

export default DashboardWidget
