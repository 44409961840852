import React from 'react'
import PropTypes from 'prop-types'
import { Alert } from '@mui/material';
import { Snackbar } from '@mui/material'

const MaterialAlert = (props) => {
  return (
    <Snackbar open={props.alertIsOpen}
    // autoHideDuration={6000}
      onClose={props.handleCloseAlert}>
      <Alert
        action=''
        elevation={6} variant='filled'
        onClose={props.handleCloseAlert} severity={props.alertSeverity}>
        {props.alertMessage}
      </Alert>
    </Snackbar>
  )
}

MaterialAlert.propTypes = {
  alertIsOpen: PropTypes.bool,
  handleCloseAlert: PropTypes.func,
  alertSeverity: PropTypes.string,
  alertMessage: PropTypes.string
}

export default MaterialAlert
