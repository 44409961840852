import React from 'react'
import { Route } from 'react-router' // react-router v4/v5
import { Container } from '@mui/material'
import List from './List'

const HabitsContainer = () => {
  return (
    <div id='habits-container'>
      <Container style={{ marginTop: '40px' }}>
        <div>
          <Route key={1} exact path='/admin/habits' component={List} />
        </div>
      </Container>
    </div>
  )
}

export default HabitsContainer
