import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
// import { Button } from '@mui/material'
import { getUsers, createUser } from 'store/modules/auth'
// import MaterialTable from 'material-table'
import {
  DataGrid,
  // GridActionsCellItem
} from '@mui/x-data-grid'
import {
  // FormControl, Select, MenuItem,
  Button
} from '@mui/material'
import DatagridAddFormDialog from 'components/Site/DatagridAddFormDialog'
// import DeleteIcon from '@mui/icons-material/Delete'

const propTypes = {
  getUsers: PropTypes.func,
  users: PropTypes.array,
  createUser: PropTypes.func
  // usersCount: PropTypes.number
  // history: PropTypes.object,
  // createUser: PropTypes.func,
}

class List extends Component {
  constructor (props) {
    super(props)
    this.tableRef = React.createRef()
    this.state = {
      rows: [],
      editRowsModel: {},
      addDialogIsOpen: false
    }
  }

  componentDidMount () {
    this.props.getUsers()
  }

  componentDidUpdate (prevProps) {
    if (this.props.users !== prevProps.users) {
      this.setRows(this.props.users)
    }
  }

  setRows (usersArr) {
    this.setState({ rows: usersArr })
  }

  render () {
    const columns = [
      { headerName: 'ID', field: 'id', editable: false },
      { headerName: 'E-mail', field: 'email', width: 300, editable: true },
      { headerName: 'Username', field: 'username', width: 200, editable: true },
      {
        headerName: 'Role',
        field: 'role',
        editable: true,
        type: 'singleSelect',
        width: 150,
        valueOptions: ['SUPERADMIN', 'ADMIN', 'USER']
      },
    ]
    return (
      <div>
        <h2>Users</h2>
        <Button variant='text' onClick={() => { this.setState({ addDialogIsOpen: true }) }}>Add User</Button>
        <div style={{ height: '500px' }}>
          <DataGrid
            onCellEditCommit={(params, _event) => {
              const data = {
                id: params.id
              }
              data[params.field] = params.value
              this.props.createUser(data)
            }}
            rows={this.state.rows}
            columns={columns} />
        </div>
        <DatagridAddFormDialog
          title='Add User'
          columns={columns.concat({
            headerName: 'Password', field: 'password', editable: true
          })}
          createCallback={(data) => { this.props.createUser(data) }}
          handleClose={() => { this.setState({ addDialogIsOpen: false }) }}
          open={this.state.addDialogIsOpen} />
      </div>
    )
  }
}

List.propTypes = propTypes

const mapStateToProps = (state) => {
  const {
    users,
    usersCount,
    isGettingUsers,
    hasGottenUsers
  } = state.auth
  return {
    users,
    usersCount,
    isGettingUsers,
    hasGottenUsers
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({
    getUsers,
    createUser
  }, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List)
