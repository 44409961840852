import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useCookies } from 'react-cookie'
import {
  getGoogleCalData,
  createTask
} from 'store/modules/bullets'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress
} from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { parseRec } from 'utils/utils'

const CalendarWidget = ({ date }) => {
  const dispatch = useDispatch()
  const [cookies, setCookie] = useCookies(['Dashboard__CalendarWidget__showWeek'])
  const {
    googleCalData, isGettingGoogleCalData,
    createdTask
  } = useSelector((state) => {
    return {
      googleCalData: state.bullets.googleCalData,
      isGettingGoogleCalData: state.bullets.isGettingGoogleCalData,
      createdTask: state.bullets.createdTask
    }
  })
  const [showWeek, setShowWeek] = useState(cookies.Dashboard__CalendarWidget__showWeek)
  const [items, setItems] = useState({})
  useEffect(() => {
    if (date) {
      dispatch(getGoogleCalData({ date }))
    } else {
      dispatch(getGoogleCalData({ date: new Date() }))
    }
  }, [])
  useEffect(() => {
    const data = { ...items }
    googleCalData
      .filter((d) => {
        if (d.recurrence && d.recurrence[0]) {
          if (d.start && d.start.date) {
            if (parseRec(d.recurrence[0], d.start.date) === false) { return false }
          }
          if (d.start && d.start.dateTime) {
            if (parseRec(d.recurrence[0], d.start.dateTime) === false) { return false }
          }
        }
        return true
      })
      .forEach((d) => {
        if (d.recurrence && d.recurrence[0]) {
          if (d.start && d.start.date) {
            d.start.date = parseRec(d.recurrence[0], d.start.date)
          }
          if (d.start && d.start.dateTime) {
            d.start.dateTime = parseRec(d.recurrence[0], d.start.dateTime)
          }
          if (d.end && d.end.date) {
            d.end.date = parseRec(d.recurrence[0], d.end.date)
          }
          if (d.end && d.end.dateTime) {
            d.end.dateTime = parseRec(d.recurrence[0], d.end.dateTime)
          }
        }
        data[d.id] = d
      })
    setItems(data)
  }, [googleCalData])

  useEffect(() => {
    if (showWeek === true) {
      dispatch(getGoogleCalData({
        dateRange: {
          startDate: moment().startOf('day'),
          endDate: moment().add(7, 'days').endOf('day')
        }
      }))
    } else {
      dispatch(getGoogleCalData({ date: new Date() }))
    }
    setCookie('Dashboard__CalendarWidget__showWeek', showWeek)
  }, [showWeek])

  useEffect(() => {
    if (createdTask.calendarId && items[createdTask.calendarId]) {
      const data = { ...items }
      delete data[createdTask.calendarId]
      setItems(data)
    }
  }, [createdTask])

  const createTaskFromCal = (d) => {
    let beginOn, dueOn
    if (d.start.date) {
      beginOn = moment(d.start.date).format()
    }
    if (d.start.dateTime) {
      beginOn = d.start.dateTime
    }
    if (d.end.date) {
      dueOn = moment(d.end.date).format()
    }
    if (d.end.dateTime) {
      dueOn = d.end.dateTime
    }
    const data = {
      title: d.summary,
      beginOn,
      dueOn,
      createdOn: new Date(),
      modifiedOn: new Date(),
      status: 'inProgress',
      metadata: JSON.stringify({ calendar: d }),
      calendarId: d.id,
      body: ''
    }
    if (d.description) {
      data.body += d.description
    }
    if (d.location) {
      if (d.description) {
        data.body += '\n\n'
      }
      data.body += d.location
    }
    dispatch(createTask(data))
  }

  const renderedItems = (data) => {
    const entries = Object.entries(data)
      .filter((i) => i[1] && i[1].start && (i[1].start.date || i[1].start.dateTime))
      .sort((a, b) => {
        let aStart, bStart
        if (a[1].start.date) {
          aStart = a[1].start.date
        }
        if (b[1].start.date) {
          bStart = b[1].start.date
        }
        if (a[1].start.dateTime) {
          aStart = a[1].start.dateTime
        }
        if (b[1].start.dateTime) {
          bStart = b[1].start.dateTime
        }
        return new Date(aStart) - new Date(bStart)
      })
    return entries.map((i) => {
      const d = i[1]
      const icon = <AddCircleIcon onClick={() => {
        createTaskFromCal(d)
      }} style={{
        width: '15px',
        cursor: 'pointer',
        height: '15px',
        marginTop: '2px',
        color: '#0288d1',
        float: 'right'
      }} />
      let when
      if (d.start && d.start.date) {
        when = 'All day today'
        if (!moment(d.start.date).isSame(moment(), 'day')) {
          when = `${moment(d.start.date).format('DD/MM/YYYY')}`
        }
      }
      if (d.start && d.start.dateTime && d.end && d.end.dateTime) {
        let startFormat = 'h:mm a'
        let endFormat = 'h:mm a'
        if (!moment(d.start.dateTime).isSame(moment(), 'day')) {
          startFormat = 'DD/MM/YYYY h:mm a'
        }
        if (!moment(d.end.dateTime).isSame(moment(), 'day')) {
          endFormat = 'DD/MM/YYYY h:mm a'
        }
        when = // eslint-disable-next-line
        `${moment(d.start.dateTime).format(startFormat)} - ${moment(d.end.dateTime).format(endFormat)}`
      }
      return (
        <div key={`calendar-widget-${d.id}`} style={{
          fontSize: '13px',
          background: 'white',
          // background: '#d9d9d9',
          // background: '#e4c44182',
          borderRadius: '5px',
          padding: '5px 10px',
          marginBottom: '5px'
        }}>
          <span>{d.summary} {icon}
          </span>
          <div style={{ marginTop: '-5px' }}>
            <small>{when} </small>
          </div>
        </div>
      )
    })
  }

  return (
    <>
      <div>
        <FormGroup>
          <FormControlLabel style={{ fontSize: '10px' }}
            labelPlacement='start'
            control={<Checkbox size='small'
              onClick={(e) => {
                setShowWeek(e.target.checked)
              }} />}
            label={(<span style={{ fontSize: '10px' }}>Week</span>)} />
        </FormGroup>
        {isGettingGoogleCalData
          ? <CircularProgress />
          : renderedItems(items)}
      </div>
    </>
  )
}

CalendarWidget.propTypes = {
  date: PropTypes.object
  // hasGottenGoogleCalData: PropTypes.bool
}

export default CalendarWidget
