import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
// import { Button } from '@mui/material'
import { getAccounts, createAccount } from 'store/modules/bullets'
import { DataGrid } from '@mui/x-data-grid'
// import { openAlert } from 'store/modules/site'

const propTypes = {
  getAccounts: PropTypes.func,
  accounts: PropTypes.array,
  // history: PropTypes.object,
  // isGettingAccounts: PropTypes.bool,
  createAccount: PropTypes.func,
}

class AccountsList extends Component {
  constructor (props) {
    super(props)
    this.tableRef = React.createRef()
    this.state = {
      accounts: []
    }
  }

  componentDidMount () {
    this.props.getAccounts()
  }

  componentDidUpdate (prevProps) {
    if (this.props.accounts !== prevProps.accounts) {
      this.setAccounts(this.props.accounts)
    }
  }

  setAccounts = (accountsArr) => {
    const accounts = accountsArr.map((h) => {
      const obj = {
        id: h.id,
        title: h.title,
        bank: h.bank,
        type: h.type,
        amount: h.amount,
      }
      return obj
    })
    this.setState({ accounts })
  }

  render () {
    const columns = [
      // { title: 'ID', field: 'id' },
      { headerName: 'Title', field: 'title', width: 180 },
      { headerName: 'Bank', field: 'bank' },
      { headerName: 'Type', field: 'type' },
      { headerName: 'Amount', field: 'amount', type: 'number', editable: true },
    ]
    return (
      <div id='accounts-list-container'>
        <h2>Accounts</h2>
        <div style={{ height: '500px' }}>
          <DataGrid
            onCellEditCommit={(params, _event) => {
              const data = {
                modifiedOn: new Date(),
                id: params.id
              }
              data[params.field] = params.value
              this.props.createAccount(data)
            }}
            rows={this.state.accounts}
            columns={columns} />
        </div>
      </div>
    )
  }
}

AccountsList.propTypes = propTypes

const mapStateToProps = (state) => {
  const {
    isGettingAccounts,
    hasGottenAccounts,
    accounts,
    isCreatingAccount,
    hasCreatedAccount,
    createdHabit
  } = state.bullets
  return {
    isGettingAccounts,
    hasGottenAccounts,
    accounts,
    isCreatingAccount,
    hasCreatedAccount,
    createdHabit
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({
    getAccounts,
    createAccount
  }, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountsList)
