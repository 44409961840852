import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import BulletIcon from 'components/Bullets/BulletIcon'
import { CircularProgress } from '@mui/material'
import moment from 'moment'
import Tooltip from '@mui/material/Tooltip'
import Linkify from 'components/Bullets/Linkify'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import TimerIcon from '@mui/icons-material/TimerOutlined'
import Subtask from 'components/Tasks/Subtask'
import { useDispatch, useSelector } from 'react-redux'
import { createTask, getAgendaTasks } from 'store/modules/bullets'
import {
  openEditTaskDialog
} from 'store/modules/site'
// import { Menu, MenuItem } from '@mui/material'
const momentDurationFormatSetup = require('moment-duration-format') // eslint-disable-line
momentDurationFormatSetup(moment)

const AgendaItem = ({
  task, currentTime,
  prev, next,
  showAllDays
}) => {
  const dispatch = useDispatch()
  const [item, setItem] = useState({})
  const [visible, setVisible] = useState(true)
  useEffect(() => {
    setItem(task)
  }, [task])

  useEffect(() => {
    if (!showAllDays) {
      if (item.beginOn &&
      moment(item.beginOn).isAfter(moment(currentTime), 'day')) {
        setVisible(false)
      }
    }
    if (showAllDays) {
      setVisible(true)
    }
  }, [showAllDays])

  const renderDescription = (bullet) => {
    let desc
    if (bullet.body) {
      desc = (
        <div style={{ fontSize: '12px', margin: '5px 0' }}>
          {bullet.body}
        </div>
      )
    }
    return desc
  }

  const renderActions = (t) => {
    return (
      <>
        <MoreVertIcon
          style={{ width: '17px', color: '#a95151', cursor: 'pointer' }}
          onClick={() => dispatch(openEditTaskDialog(t))}
        />
      </>
    )
  }

  const renderChildren = (children) => {
    if (children && children.length > 0) {
      const subtasks = children.map((c) => {
        return <Subtask key={`agenda-timeline-subtask-${c.id}`} task={c} />
      })
      return (
        <div>
          <div>{subtasks}</div>
        </div>
      )
    }
  }

  const renderDuration = (task) => {
    let dueOn, startStats
    let dateFormat = 'h:mm a'
    let dueFormat = 'h:mm a'
    let duration = ''
    if (task.started && task.startedOn && !task.completed && task.status !== 'done') {
      startStats = (
        <div style={{ fontSize: '10px', marginTop: '5px' }}>
          <TimerIcon
            style={{ width: '10px', height: '10px', marginRight: '3px' }}
          />
          {moment(task.startedOn).fromNow()}
        </div>
      )
    }
    if (!moment(task.beginOn).isSame(moment(currentTime), 'day')) {
      dateFormat = 'D/M/YY h:mm a'
    }
    if (task.dueOn) {
      if (!moment(task.dueOn).isSame(moment(currentTime), 'day')) {
        dueFormat = 'D/M/YY h:mm a'
      }
      dueOn = (
        <span>
          &nbsp;- {moment(task.dueOn).format(dueFormat)}
        </span>
      )
      const diff = moment(task.dueOn).diff(moment(task.beginOn || task.startedOn))
      duration = (
        <span>
          &nbsp; {moment.duration(diff, 'milliseconds').format('y[y] w[w] d[d] h[h] m[m]', { trim: 'both' })}
        </span>
      )
    }
    const time = (
      <div style={{ fontSize: '10px', margin: '5px 0' }}>
        {moment(task.beginOn || task.startedOn).format(dateFormat)}{dueOn}
        {duration}
        {startStats}
      </div>
    )
    return time
  }

  const dotStyle = { cursor: 'pointer' }
  const connectorStyle = {}
  // let hr
  if (item.startedOn) { dotStyle.border = '2px solid #f59393' }
  if (moment(currentTime).isSameOrAfter(item.beginOn)) {
    dotStyle.border = '2px solid #f59393'
  }
  if (next && next.beginOn &&
    moment(currentTime).isSameOrAfter(next.beginOn)) {
    connectorStyle.backgroundColor = '#f59393'
  }
  const hrStyle = {
    fontSize: '15px',
    textAlign: 'left',
    paddingBottom: '15px',
    fontFamily: 'Playfair Display',
    cursor: 'pointer'
  }
  let hr = (
    <div style={hrStyle}>
      <Tooltip
        title={moment(item.beginOn || item.startedOn).format('MMMM Do')}
        placement='top'>
        <span>{moment(item.beginOn || item.startedOn).format('dddd')}</span>
      </Tooltip>
    </div>)
  if (moment(item.beginOn).isSame(moment(), 'day')) {
    hr = (
      <div style={hrStyle}>
        <Tooltip
          title={moment(item.beginOn || item.startedOn).format('MMMM Do')}
          placement='top'>
          <span>Today</span>
        </Tooltip>
      </div>)
  }
  if (prev && prev.beginOn) {
    if (moment(item.beginOn).isSame(prev.beginOn, 'day')) {
      hr = ''
    }
  }
  return (
    <>
      {visible ? (
        <TimelineItem key={`agenda-timeline-${item.id}`}>
          <TimelineOppositeContent
            style={{ flex: 0, fontSize: '10px', marginTop: '15px' }}
            color='text.secondary'>
            {moment(item.beginOn || item.startedOn).format('hh:mm a')}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <Tooltip title='Complete' placement='top'>
              <TimelineDot
                style={dotStyle}
                onClick={() => {
                  const task = {
                    id: item.id,
                    status: 'done',
                    completed: true,
                    completedOn: new Date()
                  }
                  dispatch(createTask(task))
                }}
                color='inherit'>
                <BulletIcon type={item.type} style={{ color: '#2ca5e0' }} />
              </TimelineDot>
            </Tooltip>
            {!next ? '' : <TimelineConnector style={connectorStyle} />}
          </TimelineSeparator>
          <TimelineContent style={{ marginTop: '10px' }}>
            {hr}
            <Linkify>{item.title}</Linkify>
            <div style={{ float: 'right', textAlign: 'right' }}>
              {renderActions(item)}
            </div>
            {renderDuration(item)}
            {renderDescription(item)}
            {renderChildren(item.children)}
          </TimelineContent>
        </TimelineItem>) : ''}
    </>
  )
}

AgendaItem.propTypes = {
  task: PropTypes.object,
  prev: PropTypes.object,
  next: PropTypes.object,
  currentTime: PropTypes.number,
  showAllDays: PropTypes.bool
}

const AgendaTimeline = ({
  date, before, after, header, currentTime,
  showAllDays, footer
}) => {
  const dispatch = useDispatch()
  const {
    agendaTasks,
    isGettingAgendaTasks,
    createdTask,
    createdSubtask
  } = useSelector((state) => {
    return {
      agendaTasks: state.bullets.agendaTasks,
      isGettingAgendaTasks: state.bullets.isGettingAgendaTasks,
      createdTask: state.bullets.createdTask,
      createdSubtask: state.bullets.createdSubtask
    }
  })
  const [items, setItems] = useState({})
  useEffect(() => {
    dispatch(getAgendaTasks())
  }, [])

  useEffect(() => {
    if (createdTask.beginOn && createdTask.dueOn &&
      !createdTask.completed && !createdTask.deleted) {
      dispatch(getAgendaTasks())
    }
    if (createdTask.completed && items[createdTask.id]) {
      const data = { ...items }
      delete data[createdTask.id]
      setItems(data)
    }
    if (createdTask.deleted && items[createdTask.id]) {
      const data = { ...items }
      delete data[createdTask.id]
      setItems(data)
    }
  }, [createdTask])

  useEffect(() => {
    if (createdSubtask.parentId && items[createdSubtask.parentId]) {
      dispatch(getAgendaTasks())
    }
    if (createdSubtask.beginOn && createdSubtask.dueOn &&
      !createdSubtask.completed && !createdSubtask.deleted) {
      dispatch(getAgendaTasks())
    }
    if (createdSubtask.completed && items[createdSubtask.id]) {
      const data = { ...items }
      delete data[createdSubtask.id]
      setItems(data)
    }
    if (createdSubtask.deleted && items[createdSubtask.id]) {
      const data = { ...items }
      delete data[createdSubtask.id]
      setItems(data)
    }
  }, [createdSubtask])

  useEffect(() => {
    const data = { ...items }
    const tasks = [...agendaTasks]
    const taskChildren = []
    tasks.forEach((t) => {
      if (t.children) {
        t.children
          .filter((c) => c.beginOn && c.dueOn)
          .forEach((c) => {
            taskChildren.push(c)
          })
      }
    })
    const mergedTasks = [...agendaTasks, ...taskChildren]
    mergedTasks
      .forEach((t) => {
        data[t.id] = t
      })
    setItems(data)
  }, [agendaTasks])

  const renderedDate = (
    <div>
      <div style={{
        fontSize: '24px',
        fontFamily: 'Playfair Display',
        fontWeight: 700
      }}>
        {moment(date).format('dddd')}
      </div>
      <div style={{ fontSize: '15px', fontFamily: 'Playfair Display', fontWeight: 400, marginTop: '-15px' }}>
        {moment(date).format('MMMM Do')}
      </div>
    </div>
  )
  const entries = Object.entries(items)
  return (
    <div>
      {date ? renderedDate : ''}
      <div style={{ float: 'right' }}>
        &nbsp;
      </div>
      {header}
      <Timeline position='right'>
        {after}
        {!isGettingAgendaTasks ? entries.sort((a, b) => {
          return new Date(a[1].beginOn) - new Date(b[1].beginOn)
        }).map((i, index) => {
          const key = i[0]
          const prev = entries[index - 1] && entries[index - 1][1] ? entries[index - 1][1] : undefined
          const next = entries[index + 1] && entries[index + 1][1] ? entries[index + 1][1] : undefined
          return (
            <AgendaItem key={`agenda-item-${key}`}
              showAllDays={showAllDays}
              task={items[key]}
              prev={prev} next={next}
              currentTime={currentTime} />
          )
        }) : <CircularProgress />}
        {before}
      </Timeline>
      {footer}
    </div>
  )
}

AgendaTimeline.propTypes = {
  date: PropTypes.object,
  before: PropTypes.object,
  after: PropTypes.object,
  header: PropTypes.object,
  footer: PropTypes.object,
  showAllDays: PropTypes.bool,
  currentTime: PropTypes.number
}

export default AgendaTimeline
