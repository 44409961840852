import { createAction, handleActions } from 'redux-actions'
// import { push } from 'react-router-redux'
import { endLoad, openAlert, startLoad } from 'store/modules/site'
import { sendApiRequest } from 'utils/api'
import { sessionService } from 'redux-react-session'

export const AUTHENTICATE = 'AUTHENTICATE'
export const authenticate = () => {
  return (dispatch) => {
    dispatch(createAction(AUTHENTICATE)())
    // refresh token
    const getSession = sessionService.loadSession()
      .then(currentSession => {
        return currentSession
      })
      .catch(err => {
        return err
      })
    const getUser = sessionService.loadUser()
      .then(currentUser => {
        return currentUser
      })
      .catch(err => {
        return err
      })
    Promise.all([getSession, getUser]).then(res => {
      if (res[0] && res[0].token) {
        dispatch(loginSuccess(res[0]))
      }
      if (res[1] && res[1].email) {
        dispatch(getUserDataSuccess(res[1]))
      }
    })
  }
}

export const LOGIN = 'LOGIN'
export const login = (data) => {
  return (dispatch) => {
    dispatch(createAction(LOGIN)())
    sendApiRequest('users/login', data, 'post').then((response) => {
      if (response.status === 'failure') {
        dispatch(loginError(response.data))
      }
      if (response.status === 'success') {
        dispatch(loginSuccess(response.data))
        dispatch(getUserData())
        // console.log(response.data)
        sessionService.saveSession(response.data)
      }
    })
  }
}

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const loginSuccess = createAction(LOGIN_SUCCESS)

export const LOGIN_ERROR = 'LOGIN_ERROR'
export const loginError = (data) => {
  return (dispatch) => {
    dispatch(createAction(LOGIN_ERROR)(data.message))
    sessionService.deleteSession()
    sessionService.deleteUser()
  }
}

export const GET_USER_DATA = 'GET_USER_DATA'
export const getUserData = () => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_USER_DATA)())
    const token = getState().auth.token
    sendApiRequest('users/me', {
      token
    }, 'get').then((response) => {
      if (response.status === 'success') {
        dispatch(getUserDataSuccess(response.data))
        sessionService.saveUser(response.data)
      }
    })
  }
}

export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS'
export const getUserDataSuccess = createAction(GET_USER_DATA_SUCCESS)

export const GET_USERS = 'GET_USERS'
export const getUsers = () => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_USERS)())
    const token = getState().auth.token
    sendApiRequest('users', {
      token
    }, 'get').then((response) => {
      if (response.status === 'success') {
        dispatch(getUsersSuccess(response.data))
      }
    })
  }
}

export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const getUsersSuccess = createAction(GET_USERS_SUCCESS)

export const CREATE_USER = 'CREATE_USER'
export const createUser = (data) => {
  return (dispatch, getState) => {
    dispatch(createAction(CREATE_USER)())
    dispatch(startLoad())
    data.token = getState().auth.token
    let url = 'users'
    let type = 'post'
    if (data.id) {
      url = `users/${data.id}`
      type = 'patch'
    }
    sendApiRequest(url, data, type).then((response) => {
      dispatch(endLoad())
      if (response.status === 'failure') {
        dispatch(createUserError(response))
      }
      if (response.status === 'success') {
        dispatch(createUserSuccess(response.data))
        dispatch(openAlert({
          message: 'User created',
          severity: 'success'
        }))
      }
    })
  }
}

export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const createUserSuccess = createAction(CREATE_USER_SUCCESS)

export const CREATE_USER_ERROR = 'CREATE_USER_ERROR'
export const createUserError = createAction(CREATE_USER_ERROR)

// export const REFRESH_TOKEN = 'REFRESH_TOKEN'
//
// export const refreshToken = () => {
//   return (dispatch, getState) => {
//     dispatch(createAction(GET_USER_DATA)())
//     const token = getState().auth.token
//     sendApiRequest('token/refresh', {
//       token
//     }, 'get').then((response) => {
//       if (response.status === 'success') {
//         dispatch(getUserDataSuccess(response.data))
//         sessionService.saveUser(response.data)
//       }
//     })
//   }
// }

export const LOGOUT = 'LOGOUT'
export const logout = () => {
  return (dispatch, getState) => {
    const token = getState().auth.token
    dispatch(createAction(LOGOUT)())
    sendApiRequest('logout', {
      token
    }, 'post').then(() => {
      sessionService.deleteSession()
      sessionService.deleteUser()
      // dispatch(push('/login'))
    })
  }
}

const initialState = {
  isLoggingIn: false,
  hasLoggedIn: false,
  loginErrorMsg: '',
  loginSuccessMsg: '',
  user: {},
  token: '',
  isGettingUsers: false,
  hasGottenUsers: false,
  users: [],
  usersCount: 0,
  isCreatingUser: false,
  hasCreatedUser: false,
  createdUser: {}
}

export default handleActions({

  [LOGIN]: (state) => ({
    ...state,
    isLoggingIn: true,
    hasLoggedIn: false,
    token: '',
    user: {},
    loginErrorMsg: '',
    loginSuccessMsg: ''
  }),

  [LOGIN_SUCCESS]: (state, { payload }) => ({
    ...state,
    isLoggingIn: false,
    hasLoggedIn: true,
    token: payload.token,
    user: payload,
    loginErrorMsg: '',
    loginSuccessMsg: 'Logged in!'
  }),

  [GET_USER_DATA_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      user: payload
    }
  },

  [LOGIN_ERROR]: (state, { payload }) => ({
    ...state,
    isLoggingIn: false,
    hasLoggedIn: false,
    user: {},
    token: '',
    loginErrorMsg: payload,
    loginSuccessMsg: ''
  }),

  [LOGOUT]: (state) => ({
    ...state,
    isLoggingIn: false,
    hasLoggedIn: false,
    token: '',
    loginErrorMsg: {},
    user: {}
  }),

  [GET_USERS]: (state) => ({
    ...state,
    isGettingUsers: true,
    hasGottenUsers: false,
    users: [],
    usersCount: 0
  }),

  [GET_USERS_SUCCESS]: (state, { payload }) => ({
    ...state,
    isGettingUsers: false,
    hasGottenUsers: true,
    users: payload.users,
    usersCount: payload.usersCount
  }),

  [CREATE_USER]: (state) => ({
    ...state,
    isCreatingUser: true,
    hasCreatedUser: false,
    createdUser: {}
  }),

  [CREATE_USER_SUCCESS]: (state, { payload }) => ({
    ...state,
    isCreatingUser: false,
    hasCreatedUser: true,
    createdUser: payload
  })

}, initialState)
