import { Button, Container, Grid, MenuItem, Select, Dialog } from '@mui/material'
// import Moment from 'moment'
// import { extendMoment } from 'moment-range'
import { DateRangePicker } from 'react-date-range'
import DashboardWidget from 'components/Dashboard/DashboardWidget'
import {
  // addMonths, addWeeks,
  endOfMonth,
  // endOfWeek,
  startOfMonth,
  // startOfWeek
} from 'date-fns'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  createTransaction, getAccounts,
  getTransactions,
  getTransactionTypes
} from 'store/modules/bullets'
import TransactionCard from './TransactionCard'
import SearchBar from 'components/Site/SearchBar'
import moment from 'moment'

const queryString = require('query-string')

const propTypes = {
  getAccounts: PropTypes.func,
  accounts: PropTypes.array,
  history: PropTypes.object,
  getTransactionTypes: PropTypes.func,
  location: PropTypes.object,
  getTransactions: PropTypes.func,
  transactions: PropTypes.array,
  transactionTypes: PropTypes.array,
  isCreatingTransaction: PropTypes.bool,
  hasCreatedTransaction: PropTypes.bool,
  createTransaction: PropTypes.func,
  isGettingTransactionTypes: PropTypes.bool,
  createdTransaction: PropTypes.object,
}
class Dashboard extends Component {
  constructor (props) {
    super(props)
    this.tableRef = React.createRef()
    this.state = {
      dateRangeIsOpen: false,
      dateRange: {
        startDate: startOfMonth(new Date()),
        endDate: endOfMonth(new Date()),
      },
      dateRanges: [{
        startDate: startOfMonth(new Date()),
        endDate: endOfMonth(new Date()),
        key: 'selection'
      }],
      calRange: [],
      debitTransactions: [],
      creditTransactions: [],
      debitTotal: 0,
      creditTotal: 0,
      jointDebitTotal: 0,
      jointCreditTotal: 0,
      transactionTypes: [],
      transactionTypesAmounts: {},
      transactionTypeFilterId: 0,
      transactionOrderBy: '',
      selectedType: {},
      transactionType: {},
      searchText: ''
    }
  }

  componentDidMount () {
    this.props.getTransactionTypes()
    this.props.getAccounts()
    const parsed = queryString.parse(this.props.location.search)
    if (parsed && parsed.startDate && parsed.endDate) {
      this.setState({
        dateRange: {
          startDate: parsed.startDate,
          endDate: parsed.endDate
        },
        dateRanges: [{
          startDate: moment(parsed.startDate).toDate(),
          endDate: moment(parsed.endDate).toDate(),
          key: 'selection'
        }]
      })
      this.props.getTransactions({
        dateRange: {
          startDate: parsed.startDate,
          endDate: parsed.endDate
        }
      })
    } else {
      this.props.getTransactions({ dateRange: this.state.dateRange })
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.transactions !== prevProps.transactions) {
      this.setTransactionsState(this.props.transactions)
    }
    if (this.props.transactionTypes !== prevProps.transactionTypes) {
      this.setTransactionTypesState(this.props.transactionTypes)
    }
  }

  setTransactionTypesState (transactionTypes) {
    this.setState({ transactionTypes })
  }

  setSearchTransactionState = (searchText) => {
    this.setState({ searchText })
    this
      .setFilteredTransactionState(this.props.transactions,
        this.state.transactionTypeFilterId, searchText)
  }

  // TODO: use regular expressions for search

  setFilteredTransactionState (transactions, typeId = 0, searchText = '') {
    let debitTotal = 0; let creditTotal = 0; let jointDebitTotal = 0
    let jointCreditTotal = 0
    const transactionTypesAmounts = {}
    const debit = transactions.filter((t) => t.entryType === 'DEBIT')
      .filter((t) => {
        if (typeId === 0) {
          return true
        } else if (typeId === t.typeId) {
          return true
        } else { return false }
      })
      .filter((t) => {
        if (this.state.searchText === '') {
          return true
        } else if (t.receiver.toLowerCase().includes(this.state.searchText.toLowerCase())) {
          return true
        } else { return false }
      })
      .map((t) => {
        if (t.type && t.type.title && t.type.title === 'Internal') {
          debitTotal += 0
        } else if (t.type && t.type.title && t.type.title === 'Joint') {
          debitTotal += 0
          jointDebitTotal += t.amount
        } else {
          debitTotal += t.amount
        }
        if (t.type && t.type.id) {
          if ({}.hasOwnProperty.call(transactionTypesAmounts, t.type.id)) {
            transactionTypesAmounts[t.type.id] += t.amount
          } else {
            transactionTypesAmounts[t.type.id] = t.amount
          }
        }
        return t
      })
    const credit = transactions.filter((t) => t.entryType === 'CREDIT')
      .filter((t) => {
        if (typeId === 0) {
          return true
        } else if (typeId === t.typeId) {
          return true
        } else { return false }
      })
      .filter((t) => {
        if (this.state.searchText === '') {
          return true
        } else if (t.payer.toLowerCase().includes(this.state.searchText.toLowerCase())) {
          return true
        } else { return false }
      })
      .map((t) => {
        // creditTotal += t.amount
        if (t.type && t.type.title && t.type.title === 'Internal') {
          creditTotal += 0
        } else if (t.type && t.type.title && t.type.title === 'Joint') {
          creditTotal += 0
          jointCreditTotal += t.amount
        } else {
          creditTotal += t.amount
        }
        return t
      })
    this.setState({
      transactionTypesAmounts,
      debitTransactions: debit,
      debitTotal: Math.round(debitTotal * 100) / 100,
      creditTotal: Math.round(creditTotal * 100) / 100,
      creditTransactions: credit,
      jointDebitTotal: Math.round(jointDebitTotal * 100) / 100,
      jointCreditTotal: Math.round(jointCreditTotal * 100) / 100,
    })
  }

  setTransactionsState (transactions) {
    let debitTotal = 0; let creditTotal = 0; let jointDebitTotal = 0
    let jointCreditTotal = 0
    const transactionTypesAmounts = {}
    const debit = transactions.filter((t) => t.entryType === 'DEBIT')
      .filter((t) => {
        if (this.state.transactionTypeFilterId === 0) {
          return true
        } else if (this.state.transactionTypeFilterId === t.typeId) {
          return true
        } else { return false }
      })
      .filter((t) => {
        if (this.state.searchText === '') {
          return true
        } else if (t.receiver.toLowerCase().includes(this.state.searchText.toLowerCase())) {
          return true
        } else { return false }
      })
      .map((t) => {
        if (t.type && t.type.title && t.type.title === 'Internal') {
          debitTotal += 0
        } else if (t.type && t.type.title && t.type.title === 'Joint') {
          debitTotal += 0
          jointDebitTotal += t.amount
        } else {
          debitTotal += t.amount
        }
        if (t.type && t.type.id) {
          if ({}.hasOwnProperty.call(transactionTypesAmounts, t.type.id)) {
            transactionTypesAmounts[t.type.id] += t.amount
          } else {
            transactionTypesAmounts[t.type.id] = t.amount
          }
        }
        return t
      })
    const credit = transactions.filter((t) => t.entryType === 'CREDIT')
      .filter((t) => {
        if (this.state.transactionTypeFilterId === 0) {
          return true
        } else if (this.state.transactionTypeFilterId === t.typeId) {
          return true
        } else { return false }
      })
      .filter((t) => {
        if (this.state.searchText === '') {
          return true
        } else if (t.payer.toLowerCase().includes(this.state.searchText.toLowerCase())) {
          return true
        } else { return false }
      })
      .map((t) => {
        if (t.type && t.type.title && t.type.title === 'Internal') {
          creditTotal += 0
        } else if (t.type && t.type.title && t.type.title === 'Joint') {
          creditTotal += 0
          jointCreditTotal += t.amount
        } else {
          creditTotal += t.amount
        }
        // creditTotal += t.amount
        return t
      })
    this.setState({
      transactionTypesAmounts,
      debitTransactions: debit,
      debitTotal: Math.round(debitTotal * 100) / 100,
      creditTotal: Math.round(creditTotal * 100) / 100,
      jointDebitTotal: Math.round(jointDebitTotal * 100) / 100,
      jointCreditTotal: Math.round(jointCreditTotal * 100) / 100,
      creditTransactions: credit
    })
  }

  renderDatePicker = () => {
    return (
      <div>
        <Button variant='contained' color='primary' onClick={() => {
          this.setState({ dateRangeIsOpen: !this.state.dateRangeIsOpen })
        }}>Date Picker
        </Button>
        <Dialog onClose={() => { this.setState({ dateRangeIsOpen: false }) }}
          open={this.state.dateRangeIsOpen}>
          <DateRangePicker
            open={this.state.dateRangeIsOpen}
            onChange={(ranges) => {
              this.props
                .history.push(
                `/admin/finance/dashboard?startDate=${ranges.selection.startDate}&endDate=${ranges.selection.endDate}`)
              this.setState({ dateRanges: [ranges.selection], dateRange: ranges.selection })
              this.props.getTransactions({ dateRange: ranges.selection })
            }}
            direction='horizontal'
            showPreview={false}
            ranges={this.state.dateRanges}
          />
        </Dialog>
      </div>
    )
  }

  renderTransactionTypeSelect (types) {
    const renderedTypes = types
      .map((t) => {
        return <MenuItem data-type={JSON.stringify(t)} key={t.id} value={t.id}>{t.title}</MenuItem>
      })
    return (
      <Select
        labelId='transaction-type-select-label'
        id='transaction-type-select'
        value={this.state.transactionTypeFilterId}
        onChange={(e) => {
          // console.log(e.currentTarget.getAttribute('data-type'))
          const transactionType = JSON.parse(e.currentTarget.getAttribute('data-type'))
          this.setState({ transactionTypeFilterId: e.target.value, transactionType })
          this.setFilteredTransactionState(this.props.transactions, e.target.value)
        }}
      >
        <MenuItem data-type={JSON.stringify({})} key={0} value={0}>
          All
        </MenuItem>
        {renderedTypes}
      </Select>
    )
  }

  // componentDidUpdate (prevProps) {
  // }

  renderBudgets (transactionTypes) {
    const arr = transactionTypes
      .filter((t) => t.showBudget)
      .map((t) => {
        let amt = 0
        if (this.state.transactionTypesAmounts[t.id]) {
          amt = Math.round(this.state.transactionTypesAmounts[t.id] * 100) / 100
        }
        let body = (
          <div>
            ${amt}
          </div>
        )
        let targetPercentage = 0; let budget = 0
        if (t.budget) {
          budget = t.budget
          body = (
            <div>
              ${amt} / ${t.budget}
            </div>
          )
          targetPercentage = (amt / t.budget) * 100
        }
        return (
          <Grid item xs key={t.id}>
            <DashboardWidget
              title={t.title}
              body={body}
              subtitle='Retrieved from Log'
              amount={amt}
              target={budget}
              targetPercentage={targetPercentage}
            />
          </Grid>
        )
      })
    return (
      <Grid item xs={12}>
        <Grid container spacing={3}>
          {arr}
        </Grid>
      </Grid>
    )
  }

  renderAccounts (accounts) {
    const arr = accounts.map((account) => {
      return (
        <Grid item xs={6} md={3} key={account.id}>
          <DashboardWidget
            title={account.title}
            body={'$' + account.amount}
            subtitle='Retrieved from Log'
            amount={account.amount}
            hideBarChart
          />
        </Grid>
      )
    })
    return (
      <Grid item xs={12}>
        <Grid container spacing={3}>
          {arr}
        </Grid>
      </Grid>
    )
  }

  renderTransactions () {
    const debit = this.state.debitTransactions
      .map((t) => {
        return (
          <TransactionCard
            key={t.id}
            isCreatingTransaction={this.props.isCreatingTransaction}
            hasCreatedTransaction={this.props.hasCreatedTransaction}
            createTransaction={this.props.createTransaction}
            getTransactionTypes={this.props.getTransactionTypes}
            isGettingTransactionTypes={this.props.isGettingTransactionTypes}
            transactionTypes={this.props.transactionTypes}
            transaction={t}
            createdTransaction={this.props.createdTransaction}
          />
        )
      })
    const credit = this.state.creditTransactions
      .map((t) => {
        return (
          <TransactionCard
            key={t.id}
            isCreatingTransaction={this.props.isCreatingTransaction}
            hasCreatedTransaction={this.props.hasCreatedTransaction}
            createTransaction={this.props.createTransaction}
            getTransactionTypes={this.props.getTransactionTypes}
            isGettingTransactionTypes={this.props.isGettingTransactionTypes}
            transactionTypes={this.props.transactionTypes}
            transaction={t}
            createdTransaction={this.props.createdTransaction}
          />
        )
      })
    return (
      <Grid item xs={12}>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <SearchBar history={this.props.history}
            callback={(value) => {
              this.setSearchTransactionState(value)
            }}
          />
          {this.renderTransactionTypeSelect(this.props.transactionTypes)}
          <br /><br />
        </Grid>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <h4>Credit</h4>
            {credit}
            <br /><br />
            Money in: ${this.state.creditTotal}
          </Grid>
          <Grid item md={6} xs={12}>
            <h4>Debit</h4>
            {debit}
            <br /><br />
            Money out: ${this.state.debitTotal}<br />
            Joint out: ${this.state.jointDebitTotal}<br />
            Total out: ${this.state.jointDebitTotal + this.state.debitTotal}
          </Grid>
        </Grid>
      </Grid>
    )
  }

  render () {
    return (
      <div id='finance-dashboard-container'>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12}><h2>Finances</h2>
              <h3>
                {moment(this.state.dateRange.startDate).format('DD/MM/YYYY')} -&nbsp;
                {moment(this.state.dateRange.endDate).format('DD/MM/YYYY')}
              </h3>
            </Grid>
            <Grid item xs={12}>
              {this.renderDatePicker()}
            </Grid>
            {this.renderAccounts(this.props.accounts)}
            {this.renderBudgets(this.props.transactionTypes)}
            {this.renderTransactions(this.props.transactions)}
          </Grid>
        </Container>
      </div>
    )
  }
}

Dashboard.propTypes = propTypes

const mapStateToProps = (state) => {
  const {
    isGettingAccounts,
    hasGottenAccounts,
    accounts,
    isGettingTransactions,
    hasGottenTransactions,
    transactions,
    isGettingTransactionTypes,
    hasGottenTransactionTypes,
    transactionTypes,
    isCreatingTransaction,
    hasCreatedTransaction,
    createdTransaction
  } = state.bullets
  return {
    isGettingAccounts,
    hasGottenAccounts,
    accounts,
    isGettingTransactions,
    hasGottenTransactions,
    transactions,
    isGettingTransactionTypes,
    hasGottenTransactionTypes,
    transactionTypes,
    isCreatingTransaction,
    hasCreatedTransaction,
    createdTransaction
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({
    getAccounts,
    getTransactions,
    createTransaction,
    getTransactionTypes
  }, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard)
