import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ReactDataGrid, { SelectColumn } from 'react-data-grid'
// import { Button } from '@mui/material'
// import { openAlert } from 'store/modules/site'

const propTypes = {
  getAccounts: PropTypes.func,
  accounts: PropTypes.array,
  // history: PropTypes.object,
  isGettingAccounts: PropTypes.bool,
  createAccount: PropTypes.func,
}

class TransactionsTable extends Component {
  constructor (props) {
    super(props)
    this.tableRef = React.createRef()
    this.state = {
      rows: [],
      columns: [
        { key: 'date', name: 'Date' },
        { key: 'payer', name: 'Payer' },
        { key: 'receiver', name: 'Receiver' },
        { key: 'amount', name: 'Amount' },
        { key: 'entryType', name: 'Entry' }
      ]
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.transactions !== prevProps.transactions) {
      this.setRowState(this.props.transactions)
    }
  }

  setRowState (transactions) {
    if (transactions) {
      this.setState({ rows: transactions })
    }
  }

  render () {
    return (
      <div id='transactions-table-container' style={{ marginTop: '20px' }}>
        <ReactDataGrid
          columns={this.state.columns}
          rows={this.state.rows}
          rowGetter={i => this.state.rows[i]}
        />
      </div>
    )
  }
}

TransactionsTable.propTypes = propTypes

const mapStateToProps = (state) => {
  // const {
  //   isGettingAccounts,
  //   hasGottenAccounts,
  //   accounts,
  //   isCreatingAccount,
  //   hasCreatedAccount,
  //   createdHabit
  // } = state.bullets
  return {
    // isGettingAccounts,
    // hasGottenAccounts,
    // accounts,
    // isCreatingAccount,
    // hasCreatedAccount,
    // createdHabit
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({
    // getAccounts,
    // createAccount
  }, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionsTable)
