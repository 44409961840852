import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { selectBoard } from 'store/modules/bullets'
import { Grid, Switch } from '@mui/material'

class SelectBoardSwitch extends Component {
  handleChange = (e) => {
    if (e.target.checked) {
      this.props.selectBoard('work')
    } else {
      this.props.selectBoard('personal')
    }
  }

  render () {
    return (
      <Grid component='label' container alignItems='center' spacing={1}>
        <Grid item>Personal</Grid>
        <Grid item>
          <Switch checked={this.props.boardSwitchIsOn}
            onChange={this.handleChange} name='checkedC' />
        </Grid>
        <Grid item>Work</Grid>
      </Grid>
    )
  }
}

SelectBoardSwitch.propTypes = {
  boardSwitchIsOn: PropTypes.bool,
  selectBoard: PropTypes.func
}

const mapStateToProps = (state) => {
  const {
    selectedBoard,
    boardSwitchIsOn
  } = state.bullets
  return {
    selectedBoard,
    boardSwitchIsOn
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({
    selectBoard
  }, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectBoardSwitch)
