const Create = require('./Create').default
export default (store) => ({
  childRoutes: [
    {
      path: '/admin/content/create',
      key: 'admin-content-create',
      getComponent: Create,
      // getComponent: () => {
      //   const Create = require('./Create').default
      //   return Create
      // },
      linkTitle: 'Create Content'
      // onEnter: requireAuth(store),
    },
    {
      path: '/admin/content/edit/:id',
      key: 'admin-edit-create',
      getComponent: Create,
      // getComponent: () => {
      //   const Create = require('./Create').default
      //   return Create
      // },
      linkTitle: 'Edit Content'
      // onEnter: requireAuth(store),
    }
  ]
})
