import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getHabits, getEpics, createHabit } from 'store/modules/bullets'
import {
  Button
} from '@mui/material'
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'
import DatagridAddFormDialog from 'components/Site/DatagridAddFormDialog'
import DeleteIcon from '@mui/icons-material/Delete'

const List = () => {
  const dispatch = useDispatch()
  const {
    epics, habits,
    user, isGettingHabits
  } = useSelector((state) => {
    const {
      isGettingHabits,
      habits,
      epics
    } = state.bullets
    const { user } = state.auth
    return {
      isGettingHabits,
      habits,
      epics,
      user
    }
  })

  const [epicsLookup, setEpicsLookup] = useState({})
  const [rows, setRows] = useState([])
  const [addDialogIsOpen, setAddDialogIsOpen] = useState(false)
  useEffect(() => {
    dispatch(getHabits())
    dispatch(getEpics())
  }, [])
  useEffect(() => {
    setEpicsLookup(getLookup(epics))
  }, [epics])
  useEffect(() => {
    setRows(getRows(habits))
  }, [habits])

  const getRows = (habitsArr) => {
    return habitsArr.map((h) => {
      const obj = {
        id: h.id,
        title: h.title,
        name: h.name,
        target: h.target,
        targetDuration: h.targetDuration,
        active: h.active,
        epicId: h.epicId
      }
      return obj
    })
  }

  const getLookup = (epicArr) => {
    const epicsLookup = {}
    epicArr.forEach((e) => {
      epicsLookup[e.id] = e.title
    })
    return epicsLookup
  }

  const columns = [
    { headerName: 'Title', field: 'title', width: 150, editable: true },
    { headerName: 'Hashtag', field: 'name', width: 150, editable: true },
    { headerName: 'Target', field: 'target', type: 'number', editable: true },
    {
      headerName: 'Target Duration',
      field: 'targetDuration',
      editable: true,
      type: 'singleSelect',
      valueOptions: ['day', 'week', 'month', 'year']
    },
    {
      headerName: 'Epic',
      field: 'epicId',
      editable: true,
      type: 'singleSelect',
      valueOptions: epics.map((e) => {
        return {
          value: e.id,
          label: e.title
        }
      }),
      valueFormatter: (params) => {
        return epicsLookup[params.value]
      }
    },
    { headerName: 'Type', field: 'type', editable: true },
    {
      headerName: 'When',
      field: 'when',
      editable: true,
      type: 'singleSelect',
      valueOptions: [
        'MORNING',
        'NOON',
        'AFTERNOON',
        'EVENING',
        'NIGHT'
      ]
    },
    { headerName: 'Active', field: 'active', type: 'boolean', editable: true },
    {
      headerName: 'Actions',
      field: 'actions',
      type: 'actions',
      getActions: (params) => [
        <GridActionsCellItem
          key={`del-${params.id}`}
          icon={<DeleteIcon />} onClick={() => {
            this.props.createHabit({
              deleted: true,
              id: params.row.id
            })
          }} label='Delete' />,
      ]
    }
  ]

  return (
    <div>
      <h2>Habits</h2>
      <Button variant='text' onClick={() => {
        setAddDialogIsOpen(true)
      }}>
        Add Habit
      </Button>
      <div style={{ height: '500px' }}>
        <DataGrid
          loading={isGettingHabits}
          onCellEditCommit={(params) => {
            const data = {
              modifiedOn: new Date(),
              id: params.id,
              modifiedBy: user.id
            }
            data[params.field] = params.value
            dispatch(createHabit(data))
          }}
          rows={rows}
          columns={columns} />
      </div>
      <DatagridAddFormDialog
        title='Add habit'
        columns={columns}
        createCallback={(data) => {
          data.createdBy = user.id
          dispatch(createHabit(data))
        }}
        handleClose={() => setAddDialogIsOpen(false)}
        open={addDialogIsOpen} />
    </div>
  )
}

export default List
