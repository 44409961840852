import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

const BackdropLoad = ({ handleClose, open }) => {
  const [bgOpen, setBgOpen] = useState(false)
  const onClose = () => {
    if (handleClose) {
      handleClose()
    }
    setBgOpen(false)
  }
  useEffect(() => {
    setBgOpen(open)
  }, [open])

  return (
    <div>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={bgOpen} onClick={onClose}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </div>
  )
}

BackdropLoad.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func
}

export default BackdropLoad
