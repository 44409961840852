import React from 'react'
import PropTypes from 'prop-types'
import NoteIcon from '@mui/icons-material/Note'
import LoopIcon from '@mui/icons-material/Loop'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import MoodIcon from '@mui/icons-material/Mood'
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied'
import MoodBadIcon from '@mui/icons-material/MoodBad'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
const iconColors = {
  note: '#f2c888',
  habit: '#c21cd2',
  task: '#0b8100',
  mood: '#ad0303',
  event: '#0557a0',
  disabled: '#cbcbcb',
  moodBad: '#367daf',
  moodGood: '#c98300'
}
const icons = {
  note: NoteIcon,
  habit: LoopIcon,
  task: CheckCircleIcon,
  mood: SentimentSatisfiedIcon,
  moodGood: MoodIcon,
  moodBad: MoodBadIcon,
  event: PlayCircleOutlineIcon,
  default: CheckCircleIcon
}

export default function BulletIcon (props) {
  const Icon = icons[props.type] || icons.default
  const color = props.disabled === true ? iconColors.disabled : iconColors[props.type]
  const style = Object.assign({ color }, props.style) || { color }
  return (<Icon style={style} onClick={props.onClick} />)
}

BulletIcon.propTypes = {
  type: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  onClick: PropTypes.func
}
