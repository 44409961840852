import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormControl, MenuItem, Select, InputLabel, Button } from '@mui/material'
import {
  getAccounts, createAccount, uploadTransactionFile,
  importTransactions
} from 'store/modules/bullets'
import UploadFileButton from 'components/Site/UploadFileButton'
import TransactionsTable from './TransactionsTable'
import { openAlert } from 'store/modules/site'
// import { openAlert } from 'store/modules/site'

const propTypes = {
  getAccounts: PropTypes.func,
  accounts: PropTypes.array,
  uploadTransactionFileSuccess: PropTypes.object,
  uploadTransactionFile: PropTypes.func,
  hasImportedTransactions: PropTypes.bool,
  importTransactions: PropTypes.func,
  openAlert: PropTypes.func
  // history: PropTypes.object,
  // isGettingAccounts: PropTypes.bool,
  // createAccount: PropTypes.func,
}

class Import extends Component {
  constructor (props) {
    super(props)
    this.tableRef = React.createRef()
    this.state = {
      selectedAccountId: 0,
      selectedAccountTitle: '',
      transactions: []
    }
  }

  componentDidMount () {
    this.props.getAccounts()
  }

  componentDidUpdate (prevProps) {
    if (this.props.uploadTransactionFileSuccess !== prevProps.uploadTransactionFileSuccess) {
      this.setRowState(this.props.uploadTransactionFileSuccess)
    }
  }

  setRowState (transactions) {
    if (transactions.data) {
      this.setState({ transactions: transactions.data })
    }
  }

  handleSelectAccountChange = (e) => {
    this.setState({
      selectedAccountId: e.target.value,
      selectedAccountTitle: e.currentTarget.getAttribute('data-title')
    })
  }

  renderSelectAccount (acc) {
    const accounts = acc.map((a, i) => {
      return (<MenuItem key={i} value={a.id} data-title={a.title}>{a.title}</MenuItem>)
    })
    return (
      <FormControl style={{ width: '100px', marginTop: '10px', textAlign: 'left' }}>
        <InputLabel id='select-account'>Account</InputLabel>
        <Select
          labelId='select-account-label'
          id='select-account-select'
          value={this.state.selectedAccountId}
          onChange={this.handleSelectAccountChange}
          style={{ width: '100%' }}
          required
          error={this.state.selectedAccountId === 0}
          helperText='Select an account'
        >
          {accounts}
        </Select>
      </FormControl>
    )
  }

  render () {
    return (
      <div id='import-transactions-container'>
        <h2>Import Transactions</h2>
        <div style={{ marginBottom: '15px' }}>
          <UploadFileButton
            callback={(file) => {
              this.props.uploadTransactionFile(file, {
                accountId: this.state.selectedAccountId,
                accountTitle: this.state.selectedAccountTitle
              })
            }}
          />
          {this.renderSelectAccount(this.props.accounts)}
          <TransactionsTable transactions={this.state.transactions} />
          <div style={{ textAlign: 'right', marginTop: '10px' }}>
            <Button
              variant='contained' color='primary'
              disabled={this.props.hasImportedTransactions}
              onClick={() => {
                if (this.state.selectedAccountId !== 0) {
                  this.props.importTransactions({
                    transactions: this.state.transactions,
                    accountId: this.state.selectedAccountId
                  })
                } else {
                  this.props.openAlert({
                    message: 'Select an account',
                    severity: 'error',
                  })
                }
              }}>Save
            </Button>&nbsp;
            <Button
              variant='contained' color='primary'
              onClick={() => {
                window.location.reload()
              }}>Clear
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

Import.propTypes = propTypes

const mapStateToProps = (state) => {
  const {
    isGettingAccounts,
    hasGottenAccounts,
    accounts,
    isCreatingAccount,
    hasCreatedAccount,
    createdAccount,
    isUploadingTransactionFile,
    hasUploadedTransactionFile,
    uploadTransactionFileError,
    uploadTransactionFileSuccess,
    isImportingTransactions,
    hasImportedTransactions,
    importedTransactions
  } = state.bullets
  return {
    isGettingAccounts,
    hasGottenAccounts,
    accounts,
    isCreatingAccount,
    hasCreatedAccount,
    createdAccount,
    isUploadingTransactionFile,
    hasUploadedTransactionFile,
    uploadTransactionFileError,
    uploadTransactionFileSuccess,
    isImportingTransactions,
    hasImportedTransactions,
    importedTransactions
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({
    getAccounts,
    createAccount,
    uploadTransactionFile,
    importTransactions,
    openAlert
  }, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Import)
