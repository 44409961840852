import {
  Button,
  Dialog, DialogActions, DialogContent,
  DialogTitle, Grid, MenuItem, Select, TextField, Tooltip
} from '@mui/material'
import Card from '@mui/material/Card'
// import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import withStyles from '@mui/styles/withStyles'
import DebitIcon from '@mui/icons-material/AccountBalance'
import CreditIcon from '@mui/icons-material/CreditCard'
// import BackspaceIcon from '@mui/icons-material/Backspace'
import EditAttributesIcon from '@mui/icons-material/EditAttributes'
import { DateTimePicker } from '@mui/lab'
// import Button from '@mui/material/Button'
// import Typography from '@mui/material/Typography'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
// import Linkify from 'react-linkify'
import { Link } from 'react-router-dom'
// const isMobile = require('is-mobile')

const styles = theme => ({
  root: {
    minWidth: 275,
  },
  bull: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
})
class TransactionEditDialog extends Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false,
      date: props.transaction.date,
      transaction: props.transaction,
      title: props.transaction.title,
      payer: props.transaction.payer,
      receiver: props.transaction.receiver,
      amount: props.transaction.amount,
      entryType: props.transaction.entryType,
      transactionTypes: props.transactionTypes,
      selectedType: props.transaction.typeId,
      transactionType: {}
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.hasCreatedTransaction !== prevProps.hasCreatedTransaction) {
      this.handleClose()
    }
    if (this.props.transaction !== prevProps.transaction) {
      this.setTransactionState(this.props.transaction)
    }
  }

  setTransactionState (transaction) {
    this.setState({
      date: transaction.date,
      transaction: transaction,
      title: transaction.title,
      payer: transaction.payer,
      receiver: transaction.receiver,
      amount: transaction.amount,
      entryType: transaction.entryType,
      selectedType: transaction.typeId
    })
  }

  handleClickOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  handleDateChange = (date) => {
    this.setState({ date })
  }

  handleDelete = () => {
    this.props.createTransaction({
      id: this.props.transaction.id,
      deleted: true,
      modifiedOn: new Date()
    })
  }

  handleEdit = () => {
    this.props.updateTransactionCard({
      id: this.props.transaction.id,
      title: this.state.title,
      date: this.state.date,
      modifiedOn: new Date(),
      typeId: this.state.selectedType,
      type: this.state.transactionType,
    })
    this.props.createTransaction({
      id: this.props.transaction.id,
      title: this.state.title,
      date: this.state.date,
      modifiedOn: new Date(),
      typeId: this.state.selectedType,
    })
  }

  handleChangeBody = (event) => {
    this.setState({ title: event.target.value })
  }

  handleChangeText = (e, name) => {
    const oldState = this.state
    oldState[name] = e.target.value
    this.setState(oldState)
  }

  renderTransactionTypes (types) {
    const renderedTypes = types.map((t) => {
      return <MenuItem data-type={JSON.stringify(t)} key={t.id} value={t.id}>{t.title}</MenuItem>
    })
    return (
      <Select
        labelId='transaction-type-edit-label'
        id='transaction-type-edit'
        value={this.state.selectedType}
        onChange={(e) => {
          // console.log(e.currentTarget.getAttribute('data-type'))
          const transactionType = JSON.parse(e.currentTarget.getAttribute('data-type'))
          this.setState({ selectedType: e.target.value, transactionType })
        }}
      >
        {renderedTypes}
      </Select>
    )
  }

  render () {
    return (
      <div>
        <div className='transactionActions'>
          <Tooltip title='Edit' placement='top'>
            <EditAttributesIcon
              className='transactionActionEditIcon'
              onClick={this.handleClickOpen}
            />
          </Tooltip><br />
        </div>
        <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby='form-dialog-title'>
          <DialogTitle id='form-dialog-title'>Edit Transaction</DialogTitle>
          <DialogContent>
            <DateTimePicker
              disableFuture
              renderInput={(props) => <TextField {...props} />}
              id='date-picker-inline'
              label='Date'
              value={this.state.date}
              onChange={this.handleDateChange}
            />
            <br />
            <Select
              labelId='transaction-entry-type-edit-label'
              id='transaction-entry-type-edit'
              value={this.state.entryType}
              onChange={(e) => {
                this.setState({ entryType: e.target.value })
              }}
            >
              <MenuItem value='CREDIT'>CREDIT</MenuItem>
              <MenuItem value='DEBIT'>DEBIT</MenuItem>
            </Select>&nbsp;&nbsp;
            {this.renderTransactionTypes(this.props.transactionTypes)}
            <TextField
              margin='dense'
              id='payer'
              label='Payer'
              type='text'
              fullWidth
              // multiline
              value={this.state.payer}
              onChange={(e) => this.handleChangeText(e, 'payer')}
            />
            <TextField
              margin='dense'
              id='receiver'
              label='Receiver'
              type='text'
              fullWidth
              // multiline
              value={this.state.receiver}
              onChange={(e) => this.handleChangeText(e, 'receiver')}
            />
            <TextField
              margin='dense'
              id='amount'
              label='Amount'
              type='text'
              fullWidth
              // multiline
              value={this.state.amount}
              onChange={(e) => this.handleChangeText(e, 'amount')}
            />
            <TextField
              margin='dense'
              id='body'
              label='Title'
              type='text'
              fullWidth
              multiline
              value={this.state.title}
              onChange={this.handleChangeBody}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  this.handleEdit()
                  event.preventDefault()
                }
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDelete} color='secondary'>
              Delete
            </Button>
            <Button onClick={this.handleClose} color='primary'>
              Cancel
            </Button>
            <Button type='submit' onClick={this.handleEdit} color='primary'>
              Edit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

TransactionEditDialog.propTypes = {
  transaction: PropTypes.object,
  hasCreatedTransaction: PropTypes.bool,
  createTransaction: PropTypes.func,
  transactionTypes: PropTypes.array,
  updateTransactionCard: PropTypes.func
}
class TransactionCard extends Component {
  constructor (props) {
    super(props)
    this.state = {
      transaction: {}
    }
  }

  componentDidMount () {
    if (this.props.transaction && this.props.transaction.id) {
      this.setTransactionState(this.props.transaction)
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.transaction !== prevProps.transaction) {
      this.setTransactionState(this.props.transaction)
    }
  }

  setTransactionState (transaction) {
    this.setState({ transaction })
  }

  updateTransactionCard = (transaction) => {
    const t = Object.assign(this.state.transaction, transaction)
    this.setTransactionState(t)
  }

  render () {
    const {
      createTransaction,
      hasCreatedTransaction,
      getTransactionTypes,
      transactionTypes,
      classes,
      createdTransaction
      // transaction
    } = this.props
    const { transaction } = this.state
    const bull = <span className={classes.bull}>•</span>
    const cardStyle = { marginBottom: '10px' }
    let icon, text, type
    const contentStyle = {}
    switch (transaction.entryType) {
      case 'DEBIT': {
        icon = <DebitIcon style={{ color: '#0b8100' }} />
        text = (<div>{transaction.receiver}</div>)
        break
      }
      case 'CREDIT': {
        icon = <CreditIcon style={{ color: '#ad0303' }} />
        text = (<div>{transaction.payer}</div>)
        break
      }
      default: {
        icon = <CreditIcon style={{ color: '#ad0303' }} />
      }
    }
    if (transaction.type && transaction.type.title) {
      type = <span>{transaction.type.title} {bull}</span>
    }
    return (
      <div>
        <Card id={`transaction-${transaction.id}`} className={classes.root} style={cardStyle}>
          <CardContent style={contentStyle}>
            <span className='transactionIcon'>
              <Tooltip title={transaction.entryType} placement='top'>
                {icon}
              </Tooltip>
            </span>
            <Grid container>
              <Grid item xs={9}>
                <div className='transactionText'>
                  <div>{transaction.title}</div>
                  {text}
                </div>
              </Grid>
              <Grid item xs={3}>
                <span className='transactionAmount'>
                  ${transaction.amount}
                </span>
              </Grid>
            </Grid>
            <div>
              <span className='transactionDate'>
                {type}
                <Link to={`/admin/finance/dashboard?d=${transaction.date}`}>{moment(transaction.date)
                  .format('DD/MM/YYYY')}
                </Link>
              </span>
              <TransactionEditDialog
                transaction={this.state.transaction}
                createTransaction={createTransaction}
                hasCreatedTransaction={hasCreatedTransaction}
                getTransactionTypes={getTransactionTypes}
                transactionTypes={transactionTypes}
                createdTransaction={createdTransaction}
                updateTransactionCard={this.updateTransactionCard}
                // open={true}
              />
            </div>
          </CardContent>
        </Card>
      </div>
    )
  }
}

TransactionCard.propTypes = {
  transaction: PropTypes.object,
  hasCreatedTransaction: PropTypes.bool,
  createTransaction: PropTypes.func,
  getTransactionTypes: PropTypes.func,
  transactionTypes: PropTypes.array,
  classes: PropTypes.object,
  createdTransaction: PropTypes.object
}

export default withStyles(styles)(TransactionCard)
