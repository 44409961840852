import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import {
  // createBullet,
  getOwntracksData,
  getGoogleFitData
} from 'store/modules/bullets'
import { openMapDialog } from 'store/modules/site'
import { Tooltip } from '@mui/material'
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk'

const StepsWidget = ({ date, dateRange }) => {
  const dispatch = useDispatch()
  const {
    googleFitData,
    owntracksData,
    // hasGottenGoogleFitData
  } = useSelector((state) => {
    return {
      googleFitData: state.bullets.googleFitData,
      // hasGottenGoogleFitData: state.bullets.googleFitData,
      owntracksData: state.bullets.owntracksData
    }
  })
  const [steps, setSteps] = useState(0)
  const [markers, setMarkers] = useState([])
  const stepsTarget = 5000
  useEffect(() => {
    if (dateRange) {
      dispatch(getGoogleFitData({ dateRange }))
      dispatch(getOwntracksData({ date }))
    } else {
      dispatch(getGoogleFitData({ date }))
      dispatch(getOwntracksData({ date }))
    }
  }, [date, dateRange])
  useEffect(() => {
    if (googleFitData.aggregated && googleFitData.aggregated.steps) {
      setSteps(googleFitData.aggregated.steps)
    }
  }, [googleFitData])
  useEffect(() => {
    const arr = []
    const data = owntracksData.map((d) => {
      return d.metadata
    })
    const m = arr.concat(data).flat()
    setMarkers(m)
  }, [owntracksData])

  const divStyle = { display: 'inline-block', paddingLeft: '5px' }
  const iconStyle = { position: 'relative', top: '7px', width: '20px', cursor: 'pointer' }
  const iconSuccessStyle = { ...iconStyle, color: '#0b8100' }
  return (
    <>
      <div style={divStyle}>
        <Tooltip title='Steps' placement='left'>
          <span>
            <DirectionsWalkIcon style={steps >= stepsTarget ? iconSuccessStyle : iconStyle}
              onClick={() => {
                dispatch(openMapDialog({ markers }))
              }}
            />
          </span>
        </Tooltip>
        &nbsp;{steps}
      </div>
    </>
  )
}

StepsWidget.propTypes = {
  date: PropTypes.object,
  dateRange: PropTypes.object
}

export default StepsWidget
