import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Route, Switch } from 'react-router' // react-router v4/v5
import { Container } from '@mui/material'
import AccountsList from './AccountsList'
import CategoriesList from './CategoriesList'
import Import from './Import'
import Dashboard from './Dashboard'

class FinanceContainer extends Component {
  render () {
    return (
      <div id='finance-container'>
        <Container style={{ marginTop: '40px' }}>
          <Switch>
            <Route key={1} exact path='/admin/finance/accounts' component={AccountsList} />
            <Route key={2} exact path='/admin/finance/import' component={Import} />
            <Route key={3} exact path='/admin/finance/dashboard' component={Dashboard} />
            <Route key={4} exact path='/admin/finance/categories' component={CategoriesList} />
          </Switch>
        </Container>
      </div>
    )
  }
}

FinanceContainer.propTypes = {
}

// const mapStateToProps = (state) => {
//   return {
//   }
// }

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({
  }, dispatch),
})

export default connect(
  // mapStateToProps,
  mapDispatchToProps
)(FinanceContainer)
