import axios from 'axios'
import config from 'config'
// import { browserHistory } from 'react-router'
import { sessionService } from 'redux-react-session'
// import { loginSuccess, getUserDataSuccess, logout } from 'store/modules/auth'
// import { push } from 'connected-react-router'
import { history } from 'store/createStore'
const { apiHost } = config

export const sendApiRequest = async (urlPart, data, method, options = {}) => {
  let filtered = {}
  let where = {}
  if (options.filter) {
    filtered = options.filter
  }
  if (options.where) {
    where = options.where
  }
  const url = `${apiHost}/api/${urlPart}?filter=${JSON.stringify(filtered)}&where=${JSON.stringify(where)}`
  if (!method) { method = 'get' }

  let headers = {}
  if (data.token && data.token !== '') {
    // url = url + `&token=${data.token}`
    headers = {
      Authorization: `Bearer ${data.token}`,
    }
    delete data.token
  }
  if (options.token && options.token !== '') {
    // url = url + `&token=${options.token}`
    headers = {
      Authorization: `Bearer ${options.token}`,
    }
  } else {
    try {
      const currentSession = await sessionService.loadSession()
      if (currentSession && currentSession.token) {
        // url = url + `&token=${currentSession.token}`
        headers = {
          Authorization: `Bearer ${currentSession.token}`,
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
  return axios({
    headers,
    method: method,
    url,
    data: data
  }).then(function (response) {
    // console.log(response)
    return {
      status: 'success',
      data: response.data
    }
  }).catch(function (error) {
    if (error.response) {
      let messages
      if (error.response.data.error && error.response.data.error.message) {
        messages = error.response.data.error.message
      }
      if (error.response.status === 401) {
        sessionService.deleteUser()
        sessionService.deleteSession()
        history.push('/admin/login')
        return {
          status: 'failure',
          data: error.response.data.error,
          formMessages: messages
        }
      }
      return {
        status: 'failure',
        data: error.response.data.error,
        formMessages: messages
      }
    } else {
      return {
        status: 'failure'
      }
    }
  })
}

export const sendBulkApiRequest = (urlPart, data, method, options = {}) => {
  let filtered = {}
  let where = {}
  if (Object.prototype.hasOwnProperty.call(options, 'filter')) {
    filtered = options.filter
  }
  if (Object.prototype.hasOwnProperty.call(options, 'where')) {
    where = options.where
  }
  const url =
    `${apiHost}/api/${urlPart}?filter=${JSON.stringify(filtered)}&where=${JSON.stringify(where)}`
  if (!method) { method = 'get' }

  let headers = {}
  if (data.token && data.token !== '') {
    // url = url + `&token=${data.token}`
    headers = {
      Authorization: `Bearer ${data.token}`,
    }
  }
  delete data.token
  return axios({
    headers,
    method: method,
    url,
    data: data.array
  }).then(function (response) {
    return {
      status: 'success',
      data: response.data
    }
  }).catch(function (error) {
    if (error.response) {
      let messages = ''
      if (error.response.data.error && error.response.data.error.details &&
        error.response.data.error.details.messages) {
        messages = error.response.data.error.details.messages
      }
      return {
        status: 'failure',
        data: error.response.data.error,
        formMessages: messages
      }
    }
  })
}

export const sendApiFileRequest = (urlPart, data, method, options = {}) => {
  let filtered = {}
  let where = {}
  if (Object.prototype.hasOwnProperty.call(options, 'filter')) {
    filtered = options.filter
  }
  if (Object.prototype.hasOwnProperty.call(options, 'where')) {
    where = options.where
  }
  let url = `${apiHost}/api/${urlPart}?filter=${JSON.stringify(filtered)}&where=${JSON.stringify(where)}`
  if (!method) { method = 'get' }
  let headers = { 'Content-Type': 'multipart/form-data' }
  // console.log(options)
  if (options.token || data.token) {
    const token = options.token || data.token
    url = url + `&token=${token}`
    // headers = {
    //   Authorization: `Bearer ${token}`,
    // }
    headers = Object.assign(headers, {
      Authorization: `Bearer ${token}`,
    })
  }
  delete data.token
  delete options.accessToken
  return axios({
    method: method,
    url,
    data: data,
    headers
  }).then(function (response) {
    return {
      status: 'success',
      data: response.data
    }
  }).catch(function (error) {
    if (error.response) {
      let messages
      if (error.response.data.error && error.response.data.error.details &&
        error.response.data.error.details.messages) {
        messages = error.response.data.error.details.messages
      }
      return {
        status: 'failure',
        data: error.response.data.error,
        formMessages: messages
      }
    }
  })
}

// export const getCoordsFromAddress = (address) => {
//   const encoded = encodeURIComponent(address)
//   const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encoded}&key=${googleGeocodingApiKey}`
//   return axios({
//     method: 'get',
//     url
//   }).then(function (response) {
//     return {
//       status: 'success',
//       data: response.data.results[0].geometry.location
//     }
//   }).catch(function (error) {
//     if (error.response) {
//       return {
//         status: 'failure'
//       }
//     }
//   })
// }
