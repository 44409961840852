import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
// import { Route, Switch } from 'react-router' // react-router v4/v5
import { Container } from '@mui/material'
// import { Link } from 'react-router-dom'
// import routes from 'routes/Admin/Content'
// import List from './List'

class GitList extends Component {
  render () {
    return (
      <div>
        My git list
      </div>
    )
  }
}

class SandboxContainer extends Component {
  render () {
    return (
      <div id='sandbox-container'>
        <Container style={{ marginTop: '40px' }}>
          Sandbox for things I'm trying out
          <GitList />
        </Container>
      </div>
    )
  }
}

SandboxContainer.propTypes = {
}

// const mapStateToProps = (state) => {
//   return {
//   }
// }

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({
  }, dispatch),
})

export default connect(
  // mapStateToProps,
  mapDispatchToProps
)(SandboxContainer)
