import React from 'react'
// import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client'
import { Provider, ReactReduxContext } from 'react-redux'
// import { Route, Switch } from 'react-router' // react-router v4/v5
// import { ConnectedRouter } from 'connected-react-router'
import configureStore, { history } from './store/createStore'
import App from './App'
import { CookiesProvider } from 'react-cookie'
const store = configureStore(window.__INITIAL_STATE__)
// const routes = require('./routes/index').default(store)
const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <Provider store={store} context={ReactReduxContext}>
    <CookiesProvider>
      <App store={store} history={history} context={ReactReduxContext} />
    </CookiesProvider>
  </Provider>
)
// ReactDOM.render(
//   <Provider store={store} context={ReactReduxContext}>
//     <CookiesProvider>
//       <App store={store} history={history} context={ReactReduxContext} />
//     </CookiesProvider>
//   </Provider>,
//   document.getElementById('root')
// )
