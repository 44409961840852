import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import BulletIcon from 'components/Bullets/BulletIcon'
import moment from 'moment'
import Tooltip from '@mui/material/Tooltip'
import { Link } from 'react-router-dom'
import Linkify from 'components/Bullets/Linkify'
import { getMoodEmoji } from 'utils/utils'
import {
  openMapDialog,
  openEditBulletDialog
} from 'store/modules/site'
import {
  getBullets,
  // getBulletsWithAggregate
} from 'store/modules/bullets'
// import BulletEditDialog from 'components/Bullets/BulletEditDialog'
const momentDurationFormatSetup = require('moment-duration-format')
momentDurationFormatSetup(moment)

const BulletRating = ({ bullet }) => {
  let rating
  if (bullet.rating) {
    const stars = (bullet.rating / 100) * 5
    rating = (
      <span style={{ fontSize: '10px' }}>
        {stars}&#11088;
      </span>
    )
  }
  return rating
}

const BulletMood = ({ bullet }) => {
  let mood
  if (bullet.mood) {
    mood = (
      <span style={{ fontSize: '10px' }}>
        {bullet.mood} {getMoodEmoji(bullet.mood)}
      </span>
    )
  }
  return mood
}

const BulletLocation = ({ bullet }) => {
  const dispatch = useDispatch()
  let location
  if (bullet.lat && bullet.lng) {
    location = (
      <span style={{ fontSize: '10px' }}>
        <span onClick={() => {
          dispatch(openMapDialog({
            markers: [{ lat:bullet.lat, lng: bullet.lng, bullet: bullet }]
          }))
        }}><span role='img' aria-label='location'>📍</span>
        </span>
      </span>
    )
  }
  return location
}

const BulletDuration = ({ bullet }) => {
  let duration
  if (bullet.task && bullet.task.startedOn &&
    bullet.task.completedOn) {
    const diff = moment(bullet.task.completedOn).diff(moment(bullet.task.startedOn))
    duration = (
      <div style={{ fontSize: '10px' }}>
        {moment.duration(diff, 'milliseconds').format('y [years], w [weeks], d [days], h [hours], m [minutes]')}
      </div>
    )
  }
  if (bullet.completedOn && bullet.metadata) {
    let metadata = bullet.metadata
    if (typeof metadata === 'string') {
      metadata = JSON.parse(bullet.metadata)
    }
    if (metadata.duration && metadata.startTime && metadata.endTime) {
      duration = (
        <div style={{ fontSize: '10px' }}>
          {moment.duration(metadata.duration, 'minutes')
            .format('y [years], w [weeks], d [days], h [hours], m [minutes]')}<br />
          {moment(metadata.startTime).format('h:mm a')} to {moment(metadata.endTime).format('h:mm a')}
        </div>
      )
    }
  }
  return duration
}

const BulletTimelineItem = ({ bullet, hasNext, visible }) => {
  const dispatch = useDispatch()
  const [item, setItem] = useState(bullet)
  useEffect(() => {
    setItem(bullet)
  }, [bullet])
  return (
    <>
      {visible ? (
        <TimelineItem key={`timeline-${item.id}`}>
          <TimelineOppositeContent
            style={{ flex: 0, fontSize: '10px', marginTop: '15px' }}
            color='text.secondary'>
            {item.createdOn ? (
              <Tooltip title={moment(item.createdOn).format('ddd DD/MM/YYYY')} placement='top'>
                <Link to={`/admin/bullets?d=${new Date(item.createdOn).toISOString()}`}>
                  <span style={{ cursor: 'pointer' }}>{moment(item.createdOn).format('hh:mm a')}</span>
                </Link>
              </Tooltip>) : ''}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <Tooltip title='Edit' placement='top'>
              <TimelineDot style={{ cursor: 'pointer' }}
                onClick={() => {
                  dispatch(openEditBulletDialog(item))
                }} color='inherit'>
                <BulletIcon type={item.type} />
              </TimelineDot>
            </Tooltip>
            {hasNext ? <TimelineConnector /> : ''}
          </TimelineSeparator>
          <TimelineContent style={{ marginTop: '10px' }}><Linkify>{item.body}</Linkify>
            <div style={{ float: 'right', textAlign: 'right' }}>
              <BulletRating bullet={item} />
              <BulletMood bullet={item} />
              <BulletLocation bullet={item} />
            </div>
            {item.description ? (
              <div style={{ fontSize: '12px', padding: '10px 0' }}>
                {item.description}
              </div>) : ''}
            <BulletDuration bullet={item} />
          </TimelineContent>
        </TimelineItem>) : ''}
    </>
  )
}

BulletTimelineItem.propTypes = {
  bullet: PropTypes.object,
  hasNext: PropTypes.bool,
  visible: PropTypes.bool
}

const BulletTimeline = ({
  date, header, footer, after, before, start,
  showDate
}) => {
  const dispatch = useDispatch()
  const [items, setItems] = useState({})
  const [bulletFilter, setBulletFilter] = useState({
    event: true,
    task: true,
    habit: true,
    note: true,
    mood: true
  })
  const {
    bullets,
    createdBullet,
    createdTask,
    createdSubtask
  } = useSelector((state) => {
    return {
      bullets: state.bullets.bullets,
      createdBullet: state.bullets.createdBullet,
      createdTask: state.bullets.createdTask,
      createdSubtask: state.bullets.createdSubtask
    }
  })

  useEffect(() => {
    if (date) {
      dispatch(getBullets({ date }))
    }
  }, [date])

  useEffect(() => {
    const data = {}
    bullets.forEach((d) => {
      data[d.id] = d
    })
    setItems(data)
  }, [bullets])

  useEffect(() => {
    const data = { ...items }
    if ((!moment(createdBullet.createdOn).isSame(moment(date), 'day') ||
      createdBullet.deleted === true) && data[createdBullet.id]) {
      delete data[createdBullet.id]
    } else if (createdBullet.type === 'task') {
      if (createdBullet.completed === true) {
        data[createdBullet.id] = createdBullet
      }
    } else {
      data[createdBullet.id] = createdBullet
    }
    setItems(data)
  }, [createdBullet])

  useEffect(() => {
    if (createdTask.bullet && createdTask.completed === true) {
      const data = { ...items }
      data[createdTask.bullet.id] = createdTask.bullet
      setItems(data)
    }
  }, [createdTask])

  useEffect(() => {
    if (createdSubtask.bullet && createdSubtask.completed === true) {
      const data = { ...items }
      data[createdSubtask.bullet.id] = createdSubtask.bullet
      setItems(data)
    }
  }, [createdSubtask])

  const renderTimelineItems = (items) => {
    const entries = Object.entries(items)
    let arr = []
    if (start === 'top') {
      arr = entries.sort((a, b) => {
        return new Date(a[1].createdOn) - new Date(b[1].createdOn)
      })
    } else {
      arr = entries.sort((a, b) => {
        return new Date(b[1].createdOn) - new Date(a[1].createdOn)
      })
    }
    return arr.map((b, idx) => {
      let hasNext = true
      if (idx === arr.length - 1 && !before) {
        hasNext = false
      }
      return (
        <BulletTimelineItem
          visible={bulletFilter[b[1].type]}
          hasNext={hasNext}
          key={`timeline-${b[1].id}`}
          bullet={b[1]} />
      )
    })
  }

  const renderFilter = () => {
    const icons = ['task', 'habit', 'event', 'note']
    const rendered = icons.map((i, k) => {
      return (
        <BulletIcon
          disabled={!bulletFilter[i]}
          key={k}
          type={i}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            bulletFilter[i] = !bulletFilter[i]
            setBulletFilter(bulletFilter)
          }}
        />
      )
    })
    return (
      <div>
        {rendered}
      </div>
    )
  }

  const renderedDate = (
    <div>
      <h3>{moment(date).format('dddd')}</h3>
      <div style={{ fontSize: '15px', fontFamily: 'Playfair Display', fontWeight: 400, marginTop: '-15px' }}>
        {moment(date).format('MMMM Do')}
      </div>
    </div>
  )
  return (
    <div>
      {date && showDate ? renderedDate : ''}
      <div style={{ textAlign: 'right' }}>
        {renderFilter()}
      </div>
      {header}
      <Timeline position='right'>
        {after}
        {renderTimelineItems(items)}
        {before}
      </Timeline>
      {footer}
    </div>
  )
}

BulletTimeline.propTypes = {
  date: PropTypes.object,
  before: PropTypes.object,
  after: PropTypes.object,
  header: PropTypes.object,
  footer: PropTypes.object,
  start: PropTypes.string,
  showDate: PropTypes.bool
}

export default BulletTimeline
