import React, { Component, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, Tooltip, CircularProgress } from '@mui/material'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  getTrelloCards,
  updateTrelloCard
} from 'store/modules/bullets'
import moment from 'moment'
import FastForwardIcon from '@mui/icons-material/FastForward'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import FastRewindIcon from '@mui/icons-material/FastRewind'
import CancelIcon from '@mui/icons-material/Cancel'

const ListCard = ({ task, callback, closeCallback }) => {
  const [item, setItem] = useState(task)
  useEffect(() => {
    setItem(task)
  }, [task])
  const iconStyle = {
    color: '#146d10',
    cursor: 'pointer',
    marginLeft: '5px',
    marginTop: '7px',
    position: 'absolute'
  }
  const rewindStyle = {
    color: '#345bb5',
    cursor: 'pointer',
    marginTop: '7px',
    position: 'absolute'
  }
  let closeIcon
  let icon = <FastForwardIcon
    onClick={() => callback(item)}
    style={iconStyle} />
  if (this.props.nextStatus === 'done') {
    iconStyle.color = '#0b8100'
    icon = <CheckCircleIcon
      onClick={() => callback(item)}
      style={iconStyle} /> // eslint-disable-next-line
    if (this.props.currentStatus === 'DONE:') {
      const closeStyle = {
        color: '#c31313',
        cursor: 'pointer',
        marginTop: '7px',
        position: 'absolute',
        marginLeft: '5px'
      }
      iconStyle.marginLeft = '30px'
      closeIcon = (
        <Tooltip title='Archive' placement='top'>
          <CancelIcon
            onClick={() => closeCallback(item)}
            style={closeStyle} />
        </Tooltip>)
    }
  }
  const rewindIcon = <FastRewindIcon
    onClick={() => this.props.rewindCallback(item)}
    style={rewindStyle} />
  let due
  if (item.due) {
    due = (<small>({moment(item.due).fromNow()})</small>)
  }
  return (
    <div>
      <Tooltip title='Rewind' placement='top'>{rewindIcon}</Tooltip>
      <span
        style={{ paddingLeft: '30px' }}>{item.name} {due}
      </span>
      {closeIcon}
      <Tooltip title='Next' placement='top'>{icon}</Tooltip>
    </div>
  )
}

ListCard.propTypes = {
  task: PropTypes.object,
  callback: PropTypes.func,
  closeCallback: PropTypes.func
}

const ListSection = ({ currentStatus, tasks, callback, closeCallback }) => {
  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <h4>{currentStatus}</h4>
        {tasks.map((c) => {
          return <ListCard
            key={`task-list-task-card-${c.id}`}
            task={c} callback={callback}
            closeCallback={closeCallback} />
        })}
      </div>
    </>
  )
}

ListSection.propTypes = {
  tasks: PropTypes.array,
  currentStatus: PropTypes.string,
  callback: PropTypes.func,
  closeCallback: PropTypes.func
}

const TodoList = () => {
  const [items, setItems] = useState({})
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        items
      </Grid>
    </Grid>
  )
}

export default TodoList
