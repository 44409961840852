import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { getUsers } from 'store/modules/auth'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Container } from '@mui/material'
import List from './List'

class UsersContainer extends Component {
  render () {
    return (
      <div id='users-container'>
        <Container style={{ marginTop: '40px' }}>
          <List />
        </Container>
      </div>
    )
  }
}

UsersContainer.propTypes = {
  // users: PropTypes.object,
  // getUsers: PropTypes.func
}

const mapStateToProps = (state) => {
  const {
    users,
    isGettingUsers,
    hasGottenUsers
  } = state.auth
  return {
    users,
    isGettingUsers,
    hasGottenUsers
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({
    getUsers
  }, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersContainer)
