import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Container } from '@mui/material'
import { getContentPublic } from 'store/modules/content'
import moment from 'moment'
// import Pomodoro from 'components/Pomodoro/Pomodoro'

class Home extends Component {
  componentDidMount () {
    this.props.getContentPublic()
  }

  renderContent = (contents) => {
    return contents.map((c, i) => {
      return (
        <div key={i}>
          <Link to={`/content/${c.id}`}>
            <div style={{
              fontSize: '40px',
              lineHeight: '40px',
              fontWeight: 'bold'
            }}>{c.title}
            </div>
            <small>{moment(c.createdOn).format('DD/MM/YYYY hh:mm a')}</small>
          </Link>
        </div>
      )
    })
  }

  render () {
    return (
      <div id='home-public-container' className='component-container'>
        <Container style={{ marginTop: '40px' }}>
          <div>
            <center><h1>Log</h1></center>
            {this.renderContent(this.props.publicContents)}
          </div>
        </Container>
      </div>
    )
  }
}

Home.propTypes = {
  getContentPublic: PropTypes.func,
  publicContents: PropTypes.array
}

const mapStateToProps = (state) => {
  const {
    isGettingPublicContent,
    hasGottenPublicContent,
    publicContents
  } = state.content
  return {
    isGettingPublicContent,
    hasGottenPublicContent,
    publicContents
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({
    getContentPublic
  }, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home)
