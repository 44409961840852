import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import {
  Checkbox,
  TextField, Button
} from '@mui/material'
import {
  openEditTaskDialog
} from 'store/modules/site'
import { createTask } from 'store/modules/bullets'

const Subtask = ({ task }) => {
  const dispatch = useDispatch()
  const [edit, setEdit] = useState(false)
  const [checked, setChecked] = useState(false)
  const [item, setItem] = useState({})

  useEffect(() => {
    setItem(task)
    if (task.completed) { setChecked(task.completed) }
  }, [task])

  const onClickSubtask = (e, c) => {
    setChecked(e.target.checked)
    if (e.target.checked) {
      dispatch(createTask({
        id: c.id,
        completed: true,
        completedOn: new Date(),
        status: 'done'
      }))
    } else {
      dispatch(createTask({
        id: c.id,
        completed: false,
        completedOn: null,
      }))
    }
  }

  let label = (
    <span style={{ fontSize: '12px' }}
      onDoubleClick={() => {
        setEdit(!edit)
      }}>{item.title}
    </span>
  )
  const buttonStyle = { margin:0, padding: 0, fontSize: '10px' }
  if (edit) {
    label = (
      <>
        <TextField
          id='target'
          type='text'
          value={item.title}
          style={{ fontSize: '12px', width: '100%' }}
          onChange={(e) => {
            const task = { ...item }
            task.title = e.target.value
            setItem(task)
          }}
          onDoubleClick={() => {
            setEdit(!edit)
          }}
        />
        <div style={{ textAlign: 'right' }}>
          <Button style={buttonStyle} onClick={() => setEdit(false)}>
            Close
          </Button>
          <Button style={buttonStyle} onClick={() => {
            dispatch(openEditTaskDialog({ id: item.id }))
          }}>
            More
          </Button>
          <Button style={buttonStyle} onClick={() => {
            dispatch(createTask({
              id: item.id,
              title: item.title
            }))
          }}>
            Save
          </Button>
          <Button style={buttonStyle} onClick={() => {
            dispatch(createTask({
              id: item.id,
              deleted: true
            }))
          }}>
            Delete
          </Button>
        </div>
      </>
    )
  }

  return (
    <div className='subtask' key={item.id}
      style={{ fontSize: '12px', width: '100%', display: 'flex', alignItems: 'center' }}>
      <div>
        <Checkbox
          checked={checked}
          onChange={(e) => onClickSubtask(e, item)}
          name={`subtask-checkbox-${item.id}`} />
      </div>
      <div style={{ width: '100%' }}>
        {label}
      </div>
    </div>
  )
}

Subtask.propTypes = {
  task: PropTypes.object
}

export default Subtask
