import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import {
  Tooltip, Dialog, DialogContent,
  DialogTitle
} from '@mui/material'
import {
  getGitCommitData,
} from 'store/modules/bullets'
import moment from 'moment'
import CodeIcon from '@mui/icons-material/Code'

const GithubWidget = ({ date }) => {
  const dispatch = useDispatch()
  const [commits, setCommits] = useState([])
  const [dialogIsOpen, setDialogIsOpen] = useState(false)
  let [totalCommits, setTotalCommits] = useState(0)
  const data = useSelector((state) => state.bullets.gitCommitData)
  useEffect(() => {
    dispatch(getGitCommitData({ date }))
  }, [date, dispatch])
  useEffect(() => {
    setGithubState(data)
  }, [data])
  const setGithubState = (dataArray) => {
    // let totalCommits = 0; const commits = []
    if (dataArray) {
      dataArray.forEach((data) => {
        if (data.body) {
          totalCommits += parseInt(data.body)
        }
        if (data.metadata) {
          data.metadata.forEach((d, idx) => {
            let truncatedName = d.name
            if (d.name.split('/') && d.name.split('/')[1]) {
              truncatedName = d.name.split('/')[1]
            }
            commits.push(
              <div key={idx} style={{ fontSize: '10px', cursor: 'pointer' }}>
                <a href={d.url}>
                  <Tooltip title={moment(d.created_at).format('DD/MM/YYYY h:mm a')} placement='top'>
                    <span style={{ opacity: '0.5' }}>
                      [{truncatedName}]&nbsp;
                    </span>
                  </Tooltip>
                  {d.message}
                  <span style={{ opacity: '0.5' }}>
                    &nbsp;{moment(d.created_at).format('h:mm a')}
                  </span>
                </a><br />
              </div>
            )
          })
        }
      })
    }
    setCommits(commits)
    setTotalCommits(totalCommits)
  }
  const divStyle = { display: 'inline-block', paddingLeft: '5px' }
  const iconStyle = { position: 'relative', top: '7px', width: '20px', cursor: 'pointer' }
  return (
    <>
      <div style={divStyle}>
        <Tooltip title='Commits' placement='left'>
          <span>
            <CodeIcon style={iconStyle} onClick={() => setDialogIsOpen(true)} />
          </span>
        </Tooltip>
        &nbsp; {totalCommits}
      </div>
      <Dialog open={dialogIsOpen}
        onClose={() => { setDialogIsOpen(false) }}>
        <DialogTitle>Commits</DialogTitle>
        <DialogContent>
          <div style={{ minWidth: '500px' }}>
            <div>
              <span>{totalCommits} commits</span>
              {commits}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

GithubWidget.propTypes = {
  date: PropTypes.object
}

export default GithubWidget
