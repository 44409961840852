const View = require('./View').default
export default (store) => ({
  childRoutes: [
    {
      path: '/content/:id',
      key: 'public-content-view',
      getComponent: View,
      // getComponent: () => {
      //   const Create = require('./Create').default
      //   return Create
      // },
      linkTitle: 'View'
      // onEnter: requireAuth(store),
    }
  ]
})
