import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import {
  getWeatherData,
  createBullet
} from 'store/modules/bullets'
import BulletIcon from './BulletIcon'

const WeatherWidget = ({ coords }) => {
  const dispatch = useDispatch()
  const [weather, setWeather] = useState('')
  const data = useSelector((state) => state.bullets.weatherData)
  useEffect(() => {
    dispatch(getWeatherData())
  }, [dispatch])
  useEffect(() => {
    setWeatherState(data)
  }, [data])
  const setWeatherState = (weatherData) => {
    if (weatherData) {
      if (weatherData.items && weatherData.items[0] &&
        weatherData.items[0].general &&
        weatherData.items[0].general.forecast) {
        setWeather(weatherData.items[0].general.forecast)
      }
    }
  }
  const createBulletFromWeather = (forecast) => {
    const data = {
      type: 'event',
      body: forecast,
      completed: true,
      completedOn: new Date()
    }
    if (coords && coords.latitude && coords.longitude) {
      data.lat = coords.latitude
      data.lng = coords.longitude
    }
    dispatch(createBullet(data))
  }
  const divStyle = { display: 'inline-block', paddingLeft: '5px' }
  const iconStyle = { position: 'relative', top: '7px', width: '20px', cursor: 'pointer' }
  return (
    <>
      {weather !== '' ? (
        <div style={divStyle}>
          <span><BulletIcon type='event' style={iconStyle} onClick={() => {
            createBulletFromWeather(
              weather
            )
          }} />
          </span>
          &nbsp; {weather}
        </div>) : ''}
    </>
  )
}

WeatherWidget.propTypes = {
  coords: PropTypes.object,
}

export default WeatherWidget
