import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Chip } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import {
  getTags,
  createTag
} from 'store/modules/bullets'

const TagItem = ({ tag }) => {
  const dispatch = useDispatch()
  const label = tag.type === 'taskCat' ? `[${tag.name}]` : `#${tag.name}`
  const bgCol = tag.active === true ? '#2ca5e0' : '#b4e4ff69'
  const color = tag.active === true ? 'white' : 'inherit'
  const style = { backgroundColor: bgCol, margin: '2px', color }
  let icon = (
    <CloseIcon
      onClick={() => { dispatch(createTag({ id: tag.id, active: false })) }}
      style={{ color: 'white', cursor: 'pointer' }}
    />
  )
  if (!tag.active) {
    icon = (
      <DoneIcon
        onClick={() => {
          dispatch(createTag({ id: tag.id, active: true }))
        }}
        style={{ color: 'inherit', cursor: 'pointer' }}
      />
    )
  }
  return (
    <Chip
      // size='small'
      style={style}
      avatar={icon}
      variant='outlined'
      label={label}
    />
  )
}

TagItem.propTypes = {
  tag: PropTypes.object
}

const TagsContainer = () => {
  const dispatch = useDispatch()
  const tags = useSelector((state) => state.bullets.tags)
  useEffect(() => {
    dispatch(getTags())
  }, [dispatch])

  return (
    <div id='tags-container'>
      <Container style={{ marginTop: '40px' }}>
        <h2>Tags</h2>
        <div style={{ marginBottom: '10px' }}>
          {tags
            .sort((a, b) => {
              return b.active - a.active
            })
            .map((t) => {
              return <TagItem tag={t} key={`tag-${t.id}`} />
            })}
        </div>
      </Container>
    </div>
  )
}

TagsContainer.propTypes = {}

export default TagsContainer
