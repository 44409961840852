import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Container } from '@mui/material'
import BulletEditor from 'components/Dashboard/BulletEditor'
import TodoList from 'components/Dashboard/TodoList'
import SelectBoardSwitch from 'components/Dashboard/SelectBoardSwitch'

class TasksContainer extends Component {
  render () {
    return (
      <div id='tasks-container' className='component-container'>
        <Container>
          <div>
            <h2>Tasks</h2>
            <BulletEditor />
            <SelectBoardSwitch />
            <TodoList cardType='today' />
            <TodoList cardType='inProgress' />
            <TodoList cardType='someday' />
            <TodoList cardType='done' />
            <br /><br />
          </div>
        </Container>
      </div>
    )
  }
}

TasksContainer.propTypes = {
}

const mapStateToProps = (state) => {
  const {
    isCreatingBullet,
    hasCreatedBullet,
    createdBullet
  } = state.bullets
  return {
    isCreatingBullet,
    hasCreatedBullet,
    createdBullet
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({
  }, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TasksContainer)
