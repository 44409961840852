import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Tooltip } from '@mui/material'
import BulletIcon from 'components/Bullets/BulletIcon'

const MoodPercentageWidget = () => {
  const {
    bullets
  } = useSelector((state) => {
    return {
      bullets: state.bullets.bullets
    }
  })
  const [mood, setMood] = useState(0)
  useEffect(() => {
    let m = 0
    let total = 0
    bullets.forEach((b) => {
      if (b.mood && b.mood !== 0) { m += b.mood; total += 1 }
    })
    setMood((m / total))
  }, [bullets])

  let moodIconType = 'mood'
  if (mood > 50) { moodIconType = 'moodGood' }
  if (mood < 50) { moodIconType = 'moodBad' }

  const divStyle = { display: 'inline-block', paddingLeft: '5px' }
  const iconStyle = { position: 'relative', top: '7px', width: '20px', cursor: 'pointer' }
  return (
    <>
      <div style={divStyle}>
        <Tooltip title='Mood' placement='left'>
          <span><BulletIcon type={moodIconType} style={iconStyle} /></span>
        </Tooltip>
        &nbsp;{!isNaN(mood)
          ? mood : 0.00}%
      </div>
    </>
  )
}

MoodPercentageWidget.propTypes = {
}

export default MoodPercentageWidget
