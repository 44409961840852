import React, { Component, useState, useCallback, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Slate, Editable,
  withReact,
} from 'slate-react'
import { Editor, Transforms, Range, Point, createEditor } from 'slate'
import { withHistory } from 'slate-history'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Container, MenuItem, Select, TextField, Chip, Collapse, Grid, IconButton } from '@mui/material'
import { createBullet, uploadBulletImage, getActiveTags } from 'store/modules/bullets'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import SendIcon from '@mui/icons-material/Send'
// import UploadFileButton from 'components/Editor/UploadFileButton'
// import { propTypes } from 'react-grid-gallery'
const isMobile = require('is-mobile')

const SHORTCUTS = {
  '-': 'note',
  '*': 'task',
  '&': 'event', // eslint-disable-next-line
  '$': 'expense',
  '+': 'habit', // eslint-disable-next-line
}

class BulletEditor extends Component {
  constructor (props) {
    super(props)
    this.state = {
      bulletValue: '',
      bulletType: 'note',
      mobileValue: '',
      metadata: {},
      image: '',
      activeTags: [],
      selectedTag: '',
      activeTagsVisible: false
    }
    this.mobileEditor = React.createRef()
  }

  componentDidUpdate (prevProps) {
    if (this.props.hasCreatedBullet !== prevProps.hasCreatedBullet) {
      this.resetMobileValue()
      this.setMetadata({})
      this.setImage('')
    }
    if (this.props.hasUploadedBulletImage !== prevProps.hasUploadedBulletImage &&
      this.props.hasUploadedBulletImage === true) {
      if (this.props.uploadBulletImageSuccess.files &&
        this.props.uploadBulletImageSuccess.files.length > 0) {
        this.props.uploadBulletImageSuccess.files.forEach((i) => {
          this.setImage(i.path)
          this.setMetadata({ image: i.path })
        })
      }
    }
  }

  setImage = (path) => {
    this.setState({ image: path })
  }

  setMetadata = (data) => {
    const newState = Object.assign(this.state.metadata, data)
    this.setState({ metadata: newState })
  }

  resetMobileValue = () => {
    this.setState({
      mobileValue: '',
      image: ''
    })
  }

  setValue = (value) => {
    this.setState({ bulletValue: value })
  }

  setType = (type) => {
    this.setState({ bulletType: type })
  }

  submitData = (value) => {
    if (value && value.length > 0 && value[0]) {
      const data = this.deserializeBullet(value[0])
      data.metadata = JSON.stringify(Object.assign(this.state.metadata, data.metadata))
      if (this.props.coords && this.props.coords.latitude && this.props.coords.longitude) {
        data.lat = this.props.coords.latitude
        data.lng = this.props.coords.longitude
      }
      this.props.createBullet(data)
    }
  }

  deserializeBullet = (b) => {
    const obj = {}
    if (b.type) {
      obj.type = b.type
    }
    if (b.children && b.children.length > 0 && b.children[0].text) {
      // const descRegex = /(?<=([`]))(?:(?=(\\?))\2.)*?(?=\1)/g
      const descRegex = /`(.*?)`/g
      const bodyRegex = /([`])(?:(?=(\\?))\2.)*?\1/g
      const descArr = b.children[0].text.match(descRegex)
      const body = b.children[0].text.replace(bodyRegex, '')
      obj.body = body
      if (descArr && descArr.length > 0) {
        obj.description = descArr.join('').replace(/[`]+/g, '')
      }
    }
    return obj
  }

  handleMobileChange = (event) => {
    this.setState({ mobileValue: event.target.value })
  }

  handleMobileSubmit = () => {
    const data = {
      body: this.state.mobileValue,
      type: this.state.bulletType
    }
    data.metadata = JSON.stringify(Object.assign(this.state.metadata, data.metadata))
    if (this.props.coords && this.props.coords.latitude && this.props.coords.longitude) {
      data.lat = this.props.coords.latitude
      data.lng = this.props.coords.longitude
    }
    if (this.state.mobileValue) {
      // const descRegex = /(?<=([`]))(?:(?=(\\?))\2.)*?(?=\1)/g
      const descRegex = /`(.*?)`/g
      const bodyRegex = /([`])(?:(?=(\\?))\2.)*?\1/g
      const descArr = this.state.mobileValue.match(descRegex)
      const body = this.state.mobileValue.replace(bodyRegex, '')
      data.body = body
      if (descArr && descArr.length > 0) {
        data.description = descArr.join('').replace(/[`]+/g, '')
      }
    }
    this.props.createBullet(data)
  }

  onHandleInsertImage = (files) => {
    this.props.uploadBulletImage(files)
  }

  addActiveTag = (tag) => {
    let selectedTag = `#${tag.name}`
    let bulletType = 'event'
    // element.dispatchEvent(new KeyboardEvent('keydown',{'key':'&'}));
    if (tag.type === 'taskCat') {
      selectedTag = `[${tag.name}]`
      bulletType = 'task'
    }
    if (isMobile()) {
      let mobileValue = this.state.mobileValue
      mobileValue += selectedTag + ' '
      this.setState({ mobileValue, bulletType })
      this.mobileEditor.current.focus()
    }
    this.setState({ selectedTag, bulletType })
  }

  renderActiveTags = (tags) => {
    const renderedTags = tags.map((t, idx) => {
      const tag = t.type === 'taskCat' ? `[${t.name}]` : `#${t.name}`
      return (<Chip
        style={{
          fontSize: '10px',
          margin: '0 2px',
          fontFamily: 'Roboto',
          fontWeight: 'normal',
          backgroundColor: '#bb7b7b',
          color: 'white'
          // fontFamily: 'Arial',
          // fontWeight: 600,
          // backgroundColor: '#b5ccd736'
        }}
        variant='solid'
        size='small'
        onClick={() => {
          this.addActiveTag(t)
        }}
        label={tag}
        key={`chip-${idx}`} />)
    })
    return (
      <Collapse in={this.state.activeTagsVisible}>
        <div style={{ marginBottom: '10px' }}>
          {renderedTags}
        </div>
      </Collapse>
    )
  }

  render () {
    // let bulletImage
    // let uploadStyle
    // if (this.props.hasUploadedBulletImage) {
    //   bulletImage = (
    //     <div
    //       id='bullet-editor-uploaded-image'
    //       style={{
    //         height: '50px',
    //         width: '50px',
    //         backgroundImage: `url('${this.state.image}')`,
    //         backgroundSize: 'cover',
    //         backgroundPosition: 'center'
    //       }} />
    //   )
    //   uploadStyle = {
    //     display: 'none'
    //   }
    // }
    // {bulletImage}
    // <div style={uploadStyle}>
    //   <UploadFileButton
    //     callback={this.onHandleInsertImage}
    //     multiple={false}
    //   />
    // </div>
    // {bulletImage}
    // <div style={uploadStyle}>
    //   <UploadFileButton
    //     callback={this.onHandleInsertImage}
    //     multiple={false}
    //   />
    // </div>
    const lineStyle = {
      position: 'absolute',
      width: '28px',
      height: '0px',
      border: '2px solid rgba(0, 0, 0, 0.5)',
      transform: 'rotate(90deg)',
      marginLeft: '-16px',
      marginTop: '22px'
    }
    let content = (
      <>
        <div style={lineStyle} />
        <BulletSlateEditor
          setValue={this.setValue}
          setType={this.setType}
          submitData={this.submitData}
          clearValue={this.clearValue}
          hasCreatedBullet={this.props.hasCreatedBullet}
          selectedTag={this.state.selectedTag}
          bulletType={this.state.bulletType}
        />
        <div>
          <Grid container>
            <Grid item xs={11}>
              <small>
                - for note, * for task, & for event,
                + for habit
              </small>
            </Grid>
            <Grid item xs={1} style={{ textAlign: 'right' }}>
              <div>
                {this.state.activeTagsVisible ? <ExpandLess
                  style={{ width: '12px' }}
                  onClick={() => { this.setState({ activeTagsVisible: false }) }}
                /> : <ExpandMore
                  style={{ width: '12px' }}
                  onClick={() => { this.setState({ activeTagsVisible: true }) }}
                />}
              </div>
            </Grid>
          </Grid>
          <div>{this.renderActiveTags(this.props.activeTags)}</div>
        </div>
      </>
    )
    if (isMobile()) {
      content = (
        <div>
          <Select
            labelId='bullet-type-label'
            id='bullet-type'
            value={this.state.bulletType}
            onChange={(e) => {
              this.setState({ bulletType: e.target.value })
            }}
          >
            <MenuItem value='note'>Note</MenuItem>
            <MenuItem value='event'>Event</MenuItem>
            <MenuItem value='habit'>Habit</MenuItem>
            <MenuItem value='task'>Task</MenuItem>
          </Select>
          <br />
          <TextField
            inputRef={this.mobileEditor}
            id='bullet-mobile'
            label='Bullet'
            multiline
            fullWidth
            rows='3'
            value={this.state.mobileValue}
            onChange={this.handleMobileChange}
          />
          <div style={{ textAlign: 'right', margin: '10px 0' }}>
            <IconButton onClick={this.handleMobileSubmit}>
              <SendIcon />
            </IconButton>
          </div>
          <div>
            {this.state.activeTagsVisible ? <ExpandLess
              style={{ width: '12px' }}
              onClick={() => { this.setState({ activeTagsVisible: false }) }}
            /> : <ExpandMore
              style={{ width: '12px' }}
              onClick={() => { this.setState({ activeTagsVisible: true }) }}
            />}
          </div>
          {this.renderActiveTags(this.props.activeTags)}
        </div>
      )
    }
    return (
      <div id='bullet-log-container'>
        <Container style={{ margin: 0, padding: 0 }}>
          <div>
            {content}
          </div>
        </Container>
      </div>
    )
  }
}

BulletEditor.propTypes = {
  // isCreatingBullet: PropTypes.bool,
  hasCreatedBullet: PropTypes.bool,
  // createdBullet: PropTypes.object,
  createBullet: PropTypes.func,
  hasUploadedBulletImage: PropTypes.bool,
  uploadBulletImageSuccess: PropTypes.object,
  uploadBulletImage: PropTypes.func,
  coords: PropTypes.object,
  activeTags: PropTypes.array
}

const mapStateToProps = (state) => {
  const {
    isCreatingBullet,
    hasCreatedBullet,
    createdBullet,
    isUploadingBulletImage,
    hasUploadedBulletImage,
    uploadBulletImageError,
    uploadBulletImageSuccess,
    activeTags
  } = state.bullets
  const { coords } = state.site
  return {
    isCreatingBullet,
    hasCreatedBullet,
    createdBullet,
    isUploadingBulletImage,
    hasUploadedBulletImage,
    uploadBulletImageError,
    uploadBulletImageSuccess,
    activeTags,
    coords
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({
    createBullet,
    uploadBulletImage,
    getActiveTags
  }, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BulletEditor)

const emptyValue = [{ type:'note', children: [{ text:'' }] }]
const BulletSlateEditor = ({
  hasCreatedBullet, setValue,
  selectedTag, bulletType,
  submitData
}) => {
  const editor =
  useMemo(
    () =>
      withShortcuts(
        withReact(
          withHistory(
            createEditor()
          )
        )
      ),
    []
  )
  const [val, setVal] = useState(emptyValue)
  // console.log(props, emptyValue)
  const onChangeValue = (value) => {
    setVal(value)
    setValue(value)
  }
  useEffect(() => {
    if (hasCreatedBullet === true) {
      Transforms.delete(editor, {
        at: {
          anchor: Editor.start(editor, []),
          focus: Editor.end(editor, []),
        },
      })
      // setVal([
      //   {
      //     type: 'paragraph',
      //     children: [{ text: '' }]
      //   }
      // ])
    }
  }, [hasCreatedBullet])
  useEffect(() => {
    if (selectedTag) {
      let text = ''
      if (val && val[0] && val[0].children && val[0].children[0] && val[0].children[0].text) {
        text = val[0].children[0].text
        text += ' ' + selectedTag + ' '
      } else {
        text += selectedTag
      }
      let type = val[0].type
      if (bulletType) { type = bulletType }
      onChangeValue([
        {
          type,
          children: [{ text }]
        }
      ])
    }
  }, [selectedTag, bulletType])

  const renderElement = useCallback(props => <Element
    {...props} />, [])
  return (
    <>
      <Slate editor={editor} value={val}
        onChange={value => onChangeValue(value)}>
        <Editable
          style={{
            padding: '5px 15px',
            margin: '10px 0',
            background: 'rgba(255, 255, 255, 0.25)',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.07)'
          }}
          renderElement={renderElement}
          placeholder={(<span>Type something...</span>)}
          spellCheck
          autoFocus
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault()
              submitData(val)
            }
          }}
        />
      </Slate>
    </>
  )
}

BulletSlateEditor.propTypes = {
  setValue: PropTypes.func,
  setTitleValue: PropTypes.func,
  title: PropTypes.string,
  uploadImages: PropTypes.func,
  hasUploadedImages: PropTypes.bool,
  images: PropTypes.object,
  selectedContent: PropTypes.object,
  initialValue: PropTypes.array,
  hasCreatedBullet: PropTypes.bool,
  submitData: PropTypes.func,
  selectedTag: PropTypes.string,
  bulletType: PropTypes.string
}

const withShortcuts = editor => {
  const { deleteBackward, insertText } = editor
  editor.insertText = text => {
    const { selection } = editor
    if (text === ' ' && selection && Range.isCollapsed(selection)) {
      const { anchor } = selection
      const block = Editor.above(editor, {
        match: n => Editor.isBlock(editor, n),
      })
      const path = block ? block[1] : []
      const start = Editor.start(editor, path)
      const range = { anchor, focus: start }
      const beforeText = Editor.string(editor, range)
      const type = SHORTCUTS[beforeText]

      if (type) {
        Transforms.select(editor, range)
        Transforms.delete(editor)
        Transforms.setNodes(
          editor,
          { type },
          { match: n => Editor.isBlock(editor, n) }
        )

        if (type === 'list-item') {
          const list = { type: 'bulleted-list', children: [] }
          Transforms.wrapNodes(editor, list, {
            match: n => n.type === 'list-item',
          })
        }
        return
      }
    }
    insertText(text)
  }

  editor.deleteBackward = (...args) => {
    const { selection } = editor

    if (selection && Range.isCollapsed(selection)) {
      const match = Editor.above(editor, {
        match: n => Editor.isBlock(editor, n),
      })

      if (match) {
        const [block, path] = match
        const start = Editor.start(editor, path)

        if (
          block.type !== 'paragraph' &&
          Point.equals(selection.anchor, start)
        ) {
          Transforms.setNodes(editor, { type: 'note' })

          if (block.type === 'list-item') {
            Transforms.unwrapNodes(editor, {
              match: n => n.type === 'bulleted-list',
              split: true,
            })
          }

          return
        }
      }

      deleteBackward(...args)
    }
  }

  return editor
}

const Element = (props) => {
  const { attributes, children, element } = props
  switch (element.type) {
    default: {
      const classname = `bullet-${element.type}`
      return <p className={classname} {...attributes}>{children}</p>
    }
  }
}

Element.propTypes = {
  attributes: PropTypes.object,
  children: PropTypes.object,
  element: PropTypes.object
}
