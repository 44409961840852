import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import {
  // openLightbox,
  // closeMapDialog,
  // openMapDialog,
  openEditTaskDialog,
  closeEditTaskDialog
} from 'store/modules/site'
import {
  createTask,
  getTasks,
  getRelatedTasks,
  getTasksByType,
  getEpics,
  getBullets, createBullet,
  bulkUpdateTasksStatuses
} from 'store/modules/bullets'
import {
  Container, Button,
  Checkbox, FormControlLabel,
} from '@mui/material'
import Board from 'react-trello'
// import TaskEditDialog from 'components/Tasks/TaskEditDialog'
import BoardCard from 'components/Tasks/BoardCard'
class Day extends Component {
  constructor (props) {
    super(props)
    this.state = {
      someday: [],
      doToday: [],
      stories: [],
      subtasks: [],
      epics: [],
      tasks: [],
      viewDone: false,
      viewNotDone: false,
      board: {
        lanes: [
          {
            id: 'someday',
            title: 'Someday',
            cards: []
          }
        ]
      }
    }
  }

  componentDidMount () {
    // this.props.getTasks({ status: 'inProgress' })
    this.props.getTasksByType({ type: 'story' })
    this.props.getTasks({ completed: { not: true } })
    // this.props.getEpics()
  }

  componentDidUpdate (prevProps) {
    // if (this.props.hasCreatedBullet !== prevProps.hasCreatedBullet &&
    //   this.props.hasCreatedBullet === true) {
    //   const opts = {
    //     limit: 5,
    //     page: 1
    //   }
    //   this.props.getBullets(opts)
    // }
    if (this.props.editTaskDialogIsOpen !== prevProps.editTaskDialogIsOpen &&
      this.props.editTaskDialogIsOpen === false) {
      this.props.getTasks({ completed: { not: true } })
    }
    if (this.props.hasBulkUpdatedTasksStatuses !== prevProps.hasBulkUpdatedTasksStatuses &&
      this.props.hasBulkUpdatedTasksStatuses === true) {
      this.props.getTasks({ completed: { not: true } })
    }
    // if (this.props.hasCreatedTask !== prevProps.hasCreatedTask &&
    //   this.props.hasCreatedTask === true) {
    //   this.props.getTasks({ completed: { not: true } })
    // }
    // if (this.props.hasUpdatedTrelloCard !== prevProps.hasUpdatedTrelloCard &&
    //   this.props.hasUpdatedTrelloCard === true) {
    //   this.props.getTasks({ status: 'inProgress' })
    //   this.props.getTasksByType({ type: 'story' })
    // }
    if (this.props.tasks !== prevProps.tasks) {
      this.setTasks(this.props.tasks)
    }
    if (this.props.stories !== prevProps.stories) {
      this.setStories(this.props.stories)
    }
    if (this.props.epics !== prevProps.epics) {
      this.setEpics(this.props.epics)
    }
  }

  setStories (stories) {
    this.setState({ stories })
  }

  setEpics (epics) {
    this.setState({ epics })
  }

  handleCheckboxChange = (e, name) => {
    const oldState = this.state
    oldState[name] = e.target.checked
    this.setState(oldState)
    this.setTasks(this.props.tasks)
  }

  formattedTaskList = (tasks, status) => {
    return tasks.filter((t) => t.status === status && !t.parentId).map((t) => {
      const obj = {
        id: t.id,
        title: t.title,
        metadata: t
      }
      const description = (<div>{t.body}</div>)
      if (t.started && t.startedOn && !t.completed && t.status !== 'done') {
        obj.label = moment(t.startedOn).fromNow()
      }
      // if (t.children && t.children.length > 0) {
      //   const subtasks = t.children.map((c) => {
      //     return (
      //       <div key={c.id}>
      //         <input type='checkbox' id={c.id} name={c.id} checked={c.completed}
      //         />
      //         <label htmlFor={c.id}>{c.title}</label>
      //       </div>
      //     )
      //   })
      //   description = (
      //     <div>
      //       {t.body}
      //       <div>{subtasks}</div>
      //     </div>
      //   )
      // }
      obj.description = description
      return obj
    })
  }

  setTasks = (tasks) => {
    const someday = this.formattedTaskList(tasks, 'someday')
    const todo = this.formattedTaskList(tasks, 'todo')
    const inProgress = this.formattedTaskList(tasks, 'inProgress')
    const lanes = [
      {
        id: 'someday',
        title: 'Someday',
        cards: someday
      },
      {
        id: 'todo',
        title: 'To-do',
        cards: todo
      },
      {
        id: 'inProgress',
        title: 'In Progress',
        cards: inProgress
      }
    ]
    if (this.state.viewDone === true) {
      const done = this.formattedTaskList(tasks, 'done')
      lanes.push({
        id: 'done',
        title: 'Done',
        cards: done
      })
    }
    if (this.state.viewNotDone === true) {
      const notDone = this.formattedTaskList(tasks, 'notDone')
      lanes.push({
        id: 'notDone',
        title: 'Did Not Do',
        cards: notDone
      })
    }
    this.setState({
      board: {
        lanes
      }
    })
  }

  render () {
    const boardStyle = { backgroundColor: 'inherit' }
    let markCompleted
    if (this.state.viewDone === true) {
      markCompleted = (
        <Button variant='contained' color='primary'
          onClick={() => {
            const ids = []
            this.state.board.lanes
              .filter((l) => l.id === 'done')
              .forEach((l) => {
                l.cards.forEach((c) => {
                  ids.push(c.id)
                })
              })
            this.props.bulkUpdateTasksStatuses({
              ids,
              data: {
                completed: true,
                completedOn: new Date()
              }
            })
          }}
        >
          Mark all &apos;Done&apos; as completed
        </Button>
      )
    }
    return (
      <div id='day-planner-container' className='component-container'>
        <Container>
          <h2>Plan your day</h2>
          <div style={{ textAlign: 'right' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.viewDone}
                  onChange={(e) => this.handleCheckboxChange(e, 'viewDone')}
                  name='viewDone' />
              }
              label='Done'
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.viewNotDone}
                  onChange={(e) => this.handleCheckboxChange(e, 'viewNotDone')}
                  name='viewNotDone' />
              }
              label='Not Done'
            />
            <div>{markCompleted}</div>
          </div>
          <Board
            components={{
              Card: (props) => {
                return <BoardCard {...props}
                  createTask={this.props.createTask}
                  handleOnEditClick={this.props.openEditTaskDialog}
                  hasCreatedSubtask={this.props.hasCreatedSubtask}
                  createdSubtask={this.props.createdSubtask}
                />
              },
              AddCardLink: (props) => {
                return (
                  <div style={{ cursor: 'pointer' }}
                    onClick={() => this.props.openEditTaskDialog({ defaultStatus: props.laneId })}>
                    Add task
                  </div>
                )
              }
            }}
            editable
            onCardMoveAcrossLanes={(_from, to, id) => {
              this.props.createTask({
                id: id,
                status: to
              })
            }}
            // onCardClick={(_id, task, _lane) => {
            //   this.props.openEditTaskDialog(task)
            // }}
            data={this.state.board} style={boardStyle}
          />
        </Container>
      </div>
    )
  }
}

Day.propTypes = {
  // getBullets: PropTypes.func,
  // bullets: PropTypes.array,
  // openLightbox: PropTypes.func,
  // createBullet: PropTypes.func,
  hasCreatedBullet: PropTypes.bool,
  // hasUpdatedTrelloCard: PropTypes.bool,
  // getGoogleCalData: PropTypes.func,
  // googleCalData: PropTypes.array,
  // openMapDialog: PropTypes.func,
  // closeMapDialog: PropTypes.func
  getTasks: PropTypes.func,
  createTask: PropTypes.func,
  editTaskDialogIsOpen: PropTypes.bool,
  closeEditTaskDialog: PropTypes.func,
  hasCreatedTask: PropTypes.bool,
  getRelatedTasks: PropTypes.func,
  relatedTasks: PropTypes.array,
  selectedTaskToEdit: PropTypes.object,
  openEditTaskDialog: PropTypes.func,
  defaultTaskStatus: PropTypes.string,
  // selectedBoard: PropTypes.object,
  tasks: PropTypes.array,
  stories: PropTypes.array,
  epics: PropTypes.array,
  getTasksByType: PropTypes.func,
  getEpics: PropTypes.func,
  bulkUpdateTasksStatuses: PropTypes.func,
  hasBulkUpdatedTasksStatuses: PropTypes.bool,
  hasCreatedSubtask: PropTypes.bool,
  createdSubtask: PropTypes.object
}

const mapStateToProps = (state) => {
  const {
    isCreatingBullet,
    hasCreatedBullet,
    createdBullet,
    getBullets,
    isGettingBullets,
    hasGottenBullets,
    bullets,
    googleCalData,
    selectedBoard,
    isCreatingTask,
    hasCreatedTask,
    createdTask,
    isGettingTasks,
    hasGottenTasks,
    tasks,
    isGettingRelatedTasks,
    hasGottenRelatedTasks,
    relatedTasks,
    isGettingStories,
    hasGottenStories,
    stories,
    isGettingSubtasks,
    hasGottenSubtasks,
    subtasks,
    epics,
    isGettingEpics,
    hasGottenEpics,
    isBulkUpdatingTasksStatuses,
    hasBulkUpdatedTasksStatuses,
    hasCreatedSubtask,
    createdSubtask
  } = state.bullets
  const {
    editTaskDialogIsOpen,
    selectedTaskToEdit,
    defaultTaskStatus
  } = state.site
  return {
    isCreatingBullet,
    hasCreatedBullet,
    createdBullet,
    getBullets,
    isGettingBullets,
    hasGottenBullets,
    bullets,
    googleCalData,
    selectedBoard,
    isCreatingTask,
    hasCreatedTask,
    createdTask,
    isGettingTasks,
    hasGottenTasks,
    tasks,
    isGettingRelatedTasks,
    hasGottenRelatedTasks,
    relatedTasks,
    isGettingStories,
    hasGottenStories,
    stories,
    isGettingSubtasks,
    hasGottenSubtasks,
    subtasks,
    epics,
    isGettingEpics,
    hasGottenEpics,
    editTaskDialogIsOpen,
    selectedTaskToEdit,
    isBulkUpdatingTasksStatuses,
    hasBulkUpdatedTasksStatuses,
    defaultTaskStatus,
    hasCreatedSubtask,
    createdSubtask
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({
    getBullets,
    // openLightbox,
    createBullet,
    // getGoogleCalData,
    // closeMapDialog,
    // openMapDialog,
    // getTrelloCards,
    // updateTrelloCard,
    bulkUpdateTasksStatuses,
    createTask,
    getTasks,
    getRelatedTasks,
    getTasksByType,
    getEpics,
    openEditTaskDialog,
    closeEditTaskDialog
  }, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Day)
