import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import TimelineConnector from '@mui/lab/TimelineConnector'
import LightModeIcon from '@mui/icons-material/LightModeOutlined'
import { Tooltip } from '@mui/material'
import { Link } from 'react-router-dom'
import moment from 'moment'

const WakeUpBullet = ({ hasNext, date }) => {
  const [sleepEndString, setSleepEndString] = useState('')
  const [sleepEndTimeString, setSleepEndTimeString] = useState('')
  const [sleepDurationHours, setSleepDurationHours] = useState(0)
  const [sleepDurationMinutes, setSleepDurationMinutes] = useState(0)
  const { data } = useSelector((state) => {
    return {
      data: state.bullets.googleFitData
    }
  })
  useEffect(() => {
    let sleepEndTime
    setSleepEndString('')
    setSleepEndTimeString('')
    if (data && data.sleep) {
      data.sleep.forEach((s) => {
        if (moment(s.endTime).isSame(date, 'day')) {
          sleepEndTime = s.endTime
          setSleepEndString(moment(sleepEndTime).format('h:mm a'))
          setSleepEndTimeString(moment(sleepEndTime).format('hh:mm a'))
          const h = moment(s.endTime).diff(moment(s.startTime), 'hours')
          if (h > 0) {
            setSleepDurationHours(h)
          }
          let m =
          moment(s.endTime).diff(moment(s.startTime), 'minutes')
          m = m - (h * 60)
          if (m > 0) {
            setSleepDurationMinutes(m)
          }
        }
      })
    }
  }, [data, date])
  return (
    <>
      {sleepEndString !== '' ? (
        <TimelineItem key='timeline-wakeup'>
          <TimelineOppositeContent
            style={{ flex: 0, fontSize: '10px', marginTop: '15px' }}
            color='text.secondary'>
            <Tooltip title={moment(date).format('ddd DD/MM/YYYY')} placement='top'>
              <Link to={`/admin/bullets?d=${new Date(date).toISOString()}`}>
                <span style={{ cursor: 'pointer' }}>{sleepEndTimeString}</span>
              </Link>
            </Tooltip>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <Tooltip title='From Google Fit' placement='top'>
              <span>
                <TimelineDot color='info'>
                  <LightModeIcon />
                </TimelineDot>
              </span>
            </Tooltip>
            {hasNext ? <TimelineConnector /> : ''}
          </TimelineSeparator>
          <TimelineContent style={{ marginTop: '10px' }}>
            Wake up!<br />
            <span>
              {sleepEndString}
              &nbsp;<small>({sleepDurationHours}h {sleepDurationMinutes}m)</small>
            </span>
          </TimelineContent>
        </TimelineItem>
      ) : ''}
    </>
  )
}

WakeUpBullet.propTypes = {
  hasNext: PropTypes.bool,
  date: PropTypes.object
}

export default WakeUpBullet
