import { combineReducers } from 'redux'
import { sessionReducer } from 'redux-react-session'
// import locationReducer from './location'
// import { reducer as form } from 'redux-form'
import { responsiveStateReducer as browser } from 'redux-responsive'
import site from 'store/modules/site'
import auth from 'store/modules/auth'
import content from 'store/modules/content'
import files from 'store/modules/files'
import bullets from 'store/modules/bullets'
// import { routerReducer } from 'react-router-redux'
import { connectRouter } from 'connected-react-router'

// export const makeRootReducer = (asyncReducers) => {
//   // Add async reducers here
//   return combineReducers({
//     // location: locationReducer,
//     // routing: routerReducer,
//     // router: connectRouter(asyncReducers),
//     browser,
//     // form,
//     ...asyncReducers
//   })
// }

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  site,
  auth,
  content,
  browser,
  files,
  bullets,
  session: sessionReducer
})
export default createRootReducer

// export const injectReducer = (store, { key, reducer }) => {
//   if (Object.hasOwnProperty.call(store.asyncReducers, key)) return
//   store.asyncReducers[key] = reducer
//   store.replaceReducer(makeRootReducer(store.asyncReducers))
// }
//
// export default makeRootReducer
