import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Tooltip } from '@mui/material'
import BulletIcon from 'components/Bullets/BulletIcon'
import { getHabits } from 'store/modules/bullets'

const HabitsCountWidget = ({ date }) => {
  const {
    habits
  } = useSelector((state) => {
    return {
      habits: state.bullets.habits
    }
  })
  const dispatch = useDispatch()
  const [habitsAmount, setHabitsAmount] = useState(0)
  const [habitsTarget, setHabitsTarget] = useState(0)
  useEffect(() => {
    let t = 0
    let a = 0
    habits.filter((h) => (h.active === true)).forEach((h) => {
      if (h.targetDuration === 'day') {
        t += h.target
        a += h.bulletCount
      }
    })
    setHabitsAmount(a)
    setHabitsTarget(t)
  }, [habits])

  useEffect(() => {
    if (date) {
      dispatch(getHabits({
        filter: { date }
      }))
    }
  }, [date])

  const divStyle = { display: 'inline-block', paddingLeft: '5px' }
  const iconStyle = { position: 'relative', top: '7px', width: '20px', cursor: 'pointer' }
  return (
    <>
      <div style={divStyle}>
        <Tooltip title='Habits' placement='left'>
          <span>
            <BulletIcon type='habit' style={iconStyle} />
          </span>
        </Tooltip>
        &nbsp; {habitsAmount} / {habitsTarget}
      </div>
    </>
  )
}

HabitsCountWidget.propTypes = {
  date: PropTypes.object
}

export default HabitsCountWidget
