import moment from 'moment'
import { createAction, handleActions } from 'redux-actions'
import { endLoad, openAlert, startLoad } from 'store/modules/site'
import { sendApiRequest } from 'utils/api'
const jsesc = require('jsesc')

export const GET_TRELLO_CARDS = 'GET_TRELLO_CARDS'
export const getTrelloCards = (data = {}) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_TRELLO_CARDS)(data.cardType))
    const boardType = getState().bullets.selectedBoard
    sendApiRequest(`trello/cards/${data.cardType}/${boardType}`, {
      token: getState().auth.token
    }, 'get').then((response) => {
      if (response.status === 'failure') {
        dispatch(getTrelloCardsError(response))
      }
      if (response.status === 'success') {
        dispatch(getTrelloCardsSuccess({
          cardType: data.cardType,
          data: response.data
        }))
      }
    })
  }
}

export const GET_TRELLO_CARDS_SUCCESS = 'GET_TRELLO_CARDS_SUCCESS'
export const getTrelloCardsSuccess = createAction(GET_TRELLO_CARDS_SUCCESS)

export const GET_TRELLO_CARDS_ERROR = 'GET_TRELLO_CARDS_ERROR'
export const getTrelloCardsError = createAction(GET_TRELLO_CARDS_ERROR)

export const CREATE_BULLET = 'CREATE_BULLET'
export const createBullet = (data) => {
  return (dispatch, getState) => {
    dispatch(createAction(CREATE_BULLET)())
    // dispatch(startLoad())
    data.token = getState().auth.token
    let url = 'bullets'
    let type = 'post'
    if (data.body) { escape(data.body) }
    if (data.id) {
      url = `bullets/${data.id}`
      type = 'patch'
    }
    sendApiRequest(url, data, type).then((response) => {
      // dispatch(endLoad())
      if (response.status === 'failure') {
        dispatch(createBulletError(response))
      }
      if (response.status === 'success') {
        dispatch(createBulletSuccess(response.data))
        dispatch(openAlert({
          message: 'Bullet created',
          severity: 'success'
        }))
      }
    })
  }
}

export const CREATE_BULLET_SUCCESS = 'CREATE_BULLET_SUCCESS'
export const createBulletSuccess = createAction(CREATE_BULLET_SUCCESS)

export const CREATE_BULLET_ERROR = 'CREATE_BULLET_ERROR'
export const createBulletError = createAction(CREATE_BULLET_ERROR)

export const UPDATE_TRELLO_CARD = 'UPDATE_TRELLO_CARD'
export const updateTrelloCard = (data) => {
  return (dispatch, getState) => {
    dispatch(createAction(UPDATE_TRELLO_CARD)())
    dispatch(startLoad())
    const contentData = data
    data.token = getState().auth.token
    data.type = getState().bullets.selectedBoard
    delete data.initialStatus
    const url = `trello/${data.cardId}/${data.status}/${data.type}`
    sendApiRequest(url, contentData, 'post').then((response) => {
      dispatch(endLoad())
      if (response.status === 'failure') {
        dispatch(updateTrelloCardError(response))
      }
      if (response.status === 'success') {
        dispatch(updateTrelloCardSuccess(response.data))
        dispatch(openAlert({
          message: 'Task updated',
          severity: 'success'
        }))
      }
    })
  }
}

export const UPDATE_TRELLO_CARD_SUCCESS = 'UPDATE_TRELLO_CARD_SUCCESS'
export const updateTrelloCardSuccess = createAction(UPDATE_TRELLO_CARD_SUCCESS)

export const UPDATE_TRELLO_CARD_ERROR = 'UPDATE_TRELLO_CARD_ERROR'
export const updateTrelloCardError = createAction(UPDATE_TRELLO_CARD_ERROR)

export const GET_BULLETS = 'GET_BULLETS'
export const getBullets = (data = {}) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_BULLETS)())
    // dispatch(startLoad())
    const opts = {
      filter: {
        where: {
          AND: []
        }
      }
    }
    if (data.limit && data.page) {
      opts.filter.take = data.limit
      opts.filter.skip = (data.page - 1) * data.limit
    }
    if (data.date) {
      opts.filter.where.AND = [
        {
          createdOn: {
            gte: moment(data.date).startOf('day'),
            lte: moment(data.date).endOf('day'),
          }
        }
      ]
    }
    if (data.dateRange) {
      opts.filter.where.AND = [
        {
          createdOn: {
            gte: moment(data.dateRange.startDate).startOf('day'),
            lte: moment(data.dateRange.endDate).endOf('day'),
          }
        }
      ]
    }
    if (data.orderBy) {
      opts.filter.orderBy = data.orderBy
    }
    if (data.search) {
      const string = encodeURIComponent(jsesc(data.search))
      opts.filter.where.AND.push(
        { body: { contains: `%25${string}%25` } }
      )
    }
    sendApiRequest('bullets', {
      token: getState().auth.token
    }, 'get', opts).then((response) => {
      // dispatch(endLoad())
      if (response.status === 'failure') {
        dispatch(getBulletsError(response))
      }
      if (response.status === 'success') {
        dispatch(getBulletsSuccess(response.data))
      }
    })
  }
}

export const GET_BULLETS_SUCCESS = 'GET_BULLETS_SUCCESS'
export const getBulletsSuccess = createAction(GET_BULLETS_SUCCESS)

export const GET_BULLETS_ERROR = 'GET_BULLETS_ERROR'
export const getBulletsError = createAction(GET_BULLETS_ERROR)

export const GET_BULLETS_WITH_AGGREGATE = 'GET_BULLETS_WITH_AGGREGATE'
export const getBulletsWithAggregate = (data = {}) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_BULLETS_WITH_AGGREGATE)())
    // dispatch(startLoad())
    let url = 'bullets/withAggregate'
    const opts = {
      filter: {
        where: {
          AND: []
        }
      }
    }
    if (data.limit && data.page) {
      opts.filter.take = data.limit
      opts.filter.skip = (data.page - 1) * data.limit
    }
    if (data.date) {
      opts.filter.where.AND = [
        {
          createdOn: {
            gte: moment(data.date).startOf('day'),
            lte: moment(data.date).endOf('day'),
          }
        }
      ]
    }
    if (data.dateRange) {
      opts.filter.where.AND = [
        {
          createdOn: {
            gte: moment(data.dateRange.startDate).startOf('day'),
            lte: moment(data.dateRange.endDate).endOf('day'),
          }
        }
      ]
      url = 'bullets/withAggregate/range'
    }
    if (data.search) {
      const string = encodeURIComponent(jsesc(data.search))
      opts.filter.where.AND.push(
        { body: { contains: `%25${string}%25` } }
      )
    }
    sendApiRequest(url, {
      token: getState().auth.token
    }, 'get', opts).then((response) => {
      // dispatch(endLoad())
      if (response.status === 'failure') {
        dispatch(getBulletsWithAggregateError(response))
      }
      if (response.status === 'success') {
        dispatch(getBulletsWithAggregateSuccess(response.data))
      }
    })
  }
}

export const GET_BULLETS_WITH_AGGREGATE_SUCCESS = 'GET_BULLETS_WITH_AGGREGATE_SUCCESS'
export const getBulletsWithAggregateSuccess = createAction(GET_BULLETS_WITH_AGGREGATE_SUCCESS)

export const GET_BULLETS_WITH_AGGREGATE_ERROR = 'GET_BULLETS_WITH_AGGREGATE_ERROR'
export const getBulletsWithAggregateError = createAction(GET_BULLETS_WITH_AGGREGATE_ERROR)

export const GET_BULLET_COUNT = 'GET_BULLET_COUNT'
export const getBulletCount = (data = {}) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_BULLET_COUNT)())
    const opts = {
      filter: {
        where: {
          AND: []
        }
      }
    }
    if (data.limit && data.page) {
      opts.filter.limit = data.limit
    }
    if (data.date) {
      opts.filter.where.AND = [
        {
          createdOn: {
            gte: moment(data.date).startOf('day'),
            lte: moment(data.date).endOf('day'),
          }
        }
      ]
    }
    if (data.dateRange) {
      opts.filter.where.AND = [
        {
          createdOn: {
            gte: moment(data.dateRange.startDate).startOf('day'),
            lte: moment(data.dateRange.endDate).endOf('day'),
          }
        }
      ]
    }
    if (data.search) {
      const string = encodeURIComponent(jsesc(data.search))
      opts.filter.where.AND.push(
        { body: { contains: `%25${string}%25` } }
      )
    }
    sendApiRequest('bullets/count', {
      token: getState().auth.token
    }, 'get', opts.filter).then((response) => {
      // console.log('bulletCount', response)
      if (response.status === 'failure') {
        dispatch(getBulletCountError(response))
      }
      if (response.status === 'success') {
        dispatch(getBulletCountSuccess(response.data.count))
      }
    })
  }
}

export const GET_BULLET_COUNT_SUCCESS = 'GET_BULLET_COUNT_SUCCESS'
export const getBulletCountSuccess = createAction(GET_BULLET_COUNT_SUCCESS)

export const GET_BULLET_COUNT_ERROR = 'GET_BULLET_COUNT_ERROR'
export const getBulletCountError = createAction(GET_BULLET_COUNT_ERROR)

export const GET_BULLET_AVERAGES = 'GET_BULLET_AVERAGES'
export const getBulletAverages = (string) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_BULLET_AVERAGES)())
    sendApiRequest(`bullets/averages/%25${encodeURIComponent(string)}%25`, {
      token: getState().auth.token
    }, 'get').then((response) => {
      if (response.status === 'failure') {
        dispatch(getBulletAveragesError(response))
      }
      if (response.status === 'success') {
        dispatch(getBulletAveragesSuccess(response.data))
      }
    })
  }
}

export const GET_BULLET_AVERAGES_SUCCESS = 'GET_BULLET_AVERAGES_SUCCESS'
export const getBulletAveragesSuccess = createAction(GET_BULLET_AVERAGES_SUCCESS)

export const GET_BULLET_AVERAGES_ERROR = 'GET_BULLET_AVERAGES_ERROR'
export const getBulletAveragesError = createAction(GET_BULLET_AVERAGES_ERROR)

export const GET_BULLETS_BILLING = 'GET_BULLETS_BILLING'
export const getBulletsBilling = (data = {}) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_BULLETS_BILLING)())
    // dispatch(startLoad())
    const opts = {
      filter: {
        where: {
          AND: []
        }
      }
    }
    if (data.limit && data.page) {
      opts.filter.limit = data.limit
      opts.filter.offset = (data.page - 1) * data.limit
    }
    if (data.date) {
      opts.filter.where.AND = [
        {
          createdOn: {
            gte: moment(data.date).startOf('day'),
            lte: moment(data.date).endOf('day'),
          }
        }
      ]
    }
    if (data.dateRange) {
      opts.filter.where.AND = [
        {
          createdOn: {
            gte: moment(data.dateRange.startDate).startOf('day'),
            lte: moment(data.dateRange.endDate).endOf('day'),
          }
        }
      ]
    }
    if (data.search) {
      const string = encodeURIComponent(jsesc(data.search))
      opts.filter.where.AND.push(
        { body: { contains: `%25${string}%25` } }
      )
    }
    sendApiRequest('bullets/billing', {
      token: getState().auth.token
    }, 'get', opts).then((response) => {
      // dispatch(endLoad())
      if (response.status === 'failure') {
        dispatch(getBulletsBillingError(response))
      }
      if (response.status === 'success') {
        dispatch(getBulletsBillingSuccess(response.data))
      }
    })
  }
}

export const GET_BULLETS_BILLING_SUCCESS = 'GET_BULLETS_BILLING_SUCCESS'
export const getBulletsBillingSuccess = createAction(GET_BULLETS_BILLING_SUCCESS)

export const GET_BULLETS_BILLING_ERROR = 'GET_BULLETS_BILLING_ERROR'
export const getBulletsBillingError = createAction(GET_BULLETS_BILLING_ERROR)

export const GET_HABITS = 'GET_HABITS'
export const getHabits = (opts) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_HABITS)())
    sendApiRequest('habits/withCount', {
      token: getState().auth.token
    }, 'get', opts).then((response) => {
      if (response.status === 'failure') {
        dispatch(getHabitsError(response))
      }
      if (response.status === 'success') {
        dispatch(getHabitsSuccess(response.data))
      }
    })
  }
}

export const GET_HABITS_SUCCESS = 'GET_HABITS_SUCCESS'
export const getHabitsSuccess = createAction(GET_HABITS_SUCCESS)

export const GET_HABITS_ERROR = 'GET_HABITS_ERROR'
export const getHabitsError = createAction(GET_HABITS_ERROR)

export const GET_EPICS = 'GET_EPICS'
export const getEpics = () => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_EPICS)())
    sendApiRequest('bullets/epics', {
      token: getState().auth.token
    }, 'get').then((response) => {
      if (response.status === 'failure') {
        dispatch(getEpicsError(response))
      }
      if (response.status === 'success') {
        dispatch(getEpicsSuccess(response.data))
      }
    })
  }
}

export const GET_EPICS_SUCCESS = 'GET_EPICS_SUCCESS'
export const getEpicsSuccess = createAction(GET_EPICS_SUCCESS)

export const GET_EPICS_ERROR = 'GET_EPICS_ERROR'
export const getEpicsError = createAction(GET_EPICS_ERROR)

export const SELECT_BOARD = 'SELECT_BOARD'
export const selectBoard = (data) => {
  return (dispatch) => {
    const d = {
      board: data,
      switch: false
    }
    if (data === 'work') {
      d.switch = true
    }
    dispatch(createAction(SELECT_BOARD)(d))
  }
}

export const CREATE_HABIT = 'CREATE_HABIT'
export const createHabit = (data) => {
  return (dispatch, getState) => {
    dispatch(createAction(CREATE_BULLET)())
    // dispatch(startLoad())
    const contentData = data
    data.token = getState().auth.token
    let url = 'habits'
    let type = 'post'
    let message = 'Habit created'
    if (data.id) {
      url = `habits/${data.id}`
      type = 'patch'
      message = 'Habit edited'
    }
    sendApiRequest(url, contentData, type).then((response) => {
      // dispatch(endLoad())
      if (response.status === 'failure') {
        dispatch(createHabitError(response))
      }
      if (response.status === 'success') {
        dispatch(createHabitSuccess(response.data))
        if (type === 'post') {
          dispatch(getHabits())
        }
        // dispatch(getHabits())
        dispatch(openAlert({
          message,
          severity: 'success'
        }))
      }
    })
  }
}

export const CREATE_HABIT_SUCCESS = 'CREATE_HABIT_SUCCESS'
export const createHabitSuccess = createAction(CREATE_HABIT_SUCCESS)

export const CREATE_HABIT_ERROR = 'CREATE_HABIT_ERROR'
export const createHabitError = createAction(CREATE_HABIT_ERROR)

export const CREATE_EPIC = 'CREATE_EPIC'
export const createEpic = (data) => {
  return (dispatch, getState) => {
    dispatch(createAction(CREATE_EPIC)())
    dispatch(startLoad())
    const contentData = data
    data.token = getState().auth.token
    let url = 'epics'
    let type = 'post'
    if (data.id) {
      url = `epics/${data.id}`
      type = 'patch'
    }
    sendApiRequest(url, contentData, type).then((response) => {
      dispatch(endLoad())
      if (response.status === 'failure') {
        dispatch(createEpicError(response))
      }
      if (response.status === 'success') {
        dispatch(createEpicSuccess(response.data))
        dispatch(getEpics())
        dispatch(openAlert({
          message: 'Epic created',
          severity: 'success'
        }))
      }
    })
  }
}

export const CREATE_EPIC_SUCCESS = 'CREATE_EPIC_SUCCESS'
export const createEpicSuccess = createAction(CREATE_EPIC_SUCCESS)

export const CREATE_EPIC_ERROR = 'CREATE_HABIT_ERROR'
export const createEpicError = createAction(CREATE_EPIC_ERROR)

export const UPLOAD_BULLET_IMAGE = 'UPLOAD_BULLET_IMAGE'
export const uploadBulletImage = (files, data = {}) => {
  return (dispatch, getState) => {
    dispatch(createAction(UPLOAD_BULLET_IMAGE)())
    dispatch(startLoad())
    const options = { token: getState().auth.token }
    const contentData = new FormData()
    for (var i = 0; i < files.length; i++) {
      contentData.append(files[i].name, files[i])
    }
    for (const [key, value] of Object.entries(data)) {
      // console.log(`${key}: ${value}`)
      contentData.append(key, value)
    }
    sendApiRequest('files', contentData, 'post', options).then((response) => {
      dispatch(endLoad())
      if (response.status === 'failure') {
        dispatch(uploadBulletImageError(response))
      }
      if (response.status === 'success') {
        dispatch(uploadBulletImageSuccess(response.data))
      }
    })
  }
}

export const UPLOAD_BULLET_IMAGE_SUCCESS = 'UPLOAD_BULLET_IMAGE_SUCCESS'
export const uploadBulletImageSuccess = createAction(UPLOAD_BULLET_IMAGE_SUCCESS)

export const UPLOAD_BULLET_IMAGE_ERROR = 'UPLOAD_BULLET_IMAGE_ERROR'
export const uploadBulletImageError = createAction(UPLOAD_BULLET_IMAGE_ERROR)

export const GET_GOOGLE_FIT_DATA = 'GET_GOOGLE_FIT_DATA'
export const getGoogleFitData = (data = {}) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_GOOGLE_FIT_DATA)())
    const opts = {
      filter: data
    }
    sendApiRequest('google/fit', {
      token: getState().auth.token
    }, 'get', opts).then((response) => {
      if (response.status === 'failure') {
        dispatch(getGoogleFitDataError(response))
      }
      if (response.status === 'success') {
        dispatch(getGoogleFitDataSuccess(response.data))
      }
    })
  }
}

export const GET_GOOGLE_FIT_DATA_SUCCESS = 'GET_GOOGLE_FIT_DATA_SUCCESS'
export const getGoogleFitDataSuccess = createAction(GET_GOOGLE_FIT_DATA_SUCCESS)

export const GET_GOOGLE_FIT_DATA_ERROR = 'GET_GOOGLE_FIT_DATA_ERROR'
export const getGoogleFitDataError = createAction(GET_GOOGLE_FIT_DATA_ERROR)

export const GET_GOOGLE_CAL_DATA = 'GET_GOOGLE_CAL_DATA'
export const getGoogleCalData = (data = {}) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_GOOGLE_CAL_DATA)())
    const opts = {
      filter: data
    }
    sendApiRequest('google/cal', {
      token: getState().auth.token
    }, 'get', opts).then((response) => {
      if (response.status === 'failure') {
        dispatch(getGoogleCalDataError(response))
      }
      if (response.status === 'success') {
        dispatch(getGoogleCalDataSuccess(response.data))
      }
    })
  }
}

export const GET_GOOGLE_CAL_DATA_SUCCESS = 'GET_GOOGLE_CAL_DATA_SUCCESS'
export const getGoogleCalDataSuccess = createAction(GET_GOOGLE_CAL_DATA_SUCCESS)

export const GET_GOOGLE_CAL_DATA_ERROR = 'GET_GOOGLE_CAL_DATA_ERROR'
export const getGoogleCalDataError = createAction(GET_GOOGLE_CAL_DATA_ERROR)

export const GET_WAKATIME_DATA = 'GET_WAKATIME_DATA'
export const getWakatimeData = (data = {}) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_WAKATIME_DATA)())
    const opts = {
      filter: data
    }
    sendApiRequest('wakatime', {
      token: getState().auth.token
    }, 'get', opts).then((response) => {
      if (response.status === 'failure') {
        dispatch(getWakatimeDataError(response))
      }
      if (response.status === 'success') {
        dispatch(getWakatimeDataSuccess(response.data))
      }
    })
  }
}

export const GET_WAKATIME_DATA_SUCCESS = 'GET_WAKATIME_DATA_SUCCESS'
export const getWakatimeDataSuccess = createAction(GET_WAKATIME_DATA_SUCCESS)

export const GET_WAKATIME_DATA_ERROR = 'GET_GOOGLE_CAL_DATA_ERROR'
export const getWakatimeDataError = createAction(GET_WAKATIME_DATA_ERROR)

export const CREATE_TASK = 'CREATE_TASK'
export const createTask = (data) => {
  return (dispatch, getState) => {
    dispatch(createAction(CREATE_TASK)())
    // dispatch(startLoad())
    data.token = getState().auth.token
    let url = 'tasks'
    let type = 'post'
    let message = 'Task Created'
    if (data.body) { escape(data.body) }
    if (data.id) {
      url = `tasks/${data.id}`
      type = 'patch'
      message = 'Task Updated'
    }
    sendApiRequest(url, data, type).then((response) => {
      // dispatch(endLoad())
      if (response.status === 'failure') {
        dispatch(createTaskError(response))
      }
      if (response.status === 'success') {
        if (response.data.parentId) {
          // console.log('subtask', response.data.parentId)
          dispatch(createSubtaskSuccess(response.data))
        } else {
          dispatch(createTaskSuccess(response.data))
        }
        dispatch(openAlert({
          message,
          severity: 'success'
        }))
      }
    })
  }
}

export const CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS'
export const createTaskSuccess = createAction(CREATE_TASK_SUCCESS)

export const CREATE_TASK_ERROR = 'CREATE_TASK_ERROR'
export const createTaskError = createAction(CREATE_TASK_ERROR)

export const CREATE_SUBTASK_SUCCESS = 'CREATE_SUBTASK_SUCCESS'
export const createSubtaskSuccess = createAction(CREATE_SUBTASK_SUCCESS)

export const BULK_UPDATE_TASKS_STATUSES = 'BULK_UPDATE_TASKS_STATUSES'
export const bulkUpdateTasksStatuses = (data) => {
  return (dispatch, getState) => {
    dispatch(createAction(BULK_UPDATE_TASKS_STATUSES)())
    dispatch(startLoad())
    data.token = getState().auth.token
    const url = 'tasks/bulk'
    const type = 'post'
    sendApiRequest(url, data, type).then((response) => {
      dispatch(endLoad())
      if (response.status === 'failure') {
        dispatch(bulkUpdateTasksStatusesError(response))
      }
      if (response.status === 'success') {
        dispatch(bulkUpdateTasksStatusesSuccess(response.data))
        dispatch(openAlert({
          message: 'Tasks Updated',
          severity: 'success'
        }))
      }
    })
  }
}

export const BULK_UPDATE_TASKS_STATUSES_SUCCESS = 'BULK_UPDATE_TASKS_STATUSES_SUCCESS'
export const bulkUpdateTasksStatusesSuccess = createAction(BULK_UPDATE_TASKS_STATUSES_SUCCESS)

export const BULK_UPDATE_TASKS_STATUSES_ERROR = 'BULK_UPDATE_TASKS_STATUSES_ERROR'
export const bulkUpdateTasksStatusesError = createAction(BULK_UPDATE_TASKS_STATUSES_ERROR)

export const GET_TASKS = 'GET_TASKS'
export const getTasks = (data = {}) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_TASKS)())
    // dispatch(startLoad())
    const opts = {
      filter: {
        where: {
          AND: []
        }
      }
    }
    if (data.limit && data.page) {
      opts.filter.take = data.limit
      opts.filter.skip = (data.page - 1) * data.limit
    }
    // if (data.date) {
    //   opts.filter.where.AND = [
    //     {
    //       dueOn: {
    //         lte: moment(data.date).startOf('day')
    //       }
    //     }
    //   ]
    // }
    // if (data.dateRange) {
    //   opts.filter.where.AND = [
    //     {
    //       dueOn: {
    //         between:[
    //           moment(data.dateRange.startDate).startOf('day'),
    //           moment(data.dateRange.endDate).endOf('day')
    //         ]
    //       }
    //     }
    //   ]
    // }
    if (data.search) {
      const string = encodeURIComponent(jsesc(data.search))
      opts.filter.where.AND.push(
        { body: { contains: `%25${string}%25` } }
      )
    }
    if (data.status) {
      opts.filter.where.AND.push({ status: data.status })
    }
    if (data.completed) {
      opts.filter.where.AND.push({ completed: data.completed })
    }
    if (data.withoutAgenda) {
      opts.filter.where.AND.push({ beginOn: null })
      // opts.filter.where.AND.push({ dueOn: null })
    }
    if (data.types) {
      opts.filter.where.AND.push({ type: { in: data.types } })
    }
    opts.filter.where.AND.push({ deleted: { not: true } })
    opts.filter.where.AND.push({ closed: { not: true } })
    sendApiRequest('tasks', {
      token: getState().auth.token
    }, 'get', opts).then((response) => {
      // dispatch(endLoad())
      if (response.status === 'failure') {
        dispatch(getTasksError(response))
      }
      if (response.status === 'success') {
        dispatch(getTasksSuccess(response.data))
      }
    })
  }
}

export const GET_TASKS_SUCCESS = 'GET_TASKS_SUCCESS'
export const getTasksSuccess = createAction(GET_TASKS_SUCCESS)

export const GET_TASKS_ERROR = 'GET_TASKS_ERROR'
export const getTasksError = createAction(GET_TASKS_ERROR)

export const GET_AGENDA_TASKS = 'GET_AGENDA_TASKS'
export const getAgendaTasks = (data = {}) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_AGENDA_TASKS)())
    const opts = {
      filter: {
        where: {
          AND: [
            { beginOn: { not: null } },
            { dueOn: { not: null } },
            { completed: { not: true } }
          ]
        }
      }
    }
    if (data.limit && data.page) {
      opts.filter.take = data.limit
      opts.filter.skip = (data.page - 1) * data.limit
    }
    // if (data.date) {
    //   opts.filter.where.AND = [
    //     {
    //       dueOn: {
    //         lte: moment(data.date).startOf('day')
    //       }
    //     }
    //   ]
    // }
    // if (data.dateRange) {
    //   opts.filter.where.AND = [
    //     {
    //       dueOn: {
    //         between:[
    //           moment(data.dateRange.startDate).startOf('day'),
    //           moment(data.dateRange.endDate).endOf('day')
    //         ]
    //       }
    //     }
    //   ]
    // }
    if (data.search) {
      const string = encodeURIComponent(jsesc(data.search))
      opts.filter.where.AND.push(
        { body: { contains: `%25${string}%25` } }
      )
    }
    if (data.status) {
      opts.filter.where.AND.push({ status: data.status })
    }
    if (data.completed) {
      opts.filter.where.AND.push({ completed: data.completed })
    }
    opts.filter.where.AND.push({ deleted: { not: true } })
    opts.filter.where.AND.push({ closed: { not: true } })
    sendApiRequest('tasks', {
      token: getState().auth.token
    }, 'get', opts).then((response) => {
      dispatch(endLoad())
      if (response.status === 'failure') {
        dispatch(getAgendaTasksError(response))
      }
      if (response.status === 'success') {
        dispatch(getAgendaTasksSuccess(response.data))
      }
    })
  }
}

export const GET_AGENDA_TASKS_SUCCESS = 'GET_AGENDA_TASKS_SUCCESS'
export const getAgendaTasksSuccess = createAction(GET_AGENDA_TASKS_SUCCESS)

export const GET_AGENDA_TASKS_ERROR = 'GET_AGENDA_TASKS_ERROR'
export const getAgendaTasksError = createAction(GET_AGENDA_TASKS_ERROR)

export const GET_TASKS_BY_TYPE = 'GET_TASKS_BY_TYPE'
export const getTasksByType = (data) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_TASKS_BY_TYPE)(data.type))
    // dispatch(startLoad())
    const opts = {
      filter: {
        where: {
          AND: []
        }
      }
    }
    opts.filter.where.AND.push({ type: data.type })
    opts.filter.where.AND.push({ completed: { not: true } })
    opts.filter.where.AND.push({ closed: { not: true } })
    opts.filter.where.AND.push({ deleted: { not: true } })
    if (data.limit && data.page) {
      opts.filter.take = data.limit
      opts.filter.skip = (data.page - 1) * data.limit
    }
    if (data.search) {
      const string = encodeURIComponent(jsesc(data.search))
      opts.filter.where.AND.push(
        { body: { contains: `%25${string}%25` } }
      )
    }
    if (data.status) {
      opts.filter.where.AND.push({ status: data.status })
    }
    if (data.parentId) {
      opts.filter.where.AND.push({ parentId: data.parentId })
    }
    // console.log(data)
    sendApiRequest('tasks', {
      token: getState().auth.token
    }, 'get', opts).then((response) => {
      // dispatch(endLoad())
      if (response.status === 'failure') {
        dispatch(getTasksByTypeError(response))
      }
      if (response.status === 'success') {
        const res = { type: data.type, data: response.data }
        dispatch(getTasksByTypeSuccess(res))
      }
    })
  }
}

export const GET_TASKS_BY_TYPE_SUCCESS = 'GET_TASKS_BY_TYPE_SUCCESS'
export const getTasksByTypeSuccess = createAction(GET_TASKS_BY_TYPE_SUCCESS)

export const GET_TASKS_BY_TYPE_ERROR = 'GET_TASKS_BY_TYPE_ERROR'
export const getTasksByTypeError = createAction(GET_TASKS_BY_TYPE_ERROR)

export const GET_RELATED_TASKS = 'GET_RELATED_TASKS'
export const getRelatedTasks = (data = {}) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_RELATED_TASKS)())
    const opts = {
      filter: {
        where: {
          AND: []
        }
      }
    }
    if (data.limit && data.page) {
      opts.filter.take = data.limit
      opts.filter.skip = (data.page - 1) * data.limit
    }
    // if (data.date) {
    //   opts.filter.where.AND = [
    //     {
    //       startOn: {
    //         lte: moment(data.date).endOf('day')
    //       }
    //     }
    //   ]
    // }
    if (data.dateRange) {
      opts.filter.where.AND = [
        {
          dueOn: {
            gte: moment(data.dateRange.startDate).startOf('day'),
            lte: moment(data.dateRange.endDate).endOf('day'),
          }
        }
      ]
    }
    if (data.search) {
      const string = encodeURIComponent(jsesc(data.search))
      opts.filter.where.AND.push(
        { body: { contains: `%25${string}%25` } }
      )
    }
    opts.filter.where.AND.push({ completed: { not: true } })
    opts.filter.where.AND.push({ closed: { not: true } })
    opts.filter.where.AND.push({ deleted: { not: true } })
    opts.filter.where.AND.push({ type: data.type })
    sendApiRequest('tasks', {
      token: getState().auth.token
    }, 'get', opts).then((response) => {
      dispatch(endLoad())
      if (response.status === 'failure') {
        dispatch(getRelatedTasksError(response))
      }
      if (response.status === 'success') {
        dispatch(getRelatedTasksSuccess(response.data))
      }
    })
  }
}

export const GET_RELATED_TASKS_SUCCESS = 'GET_RELATED_TASKS_SUCCESS'
export const getRelatedTasksSuccess = createAction(GET_RELATED_TASKS_SUCCESS)

export const GET_RELATED_TASKS_ERROR = 'GET_RELATED_TASKS_ERROR'
export const getRelatedTasksError = createAction(GET_RELATED_TASKS_ERROR)

export const GET_TASK_BY_ID = 'GET_TASK_BY_ID'
export const getTaskById = (id) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_TASK_BY_ID)())
    dispatch(startLoad())
    const data = {
      token: getState().auth.token
    }
    sendApiRequest(`tasks/${id}`, data).then((response) => {
      dispatch(endLoad())
      if (response.status === 'failure') {
        dispatch(getTaskByIdError(response))
      }
      if (response.status === 'success') {
        // console.log(response.data)
        dispatch(getTaskByIdSuccess(response.data))
      }
    })
  }
}

export const GET_TASK_BY_ID_SUCCESS = 'GET_TASK_BY_ID_SUCCESS'
export const getTaskByIdSuccess = createAction(GET_TASK_BY_ID_SUCCESS)

export const GET_TASK_BY_ID_ERROR = 'GET_TASK_BY_ID_ERROR'
export const getTaskByIdError = createAction(GET_RELATED_TASKS_ERROR)

export const GET_TAGS = 'GET_TAGS'
export const getTags = () => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_TAGS)())
    sendApiRequest('bullets/tags', {
      token: getState().auth.token
    }, 'get').then((response) => {
      if (response.status === 'failure') {
        dispatch(getTagsError(response))
      }
      if (response.status === 'success') {
        dispatch(getTagsSuccess(response.data))
      }
    })
  }
}

export const GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS'
export const getTagsSuccess = createAction(GET_TAGS_SUCCESS)

export const GET_TAGS_ERROR = 'GET_TAGS_ERROR'
export const getTagsError = createAction(GET_TAGS_ERROR)

export const GET_ACTIVE_TAGS = 'GET_ACTIVE_TAGS'
export const getActiveTags = () => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_ACTIVE_TAGS)())
    sendApiRequest('bullets/tags/active', {
      token: getState().auth.token
    }, 'get').then((response) => {
      if (response.status === 'failure') {
        dispatch(getActiveTagsError(response))
      }
      if (response.status === 'success') {
        dispatch(getActiveTagsSuccess(response.data))
      }
    })
  }
}

export const GET_ACTIVE_TAGS_SUCCESS = 'GET_ACTIVE_TAGS_SUCCESS'
export const getActiveTagsSuccess = createAction(GET_ACTIVE_TAGS_SUCCESS)

export const GET_ACTIVE_TAGS_ERROR = 'GET_ACTIVE_TAGS_ERROR'
export const getActiveTagsError = createAction(GET_ACTIVE_TAGS_ERROR)

export const CREATE_TAG = 'CREATE_TAG'
export const createTag = (data) => {
  return (dispatch, getState) => {
    dispatch(createAction(CREATE_TAG)())
    dispatch(startLoad())
    data.token = getState().auth.token
    let url = 'bullets/tags'
    let type = 'post'
    if (data.id) {
      url = `bullets/tag/${data.id}`
      type = 'patch'
    }
    sendApiRequest(url, data, type).then((response) => {
      dispatch(endLoad())
      if (response.status === 'failure') {
        dispatch(createTagError(response))
      }
      if (response.status === 'success') {
        dispatch(createTagSuccess(response.data))
        dispatch(getTags())
        dispatch(openAlert({
          message: 'Tag updated',
          severity: 'success'
        }))
      }
    })
  }
}

export const CREATE_TAG_SUCCESS = 'CREATE_TAG_SUCCESS'
export const createTagSuccess = createAction(CREATE_TAG_SUCCESS)

export const CREATE_TAG_ERROR = 'CREATE_BULLET_ERROR'
export const createTagError = createAction(CREATE_TAG_ERROR)

export const GET_GIT_COMMIT_DATA = 'GET_GIT_COMMIT_DATA'
export const getGitCommitData = (data = {}) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_GIT_COMMIT_DATA)())
    const opts = {
      filter: data
    }
    sendApiRequest('git/commits', {
      token: getState().auth.token
    }, 'get', opts).then((response) => {
      if (response.status === 'failure') {
        dispatch(getGitCommitDataError(response))
      }
      if (response.status === 'success') {
        dispatch(getGitCommitDataSuccess(response.data))
      }
    })
  }
}

export const GET_GIT_COMMIT_DATA_SUCCESS = 'GET_GIT_COMMIT_DATA_SUCCESS'
export const getGitCommitDataSuccess = createAction(GET_GIT_COMMIT_DATA_SUCCESS)

export const GET_GIT_COMMIT_DATA_ERROR = 'GET_GIT_COMMIT_DATA_ERROR'
export const getGitCommitDataError = createAction(GET_GIT_COMMIT_DATA_ERROR)

export const CREATE_ACCOUNT = 'CREATE_ACCOUNT'
export const createAccount = (data) => {
  return (dispatch, getState) => {
    dispatch(createAction(CREATE_BULLET)())
    dispatch(startLoad())
    const contentData = data
    data.token = getState().auth.token
    let url = 'finance/accounts'
    let type = 'post'
    if (data.id) {
      url = `finance/accounts/${data.id}`
      type = 'patch'
    }
    sendApiRequest(url, contentData, type).then((response) => {
      dispatch(endLoad())
      if (response.status === 'failure') {
        dispatch(createAccountError(response))
      }
      if (response.status === 'success') {
        dispatch(createAccountSuccess(response.data))
        dispatch(getAccounts())
        dispatch(openAlert({
          message: 'Account created',
          severity: 'success'
        }))
      }
    })
  }
}

export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS'
export const createAccountSuccess = createAction(CREATE_ACCOUNT_SUCCESS)

export const CREATE_ACCOUNT_ERROR = 'CREATE_ACCOUNT_ERROR'
export const createAccountError = createAction(CREATE_ACCOUNT_ERROR)

export const GET_ACCOUNTS = 'GET_ACCOUNTS'
export const getAccounts = () => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_ACCOUNTS)())
    sendApiRequest('finance/accounts', {
      token: getState().auth.token
    }, 'get').then((response) => {
      if (response.status === 'failure') {
        dispatch(getAccountsError(response))
      }
      if (response.status === 'success') {
        dispatch(getAccountsSuccess(response.data))
      }
    })
  }
}

export const GET_ACCOUNTS_SUCCESS = 'GET_ACCOUNTS_SUCCESS'
export const getAccountsSuccess = createAction(GET_ACCOUNTS_SUCCESS)

export const GET_ACCOUNTS_ERROR = 'GET_ACCOUNTS_ERROR'
export const getAccountsError = createAction(GET_ACCOUNTS_ERROR)

export const UPLOAD_TRANSACTION_FILE = 'UPLOAD_TRANSACTION_FILE'
export const uploadTransactionFile = (files, data = {}) => {
  return (dispatch, getState) => {
    dispatch(createAction(UPLOAD_TRANSACTION_FILE)())
    dispatch(startLoad())
    const options = { token: getState().auth.token }
    const contentData = new FormData()
    for (let i = 0; i < files.length; i++) {
      contentData.append('file', files[i])
    }
    for (const i in data) {
      contentData.append(i, data[i])
    }
    sendApiRequest('finance/import', contentData, 'post', options).then((response) => {
      dispatch(endLoad())
      if (response.status === 'failure') {
        dispatch(uploadTransactionFileError(response))
      }
      if (response.status === 'success') {
        dispatch(uploadTransactionFileSuccess(response.data))
      }
    })
  }
}

export const UPLOAD_TRANSACTION_FILE_SUCCESS = 'UPLOAD_TRANSACTION_FILE_SUCCESS'
export const uploadTransactionFileSuccess = createAction(UPLOAD_TRANSACTION_FILE_SUCCESS)

export const UPLOAD_TRANSACTION_FILE_ERROR = 'UPLOAD_TRANSACTION_FILE_ERROR'
export const uploadTransactionFileError = createAction(UPLOAD_TRANSACTION_FILE_ERROR)

export const IMPORT_TRANSACTIONS = 'IMPORT_TRANSACTIONS'
export const importTransactions = (data) => {
  return (dispatch, getState) => {
    dispatch(createAction(IMPORT_TRANSACTIONS)())
    dispatch(startLoad())
    const contentData = data
    data.token = getState().auth.token
    const url = 'finance/import/transactions'
    const type = 'post'
    sendApiRequest(url, contentData, type).then((response) => {
      dispatch(endLoad())
      if (response.status === 'failure') {
        dispatch(importTransactionsError(response))
      }
      if (response.status === 'success') {
        dispatch(importTransactionsSuccess(response.data))
        dispatch(openAlert({
          message: 'Transactions created',
          severity: 'success'
        }))
      }
    })
  }
}

export const IMPORT_TRANSACTIONS_SUCCESS = 'IMPORT_TRANSACTIONS_SUCCESS'
export const importTransactionsSuccess = createAction(IMPORT_TRANSACTIONS_SUCCESS)

export const IMPORT_TRANSACTIONS_ERROR = 'IMPORT_TRANSACTIONS_ERROR'
export const importTransactionsError = createAction(IMPORT_TRANSACTIONS_ERROR)

export const GET_TRANSACTIONS = 'GET_TRANSACTIONS'
export const getTransactions = (data = {}) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_TRANSACTIONS)())
    dispatch(startLoad())
    const opts = {
      filter: {
        where: {
          AND: []
        }
      }
    }
    if (data.limit && data.page) {
      opts.filter.take = data.limit
      opts.filter.skip = (data.page - 1) * data.limit
    }
    if (data.date) {
      opts.filter.where.AND = [
        {
          date: {
            gte: moment(data.date).startOf('day'),
            lte: moment(data.date).endOf('day'),
          }
        }
      ]
    }
    if (data.dateRange) {
      opts.filter.where.AND = [
        {
          date: {
            gte: moment(data.dateRange.startDate).startOf('day'),
            lte: moment(data.dateRange.endDate).endOf('day'),
          }
        }
      ]
    }
    if (data.search) {
      const string = encodeURIComponent(jsesc(data.search))
      opts.filter.where.AND.push(
        { body: { contains: `%25${string}%25` } }
      )
    }
    opts.filter.where.AND.push({
      deleted: false
    })
    sendApiRequest('finance/transactions', {
      token: getState().auth.token
    }, 'get', opts).then((response) => {
      dispatch(endLoad())
      if (response.status === 'failure') {
        dispatch(getTransactionsError(response))
      }
      if (response.status === 'success') {
        dispatch(getTransactionsSuccess(response.data))
      }
    })
  }
}

export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS'
export const getTransactionsSuccess = createAction(GET_TRANSACTIONS_SUCCESS)

export const GET_TRANSACTIONS_ERROR = 'GET_TRANSACTIONS_ERROR'
export const getTransactionsError = createAction(GET_TRANSACTIONS_ERROR)

export const CREATE_TRANSACTION = 'CREATE_TRANSACTION'
export const createTransaction = (data) => {
  return (dispatch, getState) => {
    dispatch(createAction(CREATE_TRANSACTION)())
    dispatch(startLoad())
    data.token = getState().auth.token
    let url = 'finance/transactions'
    let type = 'post'
    if (data.body) { escape(data.body) }
    if (data.id) {
      url = `finance/transactions/${data.id}`
      type = 'patch'
    }
    sendApiRequest(url, data, type).then((response) => {
      dispatch(endLoad())
      if (response.status === 'failure') {
        dispatch(createTransactionError(response))
      }
      if (response.status === 'success') {
        dispatch(createTransactionSuccess(response.data))
        dispatch(openAlert({
          message: 'Transaction updated',
          severity: 'success'
        }))
      }
    })
  }
}

export const CREATE_TRANSACTION_SUCCESS = 'CREATE_TRANSACTION_SUCCESS'
export const createTransactionSuccess = createAction(CREATE_TRANSACTION_SUCCESS)

export const CREATE_TRANSACTION_ERROR = 'CREATE_TRANSACTION_ERROR'
export const createTransactionError = createAction(CREATE_TRANSACTION_ERROR)

export const GET_TRANSACTION_TYPES = 'GET_TRANSACTION_TYPES'
export const getTransactionTypes = () => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_TRANSACTION_TYPES)())
    sendApiRequest('finance/transactions/types', {
      token: getState().auth.token
    }, 'get').then((response) => {
      if (response.status === 'failure') {
        dispatch(getTransactionTypesError(response))
      }
      if (response.status === 'success') {
        dispatch(getTransactionTypesSuccess(response.data))
      }
    })
  }
}

export const GET_TRANSACTION_TYPES_SUCCESS = 'GET_TRANSACTION_TYPES_SUCCESS'
export const getTransactionTypesSuccess = createAction(GET_TRANSACTION_TYPES_SUCCESS)

export const GET_TRANSACTION_TYPES_ERROR = 'GET_TRANSACTION_TYPES_ERROR'
export const getTransactionTypesError = createAction(GET_TRANSACTION_TYPES_ERROR)

export const CREATE_TRANSACTION_TYPE = 'CREATE_TRANSACTION_TYPE'
export const createTransactionType = (data) => {
  return (dispatch, getState) => {
    dispatch(createAction(CREATE_TRANSACTION_TYPE)())
    dispatch(startLoad())
    data.token = getState().auth.token
    let url = 'finance/transactions/types'
    let type = 'post'
    let message = 'Transaction type created'
    if (data.id) {
      url = `finance/transactions/types/${data.id}`
      type = 'patch'
      message = 'Transaction type updated'
    }
    sendApiRequest(url, data, type).then((response) => {
      dispatch(endLoad())
      if (response.status === 'failure') {
        dispatch(createTransactionTypeError(response))
      }
      if (response.status === 'success') {
        dispatch(createTransactionTypeSuccess(response.data))
        dispatch(openAlert({
          message,
          severity: 'success'
        }))
      }
    })
  }
}

export const CREATE_TRANSACTION_TYPE_SUCCESS = 'CREATE_TRANSACTION_TYPE_SUCCESS'
export const createTransactionTypeSuccess = createAction(CREATE_TRANSACTION_TYPE_SUCCESS)

export const CREATE_TRANSACTION_TYPE_ERROR = 'CREATE_TRANSACTION_TYPE_ERROR'
export const createTransactionTypeError = createAction(CREATE_TRANSACTION_TYPE_ERROR)

export const GET_OWNTRACKS_DATA = 'GET_OWNTRACKS_DATA'
export const getOwntracksData = (data = {}) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_OWNTRACKS_DATA)())
    const opts = {
      filter: data
    }
    sendApiRequest('owntracks', {
      token: getState().auth.token
    }, 'get', opts).then((response) => {
      if (response.status === 'failure') {
        dispatch(getOwntracksDataError(response))
      }
      if (response.status === 'success') {
        dispatch(getOwntracksDataSuccess(response.data))
      }
    })
  }
}

export const GET_OWNTRACKS_DATA_SUCCESS = 'GET_OWNTRACKS_DATA_SUCCESS'
export const getOwntracksDataSuccess = createAction(GET_OWNTRACKS_DATA_SUCCESS)

export const GET_OWNTRACKS_DATA_ERROR = 'GET_OWNTRACKS_DATA_ERROR'
export const getOwntracksDataError = createAction(GET_OWNTRACKS_DATA_ERROR)

export const GET_WEATHER_DATA = 'GET_WEATHER_DATA'
export const getWeatherData = (data = {}) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_WEATHER_DATA)())
    const opts = {
      filter: data
    }
    sendApiRequest('weather', {
      token: getState().auth.token
    }, 'get', opts).then((response) => {
      if (response.status === 'failure') {
        dispatch(getWeatherDataError(response))
      }
      if (response.status === 'success') {
        dispatch(getWeatherDataSuccess(response.data))
      }
    })
  }
}

export const GET_WEATHER_DATA_SUCCESS = 'GET_WEATHER_DATA_SUCCESS'
export const getWeatherDataSuccess = createAction(GET_WEATHER_DATA_SUCCESS)

export const GET_WEATHER_DATA_ERROR = 'GET_WEATHER_DATA_ERROR'
export const getWeatherDataError = createAction(GET_WEATHER_DATA_ERROR)

const initialState = {
  isGettingTrelloCards: false,
  hasGottenTrelloCards: false,
  isGettingTrelloCardsSomeday: false,
  hasGottenTrelloCardsSomeday: false,
  isGettingTrelloCardsToday: false,
  hasGottenTrelloCardsToday: false,
  isGettingTrelloCardsInProgress: false,
  hasGottenTrelloCardsInProgress: false,
  isGettingTrelloCardsDone: false,
  hasGottenTrelloCardsDone: false,
  trelloCardsBoardList: {},
  trelloCardsSomeday: [],
  trelloCardsToday: [],
  trelloCardsInProgress: [],
  trelloCardsDone: [],
  isCreatingBullet: false,
  hasCreatedBullet: false,
  createdBullet: {},
  isGettingBullets: false,
  hasGottenBullets: false,
  bullets: [],
  bulletsData: {},
  isGettingHabits: false,
  hasGottenHabits: false,
  habits: [],
  isGettingEpics: false,
  hasGottenEpics: false,
  epics: [],
  selectedBoard: 'personal',
  boardSwitchIsOn: false,
  isCreatingHabit: false,
  hasCreatedHabit: false,
  createdHabit: {},
  isCreatingEpic: false,
  hasCreatedEpic: false,
  createdEpic: {},
  isUploadingBulletImage: false,
  hasUploadedBulletImage: false,
  uploadBulletImageError: {},
  uploadBulletImageSuccess: {},
  isUpdatingTrelloCard: false,
  hasUpdatedTrelloCard: false,
  isGettingGoogleFitData: false,
  hasGottenGoogleFitData: false,
  googleFitData: {},
  isGettingGoogleCalData: false,
  hasGottenGoogleCalData: false,
  googleCalData: [],
  isGettingWakatimeData: false,
  hasGottenWakatimeData: false,
  wakatimeData: {},
  isGettingBulletCount: false,
  hasGottenBulletCount: false,
  bulletCount: 0,
  isCreatingTask: false,
  hasCreatedTask: false,
  createdTask: {},
  isGettingTasks: false,
  hasGottenTasks: false,
  tasks: [],
  isGettingRelatedTasks: false,
  hasGottenRelatedTasks: false,
  relatedTasks: [],
  isGettingStories: false,
  hasGottenStories: false,
  stories: [],
  isGettingSubtasks: false,
  hasGottenSubtasks: false,
  subtasks: [],
  isGettingBulletsBilling: false,
  hasGottenBulletsBilling: false,
  bulletsBilling: [],
  isGettingTags: false,
  hasGottenTags: false,
  tags: [],
  isGettingActiveTags: false,
  hasGottenActiveTags: false,
  activeTags: [],
  bulletData: {},
  isGettingGitCommitData: false,
  hasGottenGitCommitData: false,
  gitCommitData: [],
  bulletRangeGrouped: [],
  isGettingAccounts: false,
  hasGottenAccounts: false,
  accounts: [],
  isUploadingTransactionFile: false,
  hasUploadedTransactionFile: false,
  uploadTransactionFileError: {},
  uploadTransactionFileSuccess: {},
  isGettingTransactions: false,
  hasGottenTransactions: false,
  transactions: [],
  isCreatingTransaction: false,
  hasCreatedTransaction: false,
  createdTransaction: {},
  isGettingTransactionTypes: false,
  hasGottenTransactionTypes: false,
  transactionTypes: [],
  isCreatingTransactionType: false,
  hasCreatedTransactionType: false,
  createdTransactionType: {},
  isGettingOwntracksData: false,
  hasGottenOwntracksData: false,
  owntracksData: [],
  isGettingWeatherData: false,
  hasGottenWeatherData: false,
  weatherData: {},
  isBulkUpdatingTasksStatuses: false,
  hasBulkUpdatedTasksStatuses: false,
  isCreatingSubtask: false,
  hasCreatedSubtask: false,
  createdSubtask: {},
  isGettingBulletAverages: false,
  hasGottenBulletAverages: false,
  bulletAverages: {},
  isGettingAgendaTasks: false,
  agendaTasks: []
}

export default handleActions({

  [GET_TRELLO_CARDS]: (state, { payload }) => {
    switch (payload) {
      case 'someday': {
        return {
          ...state,
          isGettingTrelloCardsSomeday: true,
          hasGottenTrelloCardsSomeday: false,
          trelloCardsSomeday: []
        }
      }
      case 'today': {
        return {
          ...state,
          isGettingTrelloCardsToday: true,
          hasGottenTrelloCardsToday: false,
          trelloCardsToday: []
        }
      }
      case 'inProgress': {
        return {
          ...state,
          isGettingTrelloCardsInProgress: true,
          hasGottenTrelloCardsInProgress: false,
          trelloCardsInProgress: []
        }
      }
      case 'done': {
        return {
          ...state,
          isGettingTrelloCardsDone: true,
          hasGottenTrelloCardsDone: false,
          trelloCardsDone: []
        }
      }
      default: {
        return {
          ...state
        }
      }
    }
  },

  [GET_TRELLO_CARDS_SUCCESS]: (state, { payload }) => {
    switch (payload.cardType) {
      case 'someday': {
        return {
          ...state,
          isGettingTrelloCardsSomeday: false,
          hasGottenTrelloCardsSomeday: true,
          trelloCardsSomeday: payload.data
        }
      }
      case 'today': {
        return {
          ...state,
          isGettingTrelloCardsToday: false,
          hasGottenTrelloCardsToday: true,
          trelloCardsToday: payload.data
        }
      }
      case 'inProgress': {
        return {
          ...state,
          isGettingTrelloCardsInProgress: false,
          hasGottenTrelloCardsInProgress: true,
          trelloCardsInProgress: payload.data
        }
      }
      case 'done': {
        return {
          ...state,
          isGettingTrelloCardsDone: false,
          hasGottenTrelloCardsDone: true,
          trelloCardsDone: payload.data
        }
      }
      default: {
        return {
          ...state
        }
      }
    }
  },

  [CREATE_BULLET]: (state) => ({
    ...state,
    isCreatingBullet: true,
    hasCreatedBullet: false,
    createdBullet: {}
  }),

  [CREATE_BULLET_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isCreatingBullet: false,
      hasCreatedBullet: true,
      createdBullet: payload,
      isUploadingBulletImage: false,
      hasUploadedBulletImage: false,
      uploadBulletImageError: {},
      uploadBulletImageSuccess: {}
    }
  },

  [GET_BULLETS]: (state) => ({
    ...state,
    isGettingBullets: true,
    hasGottenBullets: false,
    bullets: []
  }),

  [GET_BULLETS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingBullets: false,
      hasGottenBullets: true,
      bullets: payload
    }
  },

  [GET_BULLETS_WITH_AGGREGATE]: (state) => ({
    ...state,
    isGettingBullets: true,
    hasGottenBullets: false,
    bullets: [],
    bulletData: {},
    bulletRangeGrouped: []
  }),

  [GET_BULLETS_WITH_AGGREGATE_SUCCESS]: (state, { payload }) => {
    const { bullets, bulletData, bulletRangeGrouped } = payload
    return {
      ...state,
      isGettingBullets: false,
      hasGottenBullets: true,
      bullets,
      bulletData,
      bulletRangeGrouped
    }
  },

  [GET_BULLETS_BILLING]: (state) => ({
    ...state,
    isGettingBulletsBilling: true,
    hasGottenBulletsBilling: false,
    bulletsBilling: []
  }),

  [GET_BULLETS_BILLING_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingBulletsBilling: false,
      hasGottenBulletsBilling: true,
      bulletsBilling: payload
    }
  },

  [GET_BULLET_COUNT]: (state) => ({
    ...state,
    isGettingBulletCount: true,
    hasGottenBulletCount: false,
    bulletCount: 0
  }),

  [GET_BULLET_COUNT_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingBulletCount: false,
      hasGottenBulletCount: true,
      bulletCount: payload
    }
  },

  [GET_BULLET_AVERAGES]: (state) => ({
    ...state,
    isGettingBulletAverages: true,
    hasGottenBulletAverages: false,
    bulletAverages: {}
  }),

  [GET_BULLET_AVERAGES_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingBulletAverages: false,
      hasGottenBulletAverages: true,
      bulletAverages: payload
    }
  },

  [GET_HABITS]: (state) => ({
    ...state,
    isGettingHabits: true,
    hasGottenHabits: false,
    habits: []
  }),

  [GET_HABITS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingHabits: false,
      hasGottenHabits: true,
      habits: payload
    }
  },

  [GET_EPICS]: (state) => ({
    ...state,
    isGettingEpics: true,
    hasGottenEpics: false,
    epics: []
  }),

  [GET_EPICS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingEpics: false,
      hasGottenEpics: true,
      epics: payload
    }
  },

  [SELECT_BOARD]: (state, { payload }) => {
    return {
      ...state,
      selectedBoard: payload.board,
      boardSwitchIsOn: payload.switch
    }
  },

  [CREATE_HABIT]: (state) => ({
    ...state,
    isCreatingHabit: true,
    hasCreatedHabit: false,
    createdHabit: {}
  }),

  [CREATE_HABIT_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isCreatingHabit: false,
      hasCreatedHabit: true,
      createdHabit: payload
    }
  },

  [CREATE_EPIC]: (state) => ({
    ...state,
    isCreatingEpic: true,
    hasCreatedEpic: false,
    createdEpic: {}
  }),

  [CREATE_EPIC_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isCreatingEpic: false,
      hasCreatedEpic: true,
      createdEpic: payload
    }
  },

  [UPLOAD_BULLET_IMAGE]: (state) => {
    return {
      ...state,
      isUploadingBulletImage: true,
      hasUploadedBulletImage: false,
      uploadBulletImageError: {},
      uploadBulletImageSuccess: {}
    }
  },

  [UPLOAD_BULLET_IMAGE_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isUploadingBulletImage: false,
      hasUploadedBulletImage: true,
      uploadBulletImageError: {},
      uploadBulletImageSuccess: payload
    }
  },

  [UPLOAD_BULLET_IMAGE_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isUploadingBulletImage: false,
      hasUploadedBulletImage: false,
      uploadBulletImageError: payload,
      uploadBulletImageSuccess: {}
    }
  },

  [UPDATE_TRELLO_CARD]: (state) => ({
    ...state,
    isUpdatingTrelloCard: true,
    hasUpdatedTrelloCard: false
  }),

  [UPDATE_TRELLO_CARD_SUCCESS]: (state) => {
    return {
      ...state,
      isUpdatingTrelloCard: false,
      hasUpdatedTrelloCard: true
    }
  },

  [GET_GOOGLE_FIT_DATA]: (state) => ({
    ...state,
    isGettingGoogleFitData: true,
    hasGottenGoogleFitData: false,
    googleFitData: {}
  }),

  [GET_GOOGLE_FIT_DATA_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingGoogleFitData: false,
      hasGottenGoogleFitData: true,
      googleFitData: payload
    }
  },

  [GET_GOOGLE_CAL_DATA]: (state) => ({
    ...state,
    isGettingGoogleCalData: true,
    hasGottenGoogleCalData: false,
    googleCalData: []
  }),

  [GET_GOOGLE_CAL_DATA_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingGoogleCalData: false,
      hasGottenGoogleCalData: true,
      googleCalData: payload
    }
  },

  [GET_WAKATIME_DATA]: (state) => ({
    ...state,
    isGettingWakatimeData: true,
    hasGottenWakatimeData: false,
    wakatimeData: []
  }),

  [GET_WAKATIME_DATA_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingWakatimeData: false,
      hasGottenWakatimeData: true,
      wakatimeData: payload
    }
  },

  [CREATE_TASK]: (state) => ({
    ...state,
    isCreatingTask: true,
    hasCreatedTask: false,
    createdTask: {},
    createdSubtask: {}
  }),

  [CREATE_TASK_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isCreatingTask: false,
      hasCreatedTask: true,
      createdTask: payload
    }
  },

  [CREATE_SUBTASK_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isCreatingSubtask: false,
      hasCreatedSubtask: true,
      createdSubtask: payload
    }
  },

  [GET_TASKS]: (state) => ({
    ...state,
    isGettingTasks: true,
    hasGottenTasks: false,
    tasks: []
  }),

  [GET_TASKS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingTasks: false,
      hasGottenTasks: true,
      tasks: payload
    }
  },

  [GET_AGENDA_TASKS]: (state) => ({
    ...state,
    isGettingAgendaTasks: true,
    agendaTasks: []
  }),

  [GET_AGENDA_TASKS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingAgendaTasks: false,
      agendaTasks: payload
    }
  },

  [BULK_UPDATE_TASKS_STATUSES]: (state) => ({
    ...state,
    isBulkUpdatingTasksStatuses: true,
    hasBulkUpdatedTasksStatuses: false
  }),

  [BULK_UPDATE_TASKS_STATUSES_SUCCESS]: (state) => {
    return {
      ...state,
      isBulkUpdatingTasksStatuses: false,
      hasBulkUpdatedTasksStatuses: true
    }
  },

  [BULK_UPDATE_TASKS_STATUSES_ERROR]: (state) => {
    return {
      ...state,
      isBulkUpdatingTasksStatuses: false,
      hasBulkUpdatedTasksStatuses: false
    }
  },

  [GET_RELATED_TASKS]: (state) => ({
    ...state,
    isGettingRelatedTasks: true,
    hasGottenRelatedTasks: false,
    relatedTasks: []
  }),

  [GET_RELATED_TASKS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingRelatedTasks: false,
      hasGottenRelatedTasks: true,
      relatedTasks: payload
    }
  },

  [GET_TASKS_BY_TYPE]: (state, { payload }) => {
    switch (payload) {
      case 'story': {
        return {
          ...state,
          isGettingStories: true,
          hasGottenStories: false,
          stories: []
        }
      }
      case 'subtask': {
        return {
          ...state,
          isGettingSubtasks: true,
          hasGottenSubtasks: false,
          subtasks: []
        }
      }
      default: {
        return {
          ...state
        }
      }
    }
  },

  [GET_TASKS_BY_TYPE_SUCCESS]: (state, { payload }) => {
    switch (payload.type) {
      case 'story': {
        return {
          ...state,
          isGettingStories: false,
          hasGottenStories: true,
          stories: payload.data
        }
      }
      case 'subtask': {
        return {
          ...state,
          isGettingSubtasks: false,
          hasGottenSubtasks: true,
          subtasks: payload.data
        }
      }
      default: {
        return {
          ...state
        }
      }
    }
  },

  [GET_TAGS]: (state) => ({
    ...state,
    isGettingTags: true,
    hasGottenTags: false,
    tags: []
  }),

  [GET_TAGS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingTags: false,
      hasGottenTags: true,
      tags: payload
    }
  },

  [GET_ACTIVE_TAGS]: (state) => ({
    ...state,
    isGettingActiveTags: true,
    hasGottenActiveTags: false,
    activeTags: []
  }),

  [GET_ACTIVE_TAGS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingActiveTags: false,
      hasGottenActiveTags: true,
      activeTags: payload
    }
  },

  [GET_GIT_COMMIT_DATA]: (state) => ({
    ...state,
    isGettingGitCommitData: true,
    hasGottenGitCommitData: false,
    gitCommitData: []
  }),

  [GET_GIT_COMMIT_DATA_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingGitCommitData: false,
      hasGottenGitCommitData: true,
      gitCommitData: payload
    }
  },

  [GET_ACCOUNTS]: (state) => ({
    ...state,
    isGettingAccounts: true,
    hasGottenAccounts: false,
    accounts: []
  }),

  [GET_ACCOUNTS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingAccounts: false,
      hasGottenAccounts: true,
      accounts: payload
    }
  },

  [UPLOAD_TRANSACTION_FILE]: (state) => {
    return {
      ...state,
      isUploadingTransactionFile: true,
      hasUploadedTransactionFile: false,
      uploadTransactionFileError: {},
      uploadTransactionFileSuccess: {}
    }
  },

  [UPLOAD_TRANSACTION_FILE_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isUploadingTransactionFile: false,
      hasUploadedTransactionFile: true,
      uploadTransactionFileError: {},
      uploadTransactionFileSuccess: payload
    }
  },

  [UPLOAD_TRANSACTION_FILE_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isUploadingTransactionFile: false,
      hasUploadedTransactionFile: false,
      uploadTransactionFileError: payload,
      uploadTransactionFileSuccess: {}
    }
  },

  [IMPORT_TRANSACTIONS]: (state) => {
    return {
      ...state,
      isImportingTransactions: true,
      hasImportedTransactions: false,
      importedTransactions: {}
    }
  },

  [IMPORT_TRANSACTIONS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isImportingTransactions: false,
      hasImportedTransactions: true,
      importedTransactions: payload
    }
  },

  [GET_TRANSACTIONS]: (state) => ({
    ...state,
    isGettingTransactions: true,
    hasGottenTransactions: false,
    transactions: []
  }),

  [GET_TRANSACTIONS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingTransactions: false,
      hasGottenTransactions: true,
      transactions: payload
    }
  },

  [CREATE_TRANSACTION]: (state) => ({
    ...state,
    isCreatingTransaction: true,
    hasCreatedTransaction: false,
    createdTransaction: {}
  }),

  [CREATE_TRANSACTION_ERROR]: (state) => ({
    ...state,
    isCreatingTransaction: false,
    hasCreatedTransaction: false,
    createdTransaction: {}
  }),

  [CREATE_TRANSACTION_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isCreatingTransaction: false,
      hasCreatedTransaction: true,
      createdTransaction: payload
    }
  },

  [GET_TRANSACTION_TYPES]: (state) => ({
    ...state,
    isGettingTransactionTypes: true,
    hasGottenTransactionTypes: false,
    transactionTypes: []
  }),

  [GET_TRANSACTION_TYPES_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingTransactionTypes: false,
      hasGottenTransactionTypes: true,
      transactionTypes: payload
    }
  },

  [CREATE_TRANSACTION_TYPE]: (state) => ({
    ...state,
    isCreatingTransactionType: true,
    hasCreatedTransactionType: false,
    createdTransactionType: {}
  }),

  [CREATE_TRANSACTION_TYPE_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isCreatingTransactionType: false,
      hasCreatedTransactionType: true,
      createdTransactionType: payload
    }
  },

  [GET_OWNTRACKS_DATA]: (state) => ({
    ...state,
    isGettingOwntracksData: false,
    hasGottenOwntracksData: false,
    owntracksData: []
  }),

  [GET_OWNTRACKS_DATA_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingOwntracksData: false,
      hasGottenOwntracksData: false,
      owntracksData: payload
    }
  },

  [GET_WEATHER_DATA]: (state) => ({
    ...state,
    isGettingWeatherData: true,
    hasGottenWeatherData: false,
    weatherData: {}
  }),

  [GET_WEATHER_DATA_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingWeatherData: false,
      hasGottenWeatherData: true,
      weatherData: payload
    }
  },

}, initialState)
