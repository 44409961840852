import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Checkbox, Tabs, Tab, List, ListItem,
  ListItemText, ListItemIcon, ListItemButton,
  Menu, MenuItem, Tooltip, CircularProgress
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { getHabits, createBullet } from 'store/modules/bullets'
import { openEditTaskDialog } from 'store/modules/site'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'

const HabitListItem = ({
  habit,
  coords,
  date,
  tab
}) => {
  const dispatch = useDispatch()
  const [menuOpen, setMenuOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [item, setItem] = useState({})
  const processHabit = (h) => {
    const count = h.bulletCount
    let elapsedDays = 0
    h.completed = false
    const bulletData = {
      type: 'habit',
      habitId: h.id,
      body: `#${h.name}`,
      createdOn: date
    }
    if (coords && coords.latitude && coords.longitude) {
      bulletData.lat = coords.latitude
      bulletData.lng = coords.longitude
    }
    if (count < h.target) {
      if (h.mostRecent && h.mostRecent.createdOn) {
        if (h.targetDuration === 'day') {
          elapsedDays = moment(date).startOf('day').diff(moment(h.mostRecent.createdOn).startOf('day'), 'days')
        }
        if (h.targetDuration === 'week') {
          elapsedDays = moment(date).startOf('day').diff(moment(h.mostRecent.createdOn).startOf('day'), 'days')
          // if (elapsedDays < 7) { elapsedDays = 0 }
        }
        if (h.targetDuration === 'month') {
          elapsedDays = moment(date).startOf('day').diff(moment(h.mostRecent.createdOn).startOf('day'), 'days')
        }
      } else {
        elapsedDays = 100
      }
    } else {
      h.completed = true
    }
    h.elapsedDays = elapsedDays
    h.count = count
    h.bulletData = bulletData
    return h
  }
  useEffect(() => {
    const data = processHabit(habit)
    setItem(data)
  }, [habit, processHabit])
  const renderActions = (h) => {
    return (
      <>
        <MoreVertIcon aria-controls='habits-actions-menu' aria-haspopup='true'
          style={{ width: '17px', color: '#a95151', cursor: 'pointer' }}
          onClick={(e) => {
            setAnchorEl(e.currentTarget)
            setMenuOpen(true)
          }}
        />
        <Menu
          id={`habits-menu-h${h.id}`}
          anchorEl={anchorEl}
          keepMounted
          open={menuOpen}
          onClose={() => setMenuOpen(false)}
        >
          <MenuItem onClick={() => {
            const data = {
              status: 'inProgress',
              habitId: h.id,
              type: 'habit',
              title: h.title,
              epicId: h.epicId,
              body: `#${h.name}`,
              beginOn: new Date(),
              dueOn: moment().add(5, 'minutes').toDate()
            }
            dispatch(openEditTaskDialog(data))
            setMenuOpen(false)
          }}>Add to Agenda
          </MenuItem>
        </Menu>
      </>
    )
  }
  const color = (item.targetDuration === 'week' && item.elapsedDays < 7) ? 'primary' : 'error'
  const icon = <AddCircleIcon style={{ color: '#2ca5e0' }} />
  const checkedIcon = <CheckCircleIcon
    style={{ color: '#0b8100' }}
  />
  const labelId = `checkbox-list-label-${item.id}`
  let title = `Last done ${item.elapsedDays} days ago`
  if (item.elapsedDays === 0) {
    title = 'Last done today'
  }
  if (item.elapsedDays === 1) {
    title = 'Last done yesterday'
  }
  let visible = false
  if (tab === 'completed' && item.completed) { visible = true }
  if (item.targetDuration === tab && item.completed === false) {
    visible = true
  }
  return (
    <>
      {visible && item.id
        ? <Tooltip title={title} placement='right-end'>
          <ListItem
            key={`item-list-item-${item.id}`}
            disablePadding
            secondaryAction={renderActions(item)}
          >
            <ListItemButton dense>
              <ListItemIcon>
                <Checkbox
                  onClick={() => {
                    dispatch(createBullet(item.bulletData))
                  }}
                  icon={icon} checkedIcon={checkedIcon}
                  inputProps={{ 'aria-labelledby': labelId }}
                  checked={item.completed} disabled={item.completed} color={color} />
              </ListItemIcon>
              <ListItemText
                id={labelId} primary={item.title || item.tag}
                secondary={`${item.count}/${item.target} ${item.targetDuration}`} />
            </ListItemButton>
          </ListItem>
        </Tooltip> //eslint-disable-line
        : ''}
    </>
  )
}

HabitListItem.propTypes = {
  habit: PropTypes.object,
  coords: PropTypes.object,
  date: PropTypes.object,
  tab: PropTypes.string
}

const HabitsWidget = ({ date, coords }) => {
  const dispatch = useDispatch()
  const {
    habits, isGettingHabits,
    createdBullet
  } = useSelector((state) => {
    return {
      habits: state.bullets.habits,
      isGettingHabits: state.bullets.isGettingHabits,
      createdBullet: state.bullets.createdBullet
    }
  })
  const [items, setItems] = useState({
    completed: {},
  })
  const [tab, setTab] = useState('day')
  // useEffect(() => {
  //   if (date) {
  //     dispatch(getHabits({
  //       filter: { date }
  //     }))
  //   } else {
  //     dispatch(getHabits())
  //   }
  // }, [])
  useEffect(() => {
    dispatch(getHabits({
      filter: { date }
    }))
  }, [date])
  useEffect(() => {
    const newItems = { ...items }
    habits
      .filter((h) => h.active)
      .forEach((h) => {
        newItems[h.id] = h
      })
    setItems(newItems)
  }, [habits])

  useEffect(() => {
    if (createdBullet.type === 'habit' &&
      items[createdBullet.habitId]) {
      const item = { ...items[createdBullet.habitId] }
      if (createdBullet.deleted !== true) {
        item.bulletCount += 1
        item.mostRecent = createdBullet
      }
      if (createdBullet.deleted === true) {
        item.bulletCount -= 1
      }
      const data = { ...items }
      data[createdBullet.habitId] = item
      setItems(data)
    }
  }, [createdBullet])

  const entries = Object.entries(items)

  return (
    <div className='habitsWidget'
      style={{ width: '100%', marginBottom: '20px', fontSize: '12px', lineHeight: '18px' }}>
      <Tabs value={tab}
        onChange={(_e, val) => setTab(val)} centered>
        <Tab value='day' label='Today' />
        <Tab value='week' label='Week' />
        <Tab value='month' label='Month' />
        <Tab value='completed' label='Done' />
      </Tabs>
      <div
        style={{
          height: '300px',
          backgroundColor: '#ffffff3d',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          marginTop: '5px'
        }}>
        {isGettingHabits
          ? <CircularProgress />
          : (
            <List
              sx={{
                width: '100%',
                bgcolor: 'background.paper',
                position: 'relative',
                overflow: 'auto',
                height: 300,
                '& ul': { padding: 0 },
              }}
              subheader={<li />}
            >
              {entries
                .map((i) => {
                  const k = i[0]
                  return (
                    <HabitListItem
                      tab={tab}
                      coords={coords}
                      date={date}
                      key={`habit-list-item-${k}`} habit={items[k]} />
                  )
                })}
            </List>
          )}
      </div>
    </div>
  )
}

HabitsWidget.propTypes = {
  coords: PropTypes.object,
  date: PropTypes.object,
}

export default HabitsWidget
