import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  getBullets,
  createBullet,
  getBulletCount,
  getBulletAverages
} from 'store/modules/bullets'
import { Grid, Button } from '@mui/material'
import BulletCard from 'routes/Admin/Bullets/BulletCard'
import Billing from 'components/Billing/Billing'
import {
  openLightbox,
  closeMapDialog,
  openMapDialog,
  openEditBulletDialog
} from 'store/modules/site'
import Pagination from '@mui/material/Pagination'
import moment from 'moment'
import { getDayNameFromNumber, getAverageFromArray } from 'utils/utils'

const queryString = require('query-string')

const propTypes = {
  getBullets: PropTypes.func,
  bullets: PropTypes.array,
  createBullet: PropTypes.func,
  openLightbox: PropTypes.func,
  location: PropTypes.object,
  bulletCount: PropTypes.number,
  getBulletCount: PropTypes.func,
  hasCreatedBullet: PropTypes.bool,
  openMapDialog: PropTypes.func,
  closeMapDialog: PropTypes.func,
  getBulletAverages: PropTypes.func,
  bulletAverages: PropTypes.object,
  isGettingBulletAverages: PropTypes.bool,
  hasGottenBulletAverages: PropTypes.bool,
  openEditBulletDialog: PropTypes.func
}

class Search extends Component {
  constructor (props) {
    super(props)
    this.state = {
      searchTerm: '',
      bullets: [],
      page: 1,
      pageCount: 0,
      count: 0,
      limit: 10,
      option: '',
      type: null,
      mostWeekdays: [],
      mostHours: [],
      averageDay: 0,
      averageWeek: 0,
      averageMonth: 0,
      sortBy: 'createdOn'
    }
  }

  componentDidMount () {
    const parsed = queryString.parse(this.props.location.search)
    const opts = {
      search: parsed.s,
      limit: this.state.limit,
      page: this.state.page,
    }
    if (this.state.sortBy === 'rating') {
      opts.orderBy = [
        { rating: 'desc' },
        { createdOn: 'desc' }
      ]
    }
    if (this.state.sortBy === 'mood') {
      opts.orderBy = [
        { mood: 'desc' },
        { createdOn: 'desc' }
      ]
    }
    if (parsed.option && parsed.option === 'duration') {
      delete opts.limit
      delete opts.page
    }
    if (parsed.type) {
      this.setState({ type: parsed.type })
    }
    // if (parsed.option && parsed.option === 'billing')  {
    //   delete opts.limit
    //   delete opts.page
    // }
    this.props.getBulletCount(opts)
    this.props.getBullets(opts)
    this.props.getBulletAverages(parsed.s)
    this.setState({ searchTerm: parsed.s, option: parsed.option || '' })
  }

  componentDidUpdate (prevProps) {
    if (this.props.bullets !== prevProps.bullets) {
      this.setBulletState(this.props.bullets)
    }
    if (this.props.bulletCount !== prevProps.bulletCount) {
      this.setBulletCountState(this.props.bulletCount)
    }
    if (this.props.location.search !== prevProps.location.search) {
      const parsed = queryString.parse(this.props.location.search)
      const opts = {
        search: parsed.s,
        limit: this.state.limit,
        page: this.state.page,
      }
      if (this.state.sortBy === 'rating') {
        opts.orderBy = [
          { rating: 'desc' },
          { createdOn: 'desc' }
        ]
      }
      if (this.state.sortBy === 'mood') {
        opts.orderBy = [
          { mood: 'desc' },
          { createdOn: 'desc' }
        ]
      }
      if (parsed.option && parsed.option === 'duration') {
        delete opts.limit
        delete opts.page
      }
      // if (parsed.s === '[BILL]') {
      //   delete opts.limit
      //   delete opts.page
      // }
      this.props.getBulletCount(opts)
      this.props.getBullets(opts)
      this.setSearchTermState(parsed.s)
      this.props.getBulletAverages(parsed.s)
    }
    if (this.props.bulletAverages !== prevProps.bulletAverages) {
      this.setAveragesState(this.props.bulletAverages)
    }
  }

  setAveragesState = (bulletAverages) => {
    if (bulletAverages) {
      const { weekdayAverages, monthlyAverage, hourlyAverages } = bulletAverages
      if (weekdayAverages) {
        let i = 0
        const weekdayCount = weekdayAverages.map((w) => {
          if (w.count > i) { i = w.count }
          w.max = i
          return w
        })
        const averageDay = getAverageFromArray(weekdayCount.map((w) => { return w.count }))
        const mostWeekdays = weekdayCount.filter((w) => w.count === i).map((w) => {
          return getDayNameFromNumber(w.dayOfWeek)
        })
        this.setState({ mostWeekdays, averageDay })
      }
      if (monthlyAverage && monthlyAverage[0] && monthlyAverage[0].monthlyAverage) {
        this.setState({ averageMonth: monthlyAverage[0].monthlyAverage })
      }
      if (hourlyAverages) {
        let h = 0
        const hourlyCount = hourlyAverages.map((w) => {
          if (w.count > h) { h = w.count }
          w.max = h
          return w
        })
        const mostHours = hourlyCount.filter((w) => w.count === h).map((w) => {
          w.hour = w.hour + 8
          if (w.hour < 12) { return `${w.hour} am` } else if (w.hour === 12) {
            return `${w.hour} pm`
          } else {
            return `${w.hour - 12} pm`
          }
        })
        this.setState({ mostHours })
      }
    }
  }

  sortBy = (name) => {
    const opts = {
      search: this.state.searchTerm,
      limit: this.state.limit,
      page: this.state.page,
    }
    switch (name) {
      case 'rating': {
        opts.orderBy = [
          { rating: 'desc' },
          { createdOn: 'desc' }
        ]
        break
      }
      case 'mood': {
        opts.orderBy = [
          { mood: 'desc' },
          { createdOn: 'desc' }
        ]
        break
      }
      default: {
        opts.orderBy = [
          { createdOn: 'desc' }
        ]
      }
    }
    this.props.getBullets(opts)
    this.setState({ sortBy: name })
  }

  computeDuration = (bullets) => {
    if (this.state.option && this.state.option === 'duration') {
      const durations = []
      const filtered = bullets.filter((b) => {
        var start = b.body.match(/start/i)
        var end = b.body.match(/end/i)
        var stop = b.body.match(/stop/i)
        if (b.type === 'event') {
          if (start || end || stop) {
            return true
          }
        }
        return false
      })
      filtered.reverse()
      filtered.forEach((b, i) => {
        var start = b.body.match(/start/i)
        if (start) {
          const startTime = b.createdOn
          const nextBullet = filtered[i + 1]
          if (nextBullet) {
            if (nextBullet.body.match(/end/i) ||
            nextBullet.body.match(/stop/i)) {
              const endTime = nextBullet.createdOn
              const duration = moment(endTime).diff(moment(startTime), 'minutes')
              durations.push({ startTime, endTime, duration })
            }
          }
        }
      })
      let totalMinutes = 0
      durations.forEach((d) => {
        totalMinutes += d.duration
      })
      const totalHours = totalMinutes / 60
      const totalCash = totalHours * 50
      return (
        <div>
          Duration:<br />
          {totalMinutes} Minutes<br />
          {totalHours} Hours (${totalCash})<br />
        </div>)
    }
  }

  setBulletCountState = (bulletCount) => {
    const pageCount = Math.ceil(bulletCount / this.state.limit)
    this.setState({ count: bulletCount, pageCount })
  }

  setBulletState = (bullets) => {
    this.setState({ bullets })
  }

  setSearchTermState = (searchTerm) => {
    this.setState({ searchTerm })
  }

  renderBullets = (bullets) => {
    if (bullets) {
      const rendered = bullets.map((b, idx) => {
        return (
          <BulletCard
            key={idx}
            bullet={b}
            createBullet={this.props.createBullet}
            hasCreatedBullet={this.props.hasCreatedBullet}
            openLightbox={this.props.openLightbox}
            closeMapDialog={this.props.closeMapDialog}
            openMapDialog={this.props.openMapDialog}
            openEditBulletDialog={this.props.openEditBulletDialog}
          />
        )
      })
      return rendered
    }
  }

  handlePageChange = (_e, page) => {
    const opts = {
      search: this.state.searchTerm,
      limit: this.state.limit,
      page: page
    }
    this.setState({ page })
    this.props.getBullets(opts)
  }

  render () {
    let pagination = (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        <Pagination
          style={{ display: 'inline-block' }}
          count={this.state.pageCount}
          page={this.state.page}
          onChange={this.handlePageChange} />
      </div>
    )
    if (this.state.option === 'duration') {
      pagination = ''
    }
    let billing
    if (this.state.option === 'billing') {
      billing = (
        <Billing
          searchTerm={this.state.searchTerm}
          page={this.state.page}
          limit={this.state.limit}
          {...this.props}
        />
      )
      // pagination = ''
    }
    return (
      <div id='bullets-list-container'>
        <h2>Bullets</h2>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <h3>Search: {this.state.searchTerm}</h3>
            You do this most often on {this.state.mostWeekdays.join(', ')} at {this.state.mostHours.join(', ')}<br />
            {this.state.averageMonth} times a month
            <div style={{ textAlign: 'right' }}>
              <Button onClick={() => { this.sortBy('createdOn') }}>By Date</Button>
              <Button onClick={() => { this.sortBy('rating') }}>By Rating</Button>
              <Button onClick={() => { this.sortBy('mood') }}>By Mood</Button>
            </div>
            {billing}
            {this.computeDuration(this.state.bullets)}
            {this.renderBullets(this.state.bullets)}
            {pagination}
          </Grid>
        </Grid>
      </div>
    )
  }
}

Search.propTypes = propTypes

const mapStateToProps = (state) => {
  const {
    isGettingBullets,
    hasGottenBullets,
    bullets,
    hasCreatedBullet,
    isGettingBulletCount,
    hasGottenBulletCount,
    bulletCount,
    isGettingBulletAverages,
    hasGottenBulletAverages,
    bulletAverages
  } = state.bullets
  return {
    isGettingBullets,
    hasGottenBullets,
    bullets,
    hasCreatedBullet,
    isGettingBulletCount,
    hasGottenBulletCount,
    bulletCount,
    isGettingBulletAverages,
    hasGottenBulletAverages,
    bulletAverages
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({
    getBullets,
    getBulletCount,
    createBullet,
    openLightbox,
    closeMapDialog,
    openMapDialog,
    openEditBulletDialog,
    getBulletAverages
  }, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Search)
