import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { TextField, Button } from '@mui/material'
import { login } from 'store/modules/auth'
import { openAlert } from 'store/modules/site'

const propTypes = {
  loginErrorMsg: PropTypes.string,
  loginSuccessMsg : PropTypes.string,
  login: PropTypes.func,
  openAlert: PropTypes.func,
  history: PropTypes.object
}

class Login extends Component {
  constructor (props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      message: ''
    }
  }

  handleTextChange = (value, type) => {
    const oldState = this.state
    oldState[type] = value
    this.setState(Object.assign(this.state, oldState))
  }

  handleOnClick = () => {
    const data = this.state
    this.props.login(data)
  }

  // updateStateMessage (value) {
  //   this.setState({ message: value })
  // }

  componentDidUpdate (prevProps) {
    if (this.props.loginErrorMsg !== prevProps.loginErrorMsg && this.props.loginErrorMsg !== '') {
      this.props.openAlert({
        message: this.props.loginErrorMsg,
        severity: 'error',
      })
    }
    if (this.props.loginSuccessMsg !== prevProps.loginSuccessMsg && this.props.loginSuccessMsg !== '') {
      this.props.openAlert({
        message: 'Logged in!',
        severity: 'success',
      })
      this.props.history.push('/admin/dashboard')
    }
  }

  render () {
    return (
      <div id='login-component'>
        <div className='admin-container' style={{ margin: '20px' }}>
          <h2>Login</h2>
          <div>
            {this.state.message}
            <form>
              <TextField
                id='email-input'
                label='Email'
                placeholder='Email'
                margin='normal'
                variant='outlined'
                value={this.state.email}
                onChange={(e) => { this.handleTextChange(e.target.value, 'email') }}
              /><br />
              <TextField
                id='password-input'
                label='password'
                placeholder='Password'
                margin='normal'
                variant='outlined'
                type='password'
                value={this.state.password}
                onChange={(e) => { this.handleTextChange(e.target.value, 'password') }}
              /><br />
              <Button variant='contained' color='secondary' onClick={() => { this.handleOnClick() }}>Submit</Button>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

Login.propTypes = propTypes

const mapStateToProps = (state) => {
  const {
    isLoggingIn,
    hasLoggedIn,
    account,
    loginErrorMsg,
    loginSuccessMsg,
    user
  } = state.auth
  return {
    isLoggingIn,
    hasLoggedIn,
    account,
    loginErrorMsg,
    loginSuccessMsg,
    user
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({
    login,
    openAlert
  }, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)
