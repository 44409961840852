import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EditIcon from '@mui/icons-material/Edit'
import moment from 'moment'
import TimerIcon from '@mui/icons-material/TimerOutlined'
import {
  Tooltip
} from '@mui/material'

const cardStyle = {
  maxWidth: '250px',
  whiteSpace: 'break-spaces',
  backgroundColor: 'white',
  marginBottom: '10px',
  padding: '5px 10px',
  cursor: 'grab'
}

const titleStyle = {
  fontSize: '12px',
  fontWeight: 'bold',
  paddingTop: '5px'
}

const descriptionStyle = {
  fontSize: '10px'
}
const editIconStyle = {
  color: '#96b6df',
  cursor: 'pointer',
  position: 'absolute',
  right: 0,
  height: '10px',
}

class BoardCard extends Component {
  formattedTaskList = (t) => {
    if (t.children && t.children.length > 0) {
      const subtasks = t.children.map((c) => {
        return (
          <div key={c.id} style={descriptionStyle}>
            <input type='checkbox' id={c.id} name={c.id} defaultChecked={c.completed}
            />
            <label htmlFor={c.id}>{c.title}</label>
          </div>
        )
      })
      return subtasks
    }
  }

  formattedStartTime = (c) => {
    let startStats
    if (c.started && c.startedOn && !c.completed && c.status !== 'done') {
      startStats = (
        <div style={{ fontSize: '8px', textAlign: 'right' }}>
          <Tooltip title='Doing Now' placement='top'>
            <TimerIcon
              style={{ width: '8px', height: '8px', marginRight: '3px' }}
            />
          </Tooltip>
          {moment(c.startedOn).fromNow()}
        </div>
      )
    }
    return startStats
  }

  render () {
    return (
      <div style={cardStyle}>
        <EditIcon
          style={editIconStyle}
          onClick={() => {
            this.props.handleOnEditClick(this.props.metadata)
          }}
        />
        <div style={titleStyle}>{this.props.title}</div>
        <div style={descriptionStyle}>{this.props.description}</div>
        {this.formattedTaskList(this.props.metadata)}
        {this.formattedStartTime(this.props.metadata)}
      </div>
    )
  }
}

BoardCard.propTypes = {
  // showDeleteButton: PropTypes.bool,
  // onDelete: PropTypes.func,
  // onClick: PropTypes.func,
  // style: PropTypes.object,
  // tagStyle: PropTypes.object,
  // className: PropTypes.string,
  // id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  metadata: PropTypes.object.isRequired,
  handleOnEditClick: PropTypes.func,
  // label: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  // tags: PropTypes.array,
}

BoardCard.defaultProps = {
  showDeleteButton: true,
  onDelete: () => {},
  onClick: () => {},
  style: {},
  tagStyle: {},
  title: 'no title',
  description: '',
  label: '',
  tags: [],
  className: ''
}

export default BoardCard
