import {
  AppBar, Button,
  Dialog, DialogActions, DialogContent,
  DialogTitle, Menu, MenuItem, Toolbar
} from '@mui/material'
import BackdropLoad from 'components/Site/Backdrop'
import Map from 'components/Site/Map'
import MaterialAlert from 'components/Site/MaterialAlert'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Lightbox from 'react-image-lightbox'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router' // react-router v4/v5
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import BulletsContainer from 'routes/Admin/Bullets/BulletsContainer'
import ContentContainer from 'routes/Admin/Content/ContentContainer'
import DashboardContainer from 'routes/Admin/Dashboard/DashboardContainer'
import FilesContainer from 'routes/Admin/Files/FilesContainer'
import FinanceContainer from 'routes/Admin/Finance/FinanceContainer'
import HabitsContainer from 'routes/Admin/Habits/HabitsContainer'
import Day from 'routes/Admin/Planner/Day'
import SandboxContainer from 'routes/Admin/Sandbox/SandboxContainer'
import TasksContainer from 'routes/Admin/Tasks/TasksContainer'
import TagsContainer from 'routes/Admin/Tags/TagsContainer'
import UsersContainer from 'routes/Admin/Users/UsersContainer'
import Login from 'routes/Login/Login'
import SearchBar from 'components/Site/SearchBar'
// import { sessionService } from 'redux-react-session'
import TaskEditDialog from 'components/Tasks/TaskEditDialog'
import BulletEditDialog from 'components/Bullets/BulletEditDialog'
import { authenticate, logout } from 'store/modules/auth'
import {
  closeAlert,
  closeBackdropLoad,
  closeLightbox,
  closeMapDialog,
  closeEditTaskDialog,
  closeEditBulletDialog,
  updateCurrentLocation,
  openEditTaskDialog
} from 'store/modules/site'
import {
  createTask,
  getRelatedTasks,
  getActiveTags,
  getEpics,
  createBullet
} from 'store/modules/bullets'
import { geolocated, geoPropTypes } from 'react-geolocated'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file

const propTypes = {
  hasLoggedIn: PropTypes.bool,
  token: PropTypes.string,
  authenticate: PropTypes.func,
  alertIsOpen: PropTypes.bool,
  closeAlert: PropTypes.func,
  alertMessage: PropTypes.string,
  alertSeverity: PropTypes.string,
  closeBackdropLoad: PropTypes.func,
  backdropIsOpen: PropTypes.bool,
  closeLightbox: PropTypes.func,
  lightboxImages: PropTypes.array,
  lightboxIndex: PropTypes.number,
  lightboxIsOpen: PropTypes.bool,
  mapDialogIsOpen: PropTypes.bool,
  closeMapDialog: PropTypes.func,
  mapDialogMarkers: PropTypes.array,
  history: PropTypes.object,
  editTaskDialogIsOpen: PropTypes.bool,
  closeEditTaskDialog: PropTypes.func,
  selectedTaskToEdit: PropTypes.object,
  createTask: PropTypes.func,
  getRelatedTasks: PropTypes.func,
  relatedTasks: PropTypes.array,
  hasCreatedBullet: PropTypes.bool,
  hasCreatedTask: PropTypes.bool,
  hasCreatedSubtask: PropTypes.bool,
  createdSubtask: PropTypes.object,
  epics: PropTypes.array,
  getEpics: PropTypes.func,
  getActiveTags: PropTypes.func,
  activeTags: PropTypes.array,
  createBullet: PropTypes.func,
  selectedBulletToEdit: PropTypes.object,
  editBulletDialogIsOpen: PropTypes.bool,
  closeEditBulletDialog: PropTypes.func,
  updateCurrentLocation: PropTypes.func,
  openEditTaskDialog: PropTypes.func,
  ...geoPropTypes
}

const MobileMenu = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  let superAdminMenu
  if (props.user && props.user.role === 'SUPERADMIN') {
    superAdminMenu = (
      <>
        <MenuItem onClick={() => {
          props.history.push('/admin/finance/dashboard')
          handleClose()
        }}>Finances
        </MenuItem>
        <MenuItem onClick={() => {
          props.history.push('/admin/finance/import')
          handleClose()
        }}>Import
        </MenuItem>
        <MenuItem onClick={() => {
          props.history.push('/admin/finance/accounts')
          handleClose()
        }}>Accounts
        </MenuItem>
        <MenuItem onClick={() => {
          props.history.push('/admin/finance/categories')
          handleClose()
        }}>Categories
        </MenuItem>
        <MenuItem onClick={() => {
          props.history.push('/')
          handleClose()
        }}>Content Home
        </MenuItem>
        <MenuItem onClick={() => {
          props.history.push('/admin/content')
          handleClose()
        }}>Content List
        </MenuItem>
        <MenuItem onClick={() => {
          props.history.push('/admin/content/create')
          handleClose()
        }}>Content Create
        </MenuItem>
        <MenuItem onClick={() => {
          props.history.push('/admin/files')
          handleClose()
        }}>Content Files
        </MenuItem>
      </>
    )
  }

  return (
    <>
      <Button aria-controls='mobile-menu' aria-haspopup='true' onClick={handleClick}>
        Menu
      </Button>
      <Menu
        id='mobile-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => {
          props.history.push('/admin')
          handleClose()
        }}>Dashboard
        </MenuItem>
        <MenuItem onClick={() => {
          props.history.push('/admin/bullets')
          handleClose()
        }}>Day Review
        </MenuItem>
        <MenuItem onClick={() => {
          props.history.push('/admin/bullets/week')
          handleClose()
        }}>Week Review
        </MenuItem>
        <MenuItem onClick={() => {
          props.history.push('/admin/bullets/month')
          handleClose()
        }}>Month Review
        </MenuItem>
        <MenuItem onClick={() => {
          props.history.push('/admin/planner/day')
          handleClose()
        }}>Day Planner
        </MenuItem>
        <MenuItem onClick={() => {
          props.history.push('/admin/habits')
          handleClose()
        }}>Habits
        </MenuItem>
        <MenuItem onClick={() => {
          props.history.push('/admin/tags')
          handleClose()
        }}>Tags
        </MenuItem>
        {superAdminMenu}
        <MenuItem onClick={() => {
          props.logout()
          props.history.push('/admin/login')
          handleClose()
        }}>Logout
        </MenuItem>
      </Menu>
    </>
  )
}

MobileMenu.propTypes = {
  history: PropTypes.object,
  logout: PropTypes.func,
  user: PropTypes.object
}

const ContentMenu = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button aria-controls='content-menu' aria-haspopup='true' onClick={handleClick}>
        Content
      </Button>
      <Menu
        id='content-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => {
          props.history.push('/')
          handleClose()
        }}>Home
        </MenuItem>
        <MenuItem onClick={() => {
          props.history.push('/admin/content')
          handleClose()
        }}>List
        </MenuItem>
        <MenuItem onClick={() => {
          props.history.push('/admin/content/create')
          handleClose()
        }}>Create
        </MenuItem>
        <MenuItem onClick={() => {
          props.history.push('/admin/files')
          handleClose()
        }}>Files
        </MenuItem>
      </Menu>
    </>
  )
}

ContentMenu.propTypes = {
  history: PropTypes.object
}

const BulletMenu = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button aria-controls='bullet-menu' aria-haspopup='true' onClick={handleClick}>
        Review
      </Button>
      <Menu
        id='bullet-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => {
          props.history.push('/admin/bullets')
          handleClose()
        }}>Day
        </MenuItem>
        <MenuItem onClick={() => {
          props.history.push('/admin/bullets/week')
          handleClose()
        }}>Week
        </MenuItem>
        <MenuItem onClick={() => {
          props.history.push('/admin/bullets/month')
          handleClose()
        }}>Month
        </MenuItem>
      </Menu>
    </>
  )
}

BulletMenu.propTypes = {
  history: PropTypes.object
}

const BulletPlanMenu = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button aria-controls='bullet-plan-menu' aria-haspopup='true' onClick={handleClick}>
        Plan
      </Button>
      <Menu
        id='bullet-plan-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => {
          props.history.push('/admin/planner/day')
          handleClose()
        }}>Day Planner
        </MenuItem>
        <MenuItem onClick={() => {
          props.history.push('/admin/habits')
          handleClose()
        }}>Habits
        </MenuItem>
        <MenuItem onClick={() => {
          props.history.push('/admin/tags')
          handleClose()
        }}>Tags
        </MenuItem>
      </Menu>
    </>
  )
}

BulletPlanMenu.propTypes = {
  history: PropTypes.object
}

const FinanceMenu = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button aria-controls='finance-menu' aria-haspopup='true' onClick={handleClick}>
        Finances
      </Button>
      <Menu
        id='finances-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => {
          props.history.push('/admin/finance/dashboard')
          handleClose()
        }}>Dashboard
        </MenuItem>
        <MenuItem onClick={() => {
          props.history.push('/admin/finance/import')
          handleClose()
        }}>Import
        </MenuItem>
        <MenuItem onClick={() => {
          props.history.push('/admin/finance/accounts')
          handleClose()
        }}>Accounts
        </MenuItem>
        <MenuItem onClick={() => {
          props.history.push('/admin/finance/categories')
          handleClose()
        }}>Categories
        </MenuItem>
      </Menu>
    </>
  )
}

FinanceMenu.propTypes = {
  history: PropTypes.object
}

const SuperAdminMenu = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button aria-controls='superadmin-menu' aria-haspopup='true' onClick={handleClick}>
        Admin
      </Button>
      <Menu
        id='superadmin-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => {
          props.history.push('/admin/users')
          handleClose()
        }}>Users
        </MenuItem>
      </Menu>
    </>
  )
}

SuperAdminMenu.propTypes = {
  history: PropTypes.object
}

const AppLinks = (props) => {
  if (props.hasLoggedIn && props.token !== '') {
    const logout = (
      <Button color='inherit' onClick={() => {
        props.logout()
        props.history.push('/admin/login')
      }}>
        Logout
      </Button>
    )
    let superAdminMenu
    if (props.user && props.user.role === 'SUPERADMIN') {
      superAdminMenu = (
        <>
          <FinanceMenu {...props} />
          <ContentMenu {...props} />
          <SuperAdminMenu {...props} />
        </>
      )
    }
    return (
      <div>
        <BulletMenu {...props} />
        <BulletPlanMenu {...props} />
        {superAdminMenu}
        {logout}
      </div>
    )
  } else {
    return (
      <div>
        <Link to='/admin/login'><Button color='inherit'>Login</Button></Link>
      </div>
    )
  }
}
class MapDialog extends Component {
  render () {
    return (
      <div>
        <Dialog open={this.props.open}
          onClose={this.props.handleCloseMapDialog} aria-labelledby='form-dialog-location-title'>
          <DialogTitle id='form-dialog-location-title'>Location</DialogTitle>
          <DialogContent>
            <div style={{ minWidth: '500px' }}>
              <Map
                isMarkerShown
                markers={this.props.markers}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.handleCloseMapDialog} color='primary'>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

MapDialog.propTypes = {
  open: PropTypes.bool,
  handleCloseMapDialog: PropTypes.func,
  markers: PropTypes.array
}

class Admin extends Component {
  constructor (props) {
    super(props)
    this.menuRef = React.createRef()
    this.getInnerRef = this.getInnerRef.bind(this)
    this.getLocation = this.getLocation.bind(this)
  }

  innerRef;
  getInnerRef (ref) {
    this.innerRef = ref
  }

  getLocation () {
    this.innerRef && this.innerRef.getLocation()
  }

  componentDidMount () {
    this.props.authenticate()
    this.props.getEpics()
    this.props.getActiveTags()
    this.getLocation()
  }

  componentDidUpdate (prevProps) {
    if (this.props.coords !== prevProps.coords) {
      this.props.updateCurrentLocation(this.props.coords)
    }
    if (this.props.hasLoggedIn !== prevProps.hasLoggedIn) {
      if (this.props.hasLoggedIn === false) {
        this.props.history.push('/admin/login')
      }
    }
  }

  render () {
    const appBarStyle = {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      color: 'black',
      alignItems: 'center'
    }
    let appLinks = (
      <>
        <Link to='/admin'><Button color='inherit'>Dashboard</Button></Link>
        <AppLinks {...this.props} />
      </>
    )
    if (window.innerWidth < 600) {
      appLinks = (
        <>
          <MobileMenu {...this.props} />
        </>
      )
      appBarStyle.alignItems = 'left'
    }
    let superAdminRoutes
    if (this.props.user && this.props.user.role && this.props.user.role === 'SUPERADMIN') {
      superAdminRoutes = (
        <>
          <Route path='/admin/files' component={FilesContainer} />
          <Route path='/admin/content' component={ContentContainer} />
          <Route path='/admin/finance' component={FinanceContainer} />
          <Route path='/admin/sandbox' component={SandboxContainer} />
          <Route path='/admin/users' component={UsersContainer} />
        </>
      )
    }
    return (
      <div
        id='admin-layout'
        style={{
          minHeight: '100vh'
        }}>
        <MaterialAlert
          alertIsOpen={this.props.alertIsOpen}
          alertMessage={this.props.alertMessage}
          alertSeverity={this.props.alertSeverity}
          handleCloseAlert={this.props.closeAlert}
        />
        {this.props.lightboxIsOpen && (
          <Lightbox
            mainSrc={this.props.lightboxImages[this.props.lightboxIndex]}
            onCloseRequest={() => this.props.closeLightbox()}
          />
        )}
        <div id='app-bar' style={{ flexGrow: 1 }}>
          <AppBar position='static' sx={appBarStyle}>
            <Toolbar>
              {appLinks}
              <SearchBar {...this.props}
                callback={(value) => {
                  const string = encodeURIComponent(value)
                  this.props.history.push(`/admin/bullets/search?s=${string}`)
                }} />
            </Toolbar>
          </AppBar>
        </div>
        <BackdropLoad handleClose={this.props.closeBackdropLoad}
          open={this.props.backdropIsOpen} theme={this.props.theme} />
        <Switch>
          <Route exact path='/admin'
            // component={DashboardContainer}
            render={() => <DashboardContainer {...this.props} />}
          />
          <Route exact path='/admin/dashboard'
            // component={DashboardContainer}
            render={() => <DashboardContainer {...this.props} />}
          />
          <Route path='/admin/login' component={Login} />
          <Route path='/admin/bullets' component={BulletsContainer} />
          <Route path='/admin/habits' component={HabitsContainer} />
          <Route path='/admin/tags' component={TagsContainer} />
          <Route path='/admin/tasks' component={TasksContainer} />
          <Route path='/admin/planner/day' component={Day} />
          {superAdminRoutes}
        </Switch>
        <MapDialog
          isMarkerShown
          open={this.props.mapDialogIsOpen}
          markers={this.props.mapDialogMarkers}
          handleCloseMapDialog={this.props.closeMapDialog}
        />
        <TaskEditDialog
          open={this.props.editTaskDialogIsOpen}
          handleClose={this.props.closeEditTaskDialog}
          task={this.props.selectedTaskToEdit}
          epics={this.props.epics}
          createTask={this.props.createTask}
          getRelatedTasks={this.props.getRelatedTasks}
          relatedTasks={this.props.relatedTasks}
          hasCreatedTask={this.props.hasCreatedTask}
          hasCreatedBullet={this.props.hasCreatedBullet}
          hasCreatedSubtask={this.props.hasCreatedSubtask}
          createdSubtask={this.props.createdSubtask}
          activeTags={this.props.activeTags}
          coords={this.props.coords}
        />
        <BulletEditDialog
          coords={this.props.coords}
        />
      </div>
    )
  }
}

Admin.propTypes = propTypes

const mapStateToProps = (state) => {
  const {
    hasLoggedIn,
    token,
    user
  } = state.auth
  const {
    alertIsOpen,
    alertSeverity,
    alertMessage,
    isLoading,
    backdropIsOpen,
    lightboxIsOpen,
    lightboxImages,
    lightboxIndex,
    mapDialogIsOpen,
    mapDialogMarkers,
    editTaskDialogIsOpen,
    selectedTaskToEdit,
    editBulletDialogIsOpen,
    selectedBulletToEdit
  } = state.site
  const {
    isGettingTask,
    relatedTasks,
    hasCreatedTask,
    hasCreatedBullet,
    hasCreatedSubtask,
    createdSubtask,
    epics,
    activeTags
  } = state.bullets
  return {
    user,
    hasLoggedIn,
    token,
    alertIsOpen,
    alertSeverity,
    alertMessage,
    isLoading,
    backdropIsOpen,
    lightboxIsOpen,
    lightboxImages,
    lightboxIndex,
    mapDialogIsOpen,
    mapDialogMarkers,
    isGettingTask,
    relatedTasks,
    hasCreatedTask,
    hasCreatedBullet,
    hasCreatedSubtask,
    createdSubtask,
    editTaskDialogIsOpen,
    epics,
    selectedTaskToEdit,
    activeTags,
    editBulletDialogIsOpen,
    selectedBulletToEdit
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({
    authenticate,
    closeAlert,
    closeBackdropLoad,
    closeLightbox,
    logout,
    closeMapDialog,
    createTask,
    getRelatedTasks,
    getActiveTags,
    getEpics,
    closeEditTaskDialog,
    createBullet,
    closeEditBulletDialog,
    updateCurrentLocation,
    openEditTaskDialog
  }, dispatch),
})

const ConnectedAdmin = connect(
  mapStateToProps,
  mapDispatchToProps
)(Admin)

const geolocationOptions = {
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
  // watchPosition: true
}
if (navigator && navigator.getCurrentPosition) {
  geolocationOptions.watchPosition = true
}
export default
geolocated(geolocationOptions)(ConnectedAdmin)
