import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import {
  getWakatimeData
} from 'store/modules/bullets'
import { Tooltip } from '@mui/material'
import ComputerOutlinedIcon from '@mui/icons-material/ComputerOutlined'

const CodingTimeWidget = ({ date }) => {
  const dispatch = useDispatch()
  const fetchData = useCallback((date) => dispatch(getWakatimeData({ date })), [dispatch])
  const targetHours = 4
  const data = useSelector((state) => state.bullets.wakatimeData)
  useEffect(() => {
    fetchData(date)
  }, [date, fetchData])
  let totalTime, hours, minutes
  const divStyle = { display: 'inline-block', paddingLeft: '5px' }
  const iconStyle = { position: 'relative', top: '7px', width: '20px', cursor: 'pointer' }
  const iconSuccessStyle = { ...iconStyle, color: '#0b8100' }
  let wStyle = iconStyle
  if (data && data.totalTime) {
    totalTime = data.totalTime
    if (totalTime.hours > 0) {
      hours = (<span>{totalTime.hours}h</span>)
      if (totalTime.hours >= targetHours) {
        wStyle = iconSuccessStyle
      }
    }
    if (totalTime.minutes > 0) {
      minutes = (<span>{totalTime.minutes}m </span>)
    }
  }
  return (
    <>
      <div style={divStyle}>
        <Tooltip title='Coding Time' placement='left'>
          <span><ComputerOutlinedIcon style={wStyle} /></span>
        </Tooltip>
        &nbsp; {hours} {minutes}
      </div>
    </>
  )
}

CodingTimeWidget.propTypes = {
  date: PropTypes.object
}

export default CodingTimeWidget
