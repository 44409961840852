import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getBulletsBilling } from 'store/modules/bullets'

const propTypes = {
  title: PropTypes.string,
  bullets: PropTypes.array
}

class Billing extends Component {
  constructor (props) {
    super(props)
    this.state = {
      recipients: {},
      senders: {}
    }
  }

  componentDidMount () {
    const opts = {
      search: this.props.searchTerm
    }
    this.props.getBulletsBilling(opts)
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.bulletsBilling !== nextProps.bulletsBilling) {
      this.computeBilling(nextProps.bulletsBilling)
    }
  }

  computeBilling (bullets) {
    let recipients = {}
    let senders = {}
    bullets.map((bullet) => {
      console.log(bullet)
      bullet.metadata.recipients.map((r) => {
        recipients[r] = {
          toReceive: 0,
          toPay: 0
        }      
      })
      bullet.metadata.senders.map((r) => {
        recipients[r] = {
          toReceive: 0,
          toPay: 0
        }      
      })
    })
    bullets.map((bullet) => {
      bullet.metadata.recipients.map((r) => {
        if (recipients.hasOwnProperty(r)) {
          recipients[r].toReceive += bullet.metadata.total
        }  
      })
      bullet.metadata.senders.map((r) => {
        if (recipients.hasOwnProperty(r)) {
          recipients[r].toPay += bullet.metadata.subtotal
        }  
      })
    })
    this.setState({ recipients })
  }

  renderBilling () {
    // const computed = bullets.map((bullet) => {
    //   return (
    //     <div>
    //       {bullet.body}<br />
    //       {JSON.stringify(bullet.metadata)}<br /><br />
    //     </div>
    //   )
    // });
    // return computed
    const rendered = []
    const recipients = this.state.recipients
    for (const prop in recipients) {
      // console.log(`${property}: ${object[property]}`);
      // return (<div>{prop}</div>)
      rendered.push(
        <div>
          Recipient: {prop}<br />
          Total To Receive: ${recipients[prop].toReceive}<br />
          Total To Pay: ${recipients[prop].toPay}<br />
          <hr />
        </div>)
    }
    return rendered
    // return recipients.map((r) => {
    //   return (
    //     <div>
    //       {r}
    //     </div>
    //   )
    // })
    // return (
    //   <div>
    //     {JSON.stringify(recipients)}
    //   </div>
    // )
  }

  render () {
    return (
      <>
        <div className='billing-container'>
        {this.renderBilling(this.props.bulletsBilling)}
        </div>
      </>
    )
  }
}

Billing.propTypes = propTypes

const mapStateToProps = (state) => {
  const {
    isGettingBulletsBilling,
    hasGottenBulletsBilling,
    bulletsBilling
  } = state.bullets
  return {
    isGettingBulletsBilling,
    hasGottenBulletsBilling,
    bulletsBilling
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({
    getBulletsBilling
  }, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Billing)
