import * as React from 'react'
import PropTypes from 'prop-types'
import Rating from '@mui/material/Rating'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied'
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied'
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined'
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied'

const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon />,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <SentimentDissatisfiedIcon />,
    label: 'Dissatisfied',
  },
  3: {
    icon: <SentimentSatisfiedIcon />,
    label: 'Neutral',
  },
  4: {
    icon: <SentimentSatisfiedAltIcon />,
    label: 'Satisfied',
  },
  5: {
    icon: <SentimentVerySatisfiedIcon />,
    label: 'Very Satisfied',
  },
}

function IconContainer (props) {
  const { value, ...other } = props
  return <span {...other}>{customIcons[value].icon}</span>
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
}

class MoodRating extends React.Component {
  render () {
    let value = 0
    if (this.props.value) {
      switch (this.props.value) {
        case 1: { value = 1; break }
        case 25: { value = 2; break }
        case 50: { value = 3; break }
        case 75: { value = 4; break }
        case 100: { value = 5; break }
        default: { value = 1 }
      }
    }
    return (
      <Rating
        name='mood-rating'
        // defaultValue={value}
        value={value}
        IconContainerComponent={IconContainer}
        highlightSelectedOnly
        onChange={this.props.onChange}
      />
    )
  }
}

MoodRating.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func
}

export default MoodRating
