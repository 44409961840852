import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
// import { Button } from '@mui/material'
import { getContent, editContentById } from 'store/modules/content'
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import ViewIcon from '@mui/icons-material/Visibility'
// import { openAlert } from 'store/modules/site'

const propTypes = {
  getContent: PropTypes.func,
  contents: PropTypes.array,
  history: PropTypes.object,
  // isGettingContent: PropTypes.bool,
  editContentById: PropTypes.func
}

class List extends Component {
  constructor (props) {
    super(props)
    // this.tableRef = React.createRef()
    this.state = {}
  }

  componentDidMount () {
    this.props.getContent()
  }

  render () {
    const columns = [
      { headerName: 'ID', field: 'id' },
      { headerName: 'Title', field: 'title' },
      { headerName: 'Text', field: 'plaintextExtract', width: 350 },
      { headerName: 'Access', field: 'access' },
      {
        headerName: 'Actions',
        field: 'actions',
        type: 'actions',
        getActions: (params) => [
          <GridActionsCellItem
            key={`view-${params.id}`}
            icon={<ViewIcon />} onClick={() => {
              window.open(`/content/${params.row.id}`, '_blank')
            }} label='View' />,
          <GridActionsCellItem
            key={`edit-${params.id}`}
            icon={<EditIcon />} onClick={() => {
              this.props.history.push(`/admin/content/edit/${params.row.id}`)
            }} label='View' />,
          <GridActionsCellItem
            key={`del-${params.id}`}
            icon={<DeleteIcon />} onClick={() => {
              this.props.editContentById({
                deleted: true,
                id: params.row.id
              })
            }} label='Delete' />,
        ]
      }
    ]
    return (
      <div id='content-list-container'>
        <h2>Content</h2>
        <div style={{ height: '500px' }}>
          <DataGrid
            // onCellEditCommit={(params, _event) => {
            //   const data = {
            //     id: params.id
            //   }
            //   data[params.field] = params.value
            //   this.props.createTransactionType(data)
            // }}
            rows={this.props.contents}
            columns={columns} />
        </div>
      </div>
    )
  }
}

List.propTypes = propTypes

const mapStateToProps = (state) => {
  const {
    getContentSuccess,
    isGettingContent,
    hasGottenContent,
    contents
  } = state.content
  return {
    getContentSuccess,
    isGettingContent,
    hasGottenContent,
    contents
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({
    getContent,
    editContentById
  }, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List)
