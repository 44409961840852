import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from './reducers'
import thunk from 'redux-thunk'
import { sessionService } from 'redux-react-session'
import { sendApiRequest } from 'utils/api'
import { loginSuccess } from 'store/modules/auth'
export const history = createBrowserHistory()
// const validateSession = (session) => {
//   // check if your session is still valid
//   return true
// }
// const options = { refreshOnCheckAuth: true, redirectPath: '/login', driver: 'COOKIES', validateSession }
// TODO: auth should be middleware
function requestMiddleware () {
  return ({ dispatch, getState }) => next => (action) => {
    const {
      request,
    } = action
    if (!request) {
      return next(action)
    }
    const { token, tokenExpiry } = getState().auth

    // 5 minutes from now
    const refreshThreshold = (new Date().getTime() + 300000)
    if (token && refreshThreshold > tokenExpiry) {
      sendApiRequest('token/refresh', {
        token
      }, 'get').then((response) => {
        if (response.status === 'success') {
          dispatch(loginSuccess(response.data))
          sessionService.saveSession({
            token: response.data.token, tokenExpiry: parseInt(response.data.tokenExpiry)
          })
        }
      })
    }
    return request(token)
  }
}

export default function configureStore (preloadedState) {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    compose(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        requestMiddleware(),
        thunk
      ),
    ),
  )
  sessionService.initSessionService(store)
  // sessionService.initSessionService(store, options)
  //   .then(() => console.log('Redux React Session is ready and a session was refreshed from your storage'))
  //   .catch(() => console.log('Redux React Session is ready and there is no session in your storage'))
  return store
}
