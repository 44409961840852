import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Button, TextField,
  Dialog, DialogActions, DialogContent,
  DialogTitle, Select, MenuItem,
  FormControl, InputLabel,
  FormControlLabel, Checkbox,
  FormGroup
} from '@mui/material'

class DatagridAddFormDialog extends Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false
    }
  }

  componentDidMount () {
    this.setInitialState(this.props.columns)
  }

  componentDidUpdate (prevProps) {
    if (this.props.columns !== prevProps.columns) {
      this.setInitialState(this.props.columns)
    }
    if (this.props.hasCreatedItem !== prevProps.hasCreatedItem) {
      this.props.handleClose()
    }
  }

  setInitialState = (columns) => {
    const state = this.state
    columns.forEach((c) => {
      switch (c.type) {
        case 'number' : {
          state[c.field] = null
          break
        }
        default: {
          state[c.field] = ''
        }
      }
    })
    this.setState(state)
  }

  handleClickOpen = () => {
    this.setState({ open: true })
  }

  handleDateChange = (date) => {
    this.setState({ selectedDate: date })
  }

  handleDelete = () => {
  }

  handleEdit = () => {
    const data = { ...this.state }
    const newData = {}
    for (const key in data) {
      if (data[key] !== null &&
        data[key] !== '' &&
        key !== 'open') {
        newData[key] = data[key]
      }
    }
    this.props.createCallback(newData)
  }

  handleClose = () => {
    this.props.handleClose()
  }

  handleChangeTextField = (val, name) => {
    const state = this.state
    state[name] = val
    this.setState(state)
  }

  renderFields = (columns) => {
    const fields = columns
      .filter((c) => c.editable === true && c.type !== 'actions')
      .map((col) => {
        switch (col.type) {
          case 'number': {
            return (
              <TextField
                margin='dense'
                id={col.field}
                label={col.headerName}
                type='number'
                fullWidth
                value={this.state[col.field]}
                onChange={(e) => this.handleChangeTextField(parseFloat(e.target.value), col.field)}
              />
            )
          }
          case 'singleSelect': {
            const items = col.valueOptions.map((i, idx) => {
              let value = i
              let label = i
              if (typeof i === 'object' && i.label && i.value) {
                value = i.value
                label = i.label
              }
              return <MenuItem key={`single-select-${idx}`} value={value}>{label}</MenuItem>
            })
            return (
              <FormControl fullWidth>
                <InputLabel id={`${col.field}-label`}>{col.headerName}</InputLabel>
                <Select
                  labelId={`${col.field}-label`}
                  id={`${col.field}-edit`}
                  value={this.state[col.field]}
                  onChange={(e) => this.handleChangeTextField(e.target.value, col.field)}
                >
                  {items}
                </Select>
              </FormControl>
            )
          }
          case 'select': {
            const items = []
            for (const key in col.lookup) {
              items.push(
                <MenuItem key={`select-${col.field}-${key}`} value={key}>{col.lookup[key]}</MenuItem>
              )
            }
            return (
              <FormControl fullWidth>
                <InputLabel id={`${col.field}-label`}>{col.headerName}</InputLabel>
                <Select
                  labelId={`${col.field}-label`}
                  id={`${col.field}-edit`}
                  value={this.state[col.field]}
                  onChange={(e) => this.handleChangeTextField(e.target.value, col.field)}
                >
                  {items}
                </Select>
              </FormControl>
            )
          }
          case 'boolean': {
            return (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => this.handleChangeTextField(e.target.checked, col.field)}
                      checked={this.state[col.field]} />
                  }
                  label={col.headerName} />
              </FormGroup>
            )
          }
          default: {
            return (
              <TextField
                margin='dense'
                id={col.field}
                label={col.headerName}
                type='text'
                fullWidth
                value={this.state[col.field]}
                onChange={(e) => this.handleChangeTextField(e.target.value, col.field)}
              />
            )
          }
        }
      })
    return (
      <div>
        {fields}
      </div>
    )
  }

  render () {
    return (
      <div>
        <Dialog open={this.props.open} onClose={this.handleClose} aria-labelledby='form-dialog-title'>
          <DialogTitle id='form-dialog-title'>{this.props.title}</DialogTitle>
          <DialogContent>
            {this.renderFields(this.props.columns)}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.handleClose} color='primary'>
              Cancel
            </Button>
            <Button type='submit' onClick={this.handleEdit} color='primary'>
              Edit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

DatagridAddFormDialog.propTypes = {
  createCallback: PropTypes.func,
  hasCreatedItem: PropTypes.bool,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  columns: PropTypes.array.isRequired
}

export default DatagridAddFormDialog
