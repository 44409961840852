import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Route, Switch } from 'react-router' // react-router v4/v5
// import { Link, BrowserRouter as Router } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Container } from '@mui/material'
import BackdropLoad from 'components/Site/Backdrop'
import ContentContainer from 'routes/Public/Content/ContentContainer'
import MaterialAlert from 'components/Site/MaterialAlert'
import {
  closeAlert,
  closeBackdropLoad,
  closeLightbox
} from 'store/modules/site'

import 'styles/main.css'
import 'react-image-lightbox/style.css'
// import 'styles/massively.css'

class Base extends Component {
  constructor (props) {
    super(props)
    this.state = {
      footerOpen: true
    }
  }

  renderDefault () {
    return (
      <Container style={{ marginTop: '40px' }}>
        <div>
          <center><h1>Bullet Journal</h1></center>
        </div>
      </Container>
    )
  }

  render () {
    let footerStyle = { display: 'none' }
    if (this.state.footerOpen) {
      footerStyle = { display: 'block' }
    }
    console.log(this.props)
    return (
      <div id='base-layout'>
        <MaterialAlert
          alertIsOpen={this.props.alertIsOpen}
          alertMessage={this.props.alertMessage}
          alertSeverity={this.props.alertSeverity}
          handleCloseAlert={this.props.closeAlert}
        />
        <BackdropLoad handleClose={this.props.closeBackdropLoad} open={this.props.backdropIsOpen} />
        <Switch>
          <Route path='/' exact component={ContentContainer} />
          <Route path='/content' component={ContentContainer} />
          <Route path='/dashboard' render={() => this.renderDefault()} />
        </Switch>
        <div id='footer' className='public-footer' style={footerStyle}>
          <Link to='/admin'>© Shoreless</Link>
        </div>
      </div>
    )
  }
}

Base.propTypes = {
  alertIsOpen: PropTypes.bool,
  closeAlert: PropTypes.func,
  alertMessage: PropTypes.string,
  alertSeverity: PropTypes.string,
  closeBackdropLoad: PropTypes.func,
  backdropIsOpen: PropTypes.bool,
}

const mapStateToProps = (state) => {
  const {
    alertIsOpen,
    alertSeverity,
    alertMessage,
    isLoading,
    backdropIsOpen,
  } = state.site
  return {
    alertIsOpen,
    alertSeverity,
    alertMessage,
    isLoading,
    backdropIsOpen,
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({
    closeAlert,
    closeBackdropLoad,
    closeLightbox,
  }, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Base)
