import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import isUrl from 'is-url'

class Linkify extends Component {
  processedText = (text) => {
    if (text) {
      const textArray = text.split(' ').map((t, i) => {
        const tag = /#[A-Za-z0-9]*/g.exec(t)
        // const cat = /(?<=\[).+?(?=\])/g.exec(t)
        const cat = /\[(.*?)\]/g.exec(t)
        if (isUrl(t)) {
          return (
            <a key={i}
              style={{ fontStyle: 'italic' }} href={t} target='_blank' rel='noopener noreferrer'>{t}&nbsp;
            </a>)
        }
        if (tag && tag[0]) {
          return (
            <Link key={i} to={`/admin/bullets/search?s=%23${tag[0].substring(1)}`}>{tag[0]}&nbsp;</Link>)
        }
        if (cat && cat[0]) {
          return (
            <Link key={i} to={`/admin/bullets/search?s=${cat.input}`}>{cat.input}&nbsp;</Link>)
        }
        return t + ' '
      })
      return textArray
    } else {
      return null
    }
  }

  render () {
    return this.processedText(this.props.children)
  }
}

Linkify.propTypes = {
  children: PropTypes.string
}

export default Linkify
