import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  // Tooltip,
  Button, TextField,
  Dialog, DialogActions, DialogContent,
  DialogTitle, Select, MenuItem,
  Checkbox, FormControlLabel,
  InputLabel, FormControl,
  Chip
} from '@mui/material'
import { DateTimePicker } from '@mui/lab'
import Subtask from 'components/Tasks/Subtask'
import moment from 'moment'
// import EditAttributesIcon from '@mui/icons-material/EditAttributes'
const statuses = [
  { name: 'inProgress', title: 'In Progress' },
  { name: 'todo', title: 'Todo' },
  { name: 'done', title: 'Done' },
  { name: 'someday', title: 'Someday' },
  { name: 'notDone', title: 'Did Not Do' }
]
class TaskEditDialog extends Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false,
      beginOn: null,
      dueOn: null,
      startedOn: null,
      startOn: null,
      endOn: null,
      task: '',
      title: '',
      body: '',
      type: 'task',
      target: '',
      targetDuration: '',
      started: false,
      isRecurring: false,
      isTimeSensitive: true,
      hasParent: false,
      edit: false,
      relatedTasks: [],
      parentId: 0,
      epicId: '',
      closed: false,
      completed: false,
      completedOn: null,
      children: [],
      subtaskToAdd: {},
      isAddingSubtask: false,
      activeTags: [],
      priority: null,
      addToCalendar: false
    }
  }

  setTaskState = (task) => {
    if (task) {
      this.setState({
        beginOn: task.beginOn,
        dueOn: task.dueOn,
        startedOn: task.startedOn,
        startOn: task.startOn,
        endOn: task.endOn,
        task: task,
        title: task.title,
        body: task.body || '',
        type: task.type,
        target: task.target || '',
        targetDuration: task.targetDuration || '',
        parentId: task.parentId,
        started: task.started,
        epicId: task.epicId,
        status: task.status,
        closed: task.closed,
        completed: task.completed,
        completedOn: task.completedOn,
        children: task.children,
        habitId: task.habitId,
        priority: task.priority
      })
      if (task.parentId) {
        this.setState({ hasParent: true })
        if (task.type === 'task') {
          this.props.getRelatedTasks({ type: 'story' })
        }
        if (task.type === 'subtask') {
          this.props.getRelatedTasks({ type: 'task' })
        }
      }
      if (task.calendarId) {
        this.setState({ addToCalendar: true })
      }
    } else {
      const defaultState = {
        beginOn: null,
        dueOn: null,
        startedOn: null,
        startOn: null,
        endOn: null,
        task: '',
        title: '',
        body: '',
        type: 'task',
        target: '',
        targetDuration: '',
        isRecurring: false,
        // isTimeSensitive: false,
        hasParent: false,
        edit: false,
        relatedTasks: [],
        parentId: 0,
        started: false,
        epicId: '',
        children: [],
        habitId: '',
        priority: null,
        addTaskToCalendar: false
      }
      if (this.props.defaultStatus) {
        defaultState.status = this.props.defaultStatus
      }
      this.setState(defaultState)
    }
  }

  componentDidMount () {
    if (this.props.task) {
      this.setTaskState(this.props.task)
    } else {
      this.setTaskState()
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.hasCreatedBullet !== prevProps.hasCreatedBullet) {
      this.props.handleClose()
    }
    if (this.props.hasCreatedTask !== prevProps.hasCreatedTask) {
      this.props.handleClose()
    }
    if (this.props.hasCreatedSubtask !== prevProps.hasCreatedSubtask) {
      // this.props.handleClose()
    }
    if (this.props.createdSubtask !== prevProps.createdSubtask) {
      this.setAdditionalChild(this.props.createdSubtask)
    }
    if (this.props.task !== prevProps.task) {
      if (this.props.task) {
        this.setTaskState(this.props.task)
      } else {
        this.setTaskState()
      }
    }
    if (this.props.relatedTasks !== prevProps.relatedTasks) {
      // console.log(this.props.relatedTasks)
      this.setRelatedTasks(this.props.relatedTasks)
    }
    if (this.props.activeTags !== prevProps.activeTags) {
      this.setActiveTags(this.props.activeTags)
    }
  }

  setActiveTags = (activeTags) => {
    this.setState({ activeTags })
  }

  setAdditionalChild = (task) => {
    if (task.id && this.state.children) {
      const children = this.state.children
      let newChildren = this.state.children
      const exists = children.filter((t) => task.id === t.id).length > 0
      if (exists) {
        newChildren = children.map((c) => {
          if (c.id === task.id) {
            return task
          } else { return c }
        })
      } else {
        newChildren.push(task)
      }
      this.setState({ children: newChildren, isAddingSubtask: false, subtaskToAdd: {} })
    }
  }

  setRelatedTasks = (relatedTasks) => {
    this.setState({ relatedTasks })
  }

  handleClickOpen = () => {
    this.setState({ open: true })
  }

  // handleClose = () => {
  //   this.setState({ open: false })
  // }

  handleDateChange = (date, name) => {
    const oldState = this.state
    if (name === 'beginOn') {
      if (moment(this.state.dueOn).isBefore(date)) {
        const diff = moment(this.state.dueOn).diff(this.state.beginOn)
        oldState.dueOn = moment(date).add(diff).toDate()
      } else if (!this.state.dueOn) {
        oldState.dueOn = moment(date).add(5, 'minutes')
      }
    }
    oldState[name] = date
    this.setState(oldState)
  }

  handleDelete = () => {
    const data = {
      id: this.state.task.id,
      deleted: true,
      modifiedOn: new Date()
    }
    this.props.createTask(data)
  }

  handleEdit = () => {
    const data = {
      title: this.state.title,
      createdOn: new Date(),
      type: this.state.type,
      body: this.state.body,
      modifiedOn: new Date(),
      started: this.state.started,
      status: this.state.status,
      completed: this.state.completed,
      closed: this.state.closed,
      dueOn: this.state.dueOn,
      beginOn: this.state.beginOn,
      priority: this.state.priority
    }
    if (this.state.task && this.state.task.id) {
      data.id = this.state.task.id
    }
    // if (this.state.beginOn) {
    //   data.beginOn = this.state.beginOn
    // }
    // if (this.state.isTimeSensitive) {
    //   if (this.state.beginOn) {
    //     data.beginOn = this.state.beginOn
    //   }
    // }
    // if (this.state.dueOn) {
    //   data.dueOn = this.state.dueOn
    // }
    if (this.state.endOn) {
      data.endOn = this.state.endOn
    }
    if (this.state.startOn) {
      data.startOn = this.state.startOn
    }
    if (this.state.started && this.state.startedOn) {
      data.startedOn = this.state.startedOn
    }
    if (this.state.hasParent) {
      data.parentId = this.state.parentId
    }
    if (!this.state.hasParent) {
      data.parentId = null
    }
    if (this.state.epicId) {
      data.epicId = this.state.epicId
    }
    if (this.state.habitId) {
      data.habitId = this.state.habitId
    }
    if (this.props.coords && this.props.coords.latitude && this.props.coords.longitude) {
      data.lat = this.props.coords.latitude
      data.lng = this.props.coords.longitude
    }
    if (this.state.addToCalendar === true) {
      data.addToCalendar = this.state.addToCalendar
    }
    this.props.createTask(data)
  }

  handleAddSubtask = (data) => {
    this.props.createTask(data)
  }

  handleChangeText = (e, name) => {
    const oldState = this.state
    oldState[name] = e.target.value
    this.setState(oldState)
  }

  handleChangeSubtaskText = (e, name) => {
    const oldState = this.state.subtaskToAdd
    oldState[name] = e.target.value
    this.setState({ subtaskToAdd: oldState })
  }

  handleCheckboxChange = (e, name) => {
    const oldState = this.state
    oldState[name] = e.target.checked
    this.setState(oldState)
    if (name === 'hasParent' && e.target.checked === true) {
      if (this.state.type === 'task') {
        this.props.getRelatedTasks({ type: 'story' })
      }
      if (this.state.type === 'subtask') {
        this.props.getRelatedTasks({ type: 'task' })
      }
    }
    if (name === 'started' && e.target.checked === true && !this.state.startedOn) {
      this.setState({ startedOn: new Date() })
    }
    if (name === 'started' && e.target.checked === false && !this.state.startedOn) {
      this.setState({ startedOn: null })
    }
  }

  renderStatuses = () => {
    const st = statuses.map((e, i) => {
      return (
        <MenuItem key={i} value={e.name}>{e.title}</MenuItem>
      )
    })
    return (
      <FormControl>
        <InputLabel id='task-status-label'>Status</InputLabel>
        <Select
          style={{ minWidth: '60px' }}
          labelId='task-status-label'
          id='task-status'
          value={this.state.status}
          onChange={(e) => {
            this.setState({ status: e.target.value })
          }}>
          {st}
        </Select>
      </FormControl>
    )
  }

  renderStarted = () => {
    if (this.state.started && this.state.startedOn) {
      return (
        <div>
          <DateTimePicker
            renderInput={(props) => <TextField {...props} />}
            id='start-date-picker-inline'
            label='Started Date'
            value={this.state.startedOn}
            onChange={(date) => this.handleDateChange(date, 'startedOn')}
          />
        </div>
      )
    }
  }

  renderTimeSensitive = () => {
    if (this.state.isTimeSensitive) {
      return (
        <div>
          <DateTimePicker
            renderInput={(props) => <TextField {...props} />}
            id='due-date-picker-inline'
            label='Begin Date'
            value={this.state.beginOn}
            onChange={(date) => this.handleDateChange(date, 'beginOn')}
          />
          &nbsp;
          <DateTimePicker
            renderInput={(props) => <TextField {...props} />}
            id='due-date-picker-inline'
            label='Due Date'
            value={this.state.dueOn}
            onChange={(date) => this.handleDateChange(date, 'dueOn')}
          />
          <Button onClick={() => this.setState({ dueOn: null, beginOn: null })}>Clear</Button>
        </div>
      )
    }
  }

  renderStartOn = () => {
    return (
      <div>
        <DateTimePicker
          renderInput={(props) => <TextField {...props} />}
          id='start-date-picker-inline'
          label='Start Date'
          value={this.state.startOn}
          onChange={(date) => this.handleDateChange(date, 'startOn')}
        />
        &nbsp;
      </div>
    )
  }

  renderRecurring = () => {
    if (this.state.isRecurring) {
      return (
        <div>
          <TextField
            margin='dense'
            id='target'
            label='Target'
            type='text'
            value={this.state.target}
            onChange={(e) => {
              this.setState({ target: e.target.value })
            }}
            // onChange={this.handleChangeBody}
          />
          <div style={{
            display: 'inline-block',
            marginTop: '5px',
            marginLeft: '10px'
          }}>
            <FormControl>
              <InputLabel id='task-target-duration-label'>Target Duration</InputLabel>
              <Select
                labelId='task-target-duration-label'
                id='task-target-duration'
                value={this.state.targetDuration}
                onChange={(e) => {
                  this.setState({ targetDuration: e.target.value })
                }}
                style={{ minWidth: '150px' }}
              >
                <MenuItem value=''>None</MenuItem>
                <MenuItem value='week'>Week</MenuItem>
                <MenuItem value='month'>Month</MenuItem>
                <MenuItem value='day'>Day</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      )
    }
  }

  renderRelatedTasks = () => {
    if (this.state.hasParent) {
      const related = this.state.relatedTasks.map((t, i) => {
        return (
          <MenuItem value={t.id} key={`rel-${i}`}>{t.title}</MenuItem>
        )
      })
      return (
        <FormControl>
          <InputLabel id='task-parent-label'>Parent Task</InputLabel>
          <Select
            labelId='task-parent-label'
            id='task-parent'
            value={this.state.parentId}
            onChange={(e) => {
              this.setState({ parentId: e.target.value })
            }}
          >
            {related}
          </Select>
        </FormControl>
      )
    }
  }

  renderChildren = () => {
    if (this.state.children && this.state.children.length > 0) {
      const subtasks = this.state.children.map((c) => {
        return <Subtask key={c.id} task={c} createTask={this.props.createTask} />
      })
      return (
        <div>
          <div>{subtasks}</div>
        </div>
      )
    }
  }

  renderAddSubtasks = () => {
    let rendered = (
      <div style={{ cursor: 'pointer' }} onClick={() => this.setState({ isAddingSubtask: true })}>
        + Add subtask
      </div>
    )
    if (this.state.isAddingSubtask) {
      rendered = (
        <div>
          <TextField
            margin='dense'
            id='title'
            label='Title'
            type='text'
            fullWidth
            // multiline
            value={this.state.subtaskToAdd.title}
            onChange={(e) => this.handleChangeSubtaskText(e, 'title')}
          />
          <div style={{ cursor: 'pointer' }}
            onClick={() => {
              const subtaskToAdd = {
                parentId: this.state.task.id,
                status: this.state.status,
                title: this.state.subtaskToAdd.title,
                type: 'subtask'
              }
              if (this.state.type === 'story') {
                subtaskToAdd.type = 'task'
              }
              if (this.props.coords && this.props.coords.latitude && this.props.coords.longitude) {
                subtaskToAdd.lat = this.props.coords.latitude
                subtaskToAdd.lng = this.props.coords.longitude
              }
              this.handleAddSubtask(subtaskToAdd)
            }}>
            + Add
          </div>
        </div>
      )
    }
    return (
      <div>
        {rendered}
      </div>
    )
  }

  renderEpics = () => {
    const epics = this.props.epics.map((e, i) => {
      return (
        <MenuItem key={i} value={e.id}>{e.title}</MenuItem>
      )
    })
    return (
      <FormControl>
        <InputLabel id='task-epic-edit-label'>Epic</InputLabel>
        <Select
          style={{ minWidth: '60px' }}
          labelId='task-epic-edit-label'
          id='task-epic-edit'
          value={this.state.epicId}
          onChange={(e) => {
            this.setState({ epicId: e.target.value })
          }}>
          {epics}
        </Select>
      </FormControl>
    )
  }

  renderActiveTags = () => {
    const renderedTags = this.state.activeTags
      .filter((t) => t.type === 'taskCat')
      .map((t, idx) => {
        const tag = `[${t.name}]`
        return (<Chip
          style={{
            fontSize: '10px',
            margin: '0 2px',
            fontFamily: 'Arial',
            fontWeight: 600,
            backgroundColor: '#b5ccd736'
          }}
          variant='outlined'
          size='small'
          onClick={() => {
            let title = this.state.title
            if (title !== '') {
              title += ' ' + tag
            } else {
              title = tag + ' '
            }
            this.setState({ title })
          }}
          label={tag}
          key={`task-chip-${idx}`} />)
      })
    return (
      <div style={{ marginBottom: '10px' }}>
        {renderedTags}
      </div>
    )
  }

  render () {
    // let icon = (
    //   <div className='taskActions' style={{ display: 'inline-block' }}>
    //       <Tooltip title='Edit' placement='top'>
    //         <EditAttributesIcon
    //           className='taskActionEditIcon'
    //           onClick={this.handleClickOpen}
    //         />
    //       </Tooltip><br />
    //     </div>
    // )
    // let title = 'Edit Task'
    // if (this.props.action && this.props.action === 'addTask') {
    //   icon = (
    //     <Button color='primary'
    //     style={{ marginBottom: '10px' }}
    //     variant='outlined' onClick={this.handleClickOpen}>Add Task</Button>
    //   )
    // }
    let title = 'Add Task'
    if (this.props.task && this.props.task.id) {
      title = 'Edit Task'
    }
    return (
      <div>
        <Dialog open={this.props.open} onClose={this.props.handleClose} aria-labelledby='form-dialog-title'>
          <DialogTitle id='task-form-dialog-title'>{title}</DialogTitle>
          <DialogContent>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.started}
                    onChange={(e) => this.handleCheckboxChange(e, 'started')}
                    name='started' />
                }
                label='Started'
              />
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.isTimeSensitive}
                    onChange={(e) => this.handleCheckboxChange(e, 'isTimeSensitive')}
                    name='isTimeSensitive' />
                }
                label='Time Sensitive'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.isRecurring}
                    onChange={(e) => this.handleCheckboxChange(e, 'isRecurring')}
                    name='isRecurring' />
                }
                label='Recurring'
              /> */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.hasParent}
                    onChange={(e) => this.handleCheckboxChange(e, 'hasParent')}
                    name='hasParent' />
                }
                label='Child'
              />
            </div>
            {/* {this.renderStartOn()} */}
            {this.renderStatuses()}
            {this.renderStarted()}
            {this.renderTimeSensitive()}
            {this.renderRecurring()}
            <FormControl>
              <InputLabel id='task-type-edit-label'>Type</InputLabel>
              <Select
                labelId='task-type-edit-label'
                id='task-type-edit'
                value={this.state.type}
                onChange={(e) => {
                  this.setState({ type: e.target.value })
                }}>
                <MenuItem value='task'>Task</MenuItem>
                <MenuItem value='subtask'>Subtask</MenuItem>
                <MenuItem value='story'>Story</MenuItem>
                <MenuItem value='habit'>Habit</MenuItem>
              </Select>&nbsp;&nbsp;
            </FormControl>&nbsp;&nbsp;
            {this.renderRelatedTasks()}&nbsp;&nbsp;
            {this.renderEpics()}&nbsp;&nbsp;
            <FormControl>
              <InputLabel id='task-priority-label'>Priority</InputLabel>
              <Select
                labelId='task-priority-label'
                id='task-priority'
                value={this.state.priority}
                onChange={(e) => {
                  this.setState({ priority: e.target.value })
                }}
              >
                <MenuItem value={null}>None</MenuItem>
                <MenuItem value={25}>Low</MenuItem>
                <MenuItem value={50}>Medium</MenuItem>
                <MenuItem value={100}>High</MenuItem>
              </Select>
            </FormControl>
            <TextField
              margin='dense'
              id='title'
              label='Title'
              type='text'
              fullWidth
              // multiline
              value={this.state.title}
              onChange={(e) => this.handleChangeText(e, 'title')}
            />
            <TextField
              margin='dense'
              id='body'
              label='Description'
              type='text'
              fullWidth
              multiline
              value={this.state.body}
              onChange={(e) => this.handleChangeText(e, 'body')}
            />
            {this.renderActiveTags()}
            <b>Subtasks</b>
            {this.renderChildren()}
            {this.renderAddSubtasks()}
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.closed}
                  onChange={(e) => this.handleCheckboxChange(e, 'closed')}
                  name='closed' />
              }
              label='Closed'
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.completed}
                  onChange={(e) => this.handleCheckboxChange(e, 'completed')}
                  name='completed' />
              }
              label='Completed'
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.addToCalendar}
                  onChange={(e) => this.handleCheckboxChange(e, 'addToCalendar')}
                  name='addToCalendar' />
              }
              label='Add To Calendar'
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDelete} color='secondary'>
              Delete
            </Button>
            <Button onClick={this.props.handleClose} color='primary'>
              Cancel
            </Button>
            <Button type='submit' onClick={this.handleEdit} color='primary'>
              Edit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

TaskEditDialog.propTypes = {
  task: PropTypes.object,
  hasCreatedBullet: PropTypes.bool,
  // createBullet: PropTypes.func,
  getRelatedTasks: PropTypes.func,
  relatedTasks: PropTypes.array,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  epics: PropTypes.array,
  createTask: PropTypes.func,
  hasCreatedTask: PropTypes.bool,
  hasCreatedSubtask: PropTypes.bool,
  defaultStatus: PropTypes.string,
  createdSubtask: PropTypes.object,
  coords: PropTypes.object,
  activeTags: PropTypes.array
}

export default TaskEditDialog
