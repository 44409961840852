import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getFiles } from 'store/modules/files'
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'
import Gallery from 'react-grid-gallery'
import ViewModuleIcon from '@mui/icons-material/ViewModule'
import ViewListIcon from '@mui/icons-material/ViewList'
import ViewIcon from '@mui/icons-material/Visibility'
import CopyIcon from '@mui/icons-material/ContentCopy'
import { openAlert } from 'store/modules/site'
const isImage = require('is-image')
// import Masonry from 'react-masonry-component'
// import { openAlert } from 'store/modules/site'

const propTypes = {
  getFiles: PropTypes.func,
  files: PropTypes.array,
  // history: PropTypes.object,
  // isGettingFiles: PropTypes.bool,
  openAlert: PropTypes.func
}

const copyToClipboard = str => {
  const el = document.createElement('textarea')
  el.value = str
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

class List extends Component {
  constructor (props) {
    super(props)
    this.tableRef = React.createRef()
    this.state = {
      view: 'table'
    }
  }

  componentDidMount () {
    this.props.getFiles()
  }

  renderTable () {
    const columns = [
      {
        headerName: 'File',
        field: 'path',
        renderCell: (params) => {
          return (
            <div style={{ width: '250px' }}>
              <img
                alt=''
                style={{ maxWidth: '100%' }}
                src={params.row.path} />
            </div>
          )
        },
      },
      {
        headerName: 'Actions',
        field: 'actions',
        type: 'actions',
        getActions: (params) => [
          <GridActionsCellItem
            key={`view-${params.id}`}
            icon={<ViewIcon />} onClick={() => {
              window.open(params.row.path)
            }} label='View' />,
          <GridActionsCellItem
            key={`copy-${params.id}`}
            icon={<CopyIcon />} onClick={() => {
              copyToClipboard(params.row.path)
              this.props.openAlert({
                message: 'Link copied to clipboard!',
                severity: 'success',
              })
            }} label='Copy' />,
        ]
      }
    ]
    return (
      <div style={{ height: '500px' }}>
        <DataGrid
          rows={this.props.files}
          columns={columns} />
      </div>
    )
  }

  renderGrid () {
    const IMAGES = this.props.files
      .filter((f) => {
        if (isImage(f.path)) { return true } else { return false }
      })
      .map((file) => {
        return {
          src: file.path,
          caption: file.path,
          thumbnail: file.path,
          thumbnailWidth: 320,
          // thumbnailHeight: 174,
        }
      })
    return (
      <div>
        <Gallery images={IMAGES} />
      </div>
    )
  }

  render () {
    let content = this.renderGrid()
    if (this.state.view === 'table') {
      content = this.renderTable()
    }

    return (
      <div id='files-list-container'>
        <h2>Files</h2>
        <div style={{ cursor: 'pointer' }}>
          <ViewListIcon onClick={() => this.setState({ view: 'table' })} />
          <ViewModuleIcon onClick={() => this.setState({ view: 'grid' })} />
        </div>
        {content}
      </div>
    )
  }
}

List.propTypes = propTypes

const mapStateToProps = (state) => {
  const {
    getFilesSuccess,
    isGettingFiles,
    hasGottenFiles,
    files
  } = state.files
  return {
    getFilesSuccess,
    isGettingFiles,
    hasGottenFiles,
    files
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({
    getFiles,
    openAlert
  }, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List)
