import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Dialog, DialogContent,
  SpeedDial, SpeedDialIcon, SpeedDialAction,
  TextField
} from '@mui/material'
import { useDispatch } from 'react-redux'
import BulletTimeline from 'components/Bullets/BulletTimeline'
import GithubWidget from 'components/Bullets/GithubWidget'
import CodingTimeWidget from 'components/Bullets/CodingTimeWidget'
import StepsWidget from 'components/Bullets/StepsWidget'
import HabitsCountWidget from 'components/Bullets/HabitsCountWidget'
import MoodPercentageWidget from 'components/Bullets/MoodPercentageWidget'
import WakeUpBullet from 'components/Bullets/WakeUpBullet'
import SleepBullet from 'components/Bullets/SleepBullet'
import BulletIcon from 'components/Bullets/BulletIcon'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import DatePicker from '@mui/lab/DatePicker'
import HabitsWidget from 'components/Habits/HabitsWidget'
import moment from 'moment'
import { openEditBulletDialog } from 'store/modules/site'
const queryString = require('query-string')

const Day = ({ location, history }) => {
  const dispatch = useDispatch()
  const [date, setDate] = useState(null)
  const [dateRange, setDateRange] = useState(null)
  const [habitDialogIsOpen, setHabitDialogIsOpen] = useState(false)
  useEffect(() => {
    const parsed = queryString.parse(location.search)
    if (parsed && parsed.d) {
      const d = new Date(parsed.d)
      setDate(d)
      setDateRange({
        startDate: moment(parsed.d).startOf('day'),
        endDate: moment(parsed.d).add(1, 'day').endOf('day')
      })
    } else {
      setDate(new Date())
      setDateRange({
        startDate: moment().startOf('day'),
        endDate: moment().add(1, 'day').endOf('day')
      })
    }
  }, [location])
  const renderHeader = () => {
    return (
      <div style={{ fontSize: '12px' }}>
        <HabitsCountWidget date={date} />
        <StepsWidget date={date} dateRange={dateRange} />
        <CodingTimeWidget date={date} /><br />
        <GithubWidget date={date} />
        <MoodPercentageWidget date={date} />
      </div>
    )
  }
  const renderDatePicker = () => {
    return (
      <div style={{ marginBottom: '10px' }}>
        <DatePicker
          label='Date'
          value={date}
          onChange={(date) => {
            if (date) {
              history.push(`/admin/bullets?d=${date.toISOString()}`)
            }
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </div>
    )
  }
  return (
    <div id='bullets-list-container'>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <h2>Review</h2>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          {renderDatePicker()}
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <BulletTimeline
            start='top'
            header={renderHeader()}
            date={date}
            after={<WakeUpBullet date={date} hasNext />}
            before={<SleepBullet date={date} />}
          />
          <br /><br />
        </Grid>
      </Grid>
      <Dialog open={habitDialogIsOpen}
        onClose={() => { setHabitDialogIsOpen(false) }}>
        <DialogContent>
          <div style={{ width: '500px' }}>
            <HabitsWidget date={date} />
          </div>
        </DialogContent>
      </Dialog>
      <SpeedDial
        ariaLabel='Day Speed Dial'
        sx={{
          alignItems: 'flex-end',
          position: 'sticky',
          bottom: 16,
        }}
        icon={<SpeedDialIcon />}
      >
        <SpeedDialAction
          key='Habits'
          icon={<BulletIcon type='habit' />}
          tooltipTitle='Habits'
          onClick={() => {
            setHabitDialogIsOpen(true)
          }}
        />
        <SpeedDialAction
          key='Bullet'
          icon={<AddCircleIcon />}
          tooltipTitle='Bullet'
          onClick={() => {
            dispatch(openEditBulletDialog({
              createdOn: date
            }))
          }}
        />
      </SpeedDial>
    </div>
  )
}

Day.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
}

export default Day
