import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Route } from 'react-router' // react-router v4/v5
import { Container } from '@mui/material'
import Day from './Day'
import Week from './Week'
import Search from './Search'
import Month from './Month'

class BulletsContainer extends Component {
  render () {
    return (
      <div id='content-container'>
        <Container style={{ marginTop: '40px' }}>
          <div>
            <Route key={1} exact path='/admin/bullets' component={Day} />
            <Route key={2} exact path='/admin/bullets/week' component={Week} />
            <Route key={3} exact path='/admin/bullets/month' component={Month} />
            <Route key={4} exact path='/admin/bullets/search' component={Search} />
          </div>
        </Container>
      </div>
    )
  }
}

BulletsContainer.propTypes = {
}

// const mapStateToProps = (state) => {
//   return {
//   }
// }

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({
  }, dispatch),
})

export default connect(
  // mapStateToProps,
  mapDispatchToProps
)(BulletsContainer)
