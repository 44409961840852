import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Route, Switch } from 'react-router' // react-router v4/v5
import { Container } from '@mui/material'
import { Link } from 'react-router-dom'
import routes from 'routes/Admin/Content'
import List from './List'

class ContentContainer extends Component {
  renderChildRoutes (routes) {
    const routing = routes().childRoutes.map((route, idx) => {
      return <Route key={idx} path={route.path} component={route.getComponent} />
    })
    return (
      <>
        <Switch>
          {routing}
        </Switch>
      </>
    )
  }

  renderNavigationLinks (routes) {
    const links = routes().childRoutes.map((route, idx) => {
      return (
        <li key={idx + 2}>
          <Link to={route.path} key={idx + 2}>{route.linkTitle}</Link>
        </li>
      )
    })
    return (
      <nav id='nav'>
        <ul className='links'>
          <li key={1}><Link to='/content' key={1}>Content</Link></li>
          {links}
        </ul>
      </nav>
    )
  }

  render () {
    return (
      <div id='content-container'>
        <Container style={{ marginTop: '40px' }}>
          <div>
            <Route key={1} exact path='/admin/content' component={List} />
            {this.renderChildRoutes(routes)}
          </div>
        </Container>
      </div>
    )
  }
}

ContentContainer.propTypes = {
}

// const mapStateToProps = (state) => {
//   return {
//   }
// }

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({
  }, dispatch),
})

export default connect(
  // mapStateToProps,
  mapDispatchToProps
)(ContentContainer)
