import React from 'react'
import moment from 'moment'

export const getDayNameFromNumber = (num) => {
  let d = ''
  switch (num) {
    case 0: {
      d = 'Monday'
      break
    }
    case 1: {
      d = 'Tuesday'
      break
    }
    case 2: {
      d = 'Wednesday'
      break
    }
    case 3: {
      d = 'Thursday'
      break
    }
    case 4: {
      d = 'Friday'
      break
    }
    case 5: {
      d = 'Saturday'
      break
    }
    case 6: {
      d = 'Sunday'
      break
    }
    default: {
      d = ''
    }
  }
  return d
}

export const getAverageFromArray = arr => arr.reduce((a, b) => a + b, 0) / arr.length

export const getMoodEmoji = (val) => {
  if (val === 1) { return (<span role='img' aria-label='mood-emoji'>&#128543;</span>) }
  if (val > 1 && val < 25) { return (<span role='img' aria-label='mood-emoji'>&#128533;</span>) }
  if (val >= 25 && val < 50) { return (<span role='img' aria-label='mood-emoji'>&#128528;</span>) }
  if (val >= 50 && val < 75) { return (<span role='img' aria-label='mood-emoji'>&#128522;</span>) }
  if (val >= 75) { return (<span role='img' aria-label='mood-emoji'>&#128516;</span>) }
}

export const isiOS = () => {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
}

export const parseRec = (recString, originalDate) => {
  const recArray = recString.split(';')
  const fr = recArray.filter((r) => r.search('RRULE:FREQ') !== -1)[0]
  let freq = ''
  if (fr) {
    const f = fr.split('RRULE:FREQ=')
    if (f.length > 0) {
      freq = f[1]
    }
  }
  // console.log(freq)
  // .split('RRULE:FREQ=')[1]
  const until = recArray.filter((r) => r.search('UNTIL=') !== -1)[0]
  const newDate = moment(originalDate)
  const now = moment()
  // console.log(freq)
  // console.log(until)
  if (until) {
    if (moment(until.split('UNTIL=')[1]).isBefore(now)) {
      // console.log('delete this')
      return false
    }
  }
  if (freq === 'YEARLY') {
    newDate.set('year', now.get('year'))
  }
  if (freq === 'MONTHLY') {
    newDate.set('year', now.get('year'))
    newDate.set('month', now.get('month'))
  }
  if (freq === 'WEEKLY') {
    const d = recArray.filter((r) => r.search('BYDAY=') !== -1)[0]
    if (d && d.length > 0) {
      const day = d.split('BYDAY=')[1]
      const dayDate = moment().startOf('Week').day(day)
      newDate.set('year', now.get('year'))
      newDate.set('month', now.get('month'))
      newDate.set('date', dayDate.get('date'))
      if (dayDate.isBefore(now)) {
        newDate.add(1, 'week')
      }
    }
  }
  return newDate
}
