import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
// import { Button } from '@mui/material'
import {
  getTransactionTypes,
  createTransactionType
} from 'store/modules/bullets'
import { DataGrid } from '@mui/x-data-grid'
// import { openAlert } from 'store/modules/site'

const propTypes = {
  getTransactionTypes: PropTypes.func,
  transactionTypes: PropTypes.array,
  // history: PropTypes.object,
  // isGettingTransactionTypes: PropTypes.bool,
  createTransactionType: PropTypes.func,
  // hasCreatedTransactionType: PropTypes.bool
}

class TransactionTypesList extends Component {
  constructor (props) {
    super(props)
    this.tableRef = React.createRef()
    this.state = {
    }
  }

  componentDidMount () {
    this.props.getTransactionTypes()
  }

  componentDidUpdate (prevProps) {
    // if (this.props.hasCreatedTransactionType !== prevProps.hasCreatedTransactionType) {
    //   this.props.getTransactionTypes()
    // }
  }

  render () {
    const columns = [
      // { title: 'ID', field: 'id' },
      { headerName: 'Title', field: 'title', width: 180 },
      { headerName: 'Type', field: 'type' },
      { headerName: 'Show Budget', field: 'showBudget', type: 'boolean', editable: true },
      { headerName: 'Budget', field: 'budget', type: 'number', editable: true }
    ]
    return (
      <div id='transaction-types-list-container'>
        <h2>Transaction Types</h2>
        <div style={{ height: '500px' }}>
          <DataGrid
            onCellEditCommit={(params, _event) => {
              const data = {
                id: params.id
              }
              data[params.field] = params.value
              this.props.createTransactionType(data)
            }}
            rows={this.props.transactionTypes}
            columns={columns} />
        </div>
      </div>
    )
  }
}

TransactionTypesList.propTypes = propTypes

const mapStateToProps = (state) => {
  const {
    isGettingTransactionTypes,
    hasGottenTransactionTypes,
    transactionTypes,
    isCreatingTransactionType,
    hasCreatedTransactionType,
    createdTransactionType
  } = state.bullets
  return {
    isGettingTransactionTypes,
    hasGottenTransactionTypes,
    transactionTypes,
    isCreatingTransactionType,
    hasCreatedTransactionType,
    createdTransactionType
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({
    getTransactionTypes,
    createTransactionType
  }, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionTypesList)
