import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  getOwntracksData,
  getGitCommitData
} from 'store/modules/bullets'
import {
  Grid, Button, Tooltip, Dialog
} from '@mui/material'
import {
  startOfMonth,
  endOfMonth,
  // addWeeks
} from 'date-fns'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import { DateRangePicker } from 'react-date-range'
import Map from 'components/Site/Map'
import DashboardWidget from 'components/Dashboard/DashboardWidget'

const queryString = require('query-string')
const moment = extendMoment(Moment)

const momentBusinessDays = require('moment-business-days')
const holidays = ['2023-09-09', '2023-11-13', '2023-12-25']
momentBusinessDays.updateLocale('us', {
  holidays,
  holidayFormat: 'YYYY-MM-DD',
  workingWeekdays: [1, 2, 3, 4, 5],
})

const TimeSheet = ({ dateRange, data }) => {
  const totalDays = momentBusinessDays(dateRange.startDate)
    .businessDiff(momentBusinessDays(dateRange.endDate))
  const businessDays = momentBusinessDays(dateRange.startDate).monthBusinessDays()
  const projects = {}
  businessDays.forEach((d) => {
    console.log(d)
  })
  // for (let i = 0; i < businessDays.length(); i++) {
  //   console.log(data[i])
  //   const gitDate = data[i].metadata[0].date
  //   let totalHours = 0;
  //   if (moment(gitDate).isSameOrBefore(dateRange.startDate, 'day')) {
  //     const totalDays = momentBusinessDays(dateRange.startDate)
  //     .businessDiff(momentBusinessDays(dateRange.gitDate))
  //   }
  //   projects[i.metadata[0].name] = ''
  // }
  // data.forEach((i) => {

  // })
  // console.log(businessDays)
  return (
    <div>
      Business Days: {totalDays}<br />
      Total Hours: {totalDays * 9}
      {JSON.stringify(projects)}
      {/* {JSON.stringify(data)} */}
    </div>
  )
}

TimeSheet.propTypes = {
  data: PropTypes.array,
  dateRange: PropTypes.object
}

class Month extends Component {
  constructor (props) {
    super(props)
    this.state = {
      dateRangeIsOpen: false,
      dateRange: {
        startDate: startOfMonth(new Date()),
        endDate: endOfMonth(new Date()),
      },
      dateRanges: [{
        startDate: startOfMonth(new Date()),
        endDate: endOfMonth(new Date()),
        key: 'selection'
      }],
      compact: true,
      isHeatmap: true,
      isPolyline: false
    }
  }

  componentDidMount () {
    const parsed = queryString.parse(this.props.location.search)
    if (parsed && parsed.startDate && parsed.endDate) {
      this.setState({
        dateRange: {
          startDate: parsed.startDate,
          endDate: parsed.endDate
        },
        dateRanges: [{
          startDate: moment(parsed.startDate).toDate(),
          endDate: moment(parsed.endDate).toDate(),
          key: 'selection'
        }]
      })
      this.props.getGitCommitData({
        dateRange: {
          startDate: parsed.startDate,
          endDate: parsed.endDate
        }
      })
      this.props.getOwntracksData({
        dateRange: {
          startDate: parsed.startDate,
          endDate: parsed.endDate
        }
      })
    } else {
      this.props.getGitCommitData({ dateRange: this.state.dateRange })
      this.props.getOwntracksData({ dateRange: this.state.dateRange })
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.owntracksData !== prevProps.owntracksData) {
      this.setMarkerState(this.props.owntracksData)
    }
  }

  setMarkerState = (owntracksData) => {
    const arr = []
    const data = owntracksData.map((d) => {
      return d.metadata
    })
    const markers = arr.concat(data).flat()
    this.setState({ markers })
  }

  renderDatePicker = () => {
    return (
      <div>
        <Button variant='contained' color='primary' onClick={() => {
          this.setState({ dateRangeIsOpen: !this.state.dateRangeIsOpen })
        }}>Date Picker
        </Button>
        <Dialog onClose={() => { this.setState({ dateRangeIsOpen: false }) }}
          open={this.state.dateRangeIsOpen}>
          <DateRangePicker
            onChange={(ranges) => {
              this.props
                .history.push( // eslint-disable-next-line
                  `/admin/bullets/month?startDate=${new Date(ranges.selection.startDate).toISOString()}&endDate=${new Date(ranges.selection.endDate).toISOString()}`)
              this.setState({ dateRanges: [ranges.selection], dateRange: ranges.selection })
              // this.setCalRange(ranges.selection)
              // this.props.getBulletsWithAggregate({ dateRange: ranges.selection })
              this.props.getOwntracksData({ dateRange: ranges.selection })
              this.props.getGitCommitData({ dateRange: ranges.selection })
            }}
            direction='horizontal'
            showPreview={false}
            ranges={this.state.dateRanges}
          />
        </Dialog>
      </div>
    )
  }

  renderGithubData (dataArray) {
    let totalCommits = 0; const commits = []
    if (dataArray) {
      dataArray.forEach((data) => {
        if (data.body) {
          totalCommits += parseInt(data.body)
        }
        if (data.metadata) {
          data.metadata.forEach((d) => {
            d.date = new Date(d.created_at)
            commits.push(d)
          })
        }
      })
    }
    const renderedCommits = commits
      .sort((a, b) => a.date - b.date)
      .map((d, idx) => {
        let truncatedName = d.name
        if (d.name.split('/') && d.name.split('/')[1]) {
          truncatedName = d.name.split('/')[1]
        }
        return (
          <div key={idx}
            style={{ fontSize: '10px', cursor: 'pointer' }}>
            <a href={d.url}>
              <Tooltip title={moment(d.created_at).format('DD/MM/YYYY h:mm a')} placement='top'>
                <span style={{ opacity: '0.5' }}>
                  [{truncatedName}]&nbsp;
                </span>
              </Tooltip>
              {d.message}
              <span style={{ opacity: '0.5' }}>
                &nbsp;{moment(d.created_at)
                  .format('DD/MM/YYYY h:mm a')}
              </span>
            </a><br />
          </div>
        )
      })
    const body = (
      <div>
        <span>{totalCommits} commits</span>
        {renderedCommits}<br /><br />
        <TimeSheet data={this.props.gitCommitData} dateRange={this.state.dateRange} />
      </div>
    )
    return (
      <DashboardWidget
        title='Git'
        body={body}
        subtitle='Retrieved from Github & Bitbucket'
        amount={totalCommits}
      />
    )
  }

  renderMap () {
    const polylineStyle = { cursor: 'pointer' }
    const heatmapStyle = { cursor: 'pointer' }
    if (this.state.isPolyline) { polylineStyle.fontWeight = 'bold' }
    if (this.state.isHeatmap) { heatmapStyle.fontWeight = 'bold' }
    return (
      <div className='dashboard-widget'>
        <div className='bar-chart'>&nbsp;</div>
        <div className='dashboard-widget-content'>
          <h4 className='title'>Map</h4>
          <Map
            isMarkerShown={false}
            markers={this.state.markers}
          />
        </div>
      </div>
    )
  }

  render () {
    return (
      <div id='month-review-container'>
        <h2>Review</h2>
        <h3>Month:&nbsp;
          {moment(this.state.dateRange.startDate).format('DD/MM/YYYY')} -&nbsp;
          {moment(this.state.dateRange.endDate).format('DD/MM/YYYY')}
        </h3> {this.renderDatePicker()}<br /><br />
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            {this.renderGithubData(this.props.gitCommitData)}
          </Grid>
          <Grid item xs={12} md={6}>
            {this.renderMap()}
          </Grid>
        </Grid>
      </div>
    )
  }
}

const propTypes = {
  // getBulletsWithAggregate: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
  owntracksData: PropTypes.array,
  getOwntracksData: PropTypes.func,
  getGitCommitData: PropTypes.func,
  gitCommitData: PropTypes.array,
}

Month.propTypes = propTypes

const mapStateToProps = (state) => {
  const {
    isGettingOwntracksData,
    owntracksData,
    gitCommitData
  } = state.bullets
  return {
    isGettingOwntracksData,
    owntracksData,
    gitCommitData
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({
    // getBulletsWithAggregate,
    // getHabits,
    // createBullet,
    // openLightbox,
    // getGoogleFitData,
    // getWakatimeData,
    // getEpics,
    getGitCommitData,
    getOwntracksData
  }, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Month)
