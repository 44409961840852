import { createAction, handleActions } from 'redux-actions'
import { sendApiRequest } from 'utils/api'
// import { sessionService } from 'redux-react-session'
import { startLoad, endLoad } from 'store/modules/site'

export const GET_FILES = 'GET_FILES'
export const getFiles = (opts = {}) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_FILES)())
    // opts.filter = {}
    const token = getState().auth.token
    opts = {
      filter: {
        order: ['id desc'],
        limit: 10,
        where: {
          and: [
            { deleted: false }
          ]
        }
      }
    }
    sendApiRequest('files', {
      token
    }, 'get', opts).then((response) => {
      if (response.status === 'failure') {
        dispatch(getFilesError(response))
      }
      if (response.status === 'success') {
        dispatch(getFilesSuccess(response.data))
      }
    })
  }
}

export const GET_FILES_SUCCESS = 'GET_FILES_SUCCESS'
export const getFilesSuccess = createAction(GET_FILES_SUCCESS)

export const GET_FILES_ERROR = 'GET_FILES_ERROR'
export const getFilesError = createAction(GET_FILES_ERROR)

export const GET_ADMIN_FILES = 'GET_ADMIN_FILES'
export const getAdminFiles = () => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_FILES)())
    sendApiRequest('files', {
      token: getState().auth.token
    }, 'get').then((response) => {
      if (response.status === 'failure') {
        dispatch(getFilesError(response))
      }
      if (response.status === 'success') {
        dispatch(getFilesSuccess(response.data))
      }
    })
  }
}

export const GET_FILE_BY_ID = 'GET_FILE_BY_ID'
export const getFileById = (id) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_FILE_BY_ID)())
    sendApiRequest(`files/${id}`, {
      token: getState().auth.token
    }, 'get').then((response) => {
      if (response.status === 'failure') {
        dispatch(getFileByIdError(response))
      }
      if (response.status === 'success') {
        dispatch(getFileByIdSuccess(response.data))
      }
    })
  }
}

export const GET_FILE_BY_ID_SUCCESS = 'GET_FILE_BY_ID_SUCCESS'
export const getFileByIdSuccess = createAction(GET_FILE_BY_ID_SUCCESS)

export const GET_FILE_BY_ID_ERROR = 'GET_FILES_ERROR'
export const getFileByIdError = createAction(GET_FILE_BY_ID_ERROR)

export const ADMIN_SELECT_FILES = 'ADMIN_SELECT_FILES'
export const adminSelectFiles = (contentId) => {
  return (dispatch, getState) => {
    // check for admin role from the server here - put it in api utils
    dispatch(createAction(ADMIN_SELECT_FILES)(contentId))
  }
}

export const DELETE_FILE_BY_ID = 'DELETE_FILE_BY_ID'
export const deleteFileById = (id) => {
  return (dispatch, getState) => {
    dispatch(createAction(DELETE_FILE_BY_ID)())
    sendApiRequest(`files/${id}`, {
      token: getState().auth.token
    }, 'delete').then((response) => {
      if (response.status === 'failure') {
        dispatch(deleteFileByIdError(response.data))
      }
      if (response.status === 'success') {
        dispatch(deleteFileByIdSuccess(response.data))
        dispatch(getFiles())
      }
    })
  }
}

export const DELETE_FILE_BY_ID_SUCCESS = 'DELETE_FILE_BY_ID_SUCCESS'
export const deleteFileByIdSuccess = createAction(DELETE_FILE_BY_ID_SUCCESS)

export const DELETE_FILE_BY_ID_ERROR = 'DELETE_FILE_BY_ID_ERROR'
export const deleteFileByIdError = createAction(DELETE_FILE_BY_ID_ERROR)

export const DELETE_FILE_BY_ID_RESET = 'DELETE_FILE_BY_ID_RESET'
export const deleteFileByIdReset = createAction(DELETE_FILE_BY_ID_RESET)

export const OPEN_FILES_DIALOG = 'OPEN_FILES_DIALOG'
export const openFilesDialog = createAction(OPEN_FILES_DIALOG)

export const CLOSE_FILES_DIALOG = 'CLOSE_FILES_DIALOG'
export const closeFilesDialog = createAction(CLOSE_FILES_DIALOG)

export const CREATE_NEW_FILES = 'CREATE_NEW_FILES'
export const createNewFiles = (data) => {
  return (dispatch, getState) => {
    dispatch(createAction(CREATE_NEW_FILES)())
    dispatch(startLoad())
    const contentData = data
    data.token = getState().auth.token
    let url = 'files'
    let type = 'post'
    if (data.id) {
      url = `files/${data.id}`
      type = 'patch'
    }
    sendApiRequest(url, contentData, type).then((response) => {
      console.log(response)
      dispatch(endLoad())
      if (response.status === 'failure') {
        dispatch(createNewFilesError(response))
      }
      if (response.status === 'success') {
        dispatch(createNewFilesSuccess(response.data))
        dispatch(getFiles())
      }
    })
  }
}

export const CREATE_NEW_FILES_SUCCESS = 'CREATE_NEW_FILES_SUCCESS'
export const createNewFilesSuccess = createAction(CREATE_NEW_FILES_SUCCESS)

export const CREATE_NEW_FILES_ERROR = 'CREATE_NEW_FILES_ERROR'
export const createNewFilesError = createAction(CREATE_NEW_FILES_ERROR)

export const RESET_FILES_FORM = 'RESET_FILES_FORM'
export const resetFilesForm = createAction(RESET_FILES_FORM)

export const EDIT_FILE_BY_ID = 'EDIT_FILE_BY_ID'
export const editFileById = (data) => {
  return (dispatch, getState) => {
    dispatch(createAction(EDIT_FILE_BY_ID)())
    const contentData = data
    data.token = getState().auth.token
    sendApiRequest(`files/${data.id}`, contentData, 'patch').then((response) => {
      if (response.status === 'failure') {
        dispatch(editFileByIdError(response))
      }
      if (response.status === 'success') {
        dispatch(editFileByIdSuccess(response.data))
        dispatch(getFileById(data.id))
      }
    })
  }
}

export const EDIT_FILE_BY_ID_SUCCESS = 'EDIT_FILE_BY_ID_SUCCESS'
export const editFileByIdSuccess = createAction(EDIT_FILE_BY_ID_SUCCESS)

export const EDIT_FILE_BY_ID_ERROR = 'CREATE_NEW_FILES_ERROR'
export const editFileByIdError = createAction(EDIT_FILE_BY_ID_ERROR)

export const UPLOAD_FILES = 'UPLOAD_FILES'
export const uploadFiles = (files, data = {}) => {
  return (dispatch, getState) => {
    dispatch(createAction(UPLOAD_FILES)())
    dispatch(startLoad())
    const options = { token: getState().auth.token }
    const contentData = new FormData()
    // contentData.append('files', files)
    for (var i = 0; i < files.length; i++) {
      contentData.append(files[i].name, files[i])
    }

    // files.map((f) => {
    //   contentData.append('file', f)
    // })
    for (const [key, value] of Object.entries(data)) {
      console.log(`${key}: ${value}`)
      contentData.append(key, value)
    }
    sendApiRequest('files', contentData, 'post', options).then((response) => {
      // console.log(response)
      dispatch(endLoad())
      if (response.status === 'failure') {
        dispatch(uploadFilesError(response))
      }
      if (response.status === 'success') {
        // console.log(response.data)
        dispatch(uploadFilesSuccess(response.data))
      }
    })
  }
}

export const UPLOAD_FILES_SUCCESS = 'UPLOAD_FILES_SUCCESS'
export const uploadFilesSuccess = createAction(UPLOAD_FILES_SUCCESS)

export const UPLOAD_FILES_ERROR = 'UPLOAD_FILES_ERROR'
export const uploadFilesError = createAction(UPLOAD_FILES_ERROR)

const initialState = {
  files: [],
  isGettingFiles: false,
  hasGottenFiles: false,
  getFilesError: {},
  adminSelectedFiles: 0,
  isDeletingFiles: false,
  hasDeletedFiles: false,
  deleteFilesError: {},
  deleteFilesSuccess: {},
  isEditingFileById: false,
  hasEditedFileById: false,
  editFileByIdError: {},
  editFileByIdSuccess: {},
  isCreatingFiles: false,
  hasCreatedFiles: false,
  createFilesSuccess: {},
  createFilesError: {},
  isGettingFileById: false,
  hasGottenFileById: false,
  selectedFiles: {},
  getFileByIdError: {},
  isUploadingFiles: false,
  hasUploadedFiles: false,
  uploadFilesError: {},
  uploadFilesSuccess: {}
}

export default handleActions({

  [GET_FILES]: (state) => ({
    ...state,
    isGettingFiles: true,
    hasGottenFiles: false,
    hasUploadedImages: false
  }),

  [GET_FILES_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingFiles: false,
      hasGottenFiles: true,
      files: payload,
      hasUploadedImages: false
    }
  },

  [GET_FILES_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isGettingFiles: true,
      hasGottenFiles: false,
      getFilesError: payload
    }
  },

  [ADMIN_SELECT_FILES]: (state, { payload }) => {
    return {
      ...state,
      adminSelectedFiles: payload
    }
  },

  [OPEN_FILES_DIALOG]: (state, { payload }) => {
    return {
      ...state,
      contentDialogIsOpen: true,
      contentDialogRole: payload
    }
  },

  [CLOSE_FILES_DIALOG]: (state) => {
    return {
      ...state,
      contentDialogIsOpen: false,
      isCreatingFiles: false,
      hasCreatedFiles: false,
      createFilesSuccess: {},
      createFilesError: {}
    }
  },

  [DELETE_FILE_BY_ID]: (state) => ({
    ...state,
    isDeletingFiles: true,
    hasDeletedFiles: false
  }),

  [DELETE_FILE_BY_ID_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isDeletingFiles: false,
      hasDeletedFiles: true,
      deleteFilesSuccess: payload
    }
  },

  [DELETE_FILE_BY_ID_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isDeletingFiles: false,
      hasDeletedFiles: false,
      deleteFilesError: payload
    }
  },

  [DELETE_FILE_BY_ID_RESET]: (state, { payload }) => {
    return {
      ...state,
      isDeletingFiles: false,
      hasDeletedFiles: false,
      deleteFilesError: {},
      deleteFilesSuccess: {}
    }
  },

  [OPEN_FILES_DIALOG]: (state, { payload }) => {
    return {
      ...state,
      contentDialogIsOpen: true,
      contentDialogRole: payload
    }
  },

  [CLOSE_FILES_DIALOG]: (state) => {
    return {
      ...state,
      contentDialogIsOpen: false,
      isCreatingFiles: false,
      hasCreatedFiles: false,
      createFilesSuccess: {},
      createFilesError: {}
    }
  },

  [RESET_FILES_FORM]: (state) => {
    return {
      ...state,
      isCreatingFiles: false,
      hasCreatedFiles: false,
      createFilesSuccess: {},
      createFilesError: {}
    }
  },

  [CREATE_NEW_FILES]: (state) => {
    return {
      ...state,
      isCreatingFiles: true,
      hasCreatedFiles: false,
      createFilesSuccess: {},
      createFilesError: {}
    }
  },

  [CREATE_NEW_FILES_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isCreatingFiles: false,
      hasCreatedFiles: true,
      createFilesSuccess: payload
    }
  },

  [CREATE_NEW_FILES_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isCreatingFiles: false,
      hasCreatedFiles: false,
      createFilesError: payload
    }
  },

  [GET_FILE_BY_ID]: (state) => ({
    ...state,
    isGettingFileById: true,
    hasGottenFileById: false,
    getFileByIdError: {},
    selectedFiles: {}
  }),

  [GET_FILE_BY_ID_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingFileById: false,
      hasGottenFileById: true,
      selectedFiles: payload,
      getFileByIdError: {}
    }
  },

  [GET_FILE_BY_ID_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isGettingFileById: false,
      hasGottenFileById: false,
      getFileByIdError: payload
    }
  },

  [EDIT_FILE_BY_ID]: (state) => {
    return {
      ...state,
      isEditingFileById: true,
      hasEditedFileById: false,
      editFileByIdError: {},
      editFileByIdSuccess: {},
    }
  },

  [EDIT_FILE_BY_ID_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isEditingFileById: false,
      hasEditedFileById: true,
      editFileByIdError: {},
      editFileByIdSuccess: payload,
    }
  },

  [EDIT_FILE_BY_ID_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isEditingFileById: false,
      hasEditedFileById: false,
      editFileByIdError: payload,
      editFileByIdSuccess: {},
    }
  },

  [UPLOAD_FILES]: (state) => {
    return {
      ...state,
      isUploadingFiles: true,
      hasUploadedFiles: false,
      uploadFilesError: {},
      uploadFilesSuccess: {}
    }
  },

  [UPLOAD_FILES_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isUploadingFiles: false,
      hasUploadedFiles: true,
      uploadFilesError: {},
      uploadFilesSuccess: payload
    }
  },

  [UPLOAD_FILES_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isUploadingFiles: false,
      hasUploadedFiles: false,
      uploadFilesError: payload,
      uploadFilesSuccess: {}
    }
  }

}, initialState)
