import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ContentEditor from 'components/Editor/ContentEditor'
import { Button, Select, MenuItem } from '@mui/material'
import {
  createNewContent,
  uploadContentImages,
  getContentById
} from 'store/modules/content'
import { openAlert } from 'store/modules/site'

const propTypes = {
  createNewContent: PropTypes.func,
  // isCreatingContent: PropTypes.bool,
  hasCreatedContent: PropTypes.bool,
  openAlert: PropTypes.func,
  uploadContentImages: PropTypes.func,
  hasUploadedContentImages: PropTypes.bool,
  uploadContentImagesSuccess: PropTypes.object,
  getContentById: PropTypes.func,
  match: PropTypes.object,
  selectedContent: PropTypes.object,
  hasEditedContentById: PropTypes.bool
}
const emptyValue = [{ type:'text', children: [{ text:'' }] }]
class Create extends Component {
  constructor (props) {
    super(props)
    this.state = {
      contentType: '',
      text: {},
      title: '',
      files: [],
      hasTestedUploadImages: false,
      createOrEdit: 'create',
      initialValue: emptyValue,
      access: 'private',
      published: false
    }
  }

  componentDidMount () {
    if (this.props.match.params.id) {
      this.props.getContentById(this.props.match.params.id)
    }
  }

  setValue = (value) => {
    this.setState({ text: value })
  }

  setTitleValue = (value) => {
    this.setState({ title: value })
  }

  setAccessState = (value) => {
    this.setState({ access: value })
  }

  setPublishedState = (value) => {
    this.setState({ published: value })
  }

  handleOnClick = () => {
    if (Object.keys(this.state.text).length > 0) {
      const data = {
        body: JSON.stringify(this.state.text),
        title: this.state.title,
        access: this.state.access
      }
      if (this.props.selectedContent && this.props.selectedContent.id) {
        data.id = this.props.selectedContent.id
      }
      this.props.createNewContent(data)
    }
  }

  handleOnPublish = () => {
    if (Object.keys(this.state.text).length > 0) {
      const data = {
        body: JSON.stringify(this.state.text),
        title: this.state.title,
        publishedOn: new Date(),
        access: this.state.access,
        published: !this.state.published
      }
      if (this.props.selectedContent && this.props.selectedContent.id) {
        data.id = this.props.selectedContent.id
      }
      this.props.createNewContent(data)
    }
  }

  updateAlert (data) {
    this.setState(Object.assign(this.state, data))
  }

  componentDidUpdate (prevProps) {
    if (this.props.match !== prevProps.match) {
      if (this.props.match.params.id) {
        this.props.getContentById(this.props.match.params.id)
      }
    }
    if (this.props.hasCreatedContent !== prevProps.hasCreatedContent &&
      this.props.hasCreatedContent === true) {
      this.props.openAlert({
        message: 'Content Saved!',
        severity: 'success',
      })
    }
    if (this.props.hasEditedContentById !== prevProps.hasEditedContentById &&
      this.props.hasEditedContentById === true) {
      this.props.openAlert({
        message: 'Content Saved!',
        severity: 'success',
      })
    }
    if (this.props.hasUploadedContentImages !== prevProps.hasUploadedContentImages &&
      this.props.hasUploadedContentImages === true) {
      this.props.openAlert({
        message: 'Images uploaded!',
        severity: 'success',
      })
    }
    if (this.props.selectedContent !== prevProps.selectedContent) {
      if (this.props.selectedContent.id) {
        this.setCreateOrEditState('edit')
      } else {
        this.setCreateOrEditState('create')
      }
      if (this.props.selectedContent.title) {
        this.setTitleState(this.props.selectedContent.title)
      }
      if (this.props.selectedContent.body) {
        this.setBodyState(this.props.selectedContent.body)
      }
      if (this.props.selectedContent.access) {
        this.setAccessState(this.props.selectedContent.access)
      }
      if (this.props.selectedContent.published) {
        this.setPublishedState(this.props.selectedContent.published)
      }
    }
  }

  setCreateOrEditState = (value) => {
    this.setState({ createOrEdit: value })
  }

  testUploadImages = () => {
    this.setState({ hasTestedUploadImages: true })
  }

  setTitleState = (value) => {
    this.setState({ title: value })
  }

  setBodyState = (valueJsonString) => {
    this.setState({ initialValue: JSON.parse(valueJsonString) })
  }

  render () {
    let publishLabel = 'Publish'
    if (this.state.published === true) {
      publishLabel = 'Unpublish'
    }
    return (
      <div id='content-create-container'>
        <h2>Write some content</h2>
        <ContentEditor
          setValue={this.setValue}
          setTitleValue={this.setTitleValue}
          title={this.state.title}
          uploadImages={this.props.uploadContentImages}
          images={this.props.uploadContentImagesSuccess}
          hasUploadedImages={this.props.hasUploadedContentImages}
          selectedContent={this.props.selectedContent}
          initialValue={this.state.initialValue}
        />
        <div style={{ textAlign: 'right' }}>
          <Select
            labelId='access-label'
            id='access-label'
            value={this.state.access}
            onChange={(e) => {
              this.setState({ access: e.target.value })
            }}
            style={{
              marginRight: '10px'
            }}
          >
            <MenuItem value='private'>Private</MenuItem>
            <MenuItem value='protected'>Protected</MenuItem>
            <MenuItem value='public'>Public</MenuItem>
          </Select>
          <Button variant='contained' color='primary' onClick={() => { this.handleOnClick() }}>Save</Button>
          <Button variant='contained' color='secondary'
            style={{ marginLeft: '5px' }}
            onClick={() => { this.handleOnPublish() }}>
            {publishLabel}
          </Button>
        </div>
      </div>
    )
  }
}

Create.propTypes = propTypes

const mapStateToProps = (state) => {
  const {
    isCreatingContent,
    hasCreatedContent,
    isUploadingContentImages,
    hasUploadedContentImages,
    uploadContentImagesSuccess,
    uploadContentImagesError,
    isGettingContentById,
    hasGottenContentById,
    selectedContent,
    isEditingContentById,
    hasEditedContentById
  } = state.content
  return {
    isCreatingContent,
    hasCreatedContent,
    isUploadingContentImages,
    hasUploadedContentImages,
    uploadContentImagesSuccess,
    uploadContentImagesError,
    isGettingContentById,
    hasGottenContentById,
    selectedContent,
    isEditingContentById,
    hasEditedContentById
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({
    createNewContent,
    openAlert,
    uploadContentImages,
    getContentById
  }, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Create)
