import React, { Component } from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  // file: PropTypes.object,
  // fileType: PropTypes.string,
  // handleFileUpload: PropTypes.func
  title: PropTypes.string,
  onChangeTitle: PropTypes.func
}

class TitleInput extends Component {
  constructor (props) {
    super(props)
    this.state = {
      title: ''
    }
  }

  // componentDidMount () {
  //   console.log(this.props.title)
  //   if (this.props.title) {
  //     this.setState({title: this.props.title})
  //   }
  // }

  handleOnChange = (e) => {
    // this.setState({ title: e.target.value })
    this.props.onChangeTitle(e)
  }

  render () {
    let hideStyle = {}
    if (this.props.title !== '') {
      hideStyle = { display: 'none' }
    }
    return (
      <>
        <div className='article-title-label' style={hideStyle}>Title</div>
        <textarea id='article-title' label='Title'
          rows={1}
          value={this.props.title} onChange={this.handleOnChange} />
      </>
    )
  }
}

TitleInput.propTypes = propTypes

export default TitleInput
