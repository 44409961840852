import { createAction, handleActions } from 'redux-actions'
import { sendApiRequest } from 'utils/api'

export const OPEN_ALERT = 'OPEN_ALERT'
export const openAlert = (data) => {
  return (dispatch) => {
    dispatch(createAction(OPEN_ALERT)(data))
  }
}

export const CLOSE_ALERT = 'CLOSE_ALERT'
export const closeAlert = () => {
  return (dispatch) => {
    dispatch(createAction(CLOSE_ALERT)())
  }
}

export const OPEN_BACKDROP_LOAD = 'OPEN_BACKDROP_LOAD'
export const openBackdropLoad = () => {
  return (dispatch) => {
    dispatch(createAction(OPEN_BACKDROP_LOAD)())
  }
}

export const CLOSE_BACKDROP_LOAD = 'CLOSE_BACKDROP_LOADT'
export const closeBackdropLoad = () => {
  return (dispatch) => {
    dispatch(createAction(CLOSE_BACKDROP_LOAD)())
  }
}

export const START_LOAD = 'START_LOAD'
export const startLoad = () => {
  return (dispatch) => {
    dispatch(createAction(START_LOAD)())
  }
}

export const END_LOAD = 'END_LOAD'
export const endLoad = () => {
  return (dispatch) => {
    dispatch(createAction(END_LOAD)())
  }
}

export const OPEN_LIGHTBOX = 'OPEN_LIGHTBOX'
export const openLightbox = (data) => {
  return (dispatch) => {
    dispatch(createAction(OPEN_LIGHTBOX)(data))
  }
}

export const CLOSE_LIGHTBOX = 'CLOSE_LIGHTBOX'
export const closeLightbox = () => {
  return (dispatch) => {
    dispatch(createAction(CLOSE_LIGHTBOX)())
  }
}

export const OPEN_MAP_DIALOG = 'OPEN_MAP_DIALOG'
export const openMapDialog = (data) => {
  return (dispatch) => {
    dispatch(createAction(OPEN_MAP_DIALOG)(data))
  }
}

export const CLOSE_MAP_DIALOG = 'CLOSE_MAP_DIALOG'
export const closeMapDialog = () => {
  return (dispatch) => {
    dispatch(createAction(CLOSE_MAP_DIALOG)())
  }
}

export const OPEN_EDIT_TASK_DIALOG = 'OPEN_EDIT_TASK_DIALOG'
export const openEditTaskDialog = (data) => {
  return (dispatch, getState) => {
    if (data && data.id) {
      data.token = getState().auth.token
      sendApiRequest(`tasks/${data.id}`, data).then((response) => {
        if (response.status === 'success') {
          dispatch(createAction(OPEN_EDIT_TASK_DIALOG)(response.data))
        }
      })
    } else {
      dispatch(createAction(OPEN_EDIT_TASK_DIALOG)(data))
    }
  }
}

export const CLOSE_EDIT_TASK_DIALOG = 'CLOSE_EDIT_TASK_DIALOG'
export const closeEditTaskDialog = () => {
  return (dispatch) => {
    dispatch(createAction(CLOSE_EDIT_TASK_DIALOG)())
  }
}

export const OPEN_EDIT_BULLET_DIALOG = 'OPEN_EDIT_BULLET_DIALOG'
export const openEditBulletDialog = (data) => {
  return (dispatch, getState) => {
    if (data && data.id) {
      data.token = getState().auth.token
      sendApiRequest(`bullets/${data.id}`, data).then((response) => {
        if (response.status === 'success') {
          dispatch(createAction(OPEN_EDIT_BULLET_DIALOG)(response.data))
        }
      })
    } else {
      dispatch(createAction(OPEN_EDIT_BULLET_DIALOG)(data))
    }
  }
}

export const CLOSE_EDIT_BULLET_DIALOG = 'CLOSE_EDIT_BULLET_DIALOG'
export const closeEditBulletDialog = () => {
  return (dispatch) => {
    dispatch(createAction(CLOSE_EDIT_BULLET_DIALOG)())
  }
}

export const UPDATE_CURRENT_LOCATION = 'UPDATE_CURRENT_LOCATION'
export const updateCurrentLocation = (data) => {
  return (dispatch) => {
    dispatch(createAction(UPDATE_CURRENT_LOCATION)(data))
  }
}

const initialState = {
  alertIsOpen: false,
  alertMessage: '',
  alertSeverity: 'info',
  alertCallback: {},
  backdropIsOpen: false,
  isLoading: false,
  lightboxIsOpen: false,
  lightboxImages: [],
  lightboxIndex: 0,
  mapDialogIsOpen: false,
  mapDialogMarkers: [],
  editTaskDialogIsOpen: false,
  selectedTaskToEdit: {},
  defaultTaskStatus: '',
  editBulletDialogIsOpen: false,
  selectedBulletToEdit: {},
  coords: {}
}

export default handleActions({

  [OPEN_ALERT]: (state, { payload }) => ({
    ...state,
    alertIsOpen: true,
    alertMessage: payload.message,
    alertSeverity: payload.severity,
    alertCallback: payload.callback
  }),

  [CLOSE_ALERT]: (state) => ({
    ...state,
    alertIsOpen: false,
    alertMessage: '',
    alertCallback: {}
  }),

  [OPEN_BACKDROP_LOAD]: (state) => ({
    ...state,
    backdropIsOpen: true
  }),

  [CLOSE_BACKDROP_LOAD]: (state) => ({
    ...state,
    backdropIsOpen: false
  }),

  [START_LOAD]: (state) => ({
    ...state,
    isLoading: true,
    backdropIsOpen: true
  }),

  [END_LOAD]: (state) => ({
    ...state,
    isLoading: false,
    backdropIsOpen: false
  }),

  [OPEN_LIGHTBOX]: (state, { payload }) => ({
    ...state,
    lightboxIsOpen: true,
    lightboxImages: [payload],
    lightboxIndex: 0
  }),

  [CLOSE_LIGHTBOX]: (state) => ({
    ...state,
    lightboxIsOpen: false,
    lightboxImages: [],
    lightboxIndex: 0
  }),

  [OPEN_MAP_DIALOG]: (state, { payload }) => ({
    ...state,
    mapDialogIsOpen: true,
    mapDialogMarkers: payload.markers,
  }),

  [CLOSE_MAP_DIALOG]: (state) => ({
    ...state,
    mapDialogIsOpen: false,
    mapDialogMarkers: []
  }),

  [OPEN_EDIT_TASK_DIALOG]: (state, { payload }) => {
    // console.log(payload)
    const p = {
      ...state,
      editTaskDialogIsOpen: true,
      selectedTaskToEdit: payload
    }
    if (payload.defaultStatus) {
      p.defaultTaskStatus = payload.defaultStatus
    }
    return p
  },

  [CLOSE_EDIT_TASK_DIALOG]: (state) => ({
    ...state,
    editTaskDialogIsOpen: false,
    selectedTaskToEdit: {},
    defaultTaskStatus: ''
  }),

  [OPEN_EDIT_BULLET_DIALOG]: (state, { payload }) => {
    const p = {
      ...state,
      editBulletDialogIsOpen: true,
      selectedBulletToEdit: payload
    }
    return p
  },

  [CLOSE_EDIT_BULLET_DIALOG]: (state) => ({
    ...state,
    editBulletDialogIsOpen: false,
    selectedBulletToEdit: {},
  }),

  [UPDATE_CURRENT_LOCATION]: (state, { payload }) => ({
    ...state,
    coords: payload
  }),

}, initialState)
