import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  getBulletsWithAggregate, getHabits, createBullet,
  getGoogleFitData, getWakatimeData,
  getEpics, getOwntracksData,
  getGitCommitData
  // getBulletsBillingSuccess
} from 'store/modules/bullets'
import {
  Grid, Button, Tooltip, Dialog,
} from '@mui/material'
import {
  startOfWeek,
  endOfWeek,
  // addWeeks
} from 'date-fns'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import { DateRangePicker } from 'react-date-range'
// import BlurOnIcon from '@mui/icons-material/BlurOn'
import Map from 'components/Site/Map'
import DashboardWidget from 'components/Dashboard/DashboardWidget'
import BulletIcon from 'components/Bullets/BulletIcon'
import { Link } from 'react-router-dom'
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk'
import ComputerOutlinedIcon from '@mui/icons-material/ComputerOutlined'
import { ResponsiveLine } from '@nivo/line'

const queryString = require('query-string')
const moment = extendMoment(Moment)
class BulletDayCompact extends Component {
  constructor (props) {
    super(props)
    this.state = {
      bullets: [],
      steps: 0,
      codeTime: 0,
      tasks: 0,
      habits: 0,
      bulletTotal: 0
    }
  }

  componentDidMount () {
    this.setRangeState(this.props.range)
    this.setBulletState(this.props.bullets)
  }

  componentDidUpdate (prevProps) {
    if (this.props.range !== prevProps.range) {
      this.setRangeState(this.props.range)
    }
    if (this.props.bullets !== prevProps.bullets) {
      this.setBulletState(this.props.bullets)
    }
  }

  renderBullets (bullets) {
    return bullets.map((bullet) => {
      let type = bullet.type
      if (bullet.type === 'mood') {
        if (bullet.number > 0.65) { type = 'moodGood' }
        if (bullet.number < 0.4) { type = 'moodBad' }
      }
      return (
        <Tooltip key={bullet.id} title={bullet.body} placement='top'>
          <span><BulletIcon type={type} /></span>
        </Tooltip>
      )
    })
  }

  setBulletState = (bullets) => {
    this.setState({
      bullets,
      bulletTotal: bullets.length
    })
  }

  setRangeState = (range) => {
    let steps = 0
    let codeTime = ''
    let tasks = 0
    let habits = 0
    if (range.fitData && range.fitData.aggregated &&
      range.fitData.aggregated.steps) { steps = range.fitData.aggregated.steps }
    if (range.wakatime && range.wakatime.totalTime &&
      range.wakatime.totalTime.text) {
      codeTime = range.wakatime.totalTime.text
    }
    if (range.bulletData.tasks) { tasks = range.bulletData.tasks }
    if (range.bulletData.habitCount) { habits = range.bulletData.habitCount }
    this.setState({
      steps,
      codeTime,
      tasks,
      habits
    })
  }

  renderAdditional (_range) {
    const divStyle = { display: 'inline-block', paddingLeft: '5px' }
    const iconStyle = { position: 'relative', top: '7px', width: '20px', cursor: 'pointer' }
    return (
      <div style={{ lineHeight: '1em', fontSize: '12px', marginBottom: '30px' }}>
        <div style={divStyle}>
          <Tooltip title='Bullets' placement='left'>
            <span>
              <BulletIcon type='note' style={iconStyle}
              />
            </span>
          </Tooltip>
          &nbsp; {this.state.bulletTotal}
        </div>
        <div style={divStyle}>
          <Tooltip title='Steps' placement='left'>
            <span>
              <DirectionsWalkIcon style={iconStyle}
              />
            </span>
          </Tooltip>
          &nbsp; {this.state.steps}
        </div><br />
        <div style={divStyle}>
          <Tooltip title='Coding Time' placement='left'>
            <span><ComputerOutlinedIcon style={iconStyle} /></span>
          </Tooltip>
          &nbsp; {this.state.codeTime}
        </div><br />
        <div style={divStyle}>
          <Tooltip title='Tasks' placement='left'>
            <span>
              <BulletIcon type='task' style={iconStyle}
              />
            </span>
          </Tooltip>
          &nbsp; {this.state.tasks}
        </div>
        <div style={divStyle}>
          <Tooltip title='Habits' placement='left'>
            <span>
              <BulletIcon type='habit' style={iconStyle}
              />
            </span>
          </Tooltip>
          &nbsp; {this.state.habits}
        </div>
      </div>
    )
  }

  render () {
    const headerStyle = {
      textAlign: 'center',
      paddingBottom: '5px'
    }
    return (
      <Grid item md xs={12}>
        <Grid item xs={12} style={headerStyle}>
          <Link to={`/admin/bullets?d=${new Date(this.props.date).toISOString()}`}>
            {moment(this.props.date).format('ddd DD/MM')}
          </Link>
        </Grid>
        <Grid item xs={12}>
          {this.renderAdditional(this.props.range)}
        </Grid>
        <Grid item xs={12}>
          {this.renderBullets(this.props.bullets)}
        </Grid>
      </Grid>
    )
  }
}

BulletDayCompact.propTypes = {
  range: PropTypes.object,
  bullets: PropTypes.array,
  date: PropTypes.string
}
class Week extends Component {
  constructor (props) {
    super(props)
    this.state = {
      habits: [],
      cats: {},
      dateRangeIsOpen: false,
      dateRange: {
        startDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
        endDate: endOfWeek(new Date(), { weekStartsOn: 1 }),
      },
      dateRanges: [{
        startDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
        endDate: endOfWeek(new Date(), { weekStartsOn: 1 }),
        key: 'selection'
      }],
      calRange: this.getCalRange({
        startDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
        endDate: endOfWeek(new Date(), { weekStartsOn: 1 }),
      }).calRange,
      dateLabels: this.getCalRange({
        startDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
        endDate: endOfWeek(new Date(), { weekStartsOn: 1 }),
      }).dateLabels,
      weekIndex: [],
      bullets: [],
      bulletRangeGrouped: [],
      bulletData: {},
      bulletFilter: 'all',
      googleFitData: [],
      wakatimeData: [],
      epics: {},
      selectedTab: 'tasks',
      compact: true,
      isHeatmap: true,
      isPolyline: false,
      markers: [],
      graphData: [],
      tasksEnabled: true,
      habitsEnabled: true,
      stepsEnabled: true,
      codeEnabled: true,
      bulletMarkers: []
    }
  }

  componentDidMount () {
    const parsed = queryString.parse(this.props.location.search)
    if (parsed && parsed.startDate && parsed.endDate) {
      const cal = this.getCalRange({
        startDate: parsed.startDate,
        endDate: parsed.endDate
      })
      this.setState({
        dateRange: {
          startDate: parsed.startDate,
          endDate: parsed.endDate
        },
        dateRanges: [{
          startDate: moment(parsed.startDate).toDate(),
          endDate: moment(parsed.endDate).toDate(),
          key: 'selection'
        }],
        calRange: cal.calRange,
        dateLabels: cal.dateLabels
      })
      this.props.getBulletsWithAggregate({
        dateRange: {
          startDate: parsed.startDate,
          endDate: parsed.endDate
        }
      })
      this.props.getOwntracksData({
        dateRange: {
          startDate: parsed.startDate,
          endDate: parsed.endDate
        }
      })
      this.props.getGitCommitData({
        dateRange: {
          startDate: parsed.startDate,
          endDate: parsed.endDate
        }
      })
    } else {
      this.props.getBulletsWithAggregate({ dateRange: this.state.dateRange })
      this.props.getOwntracksData({ dateRange: this.state.dateRange })
      this.props.getGitCommitData({ dateRange: this.state.dateRange })
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.bulletRangeGrouped !== prevProps.bulletRangeGrouped) {
      this.setBulletState(this.props.bulletRangeGrouped)
    }
    if (this.props.bulletData !== prevProps.bulletData) {
      this.setBulletDataState(this.props.bulletData)
    }
    if (this.props.owntracksData !== prevProps.owntracksData) {
      this.setMarkerState(this.props.owntracksData)
    }
  }

  getCalRange (dateRange) {
    const calRange = Array.from(moment()
      .range(dateRange.startDate, dateRange.endDate).by('days'))
    const dateLabels = calRange.map((m) => {
      return m.format('ddd DD/MM')
    })
    return { calRange, dateLabels }
    // this.setState({ calRange, dateLabels })
  }

  renderCompactDays (bulletRange) {
    const bullets = bulletRange.map((range, ri) => {
      const key = `c-${ri}`
      return (
        <BulletDayCompact
          key={key}
          range={range}
          bulletData={range.bulletData}
          date={range.date} bullets={range.bullets} />
      )
    })
    return (
      <div>
        <Grid key='h-1' container spacing={1}>
          {bullets}
        </Grid>
      </div>
    )
  }

  setBulletState (bulletRange) {
    const bulletMarkers = []
    bulletRange.forEach((range) => {
      range.bullets.forEach((b) => {
        if (b.lat && b.lng) {
          bulletMarkers.push({ lat: b.lat, lng: b.lng })
        }
      })
    })
    let graphData = this.state.graphData
    const tasks = bulletRange.map((r) => {
      return {
        x: moment(r.date).format('ddd DD/MM'),
        y: !isNaN(r.bulletData.tasks) && this.state.tasksEnabled ? r.bulletData.tasks : 0
      }
    })
    const habits = bulletRange.map((r) => {
      return {
        x: moment(r.date).format('ddd DD/MM'),
        y: !isNaN(r.bulletData.habitCount) && this.state.habitsEnabled ? r.bulletData.habitCount : 0
      }
    })
    const code = bulletRange.map((r) => {
      const value = this.state.codeEnabled ? parseInt(r.wakatime.cummulative_total.decimal) : 0
      return {
        x: moment(r.date).format('ddd DD/MM'),
        y: value
      }
    })
    const steps = bulletRange.map((r) => {
      let value = 0
      if (r.fitData && r.fitData.aggregated &&
        r.fitData.aggregated.steps &&
        this.state.stepsEnabled) {
        value = r.fitData.aggregated.steps / 100
      }
      return {
        x: moment(r.date).format('ddd DD/MM'),
        y: value
      }
    })
    graphData = [
      {
        id: 'tasks',
        data: tasks
      },
      {
        id: 'steps',
        data: steps
      },
      {
        id: 'code',
        data: code
      },
      {
        id: 'habits',
        data: habits
      }
    ]
    // graphData.code = code
    // graphData.tasks = tasks
    // graphData.habits = habits
    // graphData.steps = steps
    this.setState({
      bulletRangeGrouped: bulletRange,
      graphData,
      bulletMarkers
    })
  }

  setBulletDataState (bulletData) {
    this.setState({ bulletData })
  }

  setMarkerState = (owntracksData) => {
    const arr = []
    const data = owntracksData.map((d) => {
      return d.metadata
    })
    const markers = arr.concat(data).flat()
    this.setState({ markers })
  }

  handleDateChange = (date) => {
    // this.setState({ selectedDate: date })
    // this.props.getBulletsWithAggregate({ date })
  }

  renderDatePicker = () => {
    return (
      <div>
        <Button variant='contained' color='primary' onClick={() => {
          this.setState({ dateRangeIsOpen: !this.state.dateRangeIsOpen })
        }}>Date Picker
        </Button>
        <Dialog onClose={() => { this.setState({ dateRangeIsOpen: false }) }}
          open={this.state.dateRangeIsOpen}>
          <DateRangePicker
            onChange={(ranges) => {
              this.props
                .history.push( // eslint-disable-next-line
                  `/admin/bullets/week?startDate=${new Date(ranges.selection.startDate).toISOString()}&endDate=${new Date(ranges.selection.endDate).toISOString()}`)
              const r = this.getCalRange(ranges.selection)
              this.setState({
                dateRanges: [ranges.selection],
                dateRange: ranges.selection,
                calRange: r.calRange,
                dateLabels: r.dateLabels
              })
              this.props.getBulletsWithAggregate({ dateRange: ranges.selection })
              this.props.getOwntracksData({ dateRange: ranges.selection })
              this.props.getGitCommitData({ dateRange: ranges.selection })
            }}
            direction='horizontal'
            showPreview={false}
            ranges={this.state.dateRanges}
          />
        </Dialog>
      </div>
    )
  }

  renderMap () {
    const polylineStyle = { cursor: 'pointer' }
    const heatmapStyle = { cursor: 'pointer' }
    if (this.state.isPolyline) { polylineStyle.fontWeight = 'bold' }
    if (this.state.isHeatmap) { heatmapStyle.fontWeight = 'bold' }
    return (
      <div className='dashboard-widget'>
        <div className='bar-chart'>&nbsp;</div>
        <div className='dashboard-widget-content'>
          <h4 className='title'>Map</h4>
          <Map
            markers={this.state.markers.concat(this.state.bulletMarkers)}
          />
        </div>
      </div>
    )
  }

  renderGithubData (dataArray) {
    let totalCommits = 0; const commits = []
    if (dataArray) {
      dataArray.forEach((data) => {
        if (data.body) {
          totalCommits += parseInt(data.body)
        }
        if (data.metadata) {
          data.metadata.forEach((d, _idx) => {
            d.date = new Date(d.created_at)
            commits.push(d)
          })
        }
      })
    }
    const renderedCommits = commits
      .sort((a, b) => a.date - b.date)
      .map((d, idx) => {
        let truncatedName = d.name
        if (d.name.split('/') && d.name.split('/')[1]) {
          truncatedName = d.name.split('/')[1]
        }
        return (
          <div key={idx}
            style={{ fontSize: '10px', cursor: 'pointer' }}>
            <a href={d.url}>
              <Tooltip title={moment(d.created_at).format('DD/MM/YYYY h:mm a')} placement='top'>
                <span style={{ opacity: '0.5' }}>
                  [{truncatedName}]&nbsp;
                </span>
              </Tooltip>
              {d.message}
              <span style={{ opacity: '0.5' }}>
                &nbsp;{moment(d.created_at)
                  .format('DD/MM/YYYY h:mm a')}
              </span>
            </a><br />
          </div>
        )
      })
    const body = (
      <div>
        <span>{totalCommits} commits</span>
        {renderedCommits}
      </div>
    )
    return (
      <DashboardWidget
        title='Git'
        body={body}
        subtitle='Retrieved from Github & Bitbucket'
        amount={totalCommits}
      />
    )
  }

  setGraphEnabledState = (d) => {
    const enabledKey = `${d.id}Enabled`
    const val = this.state[enabledKey]
    const state = this.state
    state[enabledKey] = !val
    this.setState(state, () => {
      this.setBulletState(this.props.bulletRangeGrouped)
    })
  }

  renderGraph () {
    return (
      <div style={{ height: '500px' }}>
        <ResponsiveLine
          data={this.state.graphData}
          margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: false,
            reverse: false
          }}
          yFormat=' >-.2f'
          // curve='natural'
          axisTop={null}
          axisRight={null}
          axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'date',
            legendOffset: 36,
            legendPosition: 'middle'
          }}
          axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'count',
            legendOffset: -40,
            legendPosition: 'middle'
          }}
          pointSize={5}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={1}
          pointBorderColor={{ from: 'serieColor' }}
          pointLabelYOffset={-12}
          useMesh
          enablePoints
          enableGridX={false}
          enableGridY={false}
          legends={[
            {
              onClick: (d) => {
                this.setGraphEnabledState(d)
              },
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 100,
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: 'left-to-right',
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: 'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1
                  }
                }
              ]
            }
          ]}
        />
      </div>
    )
  }

  render () {
    const bullets = this.renderCompactDays(this.state.bulletRangeGrouped)
    return (
      <div id='bullets-list-container'>
        <h2>Review</h2>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <h3>Week:&nbsp;
              {moment(this.state.dateRange.startDate).format('DD/MM/YYYY')} -&nbsp;
              {moment(this.state.dateRange.endDate).format('DD/MM/YYYY')}
            </h3>
            {this.renderDatePicker()}<br /><br />
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {this.renderGraph(this.state.bulletRangeGrouped)}
                </Grid>
                <Grid item xs={12}>
                  {bullets}
                </Grid>
                <Grid item xs={12} md={6}>
                  {this.renderMap()}
                </Grid>
                <Grid item xs={12} md={6}>
                  {this.renderGithubData(this.props.gitCommitData)}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const propTypes = {
  getBulletsWithAggregate: PropTypes.func,
  // bullets: PropTypes.array,
  // hasCreatedBullet: PropTypes.bool,
  // habits: PropTypes.array,
  // getHabits: PropTypes.func,
  // createBullet: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
  // openLightbox: PropTypes.func,
  // closeMapDialog: PropTypes.func,
  // openMapDialog: PropTypes.func,
  bulletData: PropTypes.object,
  bulletRangeGrouped: PropTypes.array,
  owntracksData: PropTypes.array,
  getOwntracksData: PropTypes.func,
  getGitCommitData: PropTypes.func,
  gitCommitData: PropTypes.array,
  // googleFitData: PropTypes.array,
  // getGoogleFitData: PropTypes.func
  // openLightbox: PropTypes.func
}

Week.propTypes = propTypes

const mapStateToProps = (state) => {
  const {
    isGettingBullets,
    hasGottenBullets,
    bullets,
    bulletRangeGrouped,
    bulletData,
    hasCreatedBullet,
    isGettingHabits,
    hasGottenHabits,
    habits,
    googleFitData,
    isGettingWakatimeData,
    hasGottenWakatimeData,
    wakatimeData,
    epics,
    isGettingEpics,
    hasGottenEpics,
    isGettingOwntracksData,
    owntracksData,
    gitCommitData
  } = state.bullets
  return {
    isGettingBullets,
    hasGottenBullets,
    bullets,
    bulletRangeGrouped,
    bulletData,
    hasCreatedBullet,
    isGettingHabits,
    hasGottenHabits,
    habits,
    googleFitData,
    isGettingWakatimeData,
    hasGottenWakatimeData,
    wakatimeData,
    epics,
    isGettingEpics,
    hasGottenEpics,
    isGettingOwntracksData,
    owntracksData,
    gitCommitData
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({
    getBulletsWithAggregate,
    getHabits,
    createBullet,
    // openLightbox,
    getGoogleFitData,
    getWakatimeData,
    getEpics,
    getOwntracksData,
    getGitCommitData
  }, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Week)
