import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Button, TextField,
  Dialog, DialogContent,
  DialogTitle, Select, MenuItem, Rating
} from '@mui/material'
import { DateTimePicker } from '@mui/lab'
import { Form, Formik } from 'formik'
import { FormSubmitButton, FormTextField } from 'components/Site/Forms/FormFields'
import MoodRating from 'components/Bullets/MoodRating'
import {
  createBullet
} from 'store/modules/bullets'
import { openEditTaskDialog, closeEditBulletDialog } from 'store/modules/site'

const initial = {
  id: 0,
  body: '',
  description: '',
  createdOn: new Date(),
  type: '',
  rating: 0,
  mood: 0
}
const BulletEditForm = ({ bullet, handleClose }) => {
  const dispatch = useDispatch()
  const [initialFormState, setInitialFormState] = useState(initial)
  const [ratingVal, setRatingVal] = useState(bullet.rating || 0)
  const [moodVal, setMoodVal] = useState(bullet.mood || 0)
  const [selectedDate, setSelectedDate] = useState(bullet.createdOn || new Date())
  const [selectedType, setSelectedType] = useState(bullet.type || 'event')
  useEffect(() => {
    const data = { ...bullet }
    if (!bullet.description) { data.description = '' }
    setInitialFormState(data)
  }, [bullet])

  const handleDelete = () => {
    dispatch(createBullet({
      id: bullet.id,
      deleted: true,
      modifiedOn: new Date()
    }))
  }

  const handleEdit = (values, actions) => {
    // actions.setSubmitting(true)
    const bulletObj = {
      id: bullet.id,
      body: values.body,
      createdOn: selectedDate,
      modifiedOn: new Date(),
      type: selectedType,
      mood: moodVal
    }
    if (values.description !== '') {
      bulletObj.description = values.description
    }
    if (ratingVal !== 0) {
      bulletObj.rating = (ratingVal / 5 * 100)
    } else { bulletObj.rating = ratingVal }
    dispatch(createBullet(bulletObj))
    actions.setSubmitting(false)
    // this.props.createBullet(bulletObj)
  }

  const handleDateChange = (date) => {
    setSelectedDate(date)
  }

  return (
    <>
      <Formik
        onSubmit={handleEdit}
        enableReinitialize
        initialValues={initialFormState}>
        <Form>
          <DateTimePicker
            renderInput={(props) => <TextField {...props} />}
            disableFuture
            id='date-picker-inline'
            label='Date'
            value={selectedDate}
            onChange={handleDateChange}
          />
          <br />
          <Select
            labelId='bullet-type-edit-label'
            id='bullet-type-edit'
            value={selectedType}
            onChange={(e) => {
              setSelectedType(e.target.value)
            }}
          >
            <MenuItem value='note'>Note</MenuItem>
            <MenuItem value='event'>Event</MenuItem>
            <MenuItem value='habit'>Habit</MenuItem>
            <MenuItem value='task'>Task</MenuItem>
            <MenuItem value='mood'>Mood</MenuItem>
          </Select>
          <FormTextField sx={{ margin: '5px 0' }} label='Body' name='body' fullWidth multiline />
          <FormTextField sx={{ margin: '5px 0' }}
            label='Description' name='description' fullWidth multiline minRows={3} />
          <Rating
            name='rating'
            value={ratingVal}
            precision={0.5}
            onChange={(_event, newValue) => {
              setRatingVal(newValue)
            }} />
          <div style={{ float: 'right' }}>
            <MoodRating
              value={moodVal}
              onChange={(_event, newValue) => {
                let mood = newValue
                switch (newValue) {
                  case 1: { mood = 1; break }
                  case 2: { mood = 25; break }
                  case 3: { mood = 50; break }
                  case 4: { mood = 75; break }
                  case 5: { mood = 100; break }
                  default: { mood = 0 }
                }
                setMoodVal(mood)
              }}
            />
          </div>
          <FormSubmitButton>Edit</FormSubmitButton>
          {bullet.type === 'task' && (
            <div style={{ float: 'left' }}>
              <Button
                onClick={() => {
                  dispatch(openEditTaskDialog({
                    id: bullet.taskID
                  }))
                }}
                color='primary'>
                Edit Task
              </Button>
            </div>
          )}
          <div style={{ float: 'right' }}>
            <Button onClick={() => handleDelete()} color='secondary'>
              Delete
            </Button>
            <Button onClick={() => handleClose()} color='primary'>
              Cancel
            </Button>
          </div>
        </Form>
      </Formik>
    </>
  )
}

BulletEditForm.propTypes = {
  bullet: PropTypes.object,
  handleClose: PropTypes.func
}

const BulletEditDialog = () => {
  const dispatch = useDispatch()
  const {
    open,
    bullet,
    hasCreatedBullet
  } = useSelector((state) => {
    return {
      open: state.site.editBulletDialogIsOpen,
      bullet: state.site.selectedBulletToEdit,
      hasCreatedBullet: state.bullets.hasCreatedBullet
    }
  })

  const handleClose = () => {
    dispatch(closeEditBulletDialog())
  }
  useEffect(() => {
    if (hasCreatedBullet === true) { dispatch(closeEditBulletDialog()) }
  }, [hasCreatedBullet])

  return (
    <>
      <Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>Edit Bullet</DialogTitle>
        <DialogContent>
          <BulletEditForm bullet={bullet} handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </>
  )
}

BulletEditDialog.propTypes = {}

export default BulletEditDialog
