import { createAction, handleActions } from 'redux-actions'
import { sendApiRequest } from 'utils/api'
// import { sessionService } from 'redux-react-session'
import { startLoad, endLoad } from 'store/modules/site'

export const GET_CONTENT = 'GET_CONTENT'
export const getContent = (opts = {}) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_CONTENT)())
    // opts.filter = {}
    const token = getState().auth.token
    opts = {
      filter: {
        orderBy: [{ id: 'desc' }]
      },
    }
    sendApiRequest('contents', {
      token
    }, 'get', opts).then((response) => {
      if (response.status === 'failure') {
        dispatch(getContentError(response))
      }
      if (response.status === 'success') {
        dispatch(getContentSuccess(response.data))
      }
    })
  }
}

export const GET_CONTENT_SUCCESS = 'GET_CONTENT_SUCCESS'
export const getContentSuccess = createAction(GET_CONTENT_SUCCESS)

export const GET_CONTENT_ERROR = 'GET_CONTENT_ERROR'
export const getContentError = createAction(GET_CONTENT_ERROR)

export const GET_CONTENT_PUBLIC = 'GET_CONTENT_PUBLIC'
export const getContentPublic = (opts = {}) => {
  return (dispatch, getState) => {
    dispatch(startLoad())
    dispatch(createAction(GET_CONTENT_PUBLIC)())
    opts = {
      filter: {
        orderBy: [{ createdOn: 'desc' }],
        where: {
          AND: [
            { deleted: false },
            { published: true },
            { access: 'public' }
          ]
        }
      },
    }
    sendApiRequest('contents/public', {}, 'get', opts).then((response) => {
      dispatch(endLoad())
      if (response.status === 'failure') {
        dispatch(getContentPublicError(response))
      }
      if (response.status === 'success') {
        dispatch(getContentPublicSuccess(response.data))
      }
    })
  }
}

export const GET_CONTENT_PUBLIC_SUCCESS = 'GET_CONTENT_PUBLIC_SUCCESS'
export const getContentPublicSuccess = createAction(GET_CONTENT_PUBLIC_SUCCESS)

export const GET_CONTENT_PUBLIC_ERROR = 'GET_CONTENT_PUBLIC_ERROR'
export const getContentPublicError = createAction(GET_CONTENT_PUBLIC_ERROR)

export const GET_CONTENT_BY_ID = 'GET_CONTENT_BY_ID'
export const getContentById = (id) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_CONTENT_BY_ID)())
    sendApiRequest(`contents/${id}`, {
      token: getState().auth.token
    }, 'get').then((response) => {
      if (response.status === 'failure') {
        dispatch(getContentByIdError(response))
      }
      if (response.status === 'success') {
        dispatch(getContentByIdSuccess(response.data))
      }
    })
  }
}

export const GET_CONTENT_BY_ID_SUCCESS = 'GET_CONTENT_BY_ID_SUCCESS'
export const getContentByIdSuccess = createAction(GET_CONTENT_BY_ID_SUCCESS)

export const GET_CONTENT_BY_ID_ERROR = 'GET_CONTENT_ERROR'
export const getContentByIdError = createAction(GET_CONTENT_BY_ID_ERROR)

export const GET_PUBLIC_CONTENT_BY_ID = 'GET_PUBLIC_CONTENT_BY_ID'
export const getPublicContentById = (id) => {
  return (dispatch) => {
    dispatch(createAction(GET_PUBLIC_CONTENT_BY_ID)())
    dispatch(startLoad())
    sendApiRequest(`contents/public/${id}`, {}, 'get').then((response) => {
      dispatch(endLoad())
      if (response.status === 'failure') {
        dispatch(getPublicContentByIdError(response))
      }
      if (response.status === 'success') {
        dispatch(getPublicContentByIdSuccess(response.data))
      }
    })
  }
}

export const GET_PUBLIC_CONTENT_BY_ID_SUCCESS = 'GET_PUBLIC_CONTENT_BY_ID_SUCCESS'
export const getPublicContentByIdSuccess = createAction(GET_PUBLIC_CONTENT_BY_ID_SUCCESS)

export const GET_PUBLIC_CONTENT_BY_ID_ERROR = 'GET_PUBLIC_CONTENT_ERROR'
export const getPublicContentByIdError = createAction(GET_PUBLIC_CONTENT_BY_ID_ERROR)

export const ADMIN_SELECT_CONTENT = 'ADMIN_SELECT_CONTENT'
export const adminSelectContent = (contentId) => {
  return (dispatch) => {
    // check for admin role from the server here - put it in api utils
    dispatch(createAction(ADMIN_SELECT_CONTENT)(contentId))
  }
}

export const DELETE_CONTENT_BY_ID = 'DELETE_CONTENT_BY_ID'
export const deleteContentById = (id) => {
  return (dispatch, getState) => {
    dispatch(createAction(DELETE_CONTENT_BY_ID)())
    sendApiRequest(`contents/${id}`, {
      token: getState().auth.token
    }, 'delete').then((response) => {
      if (response.status === 'failure') {
        dispatch(deleteContentByIdError(response.data))
      }
      if (response.status === 'success') {
        dispatch(deleteContentByIdSuccess(response.data))
        dispatch(getContent())
      }
    })
  }
}

export const DELETE_CONTENT_BY_ID_SUCCESS = 'DELETE_CONTENT_BY_ID_SUCCESS'
export const deleteContentByIdSuccess = createAction(DELETE_CONTENT_BY_ID_SUCCESS)

export const DELETE_CONTENT_BY_ID_ERROR = 'DELETE_CONTENT_BY_ID_ERROR'
export const deleteContentByIdError = createAction(DELETE_CONTENT_BY_ID_ERROR)

export const DELETE_CONTENT_BY_ID_RESET = 'DELETE_CONTENT_BY_ID_RESET'
export const deleteContentByIdReset = createAction(DELETE_CONTENT_BY_ID_RESET)

export const OPEN_CONTENT_DIALOG = 'OPEN_CONTENT_DIALOG'
export const openContentDialog = createAction(OPEN_CONTENT_DIALOG)

export const CLOSE_CONTENT_DIALOG = 'CLOSE_CONTENT_DIALOG'
export const closeContentDialog = createAction(CLOSE_CONTENT_DIALOG)

export const CREATE_NEW_CONTENT = 'CREATE_NEW_CONTENT'
export const createNewContent = (data) => {
  return (dispatch, getState) => {
    dispatch(createAction(CREATE_NEW_CONTENT)())
    dispatch(startLoad())
    const contentData = data
    data.token = getState().auth.token
    let url = 'contents'
    let type = 'post'
    if (data.id) {
      url = `contents/${data.id}`
      type = 'patch'
    }
    sendApiRequest(url, contentData, type).then((response) => {
      console.log(response)
      dispatch(endLoad())
      if (response.status === 'failure') {
        dispatch(createNewContentError(response))
      }
      if (response.status === 'success') {
        dispatch(createNewContentSuccess(response.data))
        dispatch(getContent())
      }
    })
  }
}

export const CREATE_NEW_CONTENT_SUCCESS = 'CREATE_NEW_CONTENT_SUCCESS'
export const createNewContentSuccess = createAction(CREATE_NEW_CONTENT_SUCCESS)

export const CREATE_NEW_CONTENT_ERROR = 'CREATE_NEW_CONTENT_ERROR'
export const createNewContentError = createAction(CREATE_NEW_CONTENT_ERROR)

export const RESET_CONTENT_FORM = 'RESET_CONTENT_FORM'
export const resetContentForm = createAction(RESET_CONTENT_FORM)

export const EDIT_CONTENT_BY_ID = 'EDIT_CONTENT_BY_ID'
export const editContentById = (data) => {
  return (dispatch, getState) => {
    dispatch(createAction(EDIT_CONTENT_BY_ID)())
    const contentData = data
    data.token = getState().auth.token
    sendApiRequest(`contents/${data.id}`, contentData, 'patch').then((response) => {
      if (response.status === 'failure') {
        dispatch(editContentByIdError(response))
      }
      if (response.status === 'success') {
        dispatch(editContentByIdSuccess(response.data))
        dispatch(getContentById(data.id))
      }
    })
  }
}

export const EDIT_CONTENT_BY_ID_SUCCESS = 'EDIT_CONTENT_BY_ID_SUCCESS'
export const editContentByIdSuccess = createAction(EDIT_CONTENT_BY_ID_SUCCESS)

export const EDIT_CONTENT_BY_ID_ERROR = 'CREATE_NEW_CONTENT_ERROR'
export const editContentByIdError = createAction(EDIT_CONTENT_BY_ID_ERROR)

export const UPLOAD_CONTENT_IMAGES = 'UPLOAD_CONTENT_IMAGES'
export const uploadContentImages = (files, data = {}) => {
  return (dispatch, getState) => {
    dispatch(createAction(UPLOAD_CONTENT_IMAGES)())
    dispatch(startLoad())
    const options = { token: getState().auth.token }
    const contentData = new FormData()
    for (var i = 0; i < files.length; i++) {
      contentData.append(files[i].name, files[i])
    }
    for (const [key, value] of Object.entries(data)) {
      contentData.append(key, value)
    }
    sendApiRequest('files', contentData, 'post', options).then((response) => {
      dispatch(endLoad())
      if (response.status === 'failure') {
        dispatch(uploadContentImagesError(response))
      }
      if (response.status === 'success') {
        dispatch(uploadContentImagesSuccess(response.data))
      }
    })
  }
}

export const UPLOAD_CONTENT_IMAGES_SUCCESS = 'UPLOAD_CONTENT_IMAGES_SUCCESS'
export const uploadContentImagesSuccess = createAction(UPLOAD_CONTENT_IMAGES_SUCCESS)

export const UPLOAD_CONTENT_IMAGES_ERROR = 'UPLOAD_CONTENT_IMAGES_ERROR'
export const uploadContentImagesError = createAction(UPLOAD_CONTENT_IMAGES_ERROR)

export const GET_FRIENDO_PHOTOS = 'GET_FRIENDO_PHOTOS'
export const getFriendoPhotos = (opts = {}) => {
  return (dispatch, getState) => {
    dispatch(createAction(GET_CONTENT)())
    // opts.filter = {}
    dispatch(startLoad())
    const token = getState().auth.token
    sendApiRequest('friendo/photos', {
      token
    }, 'get', opts).then((response) => {
      dispatch(endLoad())
      if (response.status === 'failure') {
        dispatch(getFriendoPhotosError(response))
      }
      if (response.status === 'success') {
        dispatch(getFriendoPhotosSuccess(response.data))
      }
    })
  }
}

export const GET_FRIENDO_PHOTOS_SUCCESS = 'GET_FRIENDO_PHOTOS_SUCCESS'
export const getFriendoPhotosSuccess = createAction(GET_FRIENDO_PHOTOS_SUCCESS)

export const GET_FRIENDO_PHOTOS_ERROR = 'GET_FRIENDO_PHOTOS_ERROR'
export const getFriendoPhotosError = createAction(GET_FRIENDO_PHOTOS_ERROR)

const initialState = {
  contents: [],
  isGettingContent: false,
  hasGottenContent: false,
  getContentError: {},
  adminSelectedContent: 0,
  isDeletingContent: false,
  hasDeletedContent: false,
  deleteContentError: {},
  deleteContentSuccess: {},
  isEditingContentById: false,
  hasEditedContentById: false,
  editContentByIdError: {},
  editContentByIdSuccess: {},
  isCreatingContent: false,
  hasCreatedContent: false,
  createContentSuccess: {},
  createContentError: {},
  isGettingContentById: false,
  hasGottenContentById: false,
  selectedContent: {},
  getContentByIdError: {},
  isUploadingContentImages: false,
  hasUploadedContentImages: false,
  uploadContentImagesError: {},
  uploadContentImagesSuccess: {},
  isGettingPublicContent: false,
  hasGottenPublicContent: false,
  publicContents: [],
  isGettingPublicContentById: false,
  hasGottenPublicContentById: false,
  selectedPublicContent: {},
  isGettingFriendoPhotos: false,
  hasGottenFriendoPhotos: false,
  friendoPhotos: {}
}

export default handleActions({

  [GET_CONTENT]: (state) => ({
    ...state,
    isGettingContent: true,
    hasGottenContent: false,
    hasUploadedImages: false
  }),

  [GET_CONTENT_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingContent: false,
      hasGottenContent: true,
      contents: payload,
      hasUploadedImages: false
    }
  },

  [GET_CONTENT_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isGettingContent: true,
      hasGottenContent: false,
      getContentError: payload
    }
  },

  [GET_CONTENT_PUBLIC]: (state) => ({
    ...state,
    isGettingPublicContent: true,
    hasGottenPublicContent: false,
    publicContents: []
  }),

  [GET_CONTENT_PUBLIC_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingPublicContent: false,
      hasGottenPublicContent: true,
      publicContents: payload
    }
  },

  [ADMIN_SELECT_CONTENT]: (state, { payload }) => {
    return {
      ...state,
      adminSelectedContent: payload
    }
  },

  [OPEN_CONTENT_DIALOG]: (state, { payload }) => {
    return {
      ...state,
      contentDialogIsOpen: true,
      contentDialogRole: payload
    }
  },

  [CLOSE_CONTENT_DIALOG]: (state) => {
    return {
      ...state,
      contentDialogIsOpen: false,
      isCreatingContent: false,
      hasCreatedContent: false,
      createContentSuccess: {},
      createContentError: {}
    }
  },

  [DELETE_CONTENT_BY_ID]: (state) => ({
    ...state,
    isDeletingContent: true,
    hasDeletedContent: false
  }),

  [DELETE_CONTENT_BY_ID_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isDeletingContent: false,
      hasDeletedContent: true,
      deleteContentSuccess: payload
    }
  },

  [DELETE_CONTENT_BY_ID_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isDeletingContent: false,
      hasDeletedContent: false,
      deleteContentError: payload
    }
  },

  [DELETE_CONTENT_BY_ID_RESET]: (state, { payload }) => {
    return {
      ...state,
      isDeletingContent: false,
      hasDeletedContent: false,
      deleteContentError: {},
      deleteContentSuccess: {}
    }
  },

  [OPEN_CONTENT_DIALOG]: (state, { payload }) => {
    return {
      ...state,
      contentDialogIsOpen: true,
      contentDialogRole: payload
    }
  },

  [CLOSE_CONTENT_DIALOG]: (state) => {
    return {
      ...state,
      contentDialogIsOpen: false,
      isCreatingContent: false,
      hasCreatedContent: false,
      createContentSuccess: {},
      createContentError: {}
    }
  },

  [RESET_CONTENT_FORM]: (state) => {
    return {
      ...state,
      isCreatingContent: false,
      hasCreatedContent: false,
      createContentSuccess: {},
      createContentError: {}
    }
  },

  [CREATE_NEW_CONTENT]: (state) => {
    return {
      ...state,
      isCreatingContent: true,
      hasCreatedContent: false,
      createContentSuccess: {},
      createContentError: {}
    }
  },

  [CREATE_NEW_CONTENT_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isCreatingContent: false,
      hasCreatedContent: true,
      createContentSuccess: payload
    }
  },

  [CREATE_NEW_CONTENT_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isCreatingContent: false,
      hasCreatedContent: false,
      createContentError: payload
    }
  },

  [GET_CONTENT_BY_ID]: (state) => ({
    ...state,
    isGettingContentById: true,
    hasGottenContentById: false,
    getContentByIdError: {},
    selectedContent: {}
  }),

  [GET_CONTENT_BY_ID_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingContentById: false,
      hasGottenContentById: true,
      selectedContent: payload,
      getContentByIdError: {}
    }
  },

  [GET_PUBLIC_CONTENT_BY_ID]: (state) => ({
    ...state,
    isGettingPublicContentById: true,
    hasGottenPublicContentById: false,
    selectedPublicContent: {}
  }),

  [GET_PUBLIC_CONTENT_BY_ID_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingPublicContentById: false,
      hasGottenPublicContentById: true,
      selectedPublicContent: payload
    }
  },

  [GET_CONTENT_BY_ID_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isGettingContentById: false,
      hasGottenContentById: false,
      getContentByIdError: payload
    }
  },

  [EDIT_CONTENT_BY_ID]: (state) => {
    return {
      ...state,
      isEditingContentById: true,
      hasEditedContentById: false,
      editContentByIdError: {},
      editContentByIdSuccess: {},
    }
  },

  [EDIT_CONTENT_BY_ID_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isEditingContentById: false,
      hasEditedContentById: true,
      editContentByIdError: {},
      editContentByIdSuccess: payload,
    }
  },

  [EDIT_CONTENT_BY_ID_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isEditingContentById: false,
      hasEditedContentById: false,
      editContentByIdError: payload,
      editContentByIdSuccess: {},
    }
  },

  [UPLOAD_CONTENT_IMAGES]: (state) => {
    return {
      ...state,
      isUploadingContentImages: true,
      hasUploadedContentImages: false,
      uploadContentImagesError: {},
      uploadContentImagesSuccess: {}
    }
  },

  [UPLOAD_CONTENT_IMAGES_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isUploadingContentImages: false,
      hasUploadedContentImages: true,
      uploadContentImagesError: {},
      uploadContentImagesSuccess: payload
    }
  },

  [UPLOAD_CONTENT_IMAGES_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isUploadingContentImages: false,
      hasUploadedContentImages: false,
      uploadContentImagesError: payload,
      uploadContentImagesSuccess: {}
    }
  },

  [GET_FRIENDO_PHOTOS]: (state) => ({
    ...state,
    isGettingFriendoPhotos: true,
    hasGottenFriendoPhotos: false,
    friendoPhotos: {}
  }),

  [GET_FRIENDO_PHOTOS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isGettingFriendoPhotos: false,
      hasGottenFriendoPhotos: true,
      friendoPhotos: payload
    }
  },

}, initialState)
