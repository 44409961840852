import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'

const propTypes = {
  callback: PropTypes.func,
  multiple: PropTypes.bool
}

class UploadFileButton extends Component {
  constructor (props) {
    super(props)
    this.state = {
      fileUploaded: false,
      files: []
    }
    this.fileUpload = {}
  }

  handleOpenFileDialog = () => {
    this.fileUpload.click()
  }

  handleFileChange = (e) => {
    if (e.target.files && e.target.files.length) {
      this.setState({
        fileUploaded: true,
        files: e.target.files
      })
      this.props.callback(e.target.files)
    }
  }

  render () {
    let input = (
      <input
        multiple
        style={{ display: 'none' }}
        type='file' label='Upload'
        ref={(ref) => { this.fileUpload = ref }}
        onChange={this.handleFileChange}
      />
    )
    if (this.props.multiple === false) {
      input = (<input
        style={{ display: 'none' }}
        type='file' label='Upload'
        ref={(ref) => { this.fileUpload = ref }}
        onChange={this.handleFileChange}
      />
      )
    }
    return (
      <div style={{ cursor: 'pointer' }}>
        {input}
        <Button
          variant='contained' color='primary'
          onClick={this.handleOpenFileDialog}
          >Upload File
        </Button>
      </div>
    )
  }
}

UploadFileButton.propTypes = propTypes

export default UploadFileButton
